<template>
  <div class="channelSuccess" v-if="channelList.length > 0">
    <div class="cardItem" v-for="(item,index) in channelList"
         :key="index"
         @click="lookInfo(item)"
    >
    <!-- ../../assets/images/yuque.png -->
      <img :src="handlerImg(item.channel)">
      <span class="channel">{{channel(item.channel)}}</span>
      <span class="channelDes">{{item.name}}</span>
    </div>
  </div>
  <div class="not-connected" v-else>
    <img src="../../assets/images/notConnected.png">
    <span class="tips">{{$t('channel.channelSuccess.channelSuccess.notConnectedText')}}</span>
    <span class="insertBtn" @click="toInsert">{{$t('channel.channelSuccess.channelSuccess.insertButtonText')}}</span>
  </div>
</template>

<script>
import { requestUrl } from "@/http/requestUrl";

export default {
  name: "channelSuccess",
  data(){
    return{
      channelList:[]
    }
  },
  props:{
    activeNav:{
      type:String,
      default:"channelAccess"
    }
  },
  methods:{
    getChannel(){
      let url = requestUrl.channel.getAccessChannel;
      let corpId = sessionStorage.getItem('_corpId');
      url += '?corpId=' + corpId
      this.$http.get(url).then(res => {
        if (res.data.code == 0 && res.data.data){
          this.channelList = res.data.data;
        }
      })
    },
    channel(channel){
      switch (channel)
      {
        case 1:
          return this.$t('channel.channelSuccess.channelNames.' + channel);
        case 2:
          return this.$t('channel.channelSuccess.channelNames.' + channel);
        case 3:
          return this.$t('channel.channelSuccess.channelNames.' + channel);
        case 4:
          return this.$t('channel.channelSuccess.channelNames.' + channel);
        case 5:
          return this.$t('channel.channelSuccess.channelNames.' + channel);
        case 6:
          return this.$t('channel.channelSuccess.channelNames.' + channel);
        case 7:
          return this.$t('channel.channelSuccess.channelNames.' + channel);
        case 8:
          return this.$t('channel.channelSuccess.channelNames.' + channel);
      }
    },
    lookInfo(item){
      this.$router.push({
        path:"/accessInformation",
        query:{
          id:item.id,
          channel:item.channel
        }
      })
    },
    toInsert(){
      this.$emit('update:activeNav','channelAccess')
      // this.$router.push({
      //   path:"/accessBind"
      // })
    },
    handlerImg(channel){
      switch (channel){
        case 1:
          return this.$t('channel.channelSuccess.channelNames.' + channel);
        case 2:
          return this.$t('channel.channelSuccess.channelNames.' + channel);
        case 3:
          return this.$t('channel.channelSuccess.channelNames.' + channel);
        case 4:
          return this.$t('channel.channelSuccess.channelNames.' + channel);
        case 5:
           return require('../../assets/images/feishu.png');
        case 6:
          return this.$t('channel.channelSuccess.channelNames.' + channel);
        case 7:
          return this.$t('channel.channelSuccess.channelNames.' + channel);
        case 8:
          return require('../../assets/images/yuque.png');
      }
    },
  },
  mounted() {
    this.getChannel();
  }
};
</script>

<style scoped lang="less">
.channelSuccess{
  padding: 20px 0;
  display: flex;
  //align-items: center;
  flex-wrap: wrap;
  max-height: calc(100% - 74px);
  overflow-y: scroll;
  .cardItem{
    width: 165px;
    height: 145px;
    background: #FFFFFF;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 0px 18px 0px rgba(29,55,129,0.07);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    margin-right: 20px;
    margin-bottom: 20px;
    .channel{
      margin: 16px 0;
    }
  }
}
.not-connected{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 156px);
  background-color: white;
  margin-top:16px;
  .tips{
    margin: 20px 0;
  }
  .insertBtn{
    width: 90px;
    height: 32px;
    background: #366aff;
    color: #FFFFFF;
    border: 1px solid #E0E6F7;
    border-radius: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
</style>
<template>
    <div id="fieldConfigure">
        <div class="candidateSet">
            <div class="candidate-header">
                <span>{{$t('contractModule.templateFileConfig.candidateSet')}}</span>
            </div>
            <div class="candidate-content">
                <div class="candidate-form-item">
                    <div class="label-text">{{$t('contractModule.templateFileConfig.candidateText')}}</div>
                    <div class="candidate-form-item-query" v-for="(query, index) in currentCandidateSetConfig.query"
                        :key="index">
                        <el-input v-model="currentCandidateSetConfig.query[index]" :placeholder="$t('contractModule.templateFileConfig.placeholder')"
                            @change="updateCandidateConfigure"></el-input>
                        <span class="delete-button" v-if="index > 0" @click="removeInput(index)"><i
                                class="iconfont guoran-tongyichicun-16-09-shanchu2"></i></span>
                    </div>
                    <el-button type="primary" size="mini" @click="addInput">{{$t('contractModule.templateFileConfig.addInput')}}</el-button>
                </div>
                <div class="candidate-form-item">
                    <div class="label-text">{{$t('contractModule.templateFileConfig.rearrange')}}</div>
                    <el-switch v-model="currentCandidateSetConfig.shuffle" active-color="#13ce66"
                        inactive-color="#ff4949" @change="updateCandidateConfigure"></el-switch>
                </div>
                <div class="candidate-form-item">
                    <div class="label-text">{{$t('contractModule.templateFileConfig.threshold')}}</div>
                    <div class="candidate-form-item-content">
                        <el-slider v-model="currentCandidateSetConfig.threshold" @change="updateCandidateConfigure"
                            max="100" :marks="candidateSetThresholdMarks"></el-slider>
                    </div>
                </div>
                <div class="candidate-form-item">
                    <div class="label-text">{{$t('contractModule.templateFileConfig.count')}}</div>
                    <div class="candidate-form-item-content">
                        <el-slider v-model="currentCandidateSetConfig.count" max="10" :marks="candidateSetNumberMarks"
                            @change="updateCandidateConfigure"></el-slider>
                    </div>
                </div>
            </div>
        </div>
        <div class="modelSet">
            <div class="modelSet-header">
                <span>{{$t('contractModule.templateFileConfig.modelSet')}}</span>
            </div>
            <div class="modelSet-content">
                <div class="modelSet-form-item">
                    <div class="label-text">{{$t('contractModule.templateFileConfig.model')}}</div>
                    <div class="modelSet-form-item-content">
                        GPT-4
                    </div>
                </div>
                <div class="modelSet-form-item">
                    <div class="label-text">{{$t('contractModule.templateFileConfig.temperature')}}</div>
                    <div class="modelSet-form-item-content" v-if="currentModelConfig">
                        <el-slider v-model="currentModelConfig.temperature" @change="updateModelConfigure" max="1"
                            :step="0.01" :marks="modelConfigTemperatureMarks"></el-slider>
                    </div>
                </div>
                <div class="modelSet-prompt-header">
                    <span>{{$t('contractModule.templateFileConfig.prompt')}}</span>
                </div>
                <div class="modelSet-prompt-content" v-if="currentModelConfig">
                    <textarea v-model="currentModelConfig.prompt" @blur="updateModelConfigure"
                        :placeholder="$t('contractModule.templateFileConfig.placeholder')"></textarea>
                </div>
            </div>
        </div>
        <div class="debug">
            <div class="debug-header">
                <div class="debug-header-tabs">
                    <div class="debug-tab" v-for="(tab, index) in tabs" :key="index"
                        :class="{ 'active': currentTab === index }" @click="switchTab(index)">
                        <!-- {{ tab.name }} <img v-if="!fileloading&&tab.name == '字段抽取预览'" height="20px" src="../../assets/images/loading3.gif"> -->
                        {{ tab.name }} <el-progress v-if="!fileloading && tab.name == '字段抽取预览' && extractDataProgess < 100 && extractDataProgess > 0" :percentage="extractDataProgess"></el-progress>
                    </div>
                </div>
              <el-popover
                  placement="bottom"
                  trigger="click"
                  ref="dataSetPopover"
                  :disabled="!isShowDataset"
              >
                <div class="dataset-range">
                  <div class="dataset-range-header">{{$t('contractModule.templateFileConfig.extractionRange')}}</div>
                  <div class="dataset-range-item" @click="currentSearchDateset('all')">
                    <div class="dataset-range-item-name">{{$t('contractModule.templateFileConfig.debuggingData')}}</div>
                    <!--                        <div class="dataset-range-item-desc" v-for="data in debugAllContractId" :key="data">-->
                    <!--                            {{ data.knowledgeName }}-->
                    <!--                        </div>-->
                  </div>
                  <div class="dataset-range-item" v-if="previousDebugContractId.length !== 0"
                       @click="currentSearchDateset('last')">
                    <div class="dataset-range-item-name">{{$t('contractModule.templateFileConfig.lastDebugData')}}</div>
                    <div class="dataset-range-item-desc" v-for="data in previousDebugContractIdFilter" :key="data">
                      {{ data.knowledgeName }}
                    </div>
                    <div v-if="previousDebugContractId.length > 3" class="ellipsis">...</div>
                  </div>
                  <div class="dataset-range-item" @click="customDataSetDialogVisible = true;$refs.dataSetPopover.doClose();checkAll = false;isIndeterminate = false;">
                    <div class="dataset-range-item-name">{{$t('contractModule.templateFileConfig.customDebugData')}}</div>
                    <div class="dataset-range-item-desc"></div>
                  </div>
                </div>
                <div slot="reference"  @click="extractFiles" class="extractFiles" :class="isDisabled ? 'disabledExtract' : ''">{{$t('contractModule.templateFileConfig.extractNow')}}</div>
<!--                <el-button slot="reference" type="primary" @click="extractFiles" size="mini" :disabled="isDisabled"-->
<!--                           style="height: 30px;">立即提取</el-button>-->
              </el-popover>

                <el-dialog :title="$t('contractModule.templateFileConfig.selectCustomData')" :visible.sync="customDataSetDialogVisible" width="600px"
                    @close="handleClose">
                  <div class="custom-dataset-form-list">
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">{{$t('contractModule.templateFileConfig.selectAll')}}</el-checkbox>
                    <div class="custom-dataset-form-item">
                      <el-checkbox-group v-model="selectedKnowledgeIds" @change="changeGroup">
                        <el-checkbox v-for="knowledge in debugAllContractId" :key="knowledge._id"
                                     :label="knowledge.knowledgeId">{{ knowledge.knowledgeName }}</el-checkbox>
                      </el-checkbox-group>
                    </div>
                  </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="customDataSetDialogVisible = false">{{$t('common.cancel')}}</el-button>
                        <el-button type="primary" @click="saveSelectedKnowledge">{{$t('common.confirm')}}</el-button>
                    </span>
                </el-dialog>
            </div>
            <div class="debug-content" v-if="currentTab === 0">
                <el-tabs tab-position="right" style="height: 100%;">
                    <el-tab-pane v-for="(actuallyData, index) in sortedData" :key="index">
                        <span slot="label">{{ actuallyData.createdAt | formatDate }}</span>
                        <el-tabs style="height: calc(100vh - 180px)">
                            <el-tab-pane v-for="(extractionResult, index) in actuallyData.extractionResult"
                                :key="index">
                                <span slot="label">{{ extractionResult.knowledgeName }}</span>
                                <div class="" style="height: calc(100vh - 210px);overflow: auto;margin-bottom: 40px;">
                                    <div class="debug-preview-item">
                                        <div class="label-text">{{$t('contractModule.templateFileConfig.extractedValues')}}</div>
                                        <div class="debug-preview-item-content">
                                            {{ extractionResult.fieldValueObject.answer }}
                                        </div>
                                    </div>
                                    <div class="debug-preview-item">
                                        <div class="label-text">{{$t('contractModule.templateFileConfig.candidate')}}</div>
                                        <div v-for="(data, index) in extractionResult.allCandidates" :key="index">
                                            <div class="debug-preview-item-content">
                                                <div class="item-content-number">
                                                    <div class="number">{{ index + 1 }}</div>
                                                    <div class="score">分数{{ data.reRankShowScore }}</div>
                                                </div>
                                                <div class="item-content-content">{{ data.content }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="debug-preview-item">
                                        <div class="label-text">{{$t('contractModule.templateFileConfig.candidateConfig')}}</div>
                                        <div class="preview-item-config">
                                            <span>{{$t('contractModule.templateFileConfig.candidateText')}}：</span>
                                            <div v-for="query in actuallyData.currentConfig.candidateSetConfig.query"
                                                :key="query" class="content">
                                                {{ query }}
                                            </div>
                                        </div>
                                        <div class="preview-item-config">
                                            <span>{{$t('contractModule.templateFileConfig.rearrange')}}：</span>
                                            <div class="content">{{ actuallyData.currentConfig.candidateSetConfig.shuffle }}
                                            </div>
                                        </div>
                                        <div class="preview-item-config">
                                            <span>{{$t('contractModule.templateFileConfig.threshold')}}：</span>
                                            <div class="content">{{ actuallyData.currentConfig.candidateSetConfig.threshold }}
                                            </div>
                                        </div>
                                        <div class="preview-item-config">
                                            <span>{{$t('contractModule.templateFileConfig.count')}}：</span>
                                            <div class="content">{{ actuallyData.currentConfig.candidateSetConfig.count }}</div>
                                        </div>
                                    </div>
                                    <div class="debug-preview-item">
                                        <div class="label-text">{{$t('contractModule.templateFileConfig.candidateConfig')}}</div>
                                        <div class="preview-item-config">
                                            <span>{{$t('contractModule.templateFileConfig.model')}}：</span>
                                            <div class="content">GPT-4</div>
                                        </div>
                                        <div class="preview-item-config">
                                            <span>{{$t('contractModule.templateFileConfig.temperature')}}：</span>
                                            <div class="content">{{ actuallyData.currentConfig.modelConfig[0].temperature }}</div>
                                        </div>
                                        <div class="preview-item-config">
                                            <span>{{$t('contractModule.templateFileConfig.prompt')}}：</span>
                                            <div class="content">{{ actuallyData.currentConfig.modelConfig[0].prompt }}</div>
                                        </div>
                                    </div>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div class="debug-content" v-if="currentTab === 1">
                <div class="loading-container" v-if="loading">
                    <!-- <div class="loading-spinner"></div> -->
                    <img height="25px" src="../../assets/images/loading3.gif">
                </div>
                <div class="" v-else>
                    <el-tabs>
                        <el-tab-pane v-for="(item, index) in extractAllData" :key="index">
                            <span slot="label">{{ item.knowledgeName }} </span>
                            <div class="debug-preview-item">
                                <div class="label-text">{{$t('contractModule.templateFileConfig.extractedValues')}}</div>
                                <div class="debug-preview-item-content">
                                    {{ item.fieldValueObject.answer }}
                                </div>
                            </div>
                            <div class="debug-preview-item">
                                <div class="label-text">{{$t('contractModule.templateFileConfig.candidate')}}</div>
                                <div v-for="(data, index) in item.allCandidates" :key="index">
                                    <div class="debug-preview-item-content">
                                        <div class="item-content-number">
                                            <div class="number">{{ index + 1 }}</div>
                                            <div class="score">{{$t('contractModule.templateFileConfig.score')}}{{ data.reRankShowScore }}</div>
                                        </div>
                                        <div class="item-content-content">{{ data.content }}</div>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
            <div class="debug-content-dataset" v-if="currentTab === 2">
                <div class="dataset-file-list">
                    <div class="file-list-item" v-for="item in fileList" :key="item.id" @click="previewContract(item)">
                        <div class="file-list-item-left">
                            <div class="file-list-item-icon">
                                <img src="../../assets/images/video.png" v-if="setSrc(item.fileSuffix) == 'video'" />
                                <img src="../../assets/images/img.png" v-else-if="setSrc(item.fileSuffix) == 'image'" />
                                <img src="../../assets/images/txt.png" v-else-if="setSrc(item.fileSuffix) == 'txt'" />
                                <img src="../../assets/images/doc.png"
                                    v-else-if="setSrc(item.fileSuffix) == 'word' || (item.fileSuffix && item.fileSuffix.includes('文档') && item.type == 1)" />
                                <img src="../../assets/images/pdf.png" v-else-if="setSrc(item.fileSuffix) == 'pdf'" />
                                <img src="../../assets/images/ppt.png" v-else-if="setSrc(item.fileSuffix) == 'ppt'" />
                                <img src="../../assets/images/xls.png" v-else-if="setSrc(item.fileSuffix) == 'excel'" />
                                <img src="../../assets/images/html.png" v-else-if="setSrc(item.fileSuffix) == 'html'" />
                                <img src="../../assets/images/mp3.png" v-else-if="setSrc(item.fileSuffix) == 'audio'" />
                                <img src="../../assets/images/other.png" v-else>
                            </div>
                            <div class="file-list-item-filename">{{ item.name }}</div>
                        </div>
                        <div class="file-list-item-right">
                          <template v-if="!item.supportLearn">
                            <el-tooltip class="item" effect="dark" :content="$t('contractModule.templateFileConfig.notSupportedLearn')" placement="top">
                              <div style="cursor: pointer;" class="adicic-cell-handle-learning">
                                <span class="achl-image">
                                  <i style="color: #a9b3c6;" class="iconfont guoran-zanweixuexi"></i>
                                </span>
                                <span style="color: #a9b3c6;" class="achl-text">{{$t('contractModule.templateFileConfig.notLearned')}}</span>
                                <span class="achl-tip iconfont guoran-tishi"></span>
                              </div>
                            </el-tooltip>
                          </template>
                          <template v-else>
                            <div v-if="!item.learningFinished && !item.learningFail" class="adicic-cell-handle-learning">
                              <img class="achl-image" src="../../assets/images/loading2.gif" alt="" srcset="">
                              <span class="achl-text">{{ $t('contractModule.templateFileConfig.learning') }}</span>
                            </div>
                            <el-tooltip class="item" effect="dark" placement="top-end" :content="$t('contractModule.templateFileConfig.relearn')" v-else-if="item.learningFail">
                              <div  class="adicic-cell-handle-learning" @click.stop="relearn(item)">
                                <i class="iconfont guoran-xuexishibai"></i>
                                <span class="achl-text fail-text">{{$t('contractModule.templateFileConfig.learnFail')}}</span>
                              </div>
                            </el-tooltip>
                            <div v-else-if="item.learningFinished" class="adicic-cell-handle-learning">
                              <span class="achl-image">
                                <i class="iconfont guoran-xuexiwancheng"></i>
                              </span>
                              <span class="achl-text">{{$t('contractModule.templateFileConfig.learnFinish')}}</span>
                            </div>
                          </template>
                          <div class="deleteIcon">
                            <i class="iconfont guoran-piliangshanchu" @click="deleteFile(item.id,item.knowledgeId)"></i>
                          </div>
                        </div>
                    </div>
                    <div class="loadFile" @click="loadFile" v-if="hasNextPage">
                      {{$t('contractModule.templateFileConfig.loadMore')}}
                    </div>
                </div>
                <div class="upload-file-button">
                    <el-button type="primary" @click="uploadFile">{{$t('contractModule.templateFileConfig.addField')}}</el-button>
                </div>
            </div>

        </div>
        <div v-if="uploadInfoVisible" class="ask-upload-notify">
            <div class="ask-upload-notify-header">
                <div class="aunh-left">
                    <span v-show="uploadInfoStatus === 'pending'" class="aunh-left-loading">
                        <img height="18px" src="https://static.guoranbot.com/cdn-office-website/askbot_doc/loading.gif"
                            alt="" srcset="">
                        <span class="aunh-left-text">{{$t('contractModule.templateFileConfig.uploading')}}</span>
                    </span>
                    <span v-show="uploadInfoStatus === 'success'" class="aunh-left-suc">
                        <i class="iconfont guoran-a-tongyichicun-duihaoyuan"></i>
                        <span class="aunh-left-text">{{$t('contractModule.templateFileConfig.uploadSuccess')}}</span>
                    </span>
                    <span v-show="uploadInfoStatus === 'error'" class="aunh-left-fail">
                        <i class="iconfont guoran-tongyichicun-cuowu"></i>
                        <span class="aunh-left-text">{{$t('contractModule.templateFileConfig.uploadFail')}}</span>
                    </span>
                    <span class="aunh-left-count">{{ uploadedSuccessCount }}/{{ activeFileList.length }}</span>
                </div>
                <div class="aunh-right">
                    <span @click="hiddenActiveFileList = !hiddenActiveFileList" v-show="!hiddenActiveFileList"
                        class="aunh-right-up">
                        <i class="arsenal_icon arsenalangle-up-solid"></i>
                    </span>
                    <span @click="hiddenActiveFileList = !hiddenActiveFileList" v-show="hiddenActiveFileList"
                        class="aunh-right-down">
                        <i class="arsenal_icon arsenalangle-down-solid"></i>
                    </span>
                    <span @click="closeUploadInfoVisible" class="aunh-right-close">
                        <i class="iconfont guoran-cuohao2"></i>
                    </span>
                </div>
            </div>
            <div v-show="hiddenActiveFileList" class="ask-upload-notify-body">
                <div class="aunb-info" v-show="uploadInfoStatus === 'error'">
                    <div class="left">
                        <span class="aunb-info-suc&err">{{$t('contractModule.templateFileConfig.success')}} : {{ uploadedSuccessCount }} {{$t('contractModule.templateFileConfig.fail')}} : {{ uploadedFailCount }}</span>
                    </div>
                </div>
                <div v-for="item in activeFileList" :key="item.uid" class="aunb-cell">
                    <div :style="{ width: (item.process * 350) + 'px' }" class="aunb-cell-process"></div>
                    <div class="aunb-cell-info">
                        <div class="aunb-cell-info-left">
                            <span v-show="item.process === 1" class="acil-suc">
                                <i class="iconfont guoran-a-tongyichicun-duihaoyuan"></i>
                            </span>
                            <span v-show="item.process === 0" class="acil-err">
                                <i class="iconfont guoran-tongyichicun-cuowu"></i>
                            </span>
                            <span class="acil-icon">
                                <img width="16px" style="margin-top: 2px;" :src="item.icon" alt="" srcset="">
                            </span>
                            <div class="acil-name-box">
                                <div class="acil-name">{{ item.name }}</div>
                                <span v-show="item.isBeyond" class="beyond">
                                    <span> {{ item.beyondType === 'total' ? $t('contractModule.templateFileConfig.exceedingCapacity') : $t('contractModule.templateFileConfig.fileSizeExceeds') }} </span>
                                </span>
                            </div>
                        </div>
                        <div class="aunb-cell-info-right" v-if="item.process !== 0">
                            <span class="acir-current">{{ formatSize(item.size * item.process) }}</span>
                            <span class="acir-total">/{{ formatSize(item.size) }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <popup-com @closeEvent="contractFieldDialog = false;" v-if="contractFieldDialog">
            <div slot="popup-name">{{ editContractFieldForm.id ? $t('contractModule.contractTemplateList.editTitle') :
                        $t('contractModule.contractTemplateList.newTitle') }}</div>
            <div slot="popup-tip"></div>
            <div slot="popup-con" class="contractDialogContainer">
                <el-form label-position="top" :model="editContractFieldForm">
                    <el-form-item :label="$t('contractModule.contractTemplateList.name')">
                        <span slot="label">
                            {{ $t('contractModule.contractTemplateList.name') }}
                            <span style="color: red">*</span>
                        </span>
                        <el-input v-model="editContractFieldForm.name" autocomplete="off"
                            :placeholder="$t('contractModule.templateFileConfig.placeholder')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('contractModule.contractTemplateList.description')">
                        <span slot="label">
                            {{ $t('contractModule.contractTemplateList.description') }}
                            <span style="color: red">*</span>
                        </span>
                        <el-input v-model="editContractFieldForm.description" autocomplete="off"
                            :placeholder="$t('contractModule.templateFileConfig.placeholder')"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="dialog-footer">
                <el-button plain size="small" @click="contractFieldDialog = false" class="cancel-btn">{{
                        $t('common.cancel') }}</el-button>
                <el-button type="primary" @click="confirmEditContractTemplateField" class="confirm-btn">{{
                    $t('common.confirm') }}</el-button>
            </div>
        </popup-com>
        <el-drawer
            :with-header="false"
            :visible.sync="drawerDoc"
            size="800px"
            direction="rtl"
            :destroy-on-close="true"
            :append-to-body="true"
            :wrapperClosable="false"
        >
        <div class="pre_title">
            <div class="close" @click="drawerDoc = false">
            <i class="iconfont guoran-tongyichicun-guanbi1"></i>
            </div>
            <div class="title">
            <template>
                {{ knowledgeItem.name }}
            </template>
            </div>
            <div class="title_right">
            <el-select
                v-model="segmentationValue"
                placeholder="请选择"
                @change="changeSegementation"
                size="small"
            >
                <el-option value="DEFAULT" :label="$t('contractModule.templateFileConfig.segPopupDefault')"></el-option>
                <el-option value="PAGE" :label="$t('contractModule.templateFileConfig.segPopupPage')"></el-option>
                <el-option value="NONE" :label="$t('contractModule.templateFileConfig.segPopupNone')"></el-option>
            </el-select>
            <i class="iconfont guoran-tongyichicun-biaozhu" :style="{color:markFlag ? '#366aff' : '#000000'}" v-if="$route.query && $route.query.withLocationMark" @click="openMark()"></i>
            </div>
        </div>
        <div class="pre_cont">
            <FilePreview ref="filePreview" :url="previewHref" :fileType="previewFileType" v-if="drawerDoc">
            </FilePreview>
        </div>
        </el-drawer>
        <popup-com  class="segmentation_popup" @closeEvent="segmentationVisible = false" v-if="segmentationVisible">
            <div slot="popup-name">{{$t('contractModule.templateFileConfig.segPopupTitle')}}</div>
            <div slot="popup-con">{{$t('contractModule.templateFileConfig.segPopupContent')}}
                <span>
                {{ segmentationValue == 'DEFAULT' ? $t('contractModule.templateFileConfig.segPopupDefault') :
                    (segmentationValue == 'PAGE' ? $t('contractModule.templateFileConfig.segPopupPage') : $t('contractModule.templateFileConfig.segPopupNone'))
                }}
                </span>
                ?</div>
            <div slot="dialog-footer">
                <el-button  plain size="small" @click="segmentationVisible = false" class="cancel-btn">{{$t('common.cancel')}}</el-button>
                <el-button  type="primary" size="small" @click="sureSegmentation" class="confirm-btn">{{$t('common.confirm')}}</el-button>
            </div>
            </popup-com>
    </div>
</template>

<script>
import { requestUrl } from "@/http/requestUrl";
import { v4 as uuidv4 } from "uuid";
import { multipartUpload, ossConfig } from "@/assets/js/AliyunIssUtil";
import formatDoc from "@/utils/formatDocList";
import debounce from "@/assets/js/debounce";
import { GetQueryString } from "@/assets/js/filterTree";
import { mapActions } from 'vuex'
import PopupCom from "@/components/popup";
import FilePreview from "@/components/FilePreview";
import moment from 'moment';
export default {
    components: { PopupCom, FilePreview },
    data() {
        return {
            currentCandidateSetConfig: {},
            currentModelConfig: {},
            candidateSetThresholdMarks: {
                0: '0',
                100: '100'
            },
            candidateSetNumberMarks: {
                0: '0',
                10: '10'
            },
            modelConfigTemperatureMarks: {
                0: '0',
                1: '1'
            },
            currentFieldData: {},
            modelConfigArray: [],
            currentTab: 0, // 默认显示第一个标签
            tabs: [
                { name: this.$t('contractModule.templateFileConfig.historyExtractionPreview'), content: this.$t('contractModule.templateFileConfig.historyExtractionPreviewContent') },
                { name: this.$t('contractModule.templateFileConfig.fieldExtractionPreview'), content: this.$t('contractModule.templateFileConfig.fieldExtractionPreviewContent') },
                { name: this.$t('contractModule.templateFileConfig.debugDatasets'), content: this.$t('contractModule.templateFileConfig.debugDatasetsContent') },
            ],
            extractFieldValue: {},
            candidatedata: [],
            loading: false,
            isDisabled:false,
            fileloading: false,
            isShowDataset: false,
            debugAllContractId: [],
            previousDebugContractId: [],
            extractAllData: [],
            customDataSetDialogVisible: false,
            currentPage: 1,
            pageSize: 20,
            fileList: [],
            hasNextPage: false,
            tableTotal: 0,
            activeFileList: [],
            hiddenActiveFileList: true,
            uploadInfoVisible: false,
            // uploadInfoStatus:1,// 0 部分任务上传失败 1 上传中 2 上传完成
            uploadInfoStatusText: "",
            ossPathList: [],
            selectedKnowledgeIds: [],
            contractFieldDialog: false,
            contractFieldForm: {},
            editContractFieldForm: {
                id: "",
                name: "",
                description: ""
            },
            actuallyExtractingData: [],
            aiLearningTimer:null,
            extractDataProgess: 0,
            checkAll:false,
            isIndeterminate:false,
            taskTimer:null,
            segmentationValue:'DEFAULT',
            segmentationVisible:false,
            knowledgeItem:{},
            drawerDoc:false,
            markFlag:false,
        }
    },
    filters: {
        formatDate(value) {
            return moment(value).format('YYYY-MM-DD HH:mm:ss');
        }
    },
    computed: {
        // 当前列表的上传状态
        uploadInfoStatus() {
            if (this.activeFileList.some(item => { return item.uploadStatus == 'pending' })) {
                return 'pending'
            } else if (this.activeFileList.some(item => { return item.uploadStatus == 'error' })) {
                return 'error'
            } else {
                return 'success'
            }
        },
        uploadedSuccessCount() {
            let count = 0
            count = JSON.parse(JSON.stringify(this.activeFileList)).filter(item => {
                return item.uploadStatus == 'success'
            }).length
            return count
        },
        uploadedFailCount() {
            let count = 0
            count = JSON.parse(JSON.stringify(this.activeFileList)).filter(item => {
                return item.uploadStatus == 'error'
            }).length
            return count
        },
        previousDebugContractIdFilter(){
            let arr = [];
            arr = JSON.parse(JSON.stringify(this.previousDebugContractId)).splice(0,3)
            return arr;
        },
        sortedData() {
            return this.sortDataByCreatedAt();
        }
    },
    methods: {
        /**
        * 通知子组件打开标注功能
        * */
        openMark() {
            this.markFlag = !this.markFlag;
            this.$refs.filePreview.openMark()
        },
        sureSegmentation() {
            this.$http.put('/knowledge-api/knowledge/process-type/' +
                this.knowledgeItem.knowledgeId + '?type=' + this.segmentationValue).then(res => {
                console.log(res)
                if(res.data.code == 0) {
                this.$message.success(this.$t('contractModule.templateFileConfig.switchSegSuccess'))
                this.drawerDoc = false
                this.segmentationVisible= false
                this.getFolderAndFile()
                } else {
                this.$message.warning(this.$t('contractModule.templateFileConfig.switchSegFail'))
                }
            })
            },
        changeSegementation() {
            this.segmentationVisible = true
        },
        previewContract(row) {
            if (row.withLocationMark && this.$route.query.withLocationMark) {
                this.drawerDoc = true
                this.segmentationValue = row.processType ? row.processType : 'DEFAULT'
                this.$nextTick(() => {
                    this.$refs.filePreview.withLocationMark = {
                        id: row.knowledgeId,
                        pageInfos: row.pageInfos,
                        withLocationMark: true,
                    }
                })
                return
            }
        },
        sortDataByCreatedAt() {
            return [...this.actuallyExtractingData].sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
            });
        },
        updateCandidateConfigure() {
            console.log("this.currentCandidateSetConfig.threshold:", this.currentCandidateSetConfig.threshold);

            // 构建要发送的候选集配置对象
            const candidateSetConfig = {
                query: this.currentCandidateSetConfig.query,
                count: this.currentCandidateSetConfig.count,
                threshold: this.currentCandidateSetConfig.threshold,
                shuffle: this.currentCandidateSetConfig.shuffle
            };

            // 发送更新请求
            this.$http.put(`${requestUrl.contractTemplateField.contractTemplateField}${this.$route.query.fieldId}`, {
                candidateSetConfig: candidateSetConfig
            }).then((res) => {
                if (res.data) {
                    // 更新成功后，重新获取字段配置数据
                    this.getFieldConfigure();
                    console.log("res.data:", res.data);
                } else {
                    this.messageFn("error", res.message);
                }
            })
                .catch((error) => {
                    console.error("Error updating contract field:", error);
                    // 处理更新失败的情况
                    // 可以显示错误消息或者采取其他适当的措施
                });
        },
        updateModelConfigure() {
            const targetModelId = "123";

            const targetModelIndex = this.modelConfigArray.findIndex(item => item.modelId === targetModelId);
            if (targetModelIndex !== -1) {
                this.modelConfigArray[targetModelIndex].temperature = this.currentModelConfig.temperature;
                this.modelConfigArray[targetModelIndex].prompt = this.currentModelConfig.prompt;

                const requestData = {
                    modelConfig: this.modelConfigArray
                };

                this.$http.put(`${requestUrl.contractTemplateField.contractTemplateField}${this.$route.query.fieldId}`, requestData)
                    .then((res) => {
                        if (res.data) {
                            this.getFieldConfigure();
                        } else {
                            this.messageFn("error", res.message);
                        }
                    })
                    .catch((error) => {
                        console.error("Error updating contract field:", error);
                    });
            } else {
                console.error("Target modelId not found in modelConfig array");
            }
        },
        ...mapActions("modelFile", [
            "updateModelFile"
        ]),
        getFieldConfigure(type) {
            let url =
                requestUrl.contractTemplateField.contractTemplateField +
                this.$route.query.fieldId;
            this.$http.get(url).then((res) => {
                console.log("获取到的数据aaa:", res.data);
                this.debugAllContractId = res.data.debugContractId;
                // this.selectedKnowledgeIds = res.data.debugContractId;
                this.previousDebugContractId = res.data.previousDebugContractId;
                this.currentCandidateSetConfig = res.data.candidateSetConfig;
                this.modelConfigArray = res.data.modelConfig
                this.currentModelConfig = this.findCurrentModel(res.data.modelConfig);
                console.log("获取到的数据aaa:", res.data);
                this.contractFieldForm = res.data;
                if (type !== 'extract'){
                  this.updateModelFile(res.data);
                }
            });
        },
        findCurrentModel(modelConfig) {
            for (const model of modelConfig) {
                if (model.currentModel) {
                    console.log("model:", model)
                    return model;
                }
            }
            return null; // 如果找不到符合条件的对象，返回 null 或者其他适当的值
        },
        switchTab(index) {
            this.currentTab = index;
        },
        handleClose() {
            this.selectedKnowledgeIds = [];
        },
        handleCheckAllChange(val){
            if (val){
              this.selectedKnowledgeIds = this.debugAllContractId.map(res => {
                return res.knowledgeId
              })
            } else {
              this.selectedKnowledgeIds = [];
            }
        },
        changeGroup(val){
          if (val.length === this.debugAllContractId.length){
            this.checkAll = true;
            this.isIndeterminate = false;
          } else {
            this.checkAll = false;
            this.isIndeterminate = true;
          }
        },
        saveSelectedKnowledge() {
            this.selectedKnowledgeIds = this.selectedKnowledgeIds.map(knowledgeId => {
                const knowledge = this.debugAllContractId.find(item => item.knowledgeId === knowledgeId);
                return { knowledgeId: knowledge.knowledgeId, knowledgeName: knowledge.knowledgeName };
            });
            this.customDataSetDialogVisible = false;
            this.getExtractFieldValue(this.selectedKnowledgeIds)
            // console.log('Selected Knowledge IDs:', this.selectedKnowledgeIds);
        },
        getActuallyExtractingData() {
            const fieldId = this.$route.query.fieldId;
            const mainId = sessionStorage.getItem('_mainId');
            this.$http.get(`${requestUrl.contractExtraction.fields}?mainId=${mainId}&fieldId=${fieldId}`).then((res) => {
                // console.log("获取到的数据:", res);
                if (res && res.status === 200) {
                    this.actuallyExtractingData = res.data;
                    console.log("this.actuallyExtractingData:", this.actuallyExtractingData);
                } else {
                    this.$message({
                        message: this.$t('contractModule.templateFileConfig.queryFailed'),
                        type: 'warning'
                    });
                }
            }).catch((error) => {
                console.error("获取数据时出错:", error);
            });
        },
        async getActuallyExtractingDataByTaskId(taskId) {
          console.log("taskId:", taskId)
          const fieldId = this.$route.query.fieldId;
          const mainId = sessionStorage.getItem('_mainId');
          // let attempts = 0;
          // const maxAttempts = 100; // 最大调用次数
          const interval = 5000; // 轮询间隔（毫秒）

          this.taskTimer = setInterval(() => {
            this.$http.get(`${requestUrl.contractExtraction.fieldRealTime}?mainId=${mainId}&fieldId=${fieldId}&taskId=${taskId}`).then(res => {
              if (res && res.status === 200) {
                if (res.data.length === 0 || res.data[0].progress < 100) { // 如果返回结果为空或者 progress 小于 100 且尝试次数未达到最大值
                  if (res.data.length !== 0) { // 如果返回结果不为空
                    this.loading = false;
                    this.extractAllData = res.data[0].extractionResult;
                    this.extractDataProgess = res.data[0].progress;
                    this.getActuallyExtractingData()
                    this.isDisabled = true;
                  }
                } else if (res.data.length !== 0) {
                  this.loading = false;
                  this.extractAllData = res.data[0].extractionResult;
                  this.extractDataProgess = res.data[0].progress;
                  this.getActuallyExtractingData();
                  this.isDisabled = false;
                  clearInterval(this.taskTimer);
                }
              } else {
                this.$message({
                  message: this.$t('contractModule.templateFileConfig.queryFailed'),
                  type: 'warning'
                });
              }
            });
          }, interval)
        },
        getExtractFieldValue(knowledgeIds) {
            this.loading = true;
            this.isDisabled = true;
            this.switchTab(1);
            this.isShowDataset = false;
            const fieldId = this.$route.query.fieldId;
            const mainId = sessionStorage.getItem('_mainId');
            const userId = sessionStorage.getItem('_uid');

            this.$http.post(`${requestUrl.contractTemplateField.extractFieldValue}/${fieldId}`, {
                mainId: mainId,
                userId: userId,
                knowledgeIds: knowledgeIds
            }).then((res) => {
                console.log("获取到的数据:", res);
                if(res.data.taskId){
                    const taskId = res.data.taskId;
                    console.log("taskId:",taskId)
                    this.getActuallyExtractingDataByTaskId(taskId);
                }else {
                    this.$message({
                        message: this.$t('contractModule.templateFileConfig.queryFailed'),
                        type: 'warning'
                    });
                    this.loading = false;
                    this.isDisabled = false;
                }
                // if (res.data.status === 'success') {
                //     this.extractAllData = res.data.data;
                //     this.extractFieldValue = res.data.data.fieldValue;
                //     this.candidatedata = res.data.data.candidates;
                //     this.loading = false;
                //     this.getFieldConfigure();
                //     this.getActuallyExtractingData();
                // } else {
                //     this.$message({
                //         message: '信息提取失败，请重新尝试。',
                //         type: 'warning'
                //     });
                //     this.loading = false;
                // }
                // console.log("this.extractFieldValu:",this.extractFieldValu)
            }).catch((error) => {
                console.error("获取数据时出错:", error);
                this.$message.error(this.$t('contractModule.templateFileConfig.queryFailed'));
            });
        },
        extractFiles(){
          if (this.isDisabled){
            this.$message({
              message:this.$t('contractModule.templateFileConfig.loading'),
              type:"warning"
            })
            this.isShowDataset = false;
            return;
          }
          if (this.fileList.length === 0){
            this.$message({
              message:this.$t('contractModule.templateFileConfig.fieldRequired'),
              type:"warning"
            })
            this.isShowDataset = false;
            return;
          }
          this.isShowDataset = true;
          this.getFieldConfigure('extract');
        },
        currentSearchDateset(dataSetRang) {
            if (dataSetRang === 'all') {
                if (!this.debugAllContractId || this.debugAllContractId.length === 0) {
                    this.$message({
                        message: this.$t('contractModule.templateFileConfig.debugAllContract'),
                        type: 'warning'
                    });
                } else {
                    const knowledgeIds = this.debugAllContractId
                    this.getExtractFieldValue(knowledgeIds)
                }
            } else if (dataSetRang === 'last') {
                const knowledgeIds = this.previousDebugContractId
                this.getExtractFieldValue(knowledgeIds)
            }
            this.$refs.dataSetPopover.doClose();
        },
        addInput() {
            this.currentCandidateSetConfig.query.push('');
        },
        removeInput(index) {
            this.currentCandidateSetConfig.query.splice(index, 1);
        },
        uploadFile() {
            let inputDom = document.createElement('input')
            inputDom.setAttribute('id', '_ef_file');
            inputDom.setAttribute('type', 'file');
            inputDom.setAttribute('multiple', 'true');
            inputDom.setAttribute("style", 'visibility:hidden');
            document.body.appendChild(inputDom);
            inputDom.click();
            inputDom.onchange = () => {
                let files = Array.from(inputDom.files);
                this.uploadInfoVisible = true;
                this.setTaskQueue(files);
                document.body.removeChild(inputDom)
            }
        },
        setTaskQueue(list) {
            let needUploadList = []
            list.forEach((fileItem) => {
                fileItem.uid = uuidv4();
                this.activeFileList.unshift(this.formatUploadFileInfo(fileItem, false, '', 'pending'));
                needUploadList.unshift(this.formatUploadFileInfo(fileItem, false, '', 'pending'));
            })
            this.concurrencyRequest(needUploadList, 1)
        },
        concurrencyRequest(fileList, maxNum) {
            let that = this
            return new Promise((resolve) => {
                if (fileList.length === 0) {
                    resolve([]);
                    return;
                }
                const results = [];
                let index = 0; // 下一个请求的下标
                let count = 0; // 当前请求完成的数量

                // 发送请求
                async function request() {
                    if (index === fileList.length) return;
                    const i = index; // 保存序号，使result和fileList相对应
                    const file = fileList[index];
                    index++;
                    try {
                        const resp = await that.upload(file);
                        // resp 加入到results
                        results[i] = resp;
                    } catch (err) {
                        // err 加入到results
                        results[i] = err;
                    } finally {
                        count++;
                        // 判断是否所有的请求都已完成
                        if (count === fileList.length) {
                            console.log('完成了');
                            resolve(results);
                        }
                        request();
                    }
                }
                // maxNum和fileList.length取最小进行调用
                const times = Math.min(maxNum, fileList.length);
                for (let i = 0; i < times; i++) {
                    request();
                }
            })
        },
        async upload(file) {
            await multipartUpload(ossConfig, file.file, (process) => {
                this.activeFileList.forEach(AllItem => {
                    if (AllItem.uid == file.uid) {
                        this.$set(AllItem, 'process', process)
                        if (AllItem.process === 0) {
                            this.$set(AllItem, 'uploadStatus', 'pending');
                        } else if (AllItem.process === 1) {
                            this.$set(AllItem, 'uploadStatus', 'success');
                        }
                    }
                })
            }).then((resp) => {
                file.response = resp;
                this.uploadSuccess(file);
            })
        },
        formatSize(size) {
            let sizeB = size * 1024 * 1024;
            let str = "";
            if (sizeB < 1024) {
                str = sizeB.toFixed(1) + "B";
            } else {
                if (size < 1) {
                    str = (sizeB / 1024).toFixed(1) + "KB";
                } else {
                    str = Number(size).toFixed(1) + "M";
                }
            }
            return str;
        },
        /**
         * 上传文件
         * */
        formatUploadFileInfo(fileInfo, isBeyond, beyondType, uploadStatus) {
            let tampFileInfo = {
                size: (fileInfo.size / 1024 / 1024).toFixed(3),
                icon: formatDoc.formatFileTypeSrc(fileInfo),
                name: fileInfo.name,
                uid: fileInfo.uid,
                process: 0,
                file: fileInfo,
                isBeyond: isBeyond,
                beyondType: beyondType,
                oldSize: fileInfo.size,
                uploadStatus: uploadStatus,

            };
            return tampFileInfo;
        },
        async uploadSuccess(file) {
            console.debug(file, 'uploadSuccess');
            let url = requestUrl.knowledgeBase.saveLocalUploadFile + '?saveType=0';
            let type = file.name.substring(file.name.lastIndexOf("."));
            let fileName = file.name.substring(0, file.name.lastIndexOf("."))
            let corpId = sessionStorage.getItem('_corpId');
            let parentId = -99999;
            // 截取字符串的后三位
            const lastThreeDigits = this.$route.query.templateId.slice(-6);
            // 将十六进制字符串转换为十进制数字
            parentId = -1 * parseInt(lastThreeDigits, 16);
            let obj = {
                "corpId": corpId,
                "parentId": parentId,
                "name": fileName,
                "volume": file.oldSize,  //文件大小
                "sourceLocation": file.file.webkitRelativePath, // 用户本地路径
                "fileSuffix": type,
                "ossPath": file.response.res.requestUrls[0]
            }
            let ossPath = decodeURIComponent(file.response.res.requestUrls[0])
            this.ossPathList.push(ossPath);
            this.$http.post(url, obj).then(res => {
                if (res.data.code == '0') {
                    // this.selectCompanyUseFlow();
                    debounce(() => {
                        this.$message({
                            message: this.$t('contractModule.templateFileConfig.uploadSuccess'),
                            type: "success"
                        })
                        this.currentPage = 1;
                        this.getFolderAndFile();
                        this.getUploadFileIds();
                    }, 2000)
                }
            })
        },
        getFolderAndFile() {
            let url = '';
            let isAskLightning = GetQueryString('isAskLightning') == 1;
            let corpId = sessionStorage.getItem('_corpId');
            let mainId = sessionStorage.getItem('_mainId');
            if (isAskLightning) {
                url = requestUrl.knowledgeBase.clientSelectTreeFolderAndFile;
            } else {
                url = requestUrl.knowledgeBase.getFolderAndFile;
            }
            let folderId = -99999;
            // 截取字符串的后三位
            const lastThreeDigits = this.$route.query.templateId.slice(-6);
            // 将十六进制字符串转换为十进制数字
            folderId = -1 * parseInt(lastThreeDigits, 16);
            url += '?page=' + this.currentPage + '&size=' + this.pageSize + '&folderId=' + folderId + '&corpId=' + corpId + '&mainId=' + mainId;
            if(this.$route.query && this.$route.query.withLocationMark) {
                url = url + '&withLocationMark=true'
            }
            if (isAskLightning) {
                let memberInDepartDto = JSON.parse(localStorage.getItem('memberInDepartDto'));
                this.$http.post(url, memberInDepartDto).then(res => {
                    if (this.currentPage === 1) {
                        this.fileList = res.data.data.list;
                        //   console.log("this.fileList:",this.fileList)
                    } else {
                        this.fileList = this.fileList.concat(res.data.data.list);
                    }
                    this.tableTotal = res.data.data.total;
                    this.hasNextPage = res.data.data.hasNextPage;
                    clearTimeout(this.aiLearningTimer);
                    this.aiLearningTimer = null;
                    this.getLearnProgress(folderId)
                })
            } else {
                this.$http.get(url).then(res => {
                    if (this.currentPage === 1) {
                        this.fileList = res.data.data.list;
                        console.log("this.fileList:", this.fileList)
                    } else {
                        this.fileList = this.fileList.concat(res.data.data.list);
                    }
                    this.tableTotal = res.data.data.total;
                    this.hasNextPage = res.data.data.hasNextPage;
                    clearTimeout(this.aiLearningTimer);
                    this.aiLearningTimer = null;
                    this.getLearnProgress(folderId)
                })
            }
        },
        loadFile() {
            this.currentPage += 1;
            this.getFolderAndFile();
        },
        getLearnProgress(id){
          clearTimeout(this.aiLearningTimer);
          this.aiLearningTimer = null;
          let url = requestUrl.knowledgeBase.aiLearnProgress;
          let corpId = sessionStorage.getItem('_corpId')
          url += '?page=' + this.currentPage + '&size=' + this.pageSize + '&folderId=' + id + '&corpId=' + corpId;
          this.$http.get(url).then(res => {
            if (res.data.code == 0 && res.data.data && res.data.data.list && res.data.data.list.length > 0){
              this.fileList.forEach(item => {
                res.data.data.list.forEach(items => {
                  if (item.id == items.id){
                    this.$set(item,'supportLearn',items.supportLearn)
                    if (items.progress){
                      this.$set(item,'learningFinished',items.progress.finished)
                      this.$set(item,'learningFail',items.progress.failed)
                    }
                  }
                })
              })
            }
            this.aiLearningTimer = setTimeout(() => {
              this.getLearnProgress(id)
            }, 10000);
          })
        },
        // 重新学习知识
        relearn(item){
          item.learningFinished = false;
          item.learningFail = false;
          let url = requestUrl.knowledgeBase.relearnKnowledge + item.knowledgeId;
          this.$http.post(url).then(res => {
            if (res && res.status == 200){
              this.$message({
                message:this.$t('contractModule.templateFileConfig.startRelearning'),
                type:'success'
              })
            }
          })
        },
        getUploadFileIds() {
            let url = "/knowledge-api/knowledgeBase/exchange-with-oss-url";
            this.$http.post(url, this.ossPathList).then(res => {
                console.log('getUploadFileIds', res)
                if (res.data.code == 0 && res.data.data) {
                    let knowledgeData = res.data.data;
                    console.log("knowledgeData", knowledgeData)
                    knowledgeData.forEach(know => {
                        const knowledge = {
                            knowledgeId: know.knowledge.id,
                            knowledgeName: know.knowledge.name
                        };
                        // 将新的对象追加到 debugAllContractId 数组中
                        this.debugAllContractId.push(knowledge);
                    });
                    console.log("更新后的知识集合:",this.debugAllContractId)
                    this.updateDebugContractId(this.debugAllContractId)
                }
            })
        },
        updateDebugContractId(debugContractId) {
            this.$http.put(`${requestUrl.contractTemplateField.contractTemplateField}${this.$route.query.fieldId}`, {
                debugContractId
            }).then((res) => {
                if (res.data) {
                    // 更新成功后，重新获取字段配置数据
                    this.getFieldConfigure();
                    this.ossPathList = [];
                } else {
                    this.messageFn("error", res.message);
                }
            })
        },
        updatePreviousDebugContractId(previousDebugContractId) {
            this.$http.put(`${requestUrl.contractTemplateField.contractTemplateField}${this.$route.query.fieldId}`, {
                previousDebugContractId
            }).then((res) => {
                if (res.data) {
                    // 更新成功后，重新获取字段配置数据
                    this.getFieldConfigure();
                } else {
                    this.messageFn("error", res.message);
                }
            })
        },
        closeUploadInfoVisible() {
            this.uploadInfoVisible = false;
            this.activeFileList = [];
        },
        deleteFile(id,knowledgeId) {
            this.$confirm(this.$t('contractModule.templateFileConfig.deleteFile'), this.$t('contractModule.templateFileConfig.deleteTitle'), {
                confirmButtonText: this.$t('common.confirm'),
                cancelButtonText: this.$t('common.cancel'),
                type: 'warning'
            }).then(() => {
                let url = requestUrl.knowledgeBase.moveDeleteFolder
                let obj = {
                    deleteNodeIds: [id],
                    newParentId: null
                }
                this.$http.delete(url, {
                    data: obj
                }).then(res => {
                    if (res.data.code == '0') {
                        this.$message({
                            message: this.$t('contractModule.templateFileConfig.deleteSuccess'),
                            duration: 2000,
                            type: "success"
                        })
                        // let knowledgeIdToDelete = this.findKnowledgeIdById(id);
                        this.previousDebugContractId = this.previousDebugContractId.filter(item => item.knowledgeId !== knowledgeId);
                        this.debugAllContractId = this.debugAllContractId.filter(item => item.knowledgeId !== knowledgeId);
                        this.updateDebugContractId(this.debugAllContractId)
                        this.updatePreviousDebugContractId(this.previousDebugContractId)
                        this.currentPage = 1;
                        this.getFolderAndFile();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: this.$t('contractModule.templateFileConfig.cancelDelete')
                });
            });

        },
        findKnowledgeIdById(id) {
            let knowledgeId;
            // 遍历 this.fileList，找到匹配 id 的项并获取其 knowledgeId
            for (const file of this.fileList) {
                if (file.id === id) {
                    knowledgeId = file.knowledgeId;
                    break;
                }
            }
            return knowledgeId;
        },
        setSrc(type) {
            if (type === 'mp4' || type === 'MP4' || type === 'mov' || type === 'MOV' || type == 'avi' || type == 'flv') {
                return 'video'
            } else if (type === 'jpg' || type === 'png' || type === 'JPG' || type === 'PNG' ||
                type === 'jpeg' || type === 'JPEG' || type === 'gif' || type === 'bmp' ||
                type === 'BMP' || type === 'WebP' || type === 'webp' || type === 'svg' || type === 'SVG' || type === 'rgb' || type === 'heic') {
                return 'image'
            } else if (type === 'doc' || type === 'docx' || type.includes('文档')) {
                return 'word'
            } else if (type === 'pdf') {
                return 'pdf'
            } else if (type === 'ppt' || type === 'pptx') {
                return 'ppt'
            } else if (type === 'xlsx' || type === 'xls') {
                return 'excel'
            } else if (type === 'txt') {
                return 'txt'
            } else if (type === 'mp3' || type === 'MP3') {
                return 'audio'
            } else {
                return type
            }
        },
        updateInfo() {
            this.contractFieldDialog = true;
            this.$nextTick(() => {
                this.editContractFieldForm.id = this.contractFieldForm._id;
                this.editContractFieldForm.name = this.contractFieldForm.name;
                this.editContractFieldForm.description = this.contractFieldForm.description;
            })
        },
        confirmEditContractTemplateField() {
            this.$http
                .put(`${requestUrl.contractTemplateField.contractTemplateField}${this.editContractFieldForm.id}`, {
                    name: this.editContractFieldForm.name,
                    description: this.editContractFieldForm.description
                })
                .then(res => {
                    if (res) {
                        this.$message({
                            message: this.$t('contractModule.templateFileConfig.editSuccess'),
                            type: "success"
                        })
                        this.contractFieldDialog = false;
                        this.getFieldConfigure();
                    } else {
                        this.$message.error(res.message);
                    }
                });
        },
    },
    mounted() {
        this.getFieldConfigure()
        this.getFolderAndFile();
        this.getActuallyExtractingData()
    },
  beforeDestroy() {
    clearTimeout(this.aiLearningTimer);
    this.aiLearningTimer = null;
    clearInterval(this.taskTimer);
    this.taskTimer = null;
  }

}
</script>

<style lang="less" scoped>
 .el-tabs--right {
    height: calc(100vh - 180px);
}
#fieldConfigure {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .candidateSet {
        width: 25%;
        background-color: #fff;

        .candidate-header {
            height: 50px;
            line-height: 50px;
            border-bottom: 1px solid #e0e6f7;

            span {
                font-size: 16px;
                color: #333;
                padding: 0px 15px;
            }
        }

        .candidate-content {
            margin: 15px 15px;

            .candidate-form-item {
                margin: 30px 0px;

                .label-text {
                    color: #616161;
                    padding-bottom: 10px;

                    .label-text-desc {
                        font-size: 12px;
                        margin-left: 10px;
                        color: #908f8f;
                    }
                }

                .candidate-form-item-content {
                    width: 95%;
                    margin-left: 10px;
                }

            }

            .candidate-form-item-query {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 5px 0px;

                .delete-button {
                    margin-left: 5px;
                    color: #366AFF;
                }
            }
        }

    }

    .modelSet {
        width: 25%;
        background-color: #fff;

        .modelSet-header {
            height: 50px;
            line-height: 50px;
            border-bottom: 1px solid #e0e6f7;

            span {
                font-size: 16px;
                color: #333;
                padding: 0px 15px;
            }
        }

        .modelSet-content {
            margin: 15px 15px;

            .modelSet-form-item {
                margin: 30px 0px;

                .label-text {
                    color: #616161;
                    padding-bottom: 10px;
                }

                .modelSet-form-item-content {
                    width: 95%;
                    margin-left: 10px;
                }

            }

            .modelSet-prompt-header {
                height: 50px;
                line-height: 50px;
                font-size: 16px;
                border-top: 1px solid #e0e6f7;
            }
        }

        .modelSet-prompt-content {
            height: calc(100% - 50px);

            textarea {
                width: calc(100% - 20px);
                /* 宽度填满父元素 */
                margin: 10px 10px 10px 10px;
                height: calc(100vh - 400px);
                /* 高度填满父元素 */
                line-height: 30px;
                resize: none;
                /* 禁用手动调整大小 */
                border: none;
                outline: none;
                /* 输入时不显示轮廓线 */
                color: rgba(28, 29, 35, .8);
                font-size: 14px;
            }
        }
    }

    .debug {
        width: 49%;
        background-color: #fff;
        height: auto;

        .debug-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            //height: 50px;
            //line-height: 50px;
            border-bottom: 1px solid #e0e6f7;
            font-size: 16px;

            .debug-header-tabs {
                display: flex;
                flex-direction: row;

                .debug-tab {
                    width: auto;
                    margin: 0px 15px;
                    padding: 12px 0;
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .el-progress{
                        width: 100px;
                        margin-left:10px;
                    }
                }
            }

            .el-button {
                margin-right: 20px;
            }
        }

        .debug-tab.active {
            color: #366AFF;
            border-bottom: 1px solid #366AFF;
        }

        .debug-content {
            margin: 10px 15px;
            height: calc(100% - 70px);
            overflow: auto;
            .debug-preview-item {
                margin: 30px 0px;

                .label-text {
                    color: #616161;
                    padding-bottom: 10px;
                }
                .preview-item-config{
                    display: flex;
                    flex-direction: row;
                    margin: 10px 0px;
                    text-align: left;
                    span{
                        margin-right: 10px;
                        width: 40%;
                    }
                    .content{
                        text-align: left;
                    }
                }

                .debug-preview-item {
                    width: 95%;
                    margin-left: 10px;
                    

                }
            }

            .debug-preview-item-content {
                display: flex;
                flex-direction: row;
                margin: 15px 0px;
                padding: 15px 0px;
                border-bottom: 1px solid #e0e6f7;

                .item-content-number {
                    width: 25%;
                    align-items: flex-start;
                    text-align: center;
                    justify-content: center;

                    .number {
                        width: 26px;
                        height: 26px;
                        border-radius: 50%;
                        color: #fff;
                        background-color: #366AFF;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex: none;
                    }

                    .score {
                        flex: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        padding: 4px;
                        color: #fff;
                        background: #366aff;
                        border-radius: 10px;
                        margin-top: 10px;
                        width: 30px;
                    }
                }

                .item-content-content {
                    align-items: flex-start;
                    padding: 0px 10px;
                }
            }

          /deep/.is-right{
            .el-tabs__nav-scroll{
              overflow-y: scroll;
            }
            .el-tabs__nav-prev{
              display: none;
            }
            .el-tabs__nav-next{
              display: none;
            }
            .is-scrollable{
              padding: 0;
            }
          }

        }
      .custom-dataset-form-list{
        padding: 0 15px;
        .custom-dataset-form-item {
          //padding: 0px 15px;
          height: calc(70vh - 100px);
          overflow-y: scroll;
          span {
            margin-left: 10px;
          }
          /deep/.el-checkbox-group{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
          }
        }
      }


        .debug-content-dataset {
            height: calc(100% - 100px);
            box-sizing: border-box;

            .upload-file-button {
                width: 300px;
                position: fixed;
                bottom: 20px;
                left: 78%;
                transform: translateX(-50%);

                .el-button {
                    width: 300px;
                }
            }

            .dataset-file-list {
                height: 100%;
                box-sizing: border-box;
                overflow-y: scroll;
                padding: 10px 20px;

                .file-list-item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 10px 0;

                    .file-list-item-left {
                        display: flex;
                        align-items: center;
                    }

                    .file-list-item-right {
                        display: flex;
                        align-items: center;
                        .adicic-cell-handle-learning {
                        margin-right: 10px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        .achl-image {
                          height: 17px;
                          line-height: 17px;
                          width: 17px;
                          border-radius: 50%;
                          display: flex;
                          align-items: center;

                          .iconfont {
                            color: #366aff;
                            font-size: 16px;
                          }
                        }

                        .achl-text {
                          margin-left: 4px;
                          font-size: 12px;
                          font-weight: 700;
                          color: #366aff;
                        }

                        .achl-tip {
                          font-size: 12px;
                          color: #A9B3C6;
                          margin-left: 4px;
                          cursor: pointer;
                        }
                        .guoran-xuexishibai{
                          color: #FE5965;
                        }
                        .fail-text{
                          color: #FE5965;
                        }
                      }
                        .deleteIcon{
                          display: none;
                          margin-left: 10px;
                          .guoran-piliangshanchu{
                            cursor: pointer;
                            color: #606266;
                          }
                        }
                    }

                    .file-list-item-icon {
                        flex: none;
                        display: flex;
                        align-items: center;
                    }

                    .file-list-item-filename {
                        margin-left: 6px;
                    }
                }

                .file-list-item:hover {
                    .deleteIcon {
                      display: flex;
                      align-items: center;
                      justify-content: flex-end;
                    }
                }

                .loadFile {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #366aff;
                    cursor: pointer;
                    margin: 10px 0;
                }
            }
        }
    }

    .loading-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70vh;
    }

    .loading-spinner {
        border: 4px solid rgb(64, 11, 236);
        border-left-color: #ffffff;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .ask-upload-notify {
        z-index: 10;
        position: absolute;
        min-height: 50px;
        bottom: 18px;
        right: 16px;
        width: 200px;
        width: 351px;
        background: #FFFFFF;
        box-shadow: 0px 1px 9px 0px rgba(57, 63, 79, 0.21);
        border-radius: 5px;

        .ask-upload-notify-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 48px;
            line-height: 48px;

            .aunh-left {
                flex: auto;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                height: 48px;
                padding-left: 12px;
                font-size: 12px;

                .aunh-left-text {
                    margin-left: 6px;
                    font-size: 12px;
                    color: #2c3e50;
                }

                .aunh-left-fail {
                    color: #FE5965;
                    font-size: 14px;
                    font-weight: 700;
                    margin-right: 5px;
                    display: flex;
                    align-items: center;
                }

                .aunh-left-loading {
                    display: flex;
                    align-items: center;
                    margin-right: 10px;

                }

                .aunh-left-suc {
                    color: #04B051;
                    font-weight: 700;
                    margin-right: 5px;
                    display: flex;
                    align-items: center;
                }

                .aunh-left-count {
                    height: 17px;
                    line-height: 17px;
                    padding: 0 5px;
                    background: #ECF1FF;
                    border-radius: 9px;
                    margin-right: 5px;
                }

                .iconfont {
                    vertical-align: middle;
                }
            }

            .aunh-right {
                flex: none;
                width: 80px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-right: 12px;

                .aunh-right-up,
                .aunh-right-down {
                    font-size: 12px;
                    font-weight: lighter;
                    cursor: pointer;
                }

                .aunh-right-close {
                    margin-left: 6px;
                    color: #A9B3C6;
                    cursor: pointer;
                }
            }
        }

        .ask-upload-notify-body {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding-bottom: 12px;
            max-height: 200px;
            overflow-y: auto;
            overflow-x: hidden;

            .aunb-info {
                height: 24px;
                line-height: 24px;
                text-align: left;
                padding: 0 12px;
                font-size: 12px;
                font-weight: bolder;
                background-color: rgba(54, 106, 255, .07);
                margin-bottom: 5px;
                display: flex;
                justify-content: space-between;

                .right {
                    color: #366AFF;
                    font-size: 12px;
                    cursor: pointer;
                }
            }

            .aunb-cell {
                height: 32px;
                width: 100%;
                margin: 4px 0;
                position: relative;

                .aunb-cell-process {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 32px;
                    border-radius: 16px;
                    width: 50%;
                    background-color: rgba(54, 106, 255, .07);
                }

                .aunb-cell-info {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 12px;
                    height: 100%;
                    line-height: 100%;
                    width: 320px;
                    position: relative;
                    z-index: 2;

                    .aunb-cell-info-left {
                        flex: auto;
                        justify-content: flex-start;
                        align-items: center;
                        display: flex;

                        .acil-suc {
                            color: #95E0B7;
                            margin-right: 6px;
                        }

                        .acil-err {
                            color: #FFA8AE;
                            margin-right: 6px;
                        }

                        .acil-name-box {
                            font-size: 14px;
                            // font-weight: 700;
                            margin-left: 4px;
                            margin-right: 6px;
                            text-align: left;

                            .acil-name {
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                overflow: hidden;
                                word-break: break-all;
                                text-overflow: ellipsis;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 1;
                                white-space: pre-line;
                                max-width: 170px;
                            }

                            .beyond {
                                margin-top: 4px;
                                color: #FE5965;
                                font-size: 12px;

                                .upgradation {
                                    cursor: pointer;
                                    color: #366AFF;
                                    margin-left: 20px;
                                }
                            }
                        }
                    }

                    .aunb-cell-info-right {
                        flex: none;
                        width: 80px;
                        justify-content: flex-end;
                        align-items: center;
                        display: flex;
                        font-size: 12px;
                        height: 32px;
                        line-height: 32px;

                        .acir-current {
                            font-weight: bolder;
                            color: #366AFF;
                        }

                        .acir-total {
                            color: #A9B3C6;
                            margin-right: 6px;
                        }

                        .acir-close {
                            color: #A9B3C6;
                        }

                        .acir-reload {
                            color: #A9B3C6;
                        }
                    }
                }
            }
        }
    }
}
 .dataset-range {
   background-color: #fff;
   padding: 10px;
   font-size: 14px;
   width: 400px;

   .dataset-range-item {
     background-color: #ecf0f8;
     border-radius: 5px;
     margin: 10px 0px;
     padding: 10px 15px;
     cursor: pointer;

     .dataset-range-item-name {
       color: #333;
       line-height: 40px;
     }

     .dataset-range-item-desc {
       color: #908f8f;
       line-height: 30px;
     }
     .ellipsis{
       color: #908f8f;
       line-height: 20px;
     }
   }
 }
 .extractFiles{
   height: 30px;
   width: 80px;
   border-radius: 5px;
   background-color: #366aff;
   color: #ffffff;
   font-size: 12px;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-right: 14px;
   cursor: pointer;
 }
 .disabledExtract{
   cursor: no-drop;
   background-color: #c0c4cc;
 }

.pre_title {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 20px 0 10px;

    .iconfont {
        cursor: pointer;
    }

    .close {
        width: 20px;
        margin-right: 15px;

        i {
        font-size: 12px;
        }
    }

    .title {
        width: calc(100% - 250px);
        text-align: left;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        .el-input {
        height: 100%;
        width: 90%;

        /deep/.el-input__inner {
            padding: 0;
            border: none;
        }
        }
    }
    .title_right {
        width: 250px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

/deep/.el-drawer__body{
    height: 100%;
}
.pre_cont {
    width: 100%;
    height: calc(100% - 40px);
}
.segmentation_popup {
    z-index: 9999;
    /deep/.popup-container {
        text-align: center;
        font-size: 14px;
        span {
            color: #366AFF;
        }
    }
}
</style>
function isInnerIPFn() {
    // 获取当前页面url
    let curPageUrl = window.location.href;
    // console.log('curPageUrl-0  '+curPageUrl);

    let reg1 = /(http|ftp|https|www):\/\//g;//去掉前缀
    curPageUrl = curPageUrl.replace(reg1, '');
    // console.log('curPageUrl-1  '+curPageUrl);

    let reg2 = /\:+/g;//替换冒号为一点
    curPageUrl = curPageUrl.replace(reg2, '.');
    // console.log('curPageUrl-2  '+curPageUrl);

    curPageUrl = curPageUrl.split('.');//通过一点来划分数组
    // console.log(curPageUrl);
    if (curPageUrl[0] == 'localhost') {
        return true;
    }


    let ipAddress = curPageUrl[0] + '.' + curPageUrl[1] + '.' + curPageUrl[2] + '.' + curPageUrl[3];

    let isInnerIp = false;//默认给定IP不是内网IP
    let ipNum = getIpNum(ipAddress);
    /**
     * 私有IP：A类  10.0.0.0    -10.255.255.255
     *       B类  172.16.0.0  -172.31.255.255
     *       C类  192.168.0.0 -192.168.255.255
     *       D类   127.0.0.0   -127.255.255.255(环回地址)
     **/
    let aBegin = getIpNum("10.0.0.0");
    let aEnd = getIpNum("10.255.255.255");
    let bBegin = getIpNum("172.16.0.0");
    let bEnd = getIpNum("172.31.255.255");
    let cBegin = getIpNum("192.168.0.0");
    let cEnd = getIpNum("192.168.255.255");
    let dBegin = getIpNum("127.0.0.0");
    let dEnd = getIpNum("127.255.255.255");
    isInnerIp = isInner(ipNum, aBegin, aEnd) || isInner(ipNum, bBegin, bEnd) || isInner(ipNum, cBegin, cEnd) || isInner(ipNum, dBegin, dEnd);
    // console.log('是否是内网:'+isInnerIp);
    return isInnerIp;
}
function getIpNum(ipAddress) {
    /*获取IP数*/
    let ip = ipAddress.split(".");
    let a = parseInt(ip[0]);
    let b = parseInt(ip[1]);
    let c = parseInt(ip[2]);
    let d = parseInt(ip[3]);
    let ipNum = a * 256 * 256 * 256 + b * 256 * 256 + c * 256 + d;
    return ipNum;
}

function isInner(userIp, begin, end) {
    return (userIp >= begin) && (userIp <= end);
}

const isInnerIP = isInnerIPFn();

export { isInnerIP }
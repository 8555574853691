<template>
	<div class="accessBind">
		<template v-if="!isAccess">
			<!-- 接入语雀 -->
			<YuqueBindCom  v-if="channel === 8" :isAccess.sync="isAccess"></YuqueBindCom>
			<!-- 接入飞书-云文档 -->
			<FeishuBindCom v-else-if="channel === 5" :isAccess.sync="isAccess"></FeishuBindCom>
		</template>
		<!-- 接入成功 -->
		<div class="accessBindSuccess" v-else>
			<img src="../../assets/images/accessSuccess.png">
			<div class="successText">
			{{ $t('channel.accessBind.accessSuccess.successText') }}
			</div>
			<div class="synchronize" @click="synchronizationFiles">
			<span>{{ $t('channel.accessBind.accessSuccess.synchronize') }}</span>
			</div>
		</div>
	</div>
</template>

<script>
// import { requestUrl } from "@/http/requestUrl";
import YuqueBindCom from './components/YuQueBindCom';
import FeishuBindCom from './components/FeiShuBindCom'
export default {
	name: "accessBind",
	data(){
		return{
		isAccess:false,
		channel:8, // 渠道标识  1:企微微盘，2:腾讯文档，3:钉钉钉盘，4:钉钉云文档,5:飞书-云文档,6:百度网盘企业版,7:百度网盘个人版,8:语雀
		
		
		}
	},
	components:{YuqueBindCom, FeishuBindCom},
	methods:{
		// 同步
		synchronizationFiles(){
			this.$router.push({
				path:"/knowledge_Management"
			})
			sessionStorage.setItem('isComeAccess',JSON.stringify({
				flag:true,
				channel:this.channel
			}))
		},
	},
	mounted() {
		this.channel = Number(this.$route.query.channel);
		this.channelAccess
	}
};
</script>

<style scoped lang="less">
.accessBind{
	width: 100%;
	height: 100%;
	background-color: white;
	position: relative;
	border-radius: 5px;
	.accessBindSuccess{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		flex-direction: column;
		.successText{
		margin: 36px 0;
		color: #616161;
		}
		.synchronize{
		flex: none;
		width: 90px;
		height: 32px;
		background: #366AFF;
		border-radius: 19px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #FFFFFF;
		cursor: pointer;
		}
	}
}

</style>
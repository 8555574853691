import { render, staticRenderFns } from "./employeeStructure.vue?vue&type=template&id=fbc269aa&scoped=true"
import script from "./employeeStructure.vue?vue&type=script&lang=js"
export * from "./employeeStructure.vue?vue&type=script&lang=js"
import style0 from "./employeeStructure.vue?vue&type=style&index=0&id=fbc269aa&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbc269aa",
  null
  
)

export default component.exports
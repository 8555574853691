<template>
    <div>
      <WebOffice
        :officeType="officeType"
        :fileId="fileId"
        :userId="userId"
        :currentVersion="currentVersion"
        :editType="editType"
        :knowledgeDetail="knowledgeDetail"
        :previousVersion="previousVersion"
        @updatePreviousVersion="updatePreviousVersion"
      />
    </div>
  </template>
  
  <script>
  import WebOffice from '../../components/wpsWebOffice.vue';
  
  export default {
    name: 'WebOfficePage',
    components: { WebOffice },
    props: {
      officeType: {
        type: String,
        required: true,
      },
      fileId: {
        type: String,
        required: true,
      },
      userId: {
        type: String,
        required: true,
      },
      currentVersion: {
        type: String,
        required: true,
      },
      editType:{
        type: String,
        default:""
      },
      knowledgeDetail:{
        type:Object,
        default: null
      },
      previousVersion:{
        type:Object,
        default: null
      }
    },
    methods:{
      updatePreviousVersion(type){
        this.$emit('updatePreviousVersion',type)
      }
    }
  };
  </script>
  
<template>
  <!-- AI生成内容弹框 -->
  <div
      v-show="aiCreateContentPopup"
      :class="[popupType === 'AiCreate' ? 'popup-ai-create-content' : 'popup-ai-made-content']"
      :style="{'top':popupPositionObj.top+'px','left':popupPositionObj.left+'px',width:popupWidth+'px'}">
    <div class="popup-ai-create-content-top-box">
      <div class="left">
        <span class="left-label"> {{ popupType === "AiCreate" ? "AI生成内容" : "AI协作" }} </span>
        <!-- <span class="look-example"> <i class="iconfont guoran-tongyichicun-dianjichakantuli"></i> 查看示例</span> -->
      </div>
      <div class="right" v-if="!isCreateing">
        <span>Esc</span>
        <i class="iconfont guoran-cuohao" @click.stop="closePopup"></i>
      </div>
    </div>
    <div :class="['popup-ai-create-content-middle-box']">
      <template
          v-if="(((!isCreateing && !isShowContinueBtn) || isShowContinueBtn) && popupType === 'AiCreate') || popupType === 'AIMade'">
        <div class="iconfont guoran-shengchengAI2" v-if="!isCreateing && !isShowContinueBtn"></div>
        <!-- @keyup.enter.native.stop="nowCreateFn" -->
        <el-input
            type="textarea"
            resize="none"
            :autosize="{ minRows: 1, maxRows: 6}"
            ref="aiCreateContentInput"
            @keyup.enter.native.stop="nowCreateFn"
            v-model="aiCreateContentValues"
            v-if="!isCreateing && !isShowContinueBtn"
            :placeholder="popupType === 'AiCreate' ? '请输入让A帮你写什么内容，如: 帮我写一份AIGC的市场分析报告' : '用AI优化或生成，如:帮我总结一下'"></el-input>
        <div
            v-if="popupType === 'AiCreate' && !isCreateing && !isShowContinueBtn"
            :class="['now-create',aiCreateContentValue === '' ? 'disabled' : '']"
            @click.stop="nowCreateFn">
          <i class="iconfont guoran-shengchengAI"></i>
          <span>立即生成</span>
        </div>

        <div
            v-else-if="popupType === 'AIMade'"
            :class="['now-create',aiCreateContentValue === '' ? 'disabled' : '']"
            @click.stop="selectOptimizedGeneration('btn')">
          <i class="iconfont guoran-tongyichicun-kaiqi1"></i>
          <span>确定</span>
        </div>

      </template>
      <template v-if="(isCreateing || isShowContinueBtn) && popupType === 'AiCreate'">
        <div :class="['is-createing',popupType === 'AiCreate' && isShowContinueBtn ? 'continue' : '']">
          <template v-if="isCreateing && !isShowContinueBtn">
            <img src="../images/loading.gif" alt="">
            <span> AI正在生成，请稍后</span>
          </template>
          <div v-if="isShowContinueBtn">
            <i class="iconfont guoran-icon_duihao-mian2 "></i>
            <span class="continue-text"> 已生成部分内容，您可以继续生成</span>
          </div>
          <div
              v-if="popupType === 'AiCreate' && isShowContinueBtn"
              :class="['now-create',aiCreateContentValue === '' ? 'disabled' : '']"
              @click.stop="continueCreate">
            <i class="iconfont guoran-shengchengAI"></i>
            <span>继续生成</span>
          </div>
        </div>
        <div class="stop-create" v-if="isCreateing">
          <span class="label" @click.stop="stopCreate(true)">停止</span>
          <span>Esc</span>
        </div>
      </template>
    </div>
    <!-- 历史 -->
    <div class="popup-ai-create-content-bottom-box history"
         v-if="historyList.length > 0 && !isCreateing && !isShowContinueBtn">
      <div class="update-box">
        <div class="header">
          <div class="label">历史</div>
          <el-popover visible-arrow="true" popper-class="show-more-mode-ai-create history-AIGC" placement="right"
                      width="300" trigger="hover">
            <div class="list">
              <div class="item" v-for="(cell, cellIndex) in historyMore" :key="cellIndex"
                   @click.stop="chooseHistory(cell)">
                <span class="acci-name one-column-ellipsis" v-html="cell.input"></span>
              </div>
            </div>
            <div class="look-more">
              <div v-if="loadStatus === 'loading'"><i class="el-icon-loading"></i>加载中...</div>
              <div v-else-if="loadStatus === 'noMore'">-- 到底了 --</div>
            </div>
            <span slot="reference" @click.stop="historyVisible = !historyVisible" v-show="historyMore.length > 0">
                            <div class="more-history">更多历史指令 ></div>
                        </span>
          </el-popover>
        </div>
        <div class="history-list">
          <div
              :class="['item','active-dom',]"
              v-for="(item,index) in firstColumn"
              :key="index"
              @click.stop="chooseHistory(item)"
              :style="{width:item.width > popupWidth ? popupWidth+10+'px' : item.width+20+'px'}">
            <div
                :class="[item.width > popupWidth ? 'one-column-ellipsis' : '']"
                :style="{width:item.width > popupWidth ? popupWidth-10+'px' : item.width+'px'}"
                v-html="item.input">
            </div>
          </div>
        </div>
        <div class="history-list">
          <div
              :class="['item','active-dom',item.width > popupWidth ? 'one-column-ellipsis' : '']"
              v-for="(item,index) in secondColumn"
              :key="index"
              @click.stop="chooseHistory(item)"
              :style="{width:item.width > popupWidth ? popupWidth+10+'px' : item.width+20+'px'}">
            <div
                :class="[item.width > popupWidth ? 'one-column-ellipsis' : '']"
                :style="{width:item.width > popupWidth ? popupWidth-10+'px' : item.width+'px'}"
                v-html="item.input">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 优化 -->
    <div class="popup-ai-create-content-bottom-box"
         v-if="!isCreateing && popupType === 'AiCreate' && !isShowContinueBtn">
      <div class="update-box">
        <div class="label">快捷指令</div>
        <div class="quick-list">
          <div
              :class="['item','active-dom active-dom'+item.selfIndex, 'quickKeys_aicreate']"
              v-for="(item,index) in aiHelpWriteListFive"
              :key="index"
              @click.stop="aiHelpWriteFn(item)">
            <i :class="['iconfont',item.icon]"></i>
            <span>{{ item.name }}</span>
          </div>
          <div class="ai-create-show-more-popup" v-if="isShowMoreAiCreate">
            <div
                :class="['item','active-dom active-dom'+item.selfIndex]"
                v-for="(item,index) in aiHelpWriteListFiveMore"
                :key="index"
                @click.stop="aiHelpWriteFn(item)">
              <i :class="['iconfont',item.icon]"></i>
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 生成 -->
    <div class="popup-ai-create-content-bottom-box AIMade" v-if=" popupType === 'AIMade'">
      <div class="update-box">
        <div class="label">优化</div>
        <div class="optimize-list">
          <div
              :class="['item','active-dom active-dom'+item.selfIndex, 'quickKeys_made']"
              v-for="(item,index) in optimizeList"
              :key="index"
              @click.stop="selectOptimizedGeneration('',item)">
            <template v-if="!item.nodes || item.nodes.length === 0">
              <i :class="['iconfont',item.icon]"></i>
              <span>{{ item.name }} {{ item.nodes ? ">" : "" }}</span>
            </template>
            <template v-else>
              <el-popover v-model="optimizeVisible" visible-arrow="true" popper-class="show-more-mode-ai-create"
                          placement="right" width="120" trigger="hover">
                <div class="item" @click.stop="changeStyle('change_style',cell)"
                     v-for="(cell, cellIndex) in MoreOptimizeList" :key="cellIndex">
                  <i :class="['iconfont', cell.icon]"></i>
                  <span class="acci-name">{{ cell.name }}</span>
                </div>
                <span slot="reference" @click.stop="optimizeVisible = !optimizeVisible">
                                    <i :class="['iconfont',item.icon]"></i>
                                    <span>{{ item.name }} {{ item.nodes ? ">" : "" }}</span>
                                </span>
              </el-popover>
            </template>
          </div>
        </div>
      </div>
      <div class="update-box last">
        <div class="label">生成</div>
        <div class="generate-list">
          <div
              :class="['item','active-dom active-dom'+item.selfIndex ,'quickKeys_made']"
              v-for="(item,index) in generateList"
              :key="index"
              @click.stop="selectOptimizedGeneration('',item)">
            <template v-if="!item.nodes || item.nodes.length === 0">
              <i :class="['iconfont',item.icon]"></i>
              <span>{{ item.name }} {{ item.nodes ? ">" : "" }}</span>
            </template>
            <template v-else>
              <el-popover v-model="generateVisible" visible-arrow="true" popper-class="show-more-mode-ai-create"
                          placement="right" width="120" trigger="hover">
                <div class="item" @click.stop="chooseTranslate('translate',cell)"
                     v-for="(cell, cellIndex) in MoreGenerateList" :key="cellIndex">
                  <i :class="['iconfont', cell.icon]"></i>
                  <span class="acci-name">{{ cell.name }}</span>
                </div>
                <span slot="reference" @click.stop="generateVisible = !generateVisible">
                                    <i :class="['iconfont',item.icon]"></i>
                                    <span>{{ item.name }} {{ item.nodes ? ">" : "" }}</span>
                                </span>
              </el-popover>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { requestUrl } from "@/http/requestUrl";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      isShowMoreAiCreate: false, // 是否显示AI生成的更多
      isCreateing: false, //是否正在生成
      aiHelpWriteListFive: [],
      aiHelpWriteListFiveMore: [],
      isShowContinueBtn: false,
      keyCodeIndexAiMade: 1,
      maxNum: 0,
      isShowMoreOptimize: false,
      MoreOptimizeList: [],
      isShowMoreGenerate: false,
      MoreGenerateList: [],
      lastEventId: "",
      aiHelpWriteItem: {},
      popupWidth: 700,
      optimizeVisible: false,
      generateVisible: false,
      firstColumn: [],
      secondColumn: [],
      historyBefore: [], // 历史指令-两行展示的数据
      historyMore: [], // 历史指令-更多数据
      historyVisible: false, // 历史指令-是否显示更多
      pageNo: 1,
      pageSize: 20,
      loadStatus: "loading",
      total: 0,
      isScroll: false,
      historyList: [], // 历史指令 全部数据
      totalElements: 0

    };
  },
  props: {
    ckeditorType: {
      type: String,
      default: "" //normal 普通类型的富文本    title 弹框类型的富文本
    },
    aiCreateContentPopup: {
      type: Boolean,
      default: false
    },
    aiHelpWriteList: {
      type: Array,
      default() {
        return [];
      }
    },
    optimizeList: {
      type: Array,
      default() {
        return [];
      }
    },
    generateList: {
      type: Array,
      default() {
        return [];
      }
    },
    popupType: {
      type: String,
      default: "AiCreate" // AiCreate  AI生成    AIMade  AI制作
    },
    aiCreateContentValue: {
      type: String,
      default: ""
    },
    evtSource: {
      type: Object,
      default() {
        return {};
      }
    },
    evtSourceUid: {
      type: String,
      default: ""
    },
    evtSourceMessageDataListCopy: {
      type: Array,
      default() {
        return [];
      }
    },
    textPlain: {
      type: String,
      default: ""
    },
    positionBtn: {
      type: Object,
      default() {
        return {};
      }
    },
    popupPositionObj: {
      type: Object,
      default() {
        return {};
      }
    }
    // historyList:{
    //     type: Array,
    // 	default(){
    // 		return []
    // 	}
    // },
    // totalElements:{
    // 	type:Number,
    // 	default:0
    // }
  },
  watch: {
    aiHelpWriteList: {
      handler(n) {
        if (n && n.length > 0) {
          let aiHelpWriteListFive = n.slice(0, 5);
          let aiHelpWriteListFiveMore = n.slice(5, n.length);
          aiHelpWriteListFive.forEach((item, index) => {
            item.selfIndex = index + 2;
          });
          aiHelpWriteListFiveMore.forEach((item, index) => {
            item.selfIndex = index + 1;
          });
          this.aiHelpWriteListFive = aiHelpWriteListFive;
          this.aiHelpWriteListFiveMore = aiHelpWriteListFiveMore;
        }
      },
      deep: true,
      immediate: true
    },
    optimizeList: {
      handler(n) {
        n.forEach((item, index) => {
          item.selfIndex = index + 2;
          if (item.code === "change_style") {
            if (item.code === "change_style") {
              item.nodes.forEach((nodesItem, nodesIndex) => {
                nodesItem.selfIndex = nodesIndex + 1;
              });
              this.MoreOptimizeList = item.nodes;
            }
            this.ShowMoreGenerateList = item.nodes;
          }
        });
      },
      immediate: true,
      deep: true
    },
    generateList: {
      handler(n) {
        n.forEach((item, index) => {
          item.selfIndex = index + 3 + this.aiHelpWriteListFive.length;
          if (item.code === "translate") {
            item.nodes.forEach((nodesItem, nodesIndex) => {
              nodesItem.selfIndex = nodesIndex + 1;
            });
            this.MoreGenerateList = item.nodes;
          }
        });
      },
      immediate: true,
      deep: true
    },
    aiCreateContentPopup(n) {
      if (n) {
        this.isCreateing = false;
        this.isShowContinueBtn = false;
        this.keyCodeIndexAiMade = 1;
        this.$nextTick(() => {
          this.$refs.aiCreateContentInput.focus();
          this.isScroll = false;
          this.judgeScroll();
        });
      } else {
        this.isShowMoreGenerate = false;
        this.isShowMoreOptimize = false;
        this.aiCreateContentValues = "";
        this.optimizeVisible = false;
        this.generateVisible = false;
        this.historyVisible = false;
      }
    }

  },
  computed: {
    aiCreateContentValues: {
      get() {
        return this.aiCreateContentValue;
      },
      set(value) {
        this.$emit("update:aiCreateContentValue", value);
      }
    },
    ...mapState({
      // 智能写作次数/容量 套餐使用信息
      combinationInfo: state => state.user.combinationInfo
    }),
    ...mapGetters("user", {
      currentVersion: "getCurrentVersion"
    })

  },
  mounted() {

  },
  methods: {
    chooseHistory(item) {
      this.aiCreateContentValues = item.input + this.aiCreateContentValues;
      this.$refs.aiCreateContentInput.focus();
    },
    //判断滚动条是否滚到底部
    judgeScroll() {
      this.$nextTick(() => {
        let dom = document.querySelector(".history-AIGC");
        if (!dom) {
          return;
        }
        dom.addEventListener("scroll", () => {
          const clientHeight = dom.clientHeight;
          const scrollTop = dom.scrollTop;
          const scrollHeight = dom.scrollHeight;
          if (clientHeight + scrollTop === scrollHeight) {
            console.log("竖向滚动条已经滚动到底部");
            if (this.historyMore.length === 0) {
              // this.loadStatus = 'noMore';
            } else {
              if (this.loadStatus === "noMore") {
                console.log(1);
              } else {
                if (this.historyList.length < this.totalElements && !this.isScroll) {
                  this.loadStatus = "loading";
                  this.pageNo++;
                  this.getUserHistoryCmd(this.popupType);
                } else {
                  this.loadStatus === "noMore";
                }
              }
            }
          }
        });
      });
    },
    // 获取用户历史指令
    getUserHistoryCmd(type) {
      console.log(type, "popupType");
      this.isScroll = true;
      let url = "";
      if (type === "AIMade") {
        url = requestUrl.knowledge.getUserHistoryCmd + "?page=" + this.pageNo + "&size=30&type=1";
      } else {
        url = requestUrl.knowledge.getUserHistoryCmd + "?page=" + this.pageNo + "&size=30&type=0";
      }
      this.$http.get(url).then((res) => {
        // res.data.content = [{
        //     input:"写一篇数字经济的de趋势报告///你到哪马赛克到那时低年级阿萨你大几阿萨你打的解散大家按到你打开圣马可打马赛克代码苏卡达玛卡没打卡拉萨较大",
        //     id:1
        // },{
        //     input:"宠物市场有发展吗",
        //     id:2
        // },{
        //     input:"数字经济领域报告///你到哪马赛克到那时低年级阿萨你大几阿萨你打的解散大家按到你打开圣马可打马赛克代码苏卡达玛卡没打卡拉萨较大",
        //     id:3
        // },{
        //     input:"数字安全在AI时代下有哪些发展",
        //     id:4
        // },{
        //     input:"帮我写一篇数字安全的分析报告，至少2000字",
        //     id:5
        // },{
        //     input:"怎么能在数字经济领域创业",
        //     id:6
        // },{
        //     input:"1111111",
        //     id:6
        // },{
        //     input:"000000就打算京东卡",
        //     id:6
        // },{
        //     input:"mdlamdkamskdmakdmaksdmas",
        //     id:7
        // }]
        res.data.content.forEach(item => {
          item.input = item.input.replaceAll("\n", "");
        });
        console.log(res.data.content, "----");
        if (res.data.totalElements === 0 || res.data.totalElements < 20) {
          this.historyList = res.data.content;
          this.loadStatus = "noMore";
          this.hnadlerData();
        } else {
          if (this.historyList.length === 0) {
            this.historyList = res.data.content;
          }
          this.historyMore = [...this.historyMore, ...res.data.content];
          this.hnadlerData();
          let historyBefore = [...this.firstColumn, ...this.secondColumn];
          let all = [...historyBefore, ...this.historyMore];
          if (all.length < res.data.totalElements) {
            this.loadStatus = "loading";
          } else if (all.length === res.data.totalElements) {
            this.loadStatus = "noMore";
          } else {
            this.loadStatus = "noMore";
          }
          if (this.pageNo === 1) {
            if (all.length < res.data.totalElements || all.length === res.data.totalElements) {
              this.loadStatus = "noMore";
            }
          }
        }
        this.isScroll = false;
      });
    },
    hnadlerData() {
      let modeListObj = this.formateModeList(this.historyList, this.popupWidth);
      console.log(modeListObj, "modeListObj");
      this.firstColumn = modeListObj.firstColumn;
      this.secondColumn = modeListObj.secondColumn;
      this.historyMore = modeListObj.historyMore;
    },
    /**
     * 根据当前模板列表 和 容器宽度 计算 模板可直接展示模板 和 更多... 展示模板
     * @method formateModeList
     * @param {array} modeList 模板数组
     * @param {number} paramBoxWidth 容器宽度
     * @return {secondColumn, firstColumn} 可直接展示/more 模板列表
     */
    formateModeList(modeList, paramBoxWidth) {
      console.log(paramBoxWidth, "paramBoxWidth");
      let withWidthModeList = [], firstColumn = [], historyMore = [], secondColumn = [], list = [];
      modeList.forEach((item, index) => {
        item.width = this.checksum(item.input) * 13 + 20; //  13 每个字符宽度 20 右边距
        if (index === 0) {
          item.preTotalWidth = item.width;
        } else {
          item.preTotalWidth = withWidthModeList[index - 1].preTotalWidth + item.width;
        }
        withWidthModeList.push(item);
      });
      console.log(withWidthModeList, "withWidthModeList");
      // let flag = true;
      withWidthModeList.forEach((item) => {
        if (item.preTotalWidth + 30 < paramBoxWidth) {
          firstColumn.push(item);
        } else {
          if (firstColumn.length === 0) {
            firstColumn.push(item);
          } else {
            list.push(item);
          }
        }
      });
      list.forEach((item, index) => {
        item.width = this.checksum(item.input) * 13 + 20; //  13 每个字符宽度 20 右边距
        if (index === 0) {
          item.preTotalWidth = item.width;
        } else {
          item.preTotalWidth = list[index - 1].preTotalWidth + item.width;
        }
      });
      list.forEach((item) => {
        if (item.preTotalWidth + 30 < paramBoxWidth) {
          secondColumn.push(item);
        } else {
          if (secondColumn.length === 0) {
            secondColumn.push(item);
          } else {
            historyMore.push(item);
          }
        }
      });
      historyMore.forEach((item, index) => {
        secondColumn.forEach(secondColumnItem => {
          if (item.id === secondColumnItem.id) {
            historyMore.splice(index, 1);
          }
        });

      });
      return {
        firstColumn, secondColumn, historyMore
      };
    },
    // 计算字符串中中文字符长度
    checksum(chars) {
      var fuhao = ["，", "。", "；", "！", "：", "【", "】", "…", "？", "“", "”", "—", "·", "、", "《", "》", "（", "）", "￥", "＠"];//一些中文符号
      var fuhao_code = [];
      for (var j = 0; j < fuhao.length; j++) {
        fuhao_code.push(fuhao[j].charCodeAt(0));
      }
      var sum = 0;
      for (var i = 0; i < chars.length; i++) {
        var c = chars.charCodeAt(i);
        if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
          // 数字、字母、英文符号等
          // 数字 1 特殊处理
          if (chars[i] == 1 || chars[i] == "i") {
            sum += 0.75;
          } else {
            sum += 1.2;
          }

        } else if (fuhao_code.indexOf(c) >= 0) {
          // 中文符号
          sum += 2;
        } else {
          // 中文文字
          sum += 2;
        }
      }
      return Math.ceil(sum / 2);
    },
    ...mapActions("user", [
      "updateCombinationInfo"
    ]),
    getPopupWidth() {
      // this.$nextTick(() => {
      let clientWidth = 0;
      if (this.ckeditorType === "popup") {
        clientWidth = document.querySelector(".lighting-popup-box").clientWidth;
      } else {
        clientWidth = document.querySelector(".ckeditor-box").clientWidth;
      }
      console.log(clientWidth, "clientWidth");
      if (clientWidth < 716) {
        this.popupWidth = clientWidth;
      } else {
        this.popupWidth = 716;
      }
      // })
    },
    keyDown() {
      window.addEventListener("keydown", (e) => {
        let domlist = Array.from(document.querySelectorAll(".active-dom"));
        domlist.forEach((item) => {
          item.style.background = "#fff";
        });
        if (this.popupType === "AIMade") {
          // this.maxNum = this.optimizeList.length+this.generateList.length+1;
          if (e.code === "ArrowDown") {
            e.preventDefault();
          }
        } else {
          if (e.code === "ArrowDown") {
            e.preventDefault();
          }
        }
        if (this.keyCodeIndexAiMade > 1) {
          document.querySelector(".active-dom" + this.keyCodeIndexAiMade).style.background = "#EAEFFF";
        }
      });
    },
    // AI生成的更多
    showMoreAiCreate() {
      this.isShowMoreAiCreate = !this.isShowMoreAiCreate;
    },
    //判断页面是否存在滚动条 返回true:存在滚动条 | false:不存在滚动条
    hasScrollbar(dom) {
      return dom.scrollHeight > dom.clientHeight;
    },

    // 选中优化生成
    async selectOptimizedGeneration(type, item) {
      if (type === "btn") {
        if (this.aiCreateContentValues === "") return;
        await this.handlerEvtSource("customize");
        this.closePopup();
      } else {
        if (item.code === "change_style") {
          this.isShowMoreOptimize = !this.isShowMoreOptimize;
          this.isShowMoreGenerate = false;
        } else if (item.code === "translate") {
          this.isShowMoreOptimize = false;
          this.isShowMoreGenerate = !this.isShowMoreGenerate;
        } else {
          this.aiHelpWriteItem = item;
          await this.handlerEvtSource("selectOptimizedGeneration", item);
          this.closePopup();
        }
      }
    },
    async changeStyle(type, item) {
      this.isShowMoreOptimize = false;
      this.optimizeVisible = false;
      this.historyVisible = false;
      this.closePopup();
      await this.handlerEvtSource(type, item);
    },
    async chooseTranslate(type, item) {
      this.isShowMoreGenerate = false;
      this.generateVisible = false;
      this.closePopup();
      await this.handlerEvtSource(type, item);

    },
    closePopup() {
      this.$emit("update:aiCreateContentPopup", false);
    },
    aiHelpWriteFn(item) {
      if (this.popupType === "AiCreate") {
        // this.aiCreateContentValues = item.tips;
        this.aiCreateContentValues = item.tips + this.aiCreateContentValues;
        this.$refs.aiCreateContentInput.focus();
      }
    },
    // 停止
    stopCreate(flag) {
      this.isCreateing = false;
      this.closePopup();
      this.$emit("stopCreate", flag);
    },
    // 继续生成
    async continueCreate() {
      if (this.evtSourceMessageDataListCopy.length > 1999) {
        this.messageFn("warning", "内容过多,无法继续生成");
      } else {
        await this.handlerEvtSource("continue");
      }
    },
    // 立即生成
    async nowCreateFn() {
      if (this.aiCreateContentValues === "") return;
      if (this.popupType === "AIMade") {
        this.closePopup();
        await this.handlerEvtSource("customize");
      } else {
        await this.handlerEvtSource("new");
      }
    },
    async handlerEvtSource(type, item) {
      console.log(this.combinationInfo, this.currentVersion, "this.combinationInfo");
      if (this.combinationInfo.bigModel && !this.combinationInfo.canActivate) {
        this.userBigModelActivate();
      }
      if (!this.combinationInfo.bigModel || (this.combinationInfo.bigModel && !this.combinationInfo.canActivate)) {
        this.messageFn("warning", "暂无权限，请联系企业管理员开通权限");
        return false;
      }
      let params = {
        input: this.aiCreateContentValues,
        userId: sessionStorage.getItem("_clientUid"),
        mainId: sessionStorage.getItem("_mainId"),
        token: sessionStorage.getItem("_token")
        // maxTokens:100
      };
      if (this.popupType === "AiCreate") {
        this.$parent.handlerImgAndIframe();
        await this.$emit("handlerQuickDel");
      }

      let text = "";
      // 选中后的ai生成
      if (this.popupType === "AIMade") {
        console.log(type, "type");
        if (type === "customize") {
          params.cmd = "customize";
          params.cmdItem = this.aiCreateContentValues;
          text = this.aiCreateContentValues;
        } else {
          if (type === "change_style") {
            params.cmd = "change_style";
            text = "变换风格为";
          } else if (type === "translate") {
            params.cmd = "translate";
            text = "翻译";
          } else {
            params.cmd = item.code;
          }
          params.cmdItem = item.code;
          text += item.name;
        }
        params.input = this.textPlain;
        // 直接AI生成
      } else {
        // 继续生成
        if (type === "continue") {
          params.uid = this.evtSourceUid;
          this.evtSourceMessageDataListCopy.forEach((item) => {
            params.input += item;
          });
        }
      }
      // 判断次数是否超出
      await this.$http.post(requestUrl.knowledge.getCount, {
        mainId: sessionStorage.getItem("_mainId"),
        userId: sessionStorage.getItem("_clientUid"),
        vipType: this.currentVersion.vipType,
        expire: this.currentVersion.expire,
        userSource: 11
      }).then((countRes) => {
        this.updateCombinationInfo(countRes.data);
        if (countRes.data.expire) {
          this.messageFn("warning", "企业当前版本已过期，请联系企业管理员续费版本");
          return false;
        }
        console.log('countRes',countRes,this.popupType)
        if (countRes.data.flowSurplus > 0) {
          if (this.popupType === "AIMade") {
            this.$emit("isAigcCreate", true, text);
          }
          this.$http.post(requestUrl.aigc.streamCompletions, params).then((res) => {
            console.log(res, "res");
            if (res.data.code == 505) {
              this.$emit("isAigcCreate", false, text);
              this.$emit("handlerCanUpload", "flow", "");
            } else if (res.data.code == 0) {
              // 使用生成后,会预增加次数,调用接口更新数据
              this.$http.post(requestUrl.knowledge.getCount, {
                mainId: sessionStorage.getItem("_mainId"),
                userId: sessionStorage.getItem("_clientUid"),
                vipType: this.currentVersion.vipType,
                expire: this.currentVersion.expire,
                userSource: sessionStorage.getItem("accountSource")
              }).then((countRes2) => {
                this.updateCombinationInfo(countRes2.data);
              });
              if (type === "new" || (type === "customize" && this.popupType === "AIMade")) {
                this.evtSourceNum = 0;
                this.isCreateing = true;
                this.isShowContinueBtn = false;
                this.$emit("continueCreate", false, true);
                this.$emit("stopCreate", false);
              } else if (type === "continue") {
                this.isShowContinueBtn = false;
                this.isCreateing = true;
                this.$emit("continueCreate", false, false);
                this.$emit("stopCreate", false);
              }
              this.$emit("eventSourceParams", { ...params, textPlain: this.textPlain, uid: res.data.data });

            } else {
              this.messageFn("error", res.message);
            }
          })
              .catch((err) => {
                console.log(err, "err");
                this.messageFn("error", err.message);
              });
          this.lastEventId = "";

        } else {
          this.$emit("handlerCanUpload", "flow", "");
        }
      });

    },
    // 用户大模型能力激活
    userBigModelActivate() {
      this.$http.get(requestUrl.company.userBigModelActivate + "?userId=" + sessionStorage.getItem("_clientUid")).then((res) => {
        if (res.data.code == 0) {
          console.log(res);
        } else {
          this.messageFn("error", res.data.message);
        }
      });
    }
  }
};
</script>
<style lang="less">
.show-more-mode-ai-create {
  padding: 0;
  z-index: 2999 !important;
}

.history-AIGC {
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;

  .look-more {
    margin-top: 10px;
    height: 35px;
    background: #FFFFFF;
    margin-top: 4px;
    color: #A9B3C6;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 10px;
    transform: scale(0.83333);
    transform-origin: 0 0;

    i {
      margin-right: 6px;
    }
  }

}
</style>
<style lang="less" scoped>
.show-more-mode-ai-create {
  padding: 0;

  .item {
    text-align: left;
    height: 35px;
    color: #2c3e50;
    cursor: pointer;
    // margin-bottom: 10px;
    box-sizing: border-box;
    padding: 10px 12px;
    display: flex;
    align-items: center;
  }

  .item:hover {
    background: #EAEFFF;
  }
}

.popup-ai-create-content, .popup-ai-made-content {
  // width: 716px;
  // height: 90px;
  background: #FFFFFF;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.08), 0 0 10px rgba(0, 0, 0, 0.08), 0 0 10px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  position: fixed;
  padding: 15px;
  font-size: 14px;

  &.popup-ai-create-content {
    z-index: 2555;
  }

  .popup-ai-create-content-top-box {
    margin-bottom: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-label {
      margin-right: 20px;
      color: #A9B3C6;

    }

    .look-example {
      color: #366AFF;
      cursor: pointer;

      .iconfont {
        vertical-align: middle;
      }
    }

    .right {
      display: flex;
      align-items: center;

      span {
        color: #A9B3C6;
        margin-right: 6px;
      }

      .iconfont {
        color: #366AFF;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }

  .popup-ai-create-content-middle-box {
    min-height: 31px;
    background: #F3F5FA;
    border-radius: 15px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;

    .guoran-shengchengAI2 {
      position: absolute;
      width: 18px;
      height: 18px;
      background: #FFFFFF;
      border-radius: 50%;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 10px;
      top: 6px;
      color: #366AFF;
    }

    /deep/ .el-textarea {
      width: 600px;
      // position: absolute;
      // right: 10px;
      // left: 25px;
      // top: 0px;
      padding-left: 20px;
      min-height: 30px;
      max-height: 240px;
      border: none;
      font-size: 14px;

      .el-textarea__inner {
        min-height: 30px;
        max-height: 240px;
        border: none;
        background: transparent;
        line-height: 30px;
        padding: 0 0 0 10px;
      }

      input::-webkit-input-placeholder {
        color: #A9B3C6;
        font-size: 14px;
      }

      input::-moz-input-placeholder {
        color: #A9B3C6;
        font-size: 14px;
      }

      input::-ms-input-placeholder {
        color: #A9B3C6;
        font-size: 14px;
      }

    }

    .now-create {
      // position: absolute;
      // right: 4px;
      // top: 4px;
      height: 24px;
      background: #366AFF;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      padding: 0 6px;
      cursor: pointer;
      font-size: 12px;

      .iconfont {
        font-size: 14px;
        margin-right: 4px;

        &.guoran-tongyichicun-kaiqi1 {
          font-size: 12px;
        }
      }

      &.disabled {
        background-color: #C4CEE7;
        color: #FFFFFF;
      }

      span {
        white-space: nowrap;
      }
    }

    .is-createing {
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.continue {
        flex: 1;
      }

      img {
        margin-right: 10px;
        width: 17px;
        height: 17px;
      }

      .guoran-tongyichicun-_duihao-mian {
        color: #09DDD5;
      }

      span {
        color: #366AFF;
      }

      .guoran-icon_duihao-mian2 {
        color: #09DDD5;
        margin-right: 10px;
      }

      .now-create {
        color: #fff;

        span {
          color: #fff;
        }
      }
    }

    .stop-create {
      padding-right: 14px;

      span {
        color: #A9B3C6;
        cursor: pointer;
      }

      .label {
        color: #366AFF;
        margin-right: 13px;
      }
    }
  }

  .popup-ai-create-content-bottom-box {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    position: relative;

    .item {
      cursor: pointer;
      // width: 120px;
      // padding: 2px 3px;
      display: flex;
      align-items: center;
      border-radius: 5px;

      .iconfont {
        margin-right: 4px;
      }
    }

    .ai-create-show-more-popup {
      position: absolute;
      max-height: 400px;
      width: 100px;
      right: -40px;
      top: 35px;
      background: #FFFFFF;
      box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.08), 0 0 10px rgba(0, 0, 0, 0.08), 0 0 10px rgba(0, 0, 0, 0.08);
      border-radius: 10px;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 10px 15px 0;

      .item {
        text-align: left;
        height: 25px;
        color: #2c3e50;
        cursor: pointer;
        margin-bottom: 10px;
        cursor: pointer;
      }

      &.optimize {
        top: -15px;
        right: -130px;
      }

      &.generate {
        top: -15px;
        right: -50px;
      }
    }

    .update-box {
      font-size: 14px;
      position: relative;
      width: 100%;

      .label {
        color: #A9B3C6;
        text-align: left;
        // margin-bottom: 10px;
      }

      .generate-list, .optimize-list, .history-list, .quick-list {
        display: flex;
        justify-content: space-between;

        .item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 25px;
          color: #2c3e50;
          font-weight: 400;
          cursor: pointer;
          white-space: nowrap;
          margin-top: 5px;
          text-align: left;

          .iconfont {
            margin-right: 6px;
          }
        }
      }

      .optimize-list {
        flex-wrap: wrap;
      }

      &.last {
        margin-top: 13px;
        position: relative;
      }

      .history-list {
        justify-content: flex-start;

        .item {
          margin-top: 0;
        }
      }
    }

    &.AIMade {
      flex-direction: column;
    }

    &.history {
      width: 100%;

      .update-box {
        width: 100%;

        .header {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .more-history {
            cursor: pointer;
            color: #366AFF;
          }
        }
      }
    }
  }
}

.active-dom:hover {
  background: #EAEFFF;
}
</style>
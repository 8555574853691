<template>
  <div class="atUserList">
    <template v-if="userList.length">
      <div class="userItem"
           v-for="item in userList" :key="item.id"
           @click.stop="selectUser(item)"
      >
        <div class="userAvatar">
          <span v-if="item.label">{{item.label.substr(-2)}}</span>
          <span v-else-if="item.name">{{item.name.substr(-2)}}</span>
        </div>
        <div class="userInfo">
          <span>{{item.label || item.name}}</span>
          <span class="department" v-if="item.departmentFullName">
            {{item.departmentFullName}}
          </span>
        </div>
      </div>
      <div class="loadMore"
           v-if="hasNextPage"
           @click="loadMore"
      >
        <span>加载更多</span>
      </div>
    </template>
    <template v-else>
      <div class="empty">
        <span>没有匹配结果</span>
      </div>
    </template>
  </div>
</template>

<script>
import { requestUrl } from "@/http/requestUrl";

export default {
  name: "atUserList",
  data(){
    return{
      userList:[],
      currentPage:1,
      staffPageSize:20,
      hasNextPage:false,
      departId:null,
      keyword:""
    }
  },
  methods:{
    selectUser(item){
      this.$emit('selectUser',item)
    },
    getUserList(){
      let uid = sessionStorage.getItem("_uid");
      let mainId = sessionStorage.getItem('_mainId');
      let url = '/portal-api/account/fetch-department-id?userId=' + uid + '&mainId=' + mainId
      this.$http.get(url).then(res => {
        if (res.data.code == 0 && res.data.data){
          let data = this.getRandomArrayElements(res.data.data,1);
          this.departId = data[0] ? data[0] : null;
          if (this.departId){
            this.getDatas(data[0])
          }
        }
      })
    },
    getDatas(departId){
      let userId = sessionStorage.getItem("_uid");
      let corpId = sessionStorage.getItem('_corpId')
      let url = '/portal-api/department/corpListLazy-v2' + "?departId=" + departId  + '&userId=' + userId + '&corpId=' + corpId  + '&keyword=' + '&page=' + this.currentPage + '&size=' + this.staffPageSize
      this.$http.get(url).then(res => {
        if (this.currentPage === 1){
          this.userList = res.data.list;
        } else {
          this.userList = this.userList.concat(res.data.list);
        }
        this.hasNextPage = res.data.hasNextPage
      })
    },
    loadMore(){
      this.currentPage += 1;
      if (this.keyword){
        this.searchDates(this.keyword)
      } else if (this.departId){
        this.getDatas(this.departId);
      }

    },
    getRandomArrayElements(arr, count) {
      var shuffled = arr.slice(0),
          i = arr.length,
          min = i - count,
          temp, index;
      while (i-- > min) {
        index = Math.floor((i + 1) * Math.random());
        temp = shuffled[index];
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
      }
      return shuffled.slice(min);
    },
    searchDates(val){
      console.log(val)
      this.keyword = val;
      let corpId = sessionStorage.getItem('_corpId')
      let url = requestUrl.department.newSearchUser + '?keyword=' + val + '&corpId=' + corpId + '&page=' + this.currentPage + '&size=' + this.staffPageSize;
      this.$http.get(url).then(res => {
        if (res.data.code == 0 && res.data.data){
          if (this.currentPage === 1){
            this.userList = res.data.data.list;
          } else {
            this.userList = this.userList.concat(res.data.data.list);
          }
          this.hasNextPage = res.data.data.hasNextPage
        }
      })
    }
  },
  mounted() {
    this.getUserList();
  }
};
</script>

<style scoped lang="less">
.atUserList{
  background-color: #ffffff;
  padding: 8px 0;
  width: 360px;
  max-height: 420px;
  box-sizing: border-box;
  border: 1px solid #dee0e3;
  box-shadow: 0 6px 24px rgba(31,35,41,0.08);
  border-radius: 6px;
  line-height: 20px;
  overflow-y: scroll;
  .userItem{
    height: 48px;
    box-sizing: border-box;
    padding: 4px 8px;
    margin: 0 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    .userAvatar{
      flex: none;
      width: 31px;
      height: 31px;
      background: #366AFF;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      font-size: 12px;
      margin-right: 8px;
    }
    .userInfo{
      display: flex;
      flex-direction: column;
      color: #1f2329;
      .department{
        color: #85959e;
        font-size: 12px;
      }
    }
  }
  .userItem:hover{
    background-color: #F3F6FF;
  }
  .loadMore{
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #366aff;
    cursor:pointer;
  }
  .empty{
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
import Vue from 'vue';
import Vuex from 'vuex';
import apphandle from "./moudles/apphandle";
import user from "@/store/moudles/user";
import news from '@/store/moudles/news'
import plugin from "@/store/moudles/plugin";
import botConfig from "@/store/moudles/botConfig";
import knowledge from "@/store/moudles/knowledge";
import modelFile from "@/store/moudles/modelFile";
import chatBased from "@/store/moudles/chatBased";
Vue.use(Vuex);
const store = new Vuex.Store({
    modules: {
        apphandle,
        user,
        news,
        plugin,
        botConfig,
        knowledge,
        modelFile,
        chatBased
    },
});
export default store;
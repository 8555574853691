const state = () => ({
    pluginInfo:{
        name:"",
        des:"",
        status:"",
        pluginType:""
    },
    pluginToolInfo:{
        name:"",
        des:"",
        status:"",
    },
    pluginAndToolEdit:{
        toolEdit:false,
        pluginEdit:false
    }
})
const getters = {
    getPluginInfo:(state) => {
        return state.pluginInfo
    },
    getPluginToolInfo:(state) => {
        return state.pluginToolInfo
    },
    getPluginToolEditStatus:(state) => {
        return state.pluginAndToolEdit.toolEdit
    }
}

const actions = {
    updatePlugin({ commit }, plugin) {
        commit("setPlugin", plugin);
    },
    updatePluginTool({ commit }, pluginTool){
        commit("setPluginTool", pluginTool);
    },
    updatePluginToolEditStatus({ commit }, status){
        commit("setPluginToolEditStatus", status);
    }
}

const mutations = {
    setPlugin(state,pluginInfo){
        state.pluginInfo = pluginInfo
    },
    setPluginTool(state,pluginTool){
        state.pluginToolInfo = pluginTool
    },
    setPluginToolEditStatus(state,status){
        state.pluginAndToolEdit.toolEdit = status;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
import axios from 'axios';
import { ssoCookieChanged, initSsoUid } from './cookie';
import { isInnerIP } from './ip';

const instance = axios.create();

let doingReSetStorage = false;

function doReSetStorage(onSuccess, onException, token) {
    if (doingReSetStorage) {
        return;
    }
    doingReSetStorage = true;
    let url = '/knowledge-api/sso/auth/managerAuth';
    if (token != null) {
        url = url + '?token=' + token + '&setJwtCookie=true';
    } else {
        url += '?setJwtCookie=true'
    }
    instance.get(url)
        .then((res) => {
            console.log(21, res.data.data.userId)
            sessionStorage.setItem("_uid", res.data.data.userId);
            sessionStorage.setItem('_mainId', res.data.data.mainId)
            // sessionStorage.setItem("accountType", res.data.data.accountType);
            sessionStorage.setItem("roleId", res.data.data.roleId);
            sessionStorage.setItem("username", res.data.data.username);
            sessionStorage.setItem("realname", res.data.data.realName);
            sessionStorage.setItem("loginSuccessTimes", res.data.data.loginSuccessTimes);
            sessionStorage.setItem('rolePrivileges', JSON.stringify(res.data.data.rolePrivileges))
            sessionStorage.setItem('_token',token);
            sessionStorage.setItem("accountSource", res.data.data.source);
            sessionStorage.setItem('_groupIds',JSON.stringify(res.data.data.groupIds));
            initSsoUid();
            doingReSetStorage = false;
            if (onSuccess) {
                onSuccess(res);
            }
        }).catch((err) => {
            doingReSetStorage = false;
            console.info('do auth exception', err);
            if (onException) {
                onException(err);
            }
        });
}

function doCheckAndRestStorageInfo() {
    if (isInnerIP) {
        return;
    }
    if (ssoCookieChanged()) {
        doReSetStorage();
    }
}

export { doCheckAndRestStorageInfo, doReSetStorage }
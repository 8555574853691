<template>
    <div class="automation_view">
        <iframe id="iframeDom" ref="iframeDom" :src="iframeSrc" frameborder="0"></iframe>
        <div class="mark" v-if="markFlag"></div>
    </div>
</template>

<script>
// 'http://localhost:8086/#/'
export default {
    data () {
        return {
            iframeSrc: 'https://wisdom.askbot.cn/automated-processes/index.html',
            // iframeSrc:'http://localhost:8083/#/',
            markFlag: false
        }
    },
    created() {
        this.iframeSrc = process.env.VUE_APP_NODE_ENV == 'production' ? 'https://wisdom.askbot.cn/automated-processes/index.html' : 'https://wisdom.test.askbot.cn/automated-processes/index.html'
    },
    mounted () {
        // let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
        // this.iframeSrc = 'http://localhost:8086/#/'
        window.addEventListener("message", (e) => {
            if (e.data.type == 'toDetail') {
                this.$emit('automationDetail', true)
            } else if (e.data.type == 'toList') {
                this.$emit('automationDetail', false)
            } else if (e.data.type == 'openMark') {
                this.markFlag = e.data.data
            }
        })
        setTimeout(() => {
            let automated = JSON.parse(localStorage.getItem("automated"));
            // 在详情页刷新，返回当前数据详情
            if(automated) {
                let iframeDom = document.getElementById("iframeDom")
                let _window = iframeDom.contentWindow
                _window.postMessage({ type: 'backDetail', data: automated.id }, '*')
            }
        }, 100)
    },
    methods: {
        addAutomation () {
            let iframeDom = document.getElementById("iframeDom")
            let _window = iframeDom.contentWindow
            _window.postMessage({ type: 'addAutomation', data: '' }, '*')
            // setTimeout(() => {
            //     // 延迟200ms 的动画帧
            //     this.markFlag = true
            // }, 100)
        },
        adjustPriority(automationSort) {
            let iframeDom = document.getElementById("iframeDom")
            let _window = iframeDom.contentWindow
            _window.postMessage({ type: 'adjustPriority', data: automationSort }, '*')
        }
    }
}
</script>

<style lang="less" scoped>
.automation_view {
    width: 100%;
    height: 100%;

    iframe {
        width: 100%;
        height: 100%;
        border: none;
        position: relative;
        z-index: 999;
    }

    .mark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        opacity: .5;
        background: #000;
        z-index: 99;
    }
}
</style>

<template>
    <div class="bot_pup">
        <div class="title">
            <section class="title_left">
                <!-- AskBot智能助手 -->
                <i class="iconfont guoran-tongyichicun-shiyongqingkuang"></i>
                <span>AI协作</span>
            </section>
            <section class="title_right">
                Esc
                <span @click="closeAi">
                    <i class="el-icon-close"></i>
                </span>
            </section>
        </div>
        <div class="content" ref="content">
            <div class="welcome_message">
                <span>
                    <b>AskBot智能助手</b>
                    将为你服务
                </span>
            </div>
            <div v-for="(message, index) in messageList" :key="message.id" class="message_body animate__animated animate__zoomIn">
                <p class="now_time" v-if="message.type == 'event' || (message.type == 'aiMessage' && message.data !== '')"> AskBot {{ message.time }}</p>
                <div class="message_cont text_color_theme" v-if="message.type == 'loading'">
                    <img src="./images/loading.gif" alt="" >
                    <span>正在生成中</span>
                </div>
                <div class="message_cont text_color_theme" v-if="message.type == 'event'" :key="message.id" >
                    {{ message.data }}
                </div>
                <div class="message_cont text_color_theme message_aiMessage" v-if="message.type == 'aiMessage' && message.data !== ''" :key="message.id" >
                    <span v-html="message.data" class="">
                    </span>
                    <section class="message_continue_building" v-if="message.continue && (index == messageList.length - 1)"  @click="continueCreate">
                        <span>
                            继续生成
                        </span>
                    </section>
                    <section class="message_btn"  :style="{justifyContent: flexFn(index)}" v-if="message.btnShow">
                        <span class="aigin" @click="aiginWrite" v-if=" index == messageList.length - 1">
                            <i class="iconfont guoran-zhongxie"></i>
                            重写
                        </span>
                        <span class="copy" @click="copyMessage(message)">
                            <i class="iconfont guoran-tongyichicun-fuzhi"></i>
                            复制
                        </span>
                        <span class="replace" v-if="message.isOnReplace" @click="AigcReplaceMessage(message)">
                            <i class="iconfont guoran-tidai"></i>
                            替代
                        </span>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default  {
    data() {
        return {
            messageList:[],
            aiTitle:''
        }
    },
    props:{
        AigcConfig: {
            type: Object,
            require: true
        }
    },
    mounted() {
        // this.onMessage()
    },
    methods: {
        closeAi() {
            this.$emit('closeAi')
        },
        init(name) {
            console.log('init');
            this.messageList = []
            this.messageList.push({
                type:'loading',
                data: '',
                time: new Date().Format('MM/dd hh:mm:ss')
            })
            this.aiTitle = name
            // start 插入测试代码
            // this.messageList.push({
            //     type: 'aiMessage',
            //     data: name + '后生成如下',
            //     time: new Date().Format('MM/dd hh:mm:ss')
            // })
            // end
        },
        onMessage(flag) {
            if(!flag) {
                this.messageList[0] = {
                    type: 'event',
                    data: this.aiTitle + '后生成如下',
                    time: new Date().Format('MM/dd hh:mm:ss')
                }
            }
            let isHaveLoadingBoo = false,isHaveLoadingIndex = -1
            this.messageList.forEach((item,index) => {
                if(item.type === 'loading'){
                    isHaveLoadingBoo = true;
                    isHaveLoadingIndex = index;
                }
            })
            if(isHaveLoadingBoo){
                this.$set(this.messageList,isHaveLoadingIndex,{
                    type: 'aiMessage',
                    data: '',
                    time: new Date().Format('MM/dd hh:mm:ss'),
                    btnShow:false,
                    continue:false,
                    isOnReplace:true,
                })
            } else {
                this.messageList.push({
                    type: 'aiMessage',
                    data: '',
                    time: new Date().Format('MM/dd hh:mm:ss'),
                    btnShow:false,
                    continue:false,
                    isOnReplace:true,
                })
            }
        },
        setMessageText(text) {
            let flag = false;
               //匹配这些中文标点符号 。 ？ ！ ， 、 ； ： “ ” ‘ ' （ ） 《 》 〈 〉 【 】 『 』 「 」 ﹃ ﹄ 〔 〕 … — ～ ﹏ ￥
            var reg = /[\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]/;
            if(reg.test(text)){
                flag = true; // 是中文标点符号
            }else{
                flag = false// 不是中文标点符号
            }
            if((text == '<br/>' && !this.messageList[this.messageList.length - 1].data) || flag && !this.messageList[this.messageList.length - 1].data) {
                return
            }
            this.messageList[this.messageList.length - 1].data = this.messageList[this.messageList.length - 1].data + text
            if(this.$refs.content.scrollHeight > this.$refs.content.clientHeight) {
                this.$refs.content.scrollTop = this.$refs.content.scrollHeight
            }
        },
        onceEnd() {
            this.messageList[this.messageList.length - 1].btnShow = true;
            // this.messageList.shift()
        },
        continue(flag) {
            this.messageList[this.messageList.length - 1].continue = flag
        },
        aiginWrite() {
            this.$emit('aiginWrite')
        },
        continueCreate(){
            this.$emit('continueCreate')
        },
        flexFn(index) {
           this.$nextTick(() => {
                let aiMessage = document.getElementsByClassName('message_aiMessage')[index]
                if(!aiMessage) return 'flex-start'
                if(aiMessage.clientWidth < 190) {
                    return 'flex-start'
                } else {
                    return 'flex-end'
                }
           })
        },
        copyMessage(message) {
            navigator.clipboard.writeText(message.data)
            this.messageFn('success', '复制成功');
        },
        AigcReplaceMessage(message) {
            message.isOnReplace = false
            this.$emit('AigcReplaceMessage', message.data)
        },
        wrapLabelReplacement(value,type) {
            let list = value.split('<br/>').filter(v => {  return !!v })
            return list.map(item => {
                if(type == 'copy') {
                    return '<p>' + item + '</p>'
                } else {
                    return '<p><span style="background-color:#CDDCFF;">' + item + '</span></p>'
                }
            }).join('')
        },
        concatList(){
            this.messageList.push({
                type:'loading',
                data: '',
                time: new Date().Format('MM/dd hh:mm:ss')
            })
        }
    },
    computed:{
    }
}
</script>
<style lang="less" scoped>
.bot_pup {
    width: 387px;
    height: 100%;
    // background: #F6F8FB;
    // box-shadow: 0px 0px 10px 0px rgba(79,82,92,0.17);
    // border-radius: 5px;
    .text_color_theme {
        color: #366AFF;
    }
    .title {
        width: 100%;
        height: 46px;
        background: #FFFFFF;
        border-radius: 5px 5px 0px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        box-sizing: border-box;
        .guoran-tongyichicun-shiyongqingkuang{
            margin-right: 6px;
        }
        .title_right {
            display: flex;
            align-items: center;
            span {
                background-color: #366aff;
                color: #FFFFFF;
                border-radius: 50%;
                width: 24px;
                height: 24px;
                margin-left: 15px;
                cursor: pointer;
                line-height: 24px;
                text-align: center;
            }
        }
    }
    .content {
        width: 100%;
        height: calc(100% - 46px);
        overflow-x: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        padding: 10px 10px 0 10px;
        .welcome_message {
            width: 100%;
            height: 20px;
            text-align: center;
            line-height: 20px;
            font-size: 12px;
            margin: 20px 0;
            span {
                padding: 2px 5px;
                background: #E1E9FF;
                border-radius: 5px;
                font-weight: 400;
                color: #000000;
                b {
                    color: #4071FF;
                    font-weight: 400;
                }
            }
        }
        .message_body {
            width: 100%;
            min-height: 60px;
            margin-bottom: 10px;
            overflow: hidden;
            // display: flex;
            // flex-direction: column;
            // justify-content: flex-start;
            text-align: left;

            // animation: bounce; /* referring directly to the animation's @keyframe declaration */
            // animation-duration: 2s; /* don't forget to set a duration! */
    
            .now_time {
                font-size: 12px;
                font-weight: 400;
                color: #A9B3C6;
                margin-bottom: 10px;
            }
            .message_cont {
                // min-width: 100px;
                max-width: 80%;
                min-height: 20px;
                background: #FFFFFF;
                border-radius: 0 20px 20px 20px;
                position: relative;
                margin-left: 12px;
                padding: 10px;
                box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.03);
                // flex:  0 0 auto;
                float: left;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                img {
                    // vertical-align: middle;
                    margin-right: 10px;
                }

            }
            .message_cont::after {
                content: ' ';
                position:absolute;
                left: -9px;
                top: 0;
                width: 0;
                height: 0;
                border-top: 6px solid #FFFFFF;
                border-right: 6px solid #FFFFFF;
                border-bottom: 6px solid transparent;
                border-left: 6px solid transparent;
            }
            .message_continue_building {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                span {
                    width: 134px;
                    height: 31px;
                    cursor: pointer;
                    background: #FFFFFF;
                    border: 1px solid #A1B9FF;
                    color:#366AFF;
                    border-radius: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 400;
                    color: #366AFF;
                    font-size: 12px;
                }
            }
            .message_aiMessage {
                margin-bottom: 50px;
                color: #000000;
                font-size: 14px;
                font-weight: 400;
                .message_btn {
                    position: absolute;
                    right: 0;
                    bottom: -30px;
                    width: 100%;
                    display: flex;
                    // align-items: center;
                    // justify-content: flex-end;
                    .aigin, .copy, .replace {
                        width: 55px;
                        height: 20px;
                        border-radius: 12px;
                        background: #FFFFFF;
                        border: 1px solid #366AFF;
                        color:#366AFF;
                        margin-right: 5px;
                        flex: none;
                        text-align: center;
                        line-height: 20px;
                        font-size: 12px;
                        float: right;
                        cursor: pointer;
                        i {
                            font-size: 12px;
                            
                        }
                    }
                    .replace {
                        color: #FFFFFF;
                        background: #366AFF;
                    }
                }
                span {
                    word-break:break-all
                }
            }
        }
    }
}
</style>
<template>
    <popup-com @closeEvent="cancelPermission" v-if="permissionDialog">
        <div slot="popup-name">权限设置</div>
        <div slot="popup-tip"></div>
        <div id="employeeStructure" slot="popup-con">
            <div class="staff-customer-input">
                <el-input
                    v-model="keyword"
                    placeholder="搜索"
                    size="small"
                    ref="serchInput"
                    clearable
                    @keyup.enter.native="searchEmployee"
                    @input="monitorSearchNull"
                ></el-input>
            </div>
            <el-select
                v-model="corpId"
                placeholder="请选择"
                v-if="corpList.length > 1"
                size="small"
                style="width: 100%; margin-bottom: 10px"
            >
                <el-option
                    v-for="item in corpList"
                    :key="item.originData.corpId"
                    :label="item.label"
                    :value="item.originData.corpId"
                >
                </el-option>
            </el-select>
            <el-tabs v-model="activeTabName" @tab-click="tabsHandleClick">
                <el-tab-pane label="员工" name="member">
                    <div v-for="(item, index) in treeData" :key="index">
                        <el-tree
                            key="selectOrganizationTreeLazy"
                            :data="[item]"
                            id="selectOrganizationTreeLazy"
                            class="member-tree"
                            node-key="id"
                            :default-expanded-keys="defaultExpandedKeysMember"
                            :expand-on-click-node="false"
                            :ref="item.id + 'TreeLazy'"
                        >
                            <div
                                class="custom-tree-node"
                                slot-scope="{ node, data }"
                                @click="onNodeClick(data)"
                            >
                                <div class="custom-tree-node-left">
                                    <!-- // 员工树结构的数据 type=0是员工，type=1是部门 -->
                                    <i
                                        :class="[
                                            'el-icon-caret-right',
                                            data.showChildren
                                                ? 'down'
                                                : 'right',
                                        ]"
                                        v-show="data.type == 1"
                                        @click.stop="nodeExpand(data, node)"
                                    >
                                    </i>
                                    <span
                                        class="data-label"
                                        v-if="isUseOpenData()"
                                    >
                                        <open-data
                                            :type="
                                                data.type == 1
                                                    ? 'departmentName'
                                                    : 'userName'
                                            "
                                            :openid="data.label"
                                        ></open-data>
                                    </span>
                                    <span v-else class="data-label">{{
                                        data.label
                                    }}</span>
                                    <div class="member-infos">
                                        <template
                                            v-if="data.departments && keyword"
                                        >
                                            <div
                                                v-for="(
                                                    cell, index
                                                ) in data.departments"
                                                class="member-data"
                                                :key="index"
                                            >
                                                <el-tooltip
                                                    placement="top"
                                                    effect="light"
                                                >
                                                    <div slot="content">
                                                        (<span
                                                            v-if="
                                                                isUseOpenData()
                                                            "
                                                        >
                                                            <span
                                                                v-if="
                                                                    data.label ==
                                                                    1
                                                                "
                                                                >{{
                                                                    companyName
                                                                }}</span
                                                            >
                                                            <!-- <iframe v-else :src="cell.originDataSlotIframeUrl" frameborder="0"></iframe> -->
                                                            <open-data
                                                                :type="'departmentName'"
                                                                :openid="
                                                                    cell.fullName
                                                                "
                                                            ></open-data>
                                                        </span>
                                                        <span
                                                            v-else
                                                            v-html="
                                                                cell.fullName
                                                            "
                                                        ></span
                                                        >)
                                                    </div>
                                                    <div v-if="isUseOpenData()">
                                                        (
                                                        <span
                                                            v-if="
                                                                data.label == 1
                                                            "
                                                            >{{
                                                                companyName
                                                            }}</span
                                                        >
                                                        <!-- <iframe v-else :src="cell.originDataIframeUrl" frameborder="0"></iframe> -->
                                                        <open-data
                                                            :type="'departmentName'"
                                                            :openid="cell.name"
                                                        ></open-data>
                                                        )
                                                    </div>
                                                    <span v-else
                                                        >({{ cell.name }})</span
                                                    >
                                                </el-tooltip>
                                            </div>
                                        </template>
                                        <span class="member-info">
                                            <span
                                                class="job-num"
                                                v-if="data.jobNumber"
                                                >({{ data.jobNumber }}</span
                                            >
                                            <template
                                                v-if="
                                                    data.jobNumber &&
                                                    !data.mobile
                                                "
                                                >)</template
                                            >
                                            <template
                                                v-if="
                                                    !data.jobNumber &&
                                                    data.mobile
                                                "
                                                >(</template
                                            >
                                            <span
                                                class="mobile"
                                                v-if="data.mobile"
                                            >
                                                <template v-if="data.jobNumber">
                                                    <b>|</b>
                                                </template>
                                                {{ data.mobile }})
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <i
                                    :class="['el-icon-check']"
                                    v-show="data.checked"
                                ></i>
                            </div>
                        </el-tree>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="员工标签" name="lable">
                    <div
                        v-for="(labelCell, labelCellIndex) in tagsOptions"
                        :key="labelCellIndex"
                        @click="selectTags(labelCell)"
                        class="ask-label-list"
                    >
                        <div class="ask-label-list-cell">
                            <svg
                                t="1717491346363"
                                class="icon allc-icon"
                                viewBox="0 0 1024 1024"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                p-id="39820"
                                width="16"
                                height="16"
                            >
                                <path
                                    d="M951.90495 457.808058l-173.915911 490.545171c-20.972213 59.336016-85.93492 90.027059-145.270937 69.054846l-409.725424-147.317006c-65.474225-23.5298-94.630716-99.74589-61.382086-161.127976l42.455943-78.262159 309.468017 146.805489c0.511517-0.511517 438.370398-319.698365 438.370398-319.698365z"
                                    fill="#7A7AF9"
                                    p-id="39821"
                                ></path>
                                <path
                                    d="M73.629602 442.974054L489.493235 31.714078C512 9.71883 542.691043-1.534552 573.382086 1.534552l301.283739 28.644974c50.640221 4.603656 90.538577 45.525047 94.119198 96.676785l18.926144 288.495804c2.04607 30.179526-9.207313 59.847534-30.691043 81.331264l-414.329081 412.794529c-44.502012 44.502012-117.137481 44.502012-161.639493 0.511517l-307.421948-303.329808c-45.525047-45.01353-45.525047-118.672033 0-163.685563z"
                                    fill="#CCDAFF"
                                    p-id="39822"
                                ></path>
                                <path
                                    d="M728.371853 194.888123c0 36.829252 29.668008 67.008777 66.49726 67.008777s67.008777-29.668008 67.008777-66.49726-29.668008-67.008777-66.49726-67.008777c-37.340769 0-67.008777 29.668008-67.008777 66.49726zM481.308957 670.087772L312.50822 501.287036M481.308957 711.009163c-10.230348 0-20.972213-4.092139-29.156491-11.7649l-168.800736-168.800736c-15.857039-15.857039-15.857039-41.944425 0-57.801465 15.857039-15.857039 41.944425-15.857039 57.801464 0l168.800736 168.800737c15.857039 15.857039 15.857039 41.944425 0 57.801464-7.672761 7.672761-18.414626 11.7649-28.644973 11.7649z"
                                    fill="#7A7AF9"
                                    p-id="39823"
                                ></path>
                            </svg>
                            <span class="allc-name">{{ labelCell.name }}</span>
                            <span
                                v-show="isCheckedLabel(labelCell.id)"
                                class="allc-checkd"
                            >
                                <i class="el-icon-check"></i>
                            </span>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>

            <span
                v-if="keyword && staffHasNext"
                class="hasNextPage"
                @click="loadStaffMore"
            >
                查看更多
            </span>
        </div>
        <div slot="dialog-footer">
            <el-button
                plain
                size="small"
                @click="cancelPermission"
                class="cancel-btn"
                >取 消</el-button
            >
            <el-button
                type="primary"
                size="small"
                @click="confirmPermission"
                class="confirm-btn"
                >确 认</el-button
            >
        </div>
    </popup-com>
</template>

<script>
import OpenData from "@/components/openData";
import PopupCom from "@/components/popup";
import { requestUrl } from "@/http/requestUrl";
export default {
    name: "employeeStructure",
    components: { PopupCom, OpenData },
    data() {
        return {
            keyword: "",
            activeName: "staff",
            treeData: [],
            defaultExpandedKeysMember: [],
            staffHasNext: false,
            corpId: "",
            corpList: [],
            currentPage: 1,
            pageSize: 20,
            permissionDialog: false,
            checkedList: [],
            checkIds: [],
            currentUrl: "https://portal.test.askbot.cn/open-data.html",
            companyName: "",
            checked: false,
            activeTabName: "member",
            tagsOptions: [],
            selectTagsIds: [],
        };
    },
    props: ["permissionList"],
    methods: {
        tabsHandleClick(value) {
            console.log(value);
        },
        isCheckedLabel(id) {
            return this.selectTagsIds.includes(id);
        },
        selectTags(data) {
            let id = data.id;
            if (this.selectTagsIds.includes(id)) {
                this.$emit("onNodeClick", {
                    checked: false,
                    id: id,
                    type: 2,
                    name: data.name,
                });
                this.selectTagsIds = this.selectTagsIds.filter(
                    (item) => item !== id
                );
            } else {
                this.selectTagsIds.push(id);
                this.$emit("onNodeClick", {
                    checked: true,
                    id: id,
                    type: 2,
                    name: data.name,
                });
            }
        },
        getLables() {
            this.$http
                .get("/api-open/tenant/customize-fields/tags?corpId=" + sessionStorage.getItem("_corpId"))
                .then((res) => {
                    if (res.data.code == 0) {
                        this.tagsOptions = res.data.data;
                        if (this.tagsOptions) {
                            let tagListIds = [];
                            if (res.data.data && res.data.data.length != 0) {
                                tagListIds = res.data.data.map(item => item.id);
                            }
                            localStorage.setItem("ask_tagListIds", tagListIds.join(","));
                            console.log("permissionList", this.permissionList);
                            this.selectTagsIds = this.permissionList.map(item => {
                                return item.id;
                            })
                            this.tagsOptionsIds = this.tagsOptions.map(
                                (tag) => {
                                    return tag.id;
                                }
                            );
                        }
                    }
                });
        },
        searchEmployee(type) {
            let keyword = this.keyword.trim();
            let url =
                requestUrl.department.newSearchUser +
                "?keyword=" +
                keyword +
                "&corpId=" +
                this.corpId +
                "&page=" +
                this.currentPage +
                "&size=" +
                this.pageSize;
            if (!keyword) {
                return;
            }
            this.$http.get(url).then((res) => {
                if (res.data.code == "0" && res.data.data) {
                    if (type !== "load") {
                        this.treeData = [];
                        this.currentPage = 1;
                    }
                    let list = res.data.data.list ? res.data.data.list : [];
                    this.staffHasNext = res.data.data.hasNextPage;
                    let memberId = this.permissionList.map((res) => {
                        return Number(res.id) || Number(res.memberId);
                    });
                    let ids = memberId ? memberId : [];
                    list.forEach((item) => {
                        item.label = item.name;
                        // if(item.departments){
                        //   item.departments.forEach(v => {
                        //     v.originDataIframeUrl = `${this.currentUrl}?corpId=${this.corpId}&openid=${v.name}&type=departmentName&style=2&datas=${item.id + ',' + item.type + '','' + item.label}`;
                        //     v.originDataSlotIframeUrl = `${this.currentUrl}?corpId=${this.corpId}&openid=${v.fullName}&type=dedepartmentNamept&style=2&datas=${item.id + ',' + item.type + ',' + item.label}`
                        //   })
                        // }
                        // item.iframeUrl = `${this.currentUrl}?corpId=${this.corpId}&openid=${item.label}&type=${item.type == 1 ? 'departmentName' : 'userName'}&style=2&datas=${item.id + ',' + item.type + ',' + item.label}`
                        this.$set(item, "checked", false);
                        if (ids.includes(item.id)) {
                            this.$set(item, "checked", true);
                        }
                    });
                    this.treeData = [...this.treeData, ...list];
                }
            });
        },
        monitorSearchNull() {
            if (!this.keyword) {
                this.treeData = [];
                this.getData(null);
            }
        },
        loadStaffMore() {
            this.currentPage += 1;
            this.searchEmployee("load");
        },
        // 节点被展开
        nodeExpand(data, node) {
            data.showChildren = !data.showChildren;
            if (data.showChildren) {
                this.getData(data.id, data, node);
            } else {
                data.children = [];
            }
        },
        // 获取员工数据
        getData(departId, data) {
            console.log(this.permissionList, "this.permissionList");
            let axiosDatas = [];
            let userId = sessionStorage.getItem("_uid");
            let corpId = sessionStorage.getItem("_corpId");
            // let url = departId ? '/portal-api/department/corpListLazy'+'?departId='+ departId+"&userId="+ userId : '/portal-api/department/corpListLazy'+'?userId='+ userId
            let url = departId
                ? "/portal-api/department/corpLazy" +
                  "?departId=" +
                  departId +
                  "&userId=" +
                  userId +
                  "&corpId=" +
                  corpId
                : "/portal-api/department/corpLazy" +
                  "?userId=" +
                  userId +
                  "&corpId=" +
                  corpId;
            this.$http.get(url).then((res) => {
                let memberId = this.permissionList.map((res) => {
                    return Number(res.id) || Number(res.memberId);
                });
                let ids = memberId;
                axiosDatas = this.handlerCheckedSelf(
                    res.data || [],
                    ids,
                    departId ? departId : -1
                );
                if (departId) {
                    data.children = [...data.children, ...axiosDatas];
                    this.defaultExpandedKeysMember = [
                        ...this.defaultExpandedKeysMember,
                        ...[departId],
                    ];
                    data.showChildren = true;
                } else {
                    this.treeData = [...axiosDatas];
                    this.defaultExpandedKeysMember = [];
                    this.corpList = JSON.parse(JSON.stringify(axiosDatas));
                }

                this.treeData.forEach((item) => {
                    if (!departId) {
                        item.showChildren = false;
                        item.children = [];
                    }
                    this.$nextTick(() => {
                        let ref = item.id + "TreeLazy";
                        setTimeout(() => {
                            this.getNodeChecked(ref, item, ids);
                        }, 500);
                    });
                });
                if (this.treeData.length > 0) {
                    this.corpId = this.treeData[0].originData
                        ? this.treeData[0].originData.corpId
                        : "";
                }
                console.log(this.treeData, "treeData");
            });
        },
        getNodeChecked(ref, data, ids) {
            if (ids.indexOf(data.id) > -1) {
                if (this.$refs[ref] && this.$refs[ref][0]) {
                    this.$set(
                        this.$refs[ref][0].getNode(data.id).data,
                        "checked",
                        true
                    );
                }
            }
            if (data.children) {
                data.children.forEach((item) => {
                    this.getNodeChecked(ref, item, ids);
                });
            }
        },
        handlerCheckedSelf(datas, ids) {
            datas.forEach((item) => {
                // item.iframeUrl = `${this.currentUrl}?corpId=${this.corpId}&openid=${item.label}&type=${item.type == 1 ? 'departmentName' : 'userName'}&style=2&datas=${item.id + ',' + item.type + ',' + item.label}`
                item.checked = false;
                item.showChildren = false;
                if (item.type === 1) {
                    item.selfType = 0;
                } else if (item.type === 0) {
                    item.selfType = 1;
                }
                if (ids && ids.indexOf(item.id) > -1) {
                    // type类型 -- 保存接口需要 ,0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段

                    this.handlerCheckedSelf(
                        item.children || [],
                        ids,
                        item.id || null
                    );
                } else {
                    this.handlerCheckedSelf(
                        item.children || [],
                        ids,
                        item.id || null
                    );
                }
            });
            return datas;
        },

        onNodeClick(data) {
            data.checked = !data.checked;
            // let isWeWorkThirdData = false;
            // isWeWorkThirdData = data.isWeWorkThirdData ||
            //     item.isWeWorkThirdData || (item.originData && item.originData.wechatBotDTO && item.originData.wechatBotDTO.bindType === 0 && item.originData.wechatBotDTO.wechatType === 0)
            this.$emit("onNodeClick", data);
            // console.debug('onNodeClick',node,data,item,isWeWorkThirdData)
        },
        cancelPermission() {
            this.permissionDialog = false;
            this.$emit("cancelPermission");
        },
        confirmPermission() {
            this.checkedList = [];
            this.dfsPreTreeRecursion(this.treeData);
            this.$emit("confirmPermission", this.checkedList);
            this.permissionDialog = false;
        },
        dfsPreTreeRecursion(tree) {
            let nodes = tree.slice();
            for (let i = 0; i < nodes.length; i++) {
                let node = nodes[i];
                if (node.checked) {
                    let obj = {
                        name: node.label,
                        type: node.type,
                        id: node.id,
                        isWeWorkThirdData:
                            node.isWeWorkThirdData ||
                            (node.originData &&
                                node.originData.wechatBotDTO &&
                                node.originData.wechatBotDTO.bindType === 0 &&
                                node.originData.wechatBotDTO.wechatType === 0),
                    };
                    this.checkedList.push(obj);
                }
                if (node.children && node.children.length) {
                    this.dfsPreTreeRecursion(node.children);
                }
            }
        },
        handlerTreeData(treeData, id) {
            treeData.forEach((item) => {
                if (item.id == id) {
                    item.checked = !item.checked;
                    this.checked = item.checked;
                }
                if (item.children && item.children.length > 0) {
                    this.handlerTreeData(item.children, id);
                }
            });
        },
        clickIframe(e) {
            if (e.data.emitName === "clickIframe") {
                this.checked = false;
                this.handlerTreeData(
                    this.treeData,
                    e.data.datas.id,
                    this.checked
                );
                this.$emit("onNodeClick", {
                    id: Number(e.data.datas.id),
                    type: e.data.datas.type,
                    label: e.data.datas.label,
                    checked: this.checked,
                });
            }
        },
    },
    mounted() {
        this.getData("");
        this.getLables();
        this.companyName = sessionStorage.getItem("realname");
        window.addEventListener("message", this.clickIframe, false);
    },
    destroyed() {
        window.removeEventListener("message", this.clickIframe);
    },
};
</script>

<style scoped lang="less">
#employeeStructure {
    .ask-label-list-cell:hover {
        background-color: #f2f6fc;
    }
    .ask-label-list-cell {
        height: 38px;
        line-height: 38px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        .allc-icon {
            width: 20px;
            margin-left: 10px;
            color: #e9efff;
        }
        .allc-name {
            flex: auto;
            margin-left: 6px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .allc-checkd {
            flex: none;
            width: 20px;
            margin-right: 12px;
            i.el-icon-check {
                font-size: 20px;
            }
        }
    }

    .staff-customer-input {
        margin-bottom: 12px;
    }
    /deep/.member-tree {
        .el-tree-node__expand-icon {
            display: none;
            font-size: 14px;
        }
        .el-icon-caret-right {
            &.down {
                transform: rotate(90deg);
            }
            &.right {
                transform: rotate(0);
            }
        }
        .el-tree-node__content {
            height: 38px !important;
        }
        .custom-tree-node {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 0 10px;
            .custom-tree-node-left {
                display: flex;
                align-items: center;
                width: 90%;
                justify-content: flex-start;
                i {
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    padding-right: 6px;
                    color: #c0c4cc;
                }
                .data-label {
                    height: 28px;
                    line-height: 28px;
                    //display: flex;
                    //align-items: center;
                    margin-right: 8px;
                    max-width: 200px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                iframe {
                    height: 22px;
                    cursor: pointer;
                }
                .member-infos {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    .member-data {
                        max-width: 200px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
                .member-info {
                    display: flex;
                    align-items: center;
                    margin-left: 8px;
                    color: #999999;
                    .job-num {
                        color: #999999;
                        margin-right: 4px;
                    }
                    .mobile {
                        color: #999999;
                        b {
                            color: #e0e6f7;
                        }
                    }
                }
            }
            .el-icon-check {
                font-size: 20px;
            }
        }
    }
}
</style>
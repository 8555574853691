const state = () => ({
    modelFile:{}, // 'success' 成功   'fail' 失败   '' 初始值
    model:{}
})

const getters = {
    getModelFile: (state) => {
        return state.modelFile;
    },
    getModel:(state) => {
        return state.model;
    }
}

const actions = {
    updateModelFile({ commit }, modelInfo) {
        commit("setIModelFile", modelInfo);
    },
    updateModel({ commit }, model){
        commit("setIModel", model);
    }

}

const mutations = {
    setIModelFile(state, modelInfo) {
        state.modelFile = modelInfo;
    },
    setIModel(state, model) {
        state.model = model;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
<template>
  <div class="channelAccess">
    <div v-for="(mode,modeIndex) in modeList"
         :key="modeIndex"
         class="modeItem"
    >
      <div class="title"> <i :class="['iconfont',mode.titleIcon]"></i> {{mode.title}}</div>
      <div class="cardList">
        <div v-for="(item,index) in mode.list"
             :key="index"
             class="cardItem"
             @click="channelCard(item)"
        >
          <img :src="item.path">
          <span class="cardName">{{item.name}}</span>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "channelAccess",
  data(){
    return{
      modeList:[
        {
          title:this.$t('channel.channelAccess.modeList.title1'),
          titleIcon:"guoran-a-zaixianwendang2",
          list:[
            {
              channel:5,
              name:this.$t('channel.channelAccess.modeList.cardName1'),
              path:require("../../assets/images/feishu.png")
            }
          ]
        },
        {
          title:this.$t('channel.channelAccess.modeList.title2'),
          titleIcon:"guoran-zhishiku",
          list:[
            {
              channel:8,
              name:this.$t('channel.channelAccess.modeList.cardName2'),
              path:require("../../assets/images/yuque.png")
            }
          ]
        }
      ]
    }
  },
  methods:{
    channelCard(item){
      console.debug('item',item)
      this.$router.push({
        path:"/accessBind",
        query:{
          channel:item.channel
        },
        meta:{
          title:item.name
        }
      })
    }
  }
};
</script>

<style scoped lang="less">
.channelAccess{
  padding: 20px 0;
  .modeItem{
    margin-bottom: 16px;
    .title{
      margin-bottom: 12px;
      font-weight: 600;
      font-size: 16px;
      .iconfont{
        font-weight: normal;
        color: #616161;
      }
    }
  }

  .cardList{
    display: flex;
    align-items: center;
  }
  .cardItem{
    width: 167px;
    height: 144px;
    background: #FFFFFF;
    box-shadow: 0px 0px 18px 0px rgba(29,55,129,0.07);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    cursor: pointer;
    .cardName{
      margin-top: 20px;
    }
  }
}
</style>
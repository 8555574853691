const state = () => ({
    botConfig:{
        name:"",
        des:"",
        status:"",
        updatedAt:"",
        type:1,
        avatar:""
    },
    isSaving:false,
    botVersion:null
})
const getters = {
    getBot:(state) => {
        return state.botConfig
    },
    getSaveStatus:(state) => {
        return state.isSaving
    },
    getBotVersion:(state) => {
        return state.botVersion
    }
}

const actions = {
    updateBotConfig({ commit }, bot) {
        commit("setBot", bot);
    },
    updateSaveStatus({ commit }, status) {
        commit("setSaveStatus", status);
    },
    updateBotVersion({ commit }, version) {
        commit("setBotVersion", version);
    },
}

const mutations = {
    setBot(state,botConfig){
        state.botConfig = botConfig
    },
    setSaveStatus(state,status){
        state.isSaving = status
    },
    setBotVersion(state,version){
        state.botVersion = version
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
<template>
    <div class="yuque-bind">
        <div class="accessBindContainer">
            <div class="title">{{$t('channel.components.YuqueBindCom.yuqueBind.title')}}</div>
            <el-form label-position="top"  :model="formLabelAlign">
                <el-form-item :label="$t('channel.components.YuqueBindCom.yuqueBind.descriptionLabel')">
                <el-input v-model="formLabelAlign.name" :placeholder="$t('channel.components.YuqueBindCom.yuqueBind.placeholder')"></el-input>
                </el-form-item>
                <el-form-item :label="$t('channel.components.YuqueBindCom.yuqueBind.accessTokenTypeLabel')">
                <div class="tokenType">
                    <div class="token-i">
                    <el-radio v-model="formLabelAlign.tokenType" :label="0" @change="changeTokenType">{{$t('channel.components.YuqueBindCom.yuqueBind.personalToken')}}</el-radio>
                    <span style="margin-left: 10px;color: #999999">{{$t('channel.components.YuqueBindCom.yuqueBind.personalTokenDesc')}}</span>
                    </div>
                    <div class="token-i">
                    <el-radio v-model="formLabelAlign.tokenType" :label="1" @change="changeTokenType">{{$t('channel.components.YuqueBindCom.yuqueBind.teamToken')}}</el-radio>
                    <span style="margin-left: 10px;color: #999999;">{{$t('channel.components.YuqueBindCom.yuqueBind.teamTokenDesc')}}</span>
                    </div>
                </div>
                </el-form-item>
                <el-form-item label="Access Token">
                <span slot="label">
                    <span>{{$t('channel.components.YuqueBindCom.yuqueBind.accessTokenLabel')}}</span>
                    <span style="color: red">*</span>
                </span>
                <el-input v-model="formLabelAlign.personalAccessToken" :placeholder="$t('channel.components.YuqueBindCom.yuqueBind.placeholder')"></el-input>
                <div class="tips">{{$t('channel.components.YuqueBindCom.yuqueBind.accessTokenRequired')}}</div>
                </el-form-item>
                <template v-if="formLabelAlign.tokenType == 0">
                <el-form-item label="">
                    <div class="getTeamOtherKnowledge">
                    <el-switch
                        v-model="formLabelAlign.getTeamOtherKnowledge">
                    </el-switch>
                    <span class="switch-title">{{$t('channel.components.YuqueBindCom.yuqueBind.getTeamOtherKnowledgeLabel')}}</span>
                    </div>
                </el-form-item>
                <el-form-item
                    :label="$t('channel.components.YuqueBindCom.yuqueBind.spaceInfoTitle')"
                    style="margin-bottom: 16px;color: #999999"
                    class="spaceTitle"
                ></el-form-item>
                <el-form-item :label="$t('channel.components.YuqueBindCom.yuqueBind.spaceNameLabel')">
                    <el-input v-model="formLabelAlign.spaceName" :placeholder="$t('channel.components.YuqueBindCom.yuqueBind.placeholder')"></el-input>
                </el-form-item>
                <el-form-item :label="$t('channel.components.YuqueBindCom.yuqueBind.spaceDomainNameLabel')">
                    <el-input v-model="formLabelAlign.spaceDomainName" :placeholder="$t('channel.components.YuqueBindCom.yuqueBind.placeholder')"></el-input>
                    <div class="tips" v-if="false">{{$t('channel.components.YuqueBindCom.yuqueBind.spaceDomainNameDesc')}}</div>
                </el-form-item>
                </template>
            <template v-else>
                <el-form-item label="Webhook地址">
                <div class="webHookUrl">
                    <el-input v-model="webHookUrl" :placeholder="$t('channel.components.YuqueBindCom.yuqueBind.placeholder')" readonly size="medium" :disabled="true"></el-input>
                    <div class="copyUrl" @click="copyMessage">
                    {{$t('channel.components.YuqueBindCom.yuqueBind.copy')}}
                    </div>
                </div>
                <div class="tips">{{$t('channel.components.YuqueBindCom.yuqueBind.webhookAddressDesc')}}</div>
                </el-form-item>
            </template>
            </el-form>
            <div class="footer">
                <span class="lookDoc" v-if="false">{{$t('channel.components.YuqueBindCom.yuqueBind.lookDocText')}}</span>
                <div class="saveBtn">
                <el-button round type="primary" size="small" @click="accessBind">{{$t('channel.components.YuqueBindCom.yuqueBind.saveBtnText')}}</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { requestUrl } from "@/http/requestUrl";
export default {
    data(){
        return {
            formLabelAlign:{
                name:"",
                spaceName:"",
                spaceDomainName:"",
                personalAccessToken:"",
                getTeamOtherKnowledge:false,
                tokenType:0,
            },
            webHookUrl:"https://wisdom.test.askbot.cn/knowledge-api/yuQueKnowledgeBase/acceptanceNotice",
        }
    },
    props:['isAccess'],
    mounted(){
        if (process.env.VUE_APP_NODE_ENV === 'production' ){
            this.webHookUrl = 'https://wisdom.askbot.cn/knowledge-api/yuQueKnowledgeBase/acceptanceNotice'
        }
    },
    methods:{
        /**
         * 渠道标识  1:企微微盘，2:腾讯文档，3:钉钉钉盘，4:钉钉云文档,5:飞书-云文档,6:百度网盘企业版,7:百度网盘个人版,8:语雀
        */
        accessBind(){
            if (!this.formLabelAlign.personalAccessToken){
                this.$message({
                    message:this.$t('channel.components.YuqueBindCom.yuqueBind.tokenRequired'),
                    duration:2000,
                    type:"warning"
                })
                return
            }
            if (!this.formLabelAlign.spaceDomainName && !this.formLabelAlign.getTeamOtherKnowledge && this.formLabelAlign.tokenType == 0){
                this.$message({
                message:this.$t('channel.components.YuqueBindCom.yuqueBind.atLeastOneConfig'),
                duration:2000,
                type:"warning"
                })
                return
            }
            let url = requestUrl.channel.channelAccess;
            let corpId = sessionStorage.getItem('_corpId');
            let obj = {
                corpId :corpId,
                channel:8,
                name:this.formLabelAlign.name,
                tokenType:this.formLabelAlign.tokenType,
                channelAccessDetailObj:{
                spaceName:this.formLabelAlign.spaceName,
                spaceDomainName:this.formLabelAlign.spaceDomainName,
                personalAccessToken:this.formLabelAlign.personalAccessToken,
                getTeamOtherKnowledge:this.formLabelAlign.getTeamOtherKnowledge,
                }
            }
            this.$http.post(url,obj).then(res => {
                if (res.data.code == 0){
                // this.isAccess = true;
                this.$emit('update:isAccess',true)
                this.$message({
                    message:this.$t('channel.components.FeiShuBindCom.message.successMessage'),
                    duration:2000,
                    type:'success'
                })
                } else if (res.data.code == -1){
                this.$message({
                    message:res.data.msg,
                    duration:2000,
                    type:"warning"
                })
                }
            })
        },
        
        copyMessage(){
            navigator.clipboard.writeText(this.webHookUrl);
            this.$message({
                message:this.$t('channel.components.YuqueBindCom.yuqueBind.copySuccessMessage'),
                duration:2000,
                type:'success'
            })
        },
        changeTokenType(){
            this.formLabelAlign.spaceName = "";
            this.formLabelAlign.spaceDomainName = "";
            this.formLabelAlign.getTeamOtherKnowledge = false;
        },
    }

}
</script>

<style lang="less" scoped>
.yuque-bind{
    width: 100%;
    height: 100%;
    background-color: white;
    position: relative;
    border-radius: 5px;
    .accessBindContainer{
        width: 500px;
        margin: 0 auto 50px auto;
        padding: 20px;
        height: calc(100% - 110px);
        overflow-y: auto;
        .tokenType{
            padding: 12px;
            background-color: #fbfcfd;
            border-radius: 5px;
        .token-i{
            display: flex;
            align-items: center;
        }
        .token-i:nth-last-child(1){
            margin-top: 16px;
        }
        }
        .webHookUrl{
            display: flex;
            align-items: center;
            .copyUrl{
                flex: none;
                width: 60px;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #366aff;
                color: #FFFFFF;
                border-radius: 5px;
                cursor: pointer;
            }
        }
        .title{
            margin-bottom: 14px;
        }
        /deep/.el-form-item__content{
            line-height: 20px;
        }
        /deep/.el-form-item__label{
            line-height: 20px;
        }
        .tips{
            font-size: 13px;
            color: #999999;
            margin-top: 10px;
        }
        .footer{
            // position: absolute;
            // bottom: 40px;
            display: flex;
            align-items: center;
            // width: 500px;
            justify-content: center;
            margin-top: 60px;
        &.first-step{
            position:inherit;
            margin-top: 10px;
            // width: 260px;
        }
        .lookDoc{
            color: #366aff;
            cursor: pointer;
        }
        .saveBtn{
            // margin-left: 160px;
            width: 100%;
            .el-button{
               width: 100%; 
            }
        }
        }
        .getTeamOtherKnowledge{
            display: flex;
            align-items:center;
        .switch-title{
            margin-left: 10px;
        }
        
        }
        .spaceTitle{
            /deep/.el-form-item__label{
                color: #999999;
            }
        }
    }
    .accessBindSuccess{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        flex-direction: column;
        .successText{
            margin: 36px 0;
            color: #616161;
            }
        .synchronize{
            flex: none;
            width: 90px;
            height: 32px;
            background: #366AFF;
            border-radius: 19px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFFFFF;
            cursor: pointer;
        }
    }
}
</style>
<template>
  <!-- svg 以链接的形式展现 -->
  <div
    class="icon"
    v-if="iconType"
    :style="{
        '-webkit-mask-image':`url(${icon})`,
       ...iconStyle
    }"
  ></div>
  <i v-else :class="[iconClass,icon]"></i>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      require: true
    },
    iconClass: {
      type: String,
      require: true
    },
    iconStyle: {
      type: Object
    }
  },
  computed: {
    iconType() {
      return this.icon && this.icon.includes("http") && this.icon.includes("svg");
    }
  }
};
</script>

<style lang="less" scoped>
.icon {
  width: 20px;
  height: 20px;
  display: block;
  -webkit-mask-size: contain;
  background-repeat: no-repeat;
}
</style>
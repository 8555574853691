<template>
	<div class="accessInformation">
		<div class="container">
			<div class="title">
				<span>{{ $t('channel.accessInformation.title') }}</span>
				<span class="lookDoc" v-if="false">查看接入文档</span>
			</div>
			<el-form label-position="left" :model="formLabelAlign">
				<!-- 语雀 -->
				<template v-if="channel === 8">
					<el-form-item :label="$t('channel.accessInformation.formLabels.yuque.descriptionLabel')">
						{{bindInfo.name ? bindInfo.name : '--'}}
						<span class="unbind" @click="unBind">
							<i class="el-icon-delete"></i>
							{{ $t('channel.accessInformation.formLabels.buttons.unbind') }}
						</span>
						</el-form-item>
					<el-form-item label="Access Token">
						{{formLabelAlign.personalAccessToken}}
					</el-form-item>
					<template v-if="bindInfo.tokenType == 0">
						<el-form-item :label="$t('channel.accessInformation.formLabels.yuque.spaceNameLabel')">
							{{formLabelAlign.spaceName ? formLabelAlign.spaceName : "--"}}
						</el-form-item>
						<el-form-item :label="$t('channel.accessInformation.formLabels.yuque.spaceDomainLabel')">
							{{formLabelAlign.spaceDomainName ? formLabelAlign.spaceDomainName : "--"}}
						</el-form-item>
						<el-form-item label="">
							<el-switch
								:disabled="true"
								v-model="formLabelAlign.getTeamOtherKnowledge">
							</el-switch>
							<span class="switch-title">{{$t('channel.accessInformation.formLabels.yuque.getTeamOtherKnowledgeLabel')}}</span>
						</el-form-item>
					</template>
					<template v-else-if="bindInfo.tokenType == 1">
						<el-form-item :label="$t('channel.accessInformation.formLabels.yuque.webhookAddressLabel')" class="webHookForm">
						</el-form-item>
						<el-form-item label="">
							<div class="webHookUrl">
							<el-input v-model="webHookUrl" :placeholder="$t('channel.accessInformation.formLabels.yuque.placeholder')" readonly size="medium" :disabled="true" style="width: 600px"></el-input>
							<div class="copyUrl" @click="copyMessage">
								{{$t('channel.accessInformation.formLabels.yuque.copy')}}
							</div>
							</div>
							<div class="tips">{{$t('channel.accessInformation.formLabels.yuque.webhookConfigTips')}}</div>
						</el-form-item>
					</template>
				</template>
				<!-- 飞书 -->
				<template v-else-if="channel === 5">
					<el-form-item :label="$t('channel.accessInformation.formLabels.feishu.spaceNameLabel')">
						{{bindInfo.name ? bindInfo.name : '--'}}
						<span class="unbind" @click="unBind"><i class="el-icon-delete"></i>{{ $t('channel.accessInformation.formLabels.buttons.unbind') }}</span>
					</el-form-item>
					<el-form-item :label="$t('channel.accessInformation.formLabels.feishu.accessTokenLabel')">{{formLabelAlign.personalAccessToken}}</el-form-item>
					<el-form-item :label="$t('channel.accessInformation.formLabels.feishu.companyNameLabel')">{{formLabelAlign.companyName}}</el-form-item>
				</template>
			</el-form>
		</div>
	</div>
</template>

<script>
import { requestUrl } from "@/http/requestUrl";

export default {
	name: "accessInformation",
	data(){
		return{
			formLabelAlign:{},
			bindId:"",
			webHookUrl:"https://wisdom.test.askbot.cn/knowledge-api/yuQueKnowledgeBase/acceptanceNotice",
			bindInfo:{},
			channel:8
		}
	},
	methods:{
		getChannel(){
			let url = requestUrl.channel.getAccessChannel;
			let corpId = sessionStorage.getItem('_corpId');
			url += '?corpId=' + corpId
			this.$http.get(url).then(res => {
				this.getCompanyInfo();
				let id = this.$route.query.id;
				if (res.data.code == 0 && res.data.data){
					let list = res.data.data.filter(item => item.id == id);
					this.formLabelAlign = list[0].channelAccessDetail ? JSON.parse(list[0].channelAccessDetail):{};
					this.bindId = list[0] ? list[0].id : '';
					this.bindInfo = list[0] ? list[0] : {}

				}
			})
		},
		unBind(){
			let url = requestUrl.channel.channelUnBInd;
			url += '?id=' + this.bindId;
			this.$http.get(url).then(res => {
				if (res.data.code == 0){
					this.$message({
						message:this.$t('channel.accessInformation.formLabels.messages.unbindSuccess'),
						duration:2000,
						type:"success"
					})
					this.formLabelAlign = {}
					this.$router.go(-1)
				} else {
					this.$message({
                        message:res.data.msg,
                        duration:2000,
                        type:"warning"
                    })
				}
			})
		},
		copyMessage(){
			navigator.clipboard.writeText(this.webHookUrl);
			this.$message({
				message:this.$t('channel.accessInformation.formLabels.messages.copySuccess'),
				duration:2000,
				type:'success'
			})
		},
		getCompanyInfo(){
			this.$http.get(requestUrl.company.getCompanyInfo).then(res => {
				if (res.data.code == 0){
					this.$set(this.formLabelAlign,'companyName',res.data.data.name)
				} else {
					this.$message({
                        message:res.data.msg,
                        duration:2000,
                        type:"warning"
                    })
				}
			})
		}
	},
	mounted() {
		this.channel = Number(this.$route.query.channel);
		this.getChannel();
		console.debug('NODE_ENV',process.env.VUE_APP_NODE_ENV)
		if (process.env.VUE_APP_NODE_ENV === 'production' ){
			this.webHookUrl = 'https://wisdom.askbot.cn/knowledge-api/yuQueKnowledgeBase/acceptanceNotice'
		}
	}
};
</script>

<style scoped lang="less">
	.accessInformation{
		height: 100%;
		width: 100%;
		background-color: #FFFFFF;
		.container{
			padding: 20px;
			.title{
				display: flex;
				align-items: center;
				margin-bottom: 16px;
				font-weight: 600;
				.lookDoc{
					margin-left: 20px;
					color: #366aff;
					cursor: pointer;
				}
			}
			.unbind{
				margin-left: 14px;
				color: #366aff;
				cursor: pointer;
				text-align: center;
				i{
					color: #366aff;
					//margin-right: 6px;
				}
			}
			.switch-title{
				margin-left: 16px;
			}
			/deep/.el-form-item{
				margin-bottom: 10px;
			}
			.webHookForm{
				/deep/.el-form-item__label{
					line-height: 20px;
				}
			}
			.webHookUrl{
				display: flex;
				align-items: center;
				.copyUrl{
					flex: none;
					width: 60px;
					height: 36px;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: #366aff;
					color: #FFFFFF;
					border-radius: 5px;
					cursor: pointer;
				}
			}
			.tips{
				font-size: 13px;
				color: #999999;
				margin-top: 10px;
				line-height: 20px;
			}
		}
	}
</style>
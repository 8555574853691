import { render, staticRenderFns } from "./ai-create-popup.vue?vue&type=template&id=a6a51b0a&scoped=true"
import script from "./ai-create-popup.vue?vue&type=script&lang=js"
export * from "./ai-create-popup.vue?vue&type=script&lang=js"
import style0 from "./ai-create-popup.vue?vue&type=style&index=0&id=a6a51b0a&prod&lang=less"
import style1 from "./ai-create-popup.vue?vue&type=style&index=1&id=a6a51b0a&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6a51b0a",
  null
  
)

export default component.exports
<template>
  <div class="channelView">
    <div class="channelLeft">
      <div class="navList">
        <div v-for="(item,index) in navList"
             :key="index"
             :class="['navItem',activeNav == item.id ?'activeNavItem':'']"
             @click="activeNav = item.id"
        >
          <i class="iconfont" :class="item.icon"></i>
          {{item.name}}
        </div>
      </div>
    </div>
    <div class="channelRight">
      <div class="tips">
        <i class="iconfont guoran-guanzhuren"></i>
        <span>{{alertTips}}</span>
      </div>
      <channel-success v-if="activeNav == 'channelSuccess'" :activeNav.sync="activeNav"></channel-success>
      <channel-access v-if="activeNav == 'channelAccess'"></channel-access>
    </div>
  </div>
</template>

<script>
import ChannelSuccess from "@/views/channel/channelSuccess";
import ChannelAccess from "@/views/channel/channelAccess";
export default {
  name: "channelView",
  components: { ChannelAccess, ChannelSuccess },
  data(){
    return{
      navList:[
        {
          name:this.$t('channel.channelSuccess.channelView.navListName1'),
          icon:"guoran-tongyichicun-yijieru",
          id:'channelSuccess'
        },
        {
          name:this.$t('channel.channelSuccess.channelView.navListName2'),
          icon:"guoran-tongyichicun-qudaojieru",
          id:'channelAccess'
        }
      ],
      activeNav:"channelSuccess",
      alertTips:this.$t('channel.channelSuccess.channelView.alertTips'),
    }
  },
  mounted(){
    this.activeNav = this.$route.query.type || 'channelSuccess'
  }
};
</script>

<style scoped lang="less">
.channelView{
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1;
  .channelLeft{
    flex: none;
    width: 220px;
    height: 100%;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 18px 0px rgb(29 55 129 / 7%);
    border-radius: 5px;
    margin-right: 16px;

    .navList{
      padding: 20px 0;
      .navItem{
        padding: 14px 20px;
        cursor: pointer;
        border-left: 2px solid #FFFFFF;
        i{
          margin-right: 10px;
          font-size: 14px;
        }
      }
      .activeNavItem{
        background-color: rgba(54,106,255,.07);
        color: #366aff;
        border-left: 2px solid #366aff;
      }
    }
  }
  .channelRight{
    flex: 1;
    height: 100%;
    .tips{
      padding: 8px 16px;
      color: #e6a23c;
      background-color: #fdf6ec;
      border-radius: 4px;
      display: flex;
      align-items: center;
      i{
        margin-right: 12px;
      }
    }
  }
}
</style>
<template>
  <div id="newsAnnouncement">
    <div class="pageLeft">
      <el-input v-model="keyword"
                :placeholder="$t('knowledgeManagement.newsAnnouncement.placeholder')"
                suffix-icon="el-icon-search"
                size="medium"
                @keyup.enter.native="searchFolder"
                @input="searchFolderNull"
      ></el-input>
      <div class="directoryTree">
        <el-tree
            :data="folderTree"
            :props="defaultProps"
            node-key="id"
            ref="dirTree"
            :expand-on-click-node="false"
            :default-expanded-keys="defaultExpandedKey"
            :current-node-key="checkFolder"
            :check-on-click-node="true"
            :highlight-current = true
            @node-click="nodeClick"
        >
          <template #default="{ node,data }">
            <div class="custom-tree-node">
              <div class="folderName">
                <i class="iconfont guoran-tongyichicun-wenjianjia-zhankaizhuangtai--folderOpen" v-if="!data.parentId || data.parentId == null"></i>
                <div v-else class="childrenIcon"></div>
                <span>{{data.name}}</span>
              </div>
              <div class="newsCount" :style="{ display:visible[data.id] ? 'none' : '' }">
                <template v-if="data.newsCount !== undefined">
                  {{ data.newsCount }}
                </template>
                <template v-else> {{ data.count }} </template>
              </div>
              <div class="dropDown" :style="{ display:visible[data.id] ? 'block' : '' }">
                <el-popover
                    placement="bottom"
                    width="160"
                    trigger="hover"
                    popper-class="commitLoadMore"
                    v-model="visible[data.id]"
                    content="">
                  <div class="popoverContainer">
                    <div class="popoverItem" @click="handleCommand('edit',data,node)">
                      <i class="iconfont guoran-tongyichicun-16-15-lianjibianji"></i>
                      {{$t('knowledgeManagement.newsAnnouncement.edit')}}
                    </div>
                    <div class="popoverItem" v-if="!data.parentId" @click="handleCommand('addChildDept',data,node)">
                      <i class="iconfont guoran-a-18-35"></i>
                      {{$t('knowledgeManagement.newsAnnouncement.addChildDirectory')}}
                    </div>
                    <div class="popoverItem" @click="handleCommand('addSameDept',data,node)">
                      <i class="iconfont guoran-xinjiangongdan"></i>
                      {{$t('knowledgeManagement.newsAnnouncement.addSameDirectory')}}
                    </div>
                    <div class="popoverItem" v-if="node.previousSibling"  @click="handleCommand('up',data,node)">
                      <i class="iconfont guoran-shangyi"></i>
                      {{$t('knowledgeManagement.newsAnnouncement.up')}}
                    </div>
                    <div class="popoverItem" v-if="node.nextSibling" @click="handleCommand('down',data,node)">
                      <i class="iconfont guoran-xiayi"></i>
                      {{$t('knowledgeManagement.newsAnnouncement.down')}}
                    </div>
                    <div class="popoverItem" @click="handleCommand('del',data,node)">
                      <i class="iconfont guoran-piliangshanchu"></i>
                      {{$t('knowledgeManagement.newsAnnouncement.del')}}
                    </div>
                  </div>
                  <i slot="reference" class="iconfont guoran-qita"></i>
                </el-popover>
                <el-dropdown trigger="click" @command="handleCommand($event,data,node)" v-if="false">
                  <span class="el-dropdown-link">
                    <i class="iconfont guoran-qita"></i>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item :command="'edit'">
                        <i class="iconfont guoran-tongyichicun-16-15-lianjibianji"></i>
                        {{$t('knowledgeManagement.newsAnnouncement.edit')}}
                      </el-dropdown-item>
                      <el-dropdown-item :command="'addChildDept'" v-if="!data.parentId">
                        <i class="iconfont guoran-a-18-35"></i>
                        {{$t('knowledgeManagement.newsAnnouncement.addChildDirectory')}}
                      </el-dropdown-item>
                      <el-dropdown-item :command="'addSameDept'">
                        <i class="iconfont guoran-xinjiangongdan"></i>
                        {{$t('knowledgeManagement.newsAnnouncement.addSameDirectory')}}
                      </el-dropdown-item>
                      <el-dropdown-item v-if="node.previousSibling" :command="'up'">
                        <i class="iconfont guoran-shangyi"></i>
                        {{$t('knowledgeManagement.newsAnnouncement.up')}}
                      </el-dropdown-item>
                      <el-dropdown-item v-if="node.nextSibling" command="down">
                        <i class="iconfont guoran-xiayi"></i>
                        {{$t('knowledgeManagement.newsAnnouncement.down')}}
                      </el-dropdown-item>
                      <el-dropdown-item :command="'del'">
                        <i class="iconfont guoran-piliangshanchu"></i>
                        {{$t('knowledgeManagement.newsAnnouncement.del')}}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
            </div>

          </template>

        </el-tree>
        <div class="loadTree"
             v-if="folderTotal - ((loadTreePage + 1) * loadTreePageSize) > 0"
             @click="loadMoreTreeData"
        >
          <span>{{$t('knowledgeManagement.newsAnnouncement.loadMore')}}</span>
        </div>
      </div>
      <div class="addDirectory">
        <div class="addDirectoryBtn" @click="addFolder">
          <i class="iconfont guoran-a-16-13"></i>
          <span>{{$t('knowledgeManagement.newsAnnouncement.addFirstDirectory')}}</span>
        </div>
      </div>
    </div>
    <div class="pageRight">
      <div class="topHeader">
        <div class="headerLeft">

        </div>
        <div class="headerRight" @click="addNews">
          <i class="iconfont guoran-tongyichicun-16-13-xinjian"></i>
        </div>
      </div>
      <div class="newsTable">
        <el-table
            ref="multipleTable"
            :data="newsTableList"
            tooltip-effect="dark"
            style="width: 100%"
            height="calc(100% - 50px)"
            :header-cell-style="{backgroundColor:'#F6F8FD',height:'50px',color:'#000000',fontWeight:400}"
            :row-style="{cursor:'pointer',color:'#000000',height:'50px'}"
            :highlight-current-row="true"
            @selection-change="handleSelectionChange"
            @row-click="getToNews"
        >
          <el-table-column
              type="selection"
              align="center"
              width="55">
          </el-table-column>
          <el-table-column
              :label="$t('knowledgeManagement.newsAnnouncement.name')">
            <template slot-scope="scope">
              <div class="newsInfomation">
                <div class="newsCover" v-if="scope.row.cover" @click.stop="() => {}">
                  <el-image
                      :src="scope.row.cover"
                      :preview-src-list=[scope.row.cover]
                  ></el-image>
                </div>
                <div class="newsContainer">
                  <div class="newsTitle">
                    <div :class="['newsStatus',scope.row.state === 'UNPUBLISHED' ?
                     'unPublishStatus' : (scope.row.state === 'PUBLISHED' ? 'publishStatus' : 'archiveStatus')]"> {{ scope.row.state | statusFilter }} </div>
                    <div class="isTop" v-if="scope.row.pinToTop">
                      {{$t('knowledgeManagement.newsAnnouncement.topping')}}
                    </div>
                    <div class="title">{{ scope.row.title }}</div>
                  </div>
                  <div class="newsDetail">
                    <div class="newsTime" v-if="scope.row.updatedAt">{{new Date(scope.row.updatedAt).format('yyyy-MM-dd hh:mm')}}</div>
                    <div class="newsViews">
                      <i class="iconfont guoran-tongyichicun-chakan"></i>
                      <span>{{$t('knowledgeManagement.newsAnnouncement.reading')}} </span>
                      <span>{{ scope.row.viewCount }}</span>
                    </div>
                    <div class="newsCreator">
                      <i class="iconfont guoran-a-18-02"></i>
                      <span>{{ scope.row.creator ? scope.row.creator.name : "--" }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="name"
              :label="$t('knowledgeManagement.newsAnnouncement.visibleRange')"
              width="300px"
          >
            <template slot-scope="scope">
              <div class="visibility">
                <template v-if="scope.row.visitConfig">
                  <template v-if="scope.row.visitConfig.users">
                    <div class="memberItem"
                         v-for="item in scope.row.visitConfig.users"
                         :key="item.memberId"
                    >
                      <el-tooltip placement="top" effect="dark">
                        <div slot="content">
                          {{ item.name }}
                        </div>
                        <el-avatar :size="30" :src="item.avatar" v-if="item.avatar"></el-avatar>
                        <span v-else-if="item.name" class="avatar">{{(item.name).substr(-2)}}</span>
                      </el-tooltip>
                    </div>
                  </template>
                  <template v-if="scope.row.visitConfig.departs">
                    <div class="departItem"
                         v-for="item in scope.row.visitConfig.departs"
                         :key="item.id"
                    >
                      <div class="departAvatar">
                        <i class="iconfont guoran-chanpin"></i>
                      </div>
                      <div class="departName">
                        <open-data :openid="item.id" :type="'departmentName'" v-if="isUseOpenData()"></open-data>
                        <template v-else>
                          {{item.name}}
                        </template>
                      </div>
                    </div>
                  </template>
                </template>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="address"
              :label="$t('knowledgeManagement.newsAnnouncement.effectiveTime')"
              width="200px"
              align="center"
          >
            <template slot-scope="scope">
              <div class="effectiveTime">
                <template v-if="scope.row.fromDateTimeAt && scope.row.toDateTimeAt">
                  <span> {{ new Date(scope.row.fromDateTimeAt).format('yyyy-MM-dd') }}</span>
                  <span style="margin: 0 2px">~</span>
                  <span> {{ new Date(scope.row.toDateTimeAt).format('yyyy-MM-dd') }} </span>
                </template>
                <template v-else-if="!scope.row.toDateTimeAt"> {{$t('knowledgeManagement.newsAnnouncement.permanentValidity')}} </template>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              fixed="right"
              :label="$t('knowledgeManagement.newsAnnouncement.operation')"
              align="center"
              width="80"
          >
            <template slot-scope="scope">
            <span class="operation">
              <div @click.stop="()=>{}">
                <el-dropdown trigger="click"  @command="newsHandleCommand($event,scope.row)">
                  <span class="el-dropdown-link">
                  <i class="iconfont guoran-qita1"></i>
                </span>
                  <el-dropdown-menu slot="dropdown" class="dropdown-menu">
                    <el-dropdown-item :command="'PUBLISHED'" v-if="scope.row.state === 'UNPUBLISHED' || scope.row.state === 'ARCHIVE'">
                      <i class="iconfont guoran-tongyichicun-fasong"></i>
                      {{$t('knowledgeManagement.newsAnnouncement.publish')}}
                    </el-dropdown-item>
                    <el-dropdown-item :command="'UNPUBLISHED'" v-if="scope.row.state === 'PUBLISHED'">
                      <i class="iconfont guoran-a-16-09"></i>
                      {{$t('knowledgeManagement.newsAnnouncement.unPublish')}}
                    </el-dropdown-item>
                    <el-dropdown-item :command="'ARCHIVE'" v-if="scope.row.state !== 'ARCHIVE'">
                      <i class="iconfont guoran-tongyichicun-yituguidangxiang1"></i>
                      {{$t('knowledgeManagement.newsAnnouncement.documentation')}}
                    </el-dropdown-item>
                    <el-dropdown-item :command="'UNARCHIVE'" v-if="scope.row.state === 'ARCHIVE'">
                      <i class="iconfont guoran-tongyichicun-yituguidangxiang1"></i>
                      {{$t('knowledgeManagement.newsAnnouncement.cancelDocumentation')}}
                    </el-dropdown-item>
                    <el-dropdown-item :command="'topping'" v-if="scope.row.state === 'PUBLISHED' && !scope.row.pinToTop">
                      <i class="iconfont guoran-zhiding"></i>
                      {{$t('knowledgeManagement.newsAnnouncement.topping')}}
                    </el-dropdown-item>
                    <el-dropdown-item :command="'shift'">
                      <i class="iconfont guoran-tongyichicun-yidong"></i>
                      {{$t('knowledgeManagement.newsAnnouncement.shift')}}
                    </el-dropdown-item>
                    <el-dropdown-item :command="'delNews'">
                      <i class="iconfont guoran-piliangshanchu"></i>
                      {{$t('knowledgeManagement.newsAnnouncement.del')}}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </span>
            </template>
          </el-table-column>
        </el-table>

        <pagination :pageSize="newsObj.pageSize" :currentPage="newsObj.pageIndex" :total="tableTotal" @currentChange="handleCurrentChange"></pagination>

        <div v-if="multipleSelection.length !== 0" class="adi-footer">
          <div class="doc-handle-box">
            <div @click="selectAll" class="dhb-cell">{{$t('knowledgeManagement.newsAnnouncement.allSelected')}}</div>
            <div @click="newsSelected('publish')" class="dhb-cell">{{$t('knowledgeManagement.newsAnnouncement.publish')}}</div>
            <div @click="newsSelected('unPublish')" class="dhb-cell">{{$t('knowledgeManagement.newsAnnouncement.unPublish')}}</div>
            <div @click="newsSelected('archive')" class="dhb-cell">{{$t('knowledgeManagement.newsAnnouncement.documentation')}}</div>
            <div @click="newsSelected('move')" class="dhb-cell">{{$t('knowledgeManagement.newsAnnouncement.shift"')}}</div>
            <div @click="newsSelected('delete')" class="dhb-cell">{{$t('knowledgeManagement.newsAnnouncement.del')}}</div>
            <div class="dhb-cell-with-number">
              <span @click="cancelSelectAll">{{$t('knowledgeManagement.newsAnnouncement.cancelSelected')}}</span>
              <span class="dhb-cell-count">{{ multipleSelection.length }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <popup-com
        @closeEvent="folderDialog = false"
        v-if="folderDialog"
        class="folderPopupCom"
    >
      <div slot="popup-name">{{folderDialogTitle}}</div>
      <div slot="popup-con-c" class="popup-con-c">
        <news-folder-form ref="newsFolderForm"></news-folder-form>
      </div>
      <div slot="dialog-footer">
        <el-button  plain size="small" @click="folderDialog = false" class="cancel-btn">{{$t('knowledgeManagement.newsAnnouncement.cancelText')}}</el-button>
        <el-button  type="primary" size="small" @click="saveFolder"  class="confirm-btn">{{$t('knowledgeManagement.newsAnnouncement.confirmText')}}</el-button>
      </div>
    </popup-com>
    <popup-com
        @closeEvent="moveNewsDialog = false"
        v-if="moveNewsDialog"
    >
      <div slot="popup-name">{{$t('knowledgeManagement.newsAnnouncement.moveNews')}}</div>
      <div slot="popup-con-c" class="moveDir">
        <span class="label">{{$t('knowledgeManagement.newsAnnouncement.moveDirectory')}}</span>
        <directory-tree ref="directoryTree" :addNews="true"></directory-tree>
      </div>
      <div slot="dialog-footer">
        <el-button  plain size="small" @click="moveNewsDialog = false" class="cancel-btn">{{$t('knowledgeManagement.newsAnnouncement.cancelText')}}</el-button>
        <el-button  type="primary" size="small" @click="confirmMoveNews"  class="confirm-btn">{{$t('knowledgeManagement.newsAnnouncement.confirmText')}}</el-button>
      </div>
    </popup-com>
    <delete-dialog @closeEvent="deleteDialog = false" v-if="deleteDialog">
      <div slot="popup-container" class="popup-container">
        <div class="delete-title">{{ deleteTitle }}</div>
        <div class="prompt-text">
         {{ deleteTips }}
        </div>
      </div>
      <div slot="dialog-footer">
        <el-button @click="deleteDialog = false" size="small" plain class="cancel-btn">{{$t('knowledgeManagement.newsAnnouncement.cancelText')}}</el-button>
        <el-button @click="confirmDeleteNews" style="background-color: #FE5965 ;color: white" size="small"  class="confirm-btn">{{$t('knowledgeManagement.newsAnnouncement.del')}}
        </el-button>
      </div>
    </delete-dialog>
  </div>
</template>

<script>
import pagination from "@/components/pagination";
import PopupCom from "@/components/popup";
import NewsFolderForm from "@/views/newsAnnouncement/components/newsFolderForm";
import { requestUrl } from "@/http/requestUrl";
import OpenData from "@/components/openData";
import DirectoryTree from "@/views/newsAnnouncement/components/directoryTree";
let that = ""
import {
  deleteNews,
  pinToTop,
  publishNews,
  archiveNews,
  unArchiveNews,
  unPublishNews,
  moveNews
} from "@/assets/js/common";
import DeleteDialog from "@/components/deleteDialog";

export default {
  name: "newsAnnouncement",
  data(){
    return{
      keyword:"",
      folderTree: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      newsTableList:[],
      multipleSelection: [],
      pageSize:20,
      currentPage:1,
      tableTotal:0,
      folderDialog:false,
      folderDialogTitle:this.$t('knowledgeManagement.newsAnnouncement.addDir'),
      folderDialogTips:"",
      newsObj:{
        directoryId:"",
        pageIndex:1,
        pageSize:20,
        pinToTop:null,
        allowComment:null,
        allowLike:null,
        title:"",
        states:null,
      },
      folderTotal:0,
      visible:{},
      checkFolder:"",
      moveNewsDialog:false,
      handNewsId:[],
      deleteDialog:false,
      deleteTitle:"",
      deleteTips:"",
      loadTreePage:0,
      loadTreePageSize:15,
      handNews:null,
      defaultExpandedKey:[],
    }
  },
  components:{
    DeleteDialog,
    OpenData,
    NewsFolderForm,
    PopupCom,
    pagination,
    DirectoryTree
  },
  created() {
    that = this;
  },
  filters:{
    statusFilter(val){
      if (val === 'UNPUBLISHED'){
        return that.$t('knowledgeManagement.newsAnnouncement.unPublishState')
      } else if (val === 'PUBLISHED'){
        return  that.$t('knowledgeManagement.newsAnnouncement.publishState')
      } else {
        return that.$t('knowledgeManagement.newsAnnouncement.documentation')
      }
    }
  },
  methods:{
    handleSelectionChange(val) {
      console.log('val',val)
      this.multipleSelection = val.map(res => {
        return res.id
      });
    },
    getToNews(data){
      let currentNode = this.$refs.dirTree.getCurrentNode();
      let directoryId = this.checkFolder;
      //如果当前选中目录下有子目录，跳转新增新闻页面时默认选中子目录的第一个
      if (currentNode && currentNode.children && currentNode.children.length > 0){
        directoryId = currentNode.children[0].id
      }
      this.$router.push({
        path:"/addNewsAnnouncement",
        query:{
          directoryId:directoryId,
          id:data.id
        }
      })
    },
    deleteRow(){

    },
    handleCurrentChange(val){
      this.newsObj.pageIndex = val;
      this.getNewsList();
    },
    addFolder(){
      this.folderDialog = true;
      this.$nextTick(() => {
        this.$refs.newsFolderForm.formFolder = {
          name:"",
          parentId:null,
          visitConfig: {
            users: [],
            departs: []
          },
          visitScope: 'CUSTOMIZE'
        }
        this.$refs.newsFolderForm.$refs.directoryTree.selectLabel = '根目录';
        this.$refs.newsFolderForm.$refs.directoryTree.selectValue = null;
      })
    },
    handleCommand(command,item,node){
      this.visible[item.id] = false;
      this.dirctoryId = item.id
      if (command === 'del'){
        if (item.children.length == 0 && item.newsCount > 0){
          this.$message({
            message:this.$t('knowledgeManagement.newsAnnouncement.currentDirectory'),
            type:"warning",
            duration:2000
          })
          return
        }
        if (item.children.length > 0 && item.newsCount > 0){
          this.$message({
            message:this.$t('knowledgeManagement.newsAnnouncement.childrenDirectory'),
            type:"warning",
            duration:2000
          })
          return
        }
        this.deleteDialog = true;
        this.deleteTitle = this.$t('knowledgeManagement.newsAnnouncement.deleteDir');
        this.deleteTips = this.$t('knowledgeManagement.newsAnnouncement.deleteDirTip');
      } else if (command === 'addChildDept'){
        this.folderDialogTitle = this.$t('knowledgeManagement.newsAnnouncement.addChildDirectory')
        if (item.children.length == 0 && item.newsCount > 0){
          this.$message({
            message:this.$t('knowledgeManagement.newsAnnouncement.moveCurrentDir'),
            type:"warning"
          })
          return
        }
        this.folderDialog = true;
        this.$nextTick(() => {
          this.$refs.newsFolderForm.formFolder = {
            name:"",
            parentId:item.id,
            visitConfig: {
              users: [],
              departs: []
            },
            visitScope: 'INHERIT'
          }
          this.$refs.newsFolderForm.$refs.directoryTree.selectLabel = item.name;
          this.$refs.newsFolderForm.$refs.directoryTree.selectValue = item.id;
        })
      } else if (command === 'edit'){
        this.folderDialog = true;
        this.folderDialogTitle = this.$t('knowledgeManagement.newsAnnouncement.editDir')
        this.getDirDetail(item.id,(res) => {
          this.$refs.newsFolderForm.formFolder = {
            name:res.name,
            parentId:res.parentId ? res.parentId : null,
            visitConfig: res.visitConfig,
            visitScope: res.visitScope,
            id:res.id
          }
          if (res.visitConfig){
            this.$refs.newsFolderForm.permissionList = res.visitConfig.users.concat(res.visitConfig.departs)
          }
          if (!res.parentId){
            this.$refs.newsFolderForm.$refs.directoryTree.selectLabel = "根目录";
            this.$refs.newsFolderForm.$refs.directoryTree.selectValue = null;
          } else {
            this.$refs.newsFolderForm.$refs.directoryTree.selectLabel = node.parent.data.name;
            this.$refs.newsFolderForm.$refs.directoryTree.selectValue = res.parentId;
          }
        })
      } else if (command === 'addSameDept'){
        this.folderDialog = true;
        this.folderDialogTitle = this.$t('knowledgeManagement.newsAnnouncement.addDir')
        this.$nextTick(() => {
          this.$refs.newsFolderForm.formFolder = {
            name:"",
            parentId:"",
            visitConfig: {
              users:[],
              departs: []
            },
            visitScope: "CUSTOMIZE"
          }
          if (!item.parentId){
            this.$refs.newsFolderForm.formFolder.parentId = null;
            this.$refs.newsFolderForm.$refs.directoryTree.selectLabel = "根目录";
            this.$refs.newsFolderForm.$refs.directoryTree.selectValue = null;
          } else {
            this.$refs.newsFolderForm.formFolder.parentId = node.parent.data.id;
            this.$refs.newsFolderForm.$refs.directoryTree.selectLabel = node.parent.data.name;
            this.$refs.newsFolderForm.$refs.directoryTree.selectValue = node.parent.data.id;
          }
        })
      } else if (command == 'up' || command == 'down'){
        let list = [];
        node.parent.childNodes.forEach(item => {
          if(item.name && item.parentId){
            list.push(item)
          } else {
            if(item.data.name && item.data.parentId){
              list.push(item.data)
            }
          }
        })
        let currentData = item;
        let params = [];
        if (command === 'up') {
          params = [...[currentData], ...[node.previousSibling.data]]
        } else {
          params = [...[node.nextSibling.data], ...[currentData]]
        }
        let activeIndex = 0
        list.forEach((item, index) => {
          if (currentData.id === item.id) {
            activeIndex = index
          }
        });
        if (command === 'up') {
          list[activeIndex] = params[1]
          list[activeIndex - 1] = params[0]
        }
        else {
          list[activeIndex] = params[0]
          list[activeIndex + 1] = params[1]
        }
        let url = requestUrl.newsDirectory.moveDirectory

        let direction = command === 'up' ? 'UP' : 'DOWN';
        this.$http.put(url,{
          id:item.id,
          direction
        }).then(res => {
          if (res.data.code == 0){
            console.log('sortListIds',res)
            this.$message({
              message:this.$t('knowledgeManagement.newsAnnouncement.moveSuccess'),
              type:"success"
            })
            this.getFolderList();
          }
        })

      }
    },
    //获取目录详情
    getDirDetail(id,callback){
      let url = requestUrl.newsDirectory.directoryDetail + id;
      this.$http.get(url).then(res => {
        if (res.data.code == 0){
          if (callback){
            callback(res.data.data)
          }
        }
      })
    },
    deleteDir(id){
      let url = requestUrl.newsDirectory.saveDirectory + '/' + id;
      this.$http.delete(url).then(res => {
        if (res.data.code == 0){
          this.$message({
            message:this.$t('knowledgeManagement.newsAnnouncement.delSuccess'),
            type:"success"
          })
          this.getFolderList();
          this.dirctoryId = "";
          this.deleteDialog = false;
        } else {
          this.$message({
            message:res.data.msg,
            type:'warning'
          })
        }
      })
    },
    addNews(){
      let currentNode = this.$refs.dirTree.getCurrentNode();
      let directoryId = this.checkFolder;
      //如果当前选中目录下有子目录，跳转新增新闻页面时默认选中子目录的第一个
      if (currentNode && currentNode.children && currentNode.children.length > 0){
        directoryId = currentNode.children[0].id
      }
      if (!directoryId){
        this.$message({
          message:this.$t('knowledgeManagement.newsAnnouncement.createDir'),
          type:"warning"
        })
        return
      }
      this.$router.push({
        path:"/addNewsAnnouncement",
        query:{
          directoryId
        }
      })
    },
    getNewsList(){
      let url = requestUrl.news.newsList;
      const params = Object.assign({}, this.newsObj)
      params.pageIndex = params.pageIndex - 1;
      this.$http.post(url,params).then(res => {
        if (res.data.code == 0 && res.data.data){
          this.newsTableList = res.data.data.rows;
          this.tableTotal = res.data.data.count;
          this.$nextTick(() => {
            this.$refs.multipleTable.doLayout();
          })
        }
      })
    },
    getFolderList(){
      let url = requestUrl.newsDirectory.loadTreeList;
      let obj = {
        "pageIndex": this.loadTreePage,
        "pageSize": this.loadTreePageSize,
        "id": null
      }
      this.$http.post(url,obj).then(res => {
        if (res.data.code == 0 && res.data.data){
          if (this.loadTreePage == 0){
            this.folderTree = res.data.data.rows;
          } else {
            this.folderTree = this.folderTree.concat(res.data.data.rows);
          }
          this.folderTotal = res.data.data.count;
          if (this.folderTree.length > 0){
            this.defaultExpandedKey = [this.folderTree[0].id];
          }
          if (!this.checkFolder){
            if (this.folderTree[0]){
              if (this.folderTree[0].children && this.folderTree[0].children.length > 0){
                this.newsObj.directoryId = this.folderTree[0].children[0].id
              } else {
                this.newsObj.directoryId = this.folderTree[0].id
              }
              this.checkFolder = this.folderTree[0].id;
              this.$nextTick(() => {
                this.$refs.dirTree.setCurrentKey(this.folderTree[0].id)
              })
              this.getNewsList();
            }
          } else {
            this.$nextTick(() => {
              this.$refs.dirTree.setCurrentKey(this.checkFolder)
            })
          }
        }
      })
    },
    loadMoreTreeData(){
      this.loadTreePage += 1;
      this.getFolderList();
    },
    saveFolder(){
      let formFolder = this.$refs.newsFolderForm.formFolder;
      if (!formFolder.name){
        this.$message({
          message:this.$t('knowledgeManagement.newsAnnouncement.dirNameReq'),
          type:"warning"
        })
        return
      }
      if (formFolder.parentId == ''){
        this.$message({
          message:this.$t('knowledgeManagement.newsAnnouncement.dirParentReq'),
          type:"warning"
        })
        return;
      }
      let obj = {
        name:formFolder.name,
        parentId:formFolder.parentId,
        visitScope:formFolder.visitScope,
        visitConfig:formFolder.visitConfig
      }
      if (!obj.visitScope){
        obj.visitScope = "CUSTOMIZE"
      }
      if (obj.visitScope === 'CUSTOMIZE' && !obj.visitConfig.users.length && !obj.visitConfig.departs.length){
        this.$message({
          message:this.$t('knowledgeManagement.newsAnnouncement.visibleReq'),
          type:"warning"
        })
        return;
      }
      let url = requestUrl.newsDirectory.saveDirectory;
      console.log('formFolder',formFolder,url)
      if (formFolder.id){
        url += '/' + formFolder.id
        this.$http.put(url,obj).then(res => {
          if (res.data.code == 0){
            this.$message({
              message:this.$t('knowledgeManagement.newsAnnouncement.editSuccess'),
              type:"success"
            })
            this.getFolderList();
            this.folderDialog = false;
          }
        })
      } else {
        this.$http.post(url,obj).then(res => {
          if (res.data.code == 0){
            if (res.data.code == 0){
              this.$message({
                message:this.$t('knowledgeManagement.newsAnnouncement.saveSuccess'),
                type:"success"
              })
              this.getFolderList();
              this.folderDialog = false;
            }
          }
        })
      }

      console.log('formFolder',formFolder)
    },
    searchFolder(){
      if (this.keyword){
        let url = requestUrl.newsDirectory.getDirectoryList;
        url += '?id=&name=' + this.keyword;
        this.$http.get(url).then(res => {
          if (res.data.code == 0 && res.data.data){
            console.log('====',res)
            this.folderTree = res.data.data;
            this.folderTotal = 0;
          }
        })
      }
    },
    searchFolderNull(){
      if (!this.keyword){
        this.folderTree = [];
        this.getFolderList();
      }
    },
    nodeClick(data){
      this.checkFolder = data.id;
      this.newsObj.directoryId = data.id;
      this.getNewsList();
    },
    newsHandleCommand(command,data){
      this.handNewsId = [data.id];
      this.handNews = data;
      let confirmButtonText = this.$t('knowledgeManagement.newsAnnouncement.confirmText');
      let cancelButtonText = this.$t('knowledgeManagement.newsAnnouncement.cancelText')
      if (command === 'delNews'){
       this.deleteDialog = true;
       this.deleteTitle = this.$t('knowledgeManagement.newsAnnouncement.delNews');
       this.deleteTips = this.$t('knowledgeManagement.newsAnnouncement.delNewsTips')
      } else if (command === 'PUBLISHED'){
        let title = this.$t('knowledgeManagement.newsAnnouncement.publish')
        this.$confirm(this.$t('knowledgeManagement.newsAnnouncement.publishNews'), title, {
          confirmButtonText: confirmButtonText,
          cancelButtonText: cancelButtonText,
          type: 'warning'
        }).then(() => {
          publishNews([data.id],() => {
            this.getNewsList();
          },this)
        }).catch(() => {

        });
      } else if (command === 'topping'){
        let message = this.$t('knowledgeManagement.newsAnnouncement.topNews');
        let title = this.$t('knowledgeManagement.newsAnnouncement.topping');
        this.$confirm(message, title, {
          confirmButtonText: confirmButtonText,
          cancelButtonText: cancelButtonText,
          type: 'warning'
        }).then(() => {
          pinToTop([data.id],() => {
            this.getNewsList();
          },this)
        }).catch(() => {

        });
      } else if (command === 'ARCHIVE'){
        let message = this.$t('knowledgeManagement.newsAnnouncement.archiveNews');
        let title = this.$t('knowledgeManagement.newsAnnouncement.documentation');
        this.$confirm(message, title, {
          confirmButtonText: confirmButtonText,
          cancelButtonText: cancelButtonText,
          type: 'warning'
        }).then(() => {
          archiveNews([data.id],() => {
            this.getNewsList();
          },this)
        }).catch(() => {

        });
      } else if (command === 'UNARCHIVE'){
        let message = this.$t('knowledgeManagement.newsAnnouncement.cancelArchiveNews');
        let title = this.$t('knowledgeManagement.newsAnnouncement.cancelDocumentation');
        this.$confirm(  message, title, {
          confirmButtonText: confirmButtonText,
          cancelButtonText: cancelButtonText,
          type: 'warning'
        }).then(() => {
          unArchiveNews([data.id],() => {
            this.getNewsList()
          },this)
        }).catch(() => {

        });
      } else if (command === 'UNPUBLISHED'){
        let message = this.$t('knowledgeManagement.newsAnnouncement.cancelPublishNews');
        let title = this.$t('knowledgeManagement.newsAnnouncement.unPublish');
        this.$confirm(  message, title, {
          confirmButtonText: confirmButtonText,
          cancelButtonText: cancelButtonText,
          type: 'warning'
        }).then(() => {
          unPublishNews([data.id],() => {
            this.getNewsList()
          },this)
        }).catch(() => {

        });
      } else if (command === 'shift'){
        this.moveNewsDialog = true;
        this.$nextTick(() => {
          let currentNode = this.$refs.dirTree.getCurrentNode();
          if (currentNode){
            this.$refs.directoryTree.selectValue = currentNode.id;
            this.$refs.directoryTree.selectLabel = currentNode.name;
          }

        })
      }
    },
    selectAll(){
      this.newsTableList.forEach(row => {
        this.$refs.multipleTable.toggleRowSelection(row,true)
      })
      this.multipleSelection = this.newsTableList.map(item => {
        return item.id
      })
    },
    cancelSelectAll(){
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
    },
    newsSelected(type){
      console.log('type',type)
      let confirmButtonText = this.$t('knowledgeManagement.newsAnnouncement.confirmText');
      let cancelButtonText = this.$t('knowledgeManagement.newsAnnouncement.cancelText')
      if (type === 'publish'){
        let message = this.$t('knowledgeManagement.newsAnnouncement.publishNews');
        let title = this.$t('knowledgeManagement.newsAnnouncement.publish');
        this.$confirm(  message, title, {
          confirmButtonText: confirmButtonText,
          cancelButtonText: cancelButtonText,
          type: 'warning'
        }).then(() => {
          publishNews(this.multipleSelection,() => {
            this.getNewsList();
          },this)
        }).catch(() => {

        });
      } else if (type === 'unPublish'){
        let message = this.$t('knowledgeManagement.newsAnnouncement.cancelPublishNews');
        let title = this.$t('knowledgeManagement.newsAnnouncement.unPublish');
        this.$confirm(  message, title, {
          confirmButtonText: confirmButtonText,
          cancelButtonText: cancelButtonText,
          type: 'warning'
        }).then(() => {
          unPublishNews(this.multipleSelection,() => {
            this.getNewsList()
          },this)
        }).catch(() => {

        });
      } else if (type === 'archive'){
        let message = this.$t('knowledgeManagement.newsAnnouncement.archiveNews');
        let title = this.$t('knowledgeManagement.newsAnnouncement.documentation');
        this.$confirm(  message, title, {
          confirmButtonText: confirmButtonText,
          cancelButtonText: cancelButtonText,
          type: 'warning'
        }).then(() => {
          archiveNews(this.multipleSelection,() => {
            this.getNewsList();
          },this)
        }).catch(() => {

        });
      } else if (type === 'delete'){
        let message = this.$t('knowledgeManagement.newsAnnouncement.batchDelNews');
        let title = this.$t('knowledgeManagement.newsAnnouncement.del');
        this.$confirm(  message, title, {
          confirmButtonText: confirmButtonText,
          cancelButtonText: cancelButtonText,
          type: 'warning'
        }).then(() => {
          deleteNews(this.multipleSelection,() => {
            this.deleteDialog = false;
            let multipleSelection = this.multipleSelection
            this.getFolderList();
            this.getNewsList();
            this.folderTree.forEach(item => {
              if (multipleSelection.indexOf(item.id) !== -1){
                item.newsCount -= 1;
              }
            })
            this.multipleSelection = [];
            this.$refs.multipleTable.clearSelection();
          },this)
        }).catch(() => {

        });
      } else if (type === 'move'){
        this.moveNewsDialog = true;
        this.handNewsId = this.multipleSelection;
        this.$nextTick(() => {
          let currentNode = this.$refs.dirTree.getCurrentNode();
          if (currentNode){
            this.$refs.directoryTree.selectValue = currentNode.id;
            this.$refs.directoryTree.selectLabel = currentNode.name;
          }
        })
      }
    },
    confirmMoveNews(){
      let directoryId = this.$refs.directoryTree.selectValue;
      moveNews(this.handNewsId,directoryId,() => {
        this.moveNewsDialog = false;
        this.handNewsId = [];
        let currentNode = this.$refs.dirTree.getCurrentNode();
        this.folderTree.forEach(item => {
          if (item.id == directoryId){
            item.newsCount += 1;
          }
          if (currentNode && (item.id == currentNode.id || item.id == currentNode.parentId)){
            item.newsCount -= 1;
          }
          if (item.children){
            item.children.forEach(items => {
              if (items.id == currentNode.id){
                items.newsCount -= 1;
              }
              if (items.id == directoryId){
                items.newsCount += 1;
              }
            })
          }
        })
        this.getFolderList()
        this.getNewsList();
      },this)
    },
    confirmDeleteNews(){
      if (this.deleteTitle == '删除新闻'){
        deleteNews(this.handNewsId,() => {
          this.deleteDialog = false;
          let currentNode = this.$refs.dirTree.getCurrentNode();
          this.getFolderList();
          this.getNewsList();
          if (this.handNews){
            this.$refs.multipleTable.toggleRowSelection(this.handNews,false)
          }
          if (currentNode){
            let parentId = currentNode.parentId
            this.folderTree.forEach(item => {
              if (item.id == currentNode.id || item.id == parentId){
                item.newsCount -= 1;
              }
              if (item.children){
                item.children.forEach(items => {
                  if (items.id == currentNode.id){
                    items.newsCount -= 1;
                  }
                })
              }
            })
          }
        },this)
      } else if (this.deleteTitle == '删除目录'){
       this.deleteDir(this.dirctoryId)
      }
    },
  },
  mounted() {
    this.getFolderList()
  }
};
</script>

<style scoped lang="less">
#newsAnnouncement{
  height: 100%;
  width: 100%;
  display: flex;
  .pageLeft{
    flex: none;
    width: 246px;
    height: 100%;
    padding: 12px;
    background-color: #ffffff;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0px 0px 18px 0px rgba(29,55,129,0.07);
    margin-right: 16px;
    /deep/.el-input__inner{
      border: 1px solid #E0E6F7;
      border-radius: 19px;
    }
    .directoryTree{
      margin: 10px 0;
      height: calc(100% - 100px);
      .custom-tree-node{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .folderName{
          flex: none;
          max-width: 160px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: break-all;
          display: flex;
          align-items: center;
          .iconfont{
            margin-right: 4px;
            color: #A1B9FF;
          }
          .childrenIcon{
            flex: none;
            width: 7px;
            height: 7px;
            background: #366AFF;
            border-radius: 1px;
            margin-right: 8px;
          }
        }
        .guoran-qita{
          color: #CED3DE;
        }
        .newsCount{
          flex: none;
          padding: 2px 4px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #F6F8FD;
          color: #A9B3C6;
        }
        .dropDown{
          display: none;
        }
      }
      .custom-tree-node:hover{
        .newsCount{
          display: none;
        }
        .dropDown{
          display: inline-block;
        }
      }
      /deep/.el-tree-node__content{
        height: 40px;
      }
      /deep/.is-current>.el-tree-node__content{
        background: #F8FAFF;
        position: relative;
        color: #366AFF;
      }
      /deep/.is-current>.el-tree-node__content::before{
        content: "";
        position: absolute;
        width: 3px;
        height: 100%;
        background: #366AFF;
        top: 0;
        left: 0;;
      }
      .loadTree{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #366aff;
        margin-top: 20px;
      }
    }
    .addDirectory{
      display: flex;
      align-items: center;
      justify-content: center;
      .addDirectoryBtn{
        flex: none;
        height: 32px;
        //width: 130px;
        padding: 0 6px;
        background: #ffffff;
        color: #366AFF;
        border: 1px solid #A1B9FF;
        border-radius: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        i{
          margin-right: 6px;
        }
      }
    }
  }
  .pageRight{
    flex: none;
    height: 100%;
    width: calc(100% - 262px);
    position: relative;
    .topHeader{
      height: 56px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      border-radius: 5px;
      margin-bottom: 16px;
      .headerRight{
        flex: none;
        width: 30px;
        height: 30px;
        background-color: #366aff;
        color: #ffffff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        i{
          font-size: 14px;
        }
      }
    }
    .newsTable{
      height: calc(100% - 72px);
      overflow: hidden;
      background-color: #ffffff;
      //position: relative;
      /deep/.el-table__body-wrapper {
        overflow-y: scroll!important;
      }
      .newsInfomation{
        display: flex;
        align-items: center;
        .newsCover{
          flex: none;
          width: 100px;
          //height: 60px;
          margin-right: 14px;
          .el-image{
            /deep/.el-image__preview{
              height: 90px;
              border-radius: 5px;
            }
          }
        }
        .newsContainer{
          display: flex;
          flex-direction: column;
          .newsTitle{
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            .newsStatus{
              flex: none;
              //width: 44px;
              padding: 0 6px;
              height: 18px;
              font-size: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 6px;
              border-radius: 2px;
            }
            .unPublishStatus{
              border: 1px solid #FFE0CF;
              color: #FF8C4C;
              background: #FFF1E9;
            }
            .publishStatus{
              color: #366aff;
              background: #ECF1FF;
              border: 1px solid #ECF1FF;
            }
            .archiveStatus{
              color: #9DA5BF;
              background: #F3F3F3;
              border: 1px solid #DDE0E9;
            }
            .title{
              color: #000000;
              font-weight: 600;
              font-size: 15px;
            }
            .isTop{
              flex: none;
              margin-right: 6px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              //width: 38px;
              padding: 0 6px;
              height: 18px;
              border-radius: 10px;
              color: #FE5965;
              border: 1px solid #FFDFE1;
              background: #FFFFFF;
            }
          }
          .newsDetail{
            color: #A9B3C6;
            display: flex;
            align-items: center;
            font-size: 12px;
            .newsTime{

            }
            .newsViews{
              margin: 0 20px;
              display: flex;
              align-items: center;
              i{
                margin-right: 4px;
                font-size: 14px;
              }
            }
            .newsCreator{
              display: flex;
              align-items: center;
              i{
                margin-right: 4px;
                font-size: 14px;
              }
            }
          }
        }
      }
      .visibility{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .memberItem{
          flex: none;
          display: flex;
          align-items: center;
          font-size: 12px;
        }
        .departItem{
          display: flex;
          align-items: center;
          font-size: 12px;
          margin-right: 6px;
        }
        .avatar{
          flex: none;
          width: 31px;
          height: 31px;
          background: #366AFF;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          margin-right: 6px;
          font-size: 12px;
        }
        .departAvatar{
          flex: none;
          width: 31px;
          height: 31px;
          background: #918EFF;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1;
          i{
            color: #FFFFFF;
          }
        }
        .departName{
          flex: none;
          padding: 0 12px 0 20px;
          height: 24px;
          line-height: 24px;
          background: #E6E5FF;
          border-radius: 11px;
          //display: flex;
          //align-items: center;
          //justify-content: center;
          margin-left: -14px;
          z-index: 0;
          max-width: 140px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          iframe,.iframe{
            // width: 100%;
            height: 24px;
            line-height: 24px;
          }
          &.user-name{
            background: #366AFF;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0;
            width: 60px;
            padding: 0;
            height: 24px;
            border-radius: 14px;
            i{
              color: #fff;
            }
            iframe{
              height: 24px;
              line-height: 24px;
              background: #366AFF;
              color: #fff;
              // margin-left: 6px;
              width: 100%;
              text-align: center;
              body{
                text-align: center;
              }
            }
          }
        }
        /deep/.el-avatar{
          margin-right: 6px;
        }
      }
      .effectiveTime{
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .adi-footer {

        display: flex;
        align-items: center;
        position: absolute;
        bottom: 70px;
        width: 100%;
        z-index: 99;
        .doc-handle-box {
          margin: 0 auto;
          background: rgba(52, 68, 115, 0.8);
          border-radius: 5px;
          width: 500px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: white;
          height: 36px;

          .dhb-cell {
            height: 14px;
            line-height: 14px;
            font-size: 13px;
            flex: 1;
            border-right: solid 1px lightgrey;
            cursor: pointer;
            text-align: center;
          }

          .dhb-cell:hover {
            font-weight: bolder;
          }

          .dhb-cell-with-number {
            height: 14px;
            line-height: 14px;
            font-size: 13px;
            flex: none;
            width: 110px;
            cursor: pointer;
            text-align: center;

            .dhb-cell-count {
              font-size: 12px;
              margin-left: 6px;
              height: 20px;
              background: #ECF1FF;
              border-radius: 10px;
              padding: 0 6px;
              color: #366AFF;
              font-weight: bolder;
            }
          }

          .dhb-cell-with-number:hover {
            font-weight: 700;
          }
        }
      }
    }
  }
  .folderPopupCom{
    .popup-con-c{
      width: 100%;
    }
  }
  .moveDir{
    display: flex;
    flex-direction: column;
    .label{
      margin-bottom: 10px;
      font-family: "Microsoft YaHei";
    }
  }
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/main/MainView'
import KnowledgeManagement from '../views/knowledgeManagement/knowledgeManagement'
import ChannelView from "@/views/channel/channelView";
import AccessBind from "@/views/channel/accessBind";
import AccessInformation from "@/views/channel/accessInformation";
import PersonalV2 from "@/views/personal/personalV2";
import versionManage from "@/views/versionManage/versionManage.vue";
import labelIndex from "@/views/labelManagement/labelIndex";

import { GetQueryString } from "@/assets/js/filterTree";
import NewsAnnouncement from "@/views/newsAnnouncement/newsAnnouncement";
import AddNewsAnnouncement from "@/views/newsAnnouncement/addNewsAnnouncement";
import KnowledgeAnalysis from "@/views/statisticalAnalysis/KnowledgeAnalysis";
import KnowledgeOverview from "@/views/statisticalAnalysis/knowledgeOverview";
import contractTemplateManagement from "@/views/contractManagement/contractTemplateManagement.vue";
import contractTemplateFieldManagement from "@/views/contractManagement/contractTemplateFieldManagement";
import contractTemplateFieldconfigure from "@/views/contractManagement/contractTemplateFieldConfigure.vue"
import Automation from '@/views/automation/autoIndex'
/**
 * 重写路由的push方法
 * 解决:vue在3.0以上的版本中的错误提示，不允许从当前路由跳转到当前路由
 * 添加，相同路由跳转时，触发watch (针对el-menu，仅限string方式传参，形如"view?id=5")
 */
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function push (location) {
  // 这个语句用来解决报错 调用原来的push函数，并捕获异常
  return originalPush.call(this, location).catch(err => err)
}
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};
Vue.use(VueRouter)
/**
 * 在知识管理应用端使用iframe引入页面时只使用knowledgeManagement页面，进行路由配置
 * */
let isAskLightning = GetQueryString('isAskLightning');
console.debug('isAskLightning', isAskLightning)
let routes = [];
if (isAskLightning == 1) {
  routes = [
    {
      path: '/',
      redirect: '/knowledge_Management'
    },
    {
      path: "/knowledge_Management",
      name: "knowledgeManagement",
      component: KnowledgeManagement,
      meta: {
        title: "知识管理",
        icon: "iconfont guoran-tongyichicun-zujianzhishiku"
      }
    },
  ]
} else {
  routes = [
    {
      path: '/',
      meta: { title: '首页' },
      component: () => import("../views/auth/Auth"),
    },
    {
      path: '/main',
      name: 'MainView',
      component: Main,
      children: [
        {

          path: "/knowledge_Management",
          name: "knowledgeManagement",
          component: KnowledgeManagement,
          meta: {
            title: "知识管理",
            icon: "iconfont guoran-tongyichicun-zujianzhishiku",
            belong: "knowledge"
          }
        },
        {
          path: "/channel_management",
          name: "channel",
          component: ChannelView,
          meta: {
            title: "渠道管理",
            icon: "iconfont guoran-tongyichicun-qudaoguanli",
            keepAlive: false,

          }
        },
        {
          path: "/labelIndex",
          name: "labelIndex",
          component: labelIndex,
          meta: {
            title: "标签管理",
            icon: "iconfont guoran-biaoqian2"
          }
        },
        {
          path: "/contract_template",
          name: "contract_template",
          component: contractTemplateManagement,
          meta: {
            title: "合同模板管理",
            icon: "iconfont guoran-biaoqian2"
          }
        },
        {
          path: "/template_field",
          name: "template_field",
          component: contractTemplateFieldManagement,
          meta: {
            title: "合同模板字段管理",
            icon: "iconfont guoran-biaoqian2",
            belong: "contract"
          }
        },
        {
          path: "/template_field_configure",
          name: "template_field_configure",
          component: contractTemplateFieldconfigure,
          meta: {
            title: "合同模板字段配置",
            icon: "iconfont guoran-biaoqian2",
            belong: "contract"
          }
        },
        {
          path: "/accessBind",
          name: "accessBind",
          component: AccessBind,
          meta: {
            title: "渠道介入",
            icon: "iconfont guoran-tongyichicun-qudaojieru",
            belong: "channel"
          }
        },
        {
          path: "/accessInformation",
          name: "accessInformation",
          component: AccessInformation,
          meta: {
            title: "接入信息",
            icon: "iconfont guoran-tongyichicun-qudaojieru",
            belong: "channel"
          }
        },
        {
          path: '/personal',
          name: "personal",
          component: PersonalV2,
          meta: {
            title: '个人中心'
          }
        },
        {
          path: '/versionManage',
          name: "versionManage",
          component: versionManage,
          meta: {
            title: '知识管理',
            belong: "knowledge"
          }
        },
        {
          path: '/automation',
          name: 'automation',
          component: Automation,
          meta: {
            title: '自动化流程',
            icon:"iconfont guoran-tongyichicun-14-12-zidonghualiucheng",
            belong: ""
          }
        },
        {
          path: '/news_announcementManage',
          name: "newsAnnouncementManage",
          component: NewsAnnouncement,
          meta: {
            title: '新闻公告管理',
            icon: "iconfont guoran-xinwengao",

          }
        },
        {
          path: '/addNewsAnnouncement',
          name: "addNewsAnnouncement",
          component: AddNewsAnnouncement,
          meta: {
            title: '新闻公告管理',
            icon: "iconfont guoran-xinwengao",
            belong: "news"
          }
        },
        {
          path: "/analysis_KnowledgeAnalysis",
          name: "analysis_KnowledgeAnalysis",
          component: KnowledgeAnalysis,
          meta: {
            title: "知识分析",
            icon: "iconfont guoran-tongyichicun-zhishiku",
            belong: "analysis"
          }
        },
        {
          path: "/analysis_knowledgeOverview",
          name: "analysis_knowledgeOverview",
          component: KnowledgeOverview,
          meta: {
            title: "知识总览",
            icon: "iconfont guoran-zonglan",
            belong: "analysis"
          }
        },
        {
          path: '/weboffice',
          name: 'WebOfficePage',
          component: () => import(/* webpackChunkName: "about" */ '../views/versionManage/webOfficePage.vue'),
          props: route => ({
            officeType: route.query.officeType,
            fileId: route.query.fileId,
            userId: route.query.userId,
            currentVersion: route.query.currentVersion,
          }),
        },
        // {
        //  path: "/analysis_KnowledgeAnalysis",
        //   name:"analysis_KnowledgeAnalysis",
        //   component: KnowledgeAnalysis,
        //   meta:{
        //     title:"知识分析",
        //     icon:"iconfont guoran-tongyichicun-zhishiku",
        //     belong:"analysis"
        //   }
        // },
        // {
        //   path:"/analysis_knowledgeOverview",
        //  name: "analysis_knowledgeOverview",
        //   component: KnowledgeOverview,
        //   meta:{
        //    title: "知识总览",
        //    icon: "iconfont guoran-zonglan",
        //     belong:"analysis"
        //   }
        // },
        // {
        //   path: '/automation',
        //   name: 'automation',
        //   component: Automation,
        //   meta: {
        //     title: '自动化流程',
        //    belong: ""
        //   }
        // },
      ]
    },

  ]
}

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (sessionStorage.getItem("_token")) {
    next()
  } else {
    if (to.path == '/' || to.path == '/knowledge_Management') {
      next()
    } else {
      if (to.path == '/') {
        next()
      } else {
        if (to.path == '/versionManage') {
          let query = JSON.stringify(to.query)
          sessionStorage.setItem('toVersionManage', query)
        }
        next('/')
      }
      next({
        path: to.path,
        query: to.query
      })
    }
  }
})



export default router

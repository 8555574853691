<template>
  <div class="elSelectTree">
    <el-select
        v-model="selectValue"
        :multiple="multiple"
        ref="seleteTree"
        filterable
        :placeholder="$t('knowledgeManagement.knowledgeTag.ruleCondition.selectPlaceholder')"
        collapse-tags
        :popper-append-to-body="false"
        :filter-method="remoteMethod"
        @remove-tag="removeTag"
        style="width: 100%"
        value-key="_id"
        @keyup.enter.native="createTag"
    >
      <el-option :label="selectLabel" :value="selectValue" class="setStyle" :class="keyword ? 'has-create' : 'no-has-create' " disabled>
        <el-tree
            :data="list"
            ref="tree"
            node-key="_id"
            check-strictly
            :expand-on-click-node="false"
            check-on-click-node
            @node-click="nodeClick"
            :default-expanded-keys="defaultExpandedCids"
            :load="loadNode"
            lazy
            :props="{id:'_id', children: 'children',label: 'name',isLeaf:'isLeaf'}"
        >
          <span class="custom-tree-node" slot-scope="{ data }">
            <div class="node-left">
              <i class="iconfont guoran-AI" v-if="data.aiCreate"></i>
              <span>{{ data.name }}</span>
            </div>
            <span class="node-right">
              <i class="iconfont guoran-tongyichicun-kaiqi1" v-if="data._id === selectValue || tagIds.indexOf(data._id) !== -1"></i>
            </span>
          </span>
        </el-tree>
        <div class="keyword" v-if="keyword && isHasCreateBtn && (!filterId)">
          <div class="create-tag" @click="createTag">{{ $t('knowledgeManagement.knowledgeTag.createTag') }}</div>
          <span>{{keyword}}</span>
        </div>
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { requestUrl } from "@/http/requestUrl";

export default {
  name: "elSelectTree",
  data(){
    return{
      selectLabel: "",
      selectValue:"",
      list:[],
      rootObj:[{
        name:"根目录",
        _id:"-1",
        isLeaf:true
      }],
      defaultProps:{
        children:"children",
        label:"name",
        isLeaf:'isLeaf'
      },
      tagIds:[],
      checkTagList:[],
      keyword:"",
      treeData:null,
      resolve:null,
      isHasCreateBtn:false,
      defaultExpandedCids:[]
    }
  },
  props:{
    filterId:{
      type:String,
      default(){
        return ''
      }
    },
    multiple:{
      type:Boolean,
      default(){
        return false
      }
    }
  },
  mounted() {
  },
  methods:{
    remoteMethod(val){
      this.keyword = val;
      let url = requestUrl.tagManage.searchTag;
      let corpId = sessionStorage.getItem('_corpId');
      let mainId = sessionStorage.getItem('_mainId');
      if (val){
        url += '?mainId=' + mainId + '&corpId=' + corpId + '&keyword=' + val + '&page=1' + "&pageSize=50";
      } else {
        url = requestUrl.tagManage.tagTree;
        url += '?mainId=' + mainId + '&corpId=' + corpId + '&parentId=' + "-1";
      }
      this.$http.get(url).then(res => {
        if (res.data.code == 0 && res.data.data){
          this.list = [];
          let data = res.data.data.list ? res.data.data.list : res.data.data;
          if (val){
            data = data.map(res => {
              res.isLeaf = true;
              return res
            })
          }
          // filterId 删除标签时，需要过滤掉当前标签以及当前标签下的所有子标签
          if (this.filterId){
            data = data.filter(item => item._id !== this.filterId && item.parentId !== this.filterId);
            this.list = data;
          } else {
            //多选时是知识打标签时，这时候过滤掉掉根目录
            if (this.multiple){
              this.list = data;
            } else {
              this.list = this.rootObj.concat(data);
            }
          }
          // 判断当前搜索出来的数据是否有跟输入框中相同的，没有的话显示创建标签按钮
          if (this.list && this.list.length > 0){
            let newList = this.list.filter(item => item.name === val);
            if (newList.length == 0){
              this.isHasCreateBtn = true
            } else {
              this.isHasCreateBtn = false;
            }
          } else {
            this.isHasCreateBtn = true;
          }
        }
      })
    },
    loadNode(node, resolve) {
      let treeData =  node.data || {};
      if (node.level === 0) {
        this.defaultExpandedCids = []
        this.treeData = node;
        this.resolve = resolve;
        let url = requestUrl.tagManage.tagTree;
        let corpId = sessionStorage.getItem('_corpId');
        let mainId = sessionStorage.getItem('_mainId');
        let uid = sessionStorage.getItem("_uid");
        url += '?mainId=' + mainId + '&corpId=' + corpId + '&uid=' + uid + '&parentId=' + "-1";
        this.$http.get(url).then(res => {
          if (res.data.code == 0){
            let data = res.data.data;
            if (this.filterId){
              data = data.filter(item => item._id !== this.filterId)
            } else if (!this.multiple){
              data = this.rootObj.concat(data);
            }
            data.forEach(item => {
              if (!item.hasChildren){
                item.isLeaf = true
              }
            })
            if (data.length <= 6){
              data.forEach(item => {
                if (item.hasChildren){
                  this.defaultExpandedCids.push(item._id)
                }
              })
            }

            resolve(data)
          }
        })
      }
      if (node.level >= 1) {
        let url = requestUrl.tagManage.tagTree
        let corpId = sessionStorage.getItem('_corpId');
        let mainId = sessionStorage.getItem('_mainId');
        let uid = sessionStorage.getItem("_uid");
        url += '?mainId=' + mainId + '&corpId=' + corpId + '&uid=' + uid + '&parentId=' + (treeData._id ? treeData._id : "-1");
        this.$http.get(url).then(res => {
          if (res.data.code == 0 && res.data.data){
            let data = res.data.data.filter(item => item._id !== this.filterId);
            data.forEach(item => {
              if (!item.hasChildren){
                item.isLeaf = true
              }
            })
            resolve(data);
          }
        })
      }
    },
    nodeClick(data){
      if (this.multiple){
        if (!this.selectValue){
          this.selectValue = [];
        }
        if (!this.selectLabel){
          this.selectLabel = [];
        }
        if (this.tagIds.indexOf(data._id) == -1){
          this.tagIds.push(data._id);
          this.checkTagList.push(data);
        } else {
          let index = this.tagIds.indexOf(data._id);
          if (index !== -1){
            this.tagIds.splice(index,1);
            this.checkTagList.splice(index,1);
          }
        }
        if (this.selectValue.indexOf(data.name) == -1){
          this.selectValue.push(data.name);
        } else {
          this.selectValue = this.selectValue.filter(item => item !== data.name);
        }
      } else {
        this.selectValue = "";
        this.selectLabel = "";
        this.selectLabel = data.name;
        this.selectValue = data._id;
        this.$refs.seleteTree.blur();
      }
    },
    removeTag(tag){
      let index = -1
      for (let i=0;i<this.checkTagList.length;i++){
        let data = this.checkTagList[i];
        if (data.name == tag){
          index = i;
          break;
        }
      }
      this.checkTagList.splice(index,1);
      this.tagIds.splice(index,1);
    },
    createTag(){
      if (!this.keyword){
        return
      }
      if (!this.multiple){
        return;
      }
      //如果已经存在搜索出来的标签，回车时自动选中第一个
      if (!(this.keyword && this.isHasCreateBtn && (!this.filterId)) && this.list.length > 0){
        if (this.tagIds.indexOf(this.list[0]._id) == -1){
          this.tagIds.push(this.list[0]._id);
        }
        if (this.selectValue.indexOf(this.list[0].name) == -1){
          this.selectValue.push(this.list[0].name);
        }
        return;
      }
      let tag = {
        name:this.keyword,
        parentId:"-1"
      }
      let url =requestUrl.tagManage.saveTag;
      let corpId = sessionStorage.getItem('_corpId');
      let mainId = sessionStorage.getItem('_mainId');
      let uid = sessionStorage.getItem("_uid");
      url += '?mainId=' + mainId + '&corpId=' + corpId + '&uid=' + uid;
      this.$http.post(url,tag).then(res => {
        if (res.data.code == 0){
          let data = res.data.data;
          if (this.multiple){
            if (this.tagIds.indexOf(data._id) == -1){
              this.tagIds.push(data._id);
            }
            if (this.selectValue.indexOf(data.name) == -1){
              this.selectValue.push(data.name);
            }
          } else {
            this.selectValue = data._id;
            this.selectLabel = data.name;
            this.$refs.seleteTree.blur();
          }
          this.isHasCreateBtn = false;
          this.keyword = '';
          this.loadNode(this.treeData,this.resolve);
        }
      })
    },
  }
};
</script>

<style scoped lang="less">
.elSelectTree{
  .setStyle {
    min-height: 250px;
    padding: 0 !important;
    margin: 0;
    //overflow-y: auto;
    cursor: default !important;
    background-color: #ffffff;
    overflow: hidden;

  }
  .has-create{
    /deep/.el-tree{
      height: 210px;
      overflow-y: scroll;
    }
  }
  .no-has-create{
    /deep/.el-tree{
      height: 250px;
      overflow-y: scroll;
    }
  }
  .custom-tree-node{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 10px);
    padding-right: 10px;
    .node-left{
      display: flex;
      align-items: center;
      .guoran-AI{
        color: #366aff;
        margin-right: 6px;
        font-size: 15px;
      }
    }
    .node-right{
      flex: none;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      i{
        font-size: 14px;
        color: #366aff;
      }
    }
  }
  /deep/.el-tree-node__content{
    height: 34px;
  }
  /deep/.el-tag--info{
    background-color: #366aff;
    border-color: #366aff;
    color: #ffffff;
    .el-tag__close{
      color: #ffffff;
      background-color: #366aff;
    }
  }
  /deep/.el-select-dropdown__item{
    height: 40px;
    line-height: 40px;
  }
  .el-select-dropdown__item.hover{
    background-color: #ffffff;
  }
  .keyword{
    display: flex;
    align-items: center;
    padding: 0 14px;
    color: #000000;
    border-top: 1px solid #E0E6F7;
    background-color: #ffffff;
    height: 40px;
    .create-tag{
      height: 30px;
      padding: 0 12px;
      color: #ffffff;
      background-color: #366aff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 6px;
      cursor: pointer;
      border-radius: 14px;
      font-size: 12px;
      flex: none;
    }
  }
}
</style>
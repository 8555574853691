import { GetQueryString } from "@/assets/js/filterTree";
import { requestUrl } from "@/http/requestUrl";
import axios from 'axios';
export const isAskLightning = () => {
    let isAsk = GetQueryString('isAskLightning');
    return isAsk == 1
}

Date.prototype.format = function (fmt) {
    var o = {
      'M+': this.getMonth() + 1,                 //月份
      'd+': this.getDate(),                    //日
      'h+': this.getHours(),                   //小时
      'm+': this.getMinutes(),                 //分
      's+': this.getSeconds(),                 //秒
      'q+': Math.floor((this.getMonth() + 3) / 3), //季度
      'S': this.getMilliseconds()             //毫秒
    }
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (var k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
      }
    }
    return fmt
  }
Date.prototype.format_cus = function () {
    var thisYear = new Date().getFullYear()
    if (thisYear != this.getFullYear()) {
      return this.format('yyyy-MM-dd hh:mm')
    }
    return this.format('MM-dd hh:mm')
}
export function timeAgo(time) {
    var timestamp = Date.parse(time.replace(/-/gi, '/')) / 1000; // 兼容iOS Safari时间格式

    var mistiming = Math.round((new Date().getTime() / 1000 - timestamp) / 60);
    if (mistiming <= 0){
        mistiming = 1;
    }
    if (mistiming < 60) {
        return mistiming + '分钟前';
    }
    var timing = Math.round(mistiming / 60);
    if (timing < 24) {
        return timing + '小时前';
    }
    var timingday = Math.round(timing / 24);
    if (timingday < 30) {
        return timingday + '天前';
    } else if (timingday > 30 && timingday < 365) {
        return Math.round(timingday / 30) + '个月前';
    } else {
        return Math.round(timingday / 365) + '年前';
    }
}
export const isUtf8 = (filePart) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsText(filePart)
        fileReader.onload = (e) => {
            const str = e.target.result
            // roughly half
            const sampleStr = str.slice(4, 4 + str.length / 2)
            if (sampleStr.indexOf("�") === -1) {
                resolve('utf-8')
            } else {
                resolve('gb2312')
            }
        }
        fileReader.onerror = () => {
            reject(new Error("Failed to read the content of the file, please check whether the file is damaged"))
        }
    })
}

async function findTag(name,id){
    let flag = false;
    let url = requestUrl.tagManage.duplicateTags;
    let corpId = sessionStorage.getItem('_corpId');
    let mainId = sessionStorage.getItem('_mainId');
    url += '?mainId=' + mainId + '&corpId=' + corpId + '&keyword=' + name;
    await this.$http.get(url).then(res => {
        let data = res.data.data;
        if (id){
            data = res.data.data.filter(item => item._id !== id);
        }
        if (data.length > 0){
            flag = true
        }
    })
    return flag
}

function publishNews(id,callback,that){
    let url = requestUrl.news.publishNews;
    axios.put(url,id).then(res => {
        if (res.data.code == 0){
            that.$message({
                message:"发布成功",
                type:"success"
            })
            if (callback){
                callback()
            }
        }
    })
}
function unPublishNews(id,callback,that){
    let url = requestUrl.news.cancelPublishNews;
    axios.put(url,id).then(res => {
        if (res.data.code == 0){
            that.$message({
                message:"取消发布成功",
                type:"success"
            })
            if (callback){
                callback()
            }
        }
    })
}
function archiveNews(id,callback,that){
    let url = requestUrl.news.archiveNews;
    axios.put(url,id).then(res => {
        if (res.data.code == 0){
            that.$message({
                message:"归档成功",
                type:"success"
            })
            if (callback){
                callback()
            }
        }
    })
}
function unArchiveNews(id,callback,that){
    let url = requestUrl.news.cancelArchiveNews;
    axios.put(url,id).then(res => {
        if (res.data.code == 0){
            that.$message({
                message:"取消归档成功",
                type:"success"
            })
            if (callback){
                callback()
            }
        }
    })
}
function pinToTop(id,callback,that){
    let url = requestUrl.news.pinToTop;
    axios.put(url,id).then(res => {
        if (res.data.code == 0){
            that.$message({
                message:"置顶成功",
                type:"success"
            })
            if (callback){
                callback()
            }
        }
    })
}
function deleteNews(id,callback,that){
    let url = requestUrl.news.deleteNews;
    axios.delete(url,{
        data:id
    }).then(res => {
        if (res.data.code == 0){
            that.$message({
                message:"删除新闻成功",
                type:"success"
            })
            if (callback){
                callback()
            }
        }
    })
}
function moveNews(id,directoryId,callback,that){
    let url = requestUrl.news.moveNews;
    axios.put(url,{
        ids:id,
        directoryId
    }).then(res => {
        if (res.data.code == 0){
            that.$message({
                message:"移动新闻成功",
                type:"success"
            })
            if (callback){
                callback()
            }
        }
    })
}
export const imageObj = {
    PDF: 'pdf1',
        pdf: 'pdf1',
        TXT: 'txt1',
        txt: 'txt1',
        selfadd: 'selfadd1',
        md: "md2",
        html: 'selfadd1',
        mode: "mode",
        general: 'general',
        word: "word1",
        Word: "word1",
        docx: "word1",
        doc: "word1",
        WORD: "word1",
        Excel: 'excel1',
        EXCEL: 'excel1',
        excel: 'excel1',
        PPT: 'ppt1',
        ppt: 'ppt1',
        pptx: 'ppt1',
        img: "image1",
        image: "image1",
        video: "video",
        audio: 'audio',
        link: "link",
        wechat: "wechat",
        mp4: "video",
        MP4: "video",
        xlsx: "excel1",
        SLSX: "excel1",
        xls: "excel1",
        XLS: "excel1",
        jpg: "image1",
        JPG: "image1",
        jpeg: "image1",
        JPEG: "image1",
        png: "image1",
        PNG: "image1",
        webp: "image1",
        WEBP: "image1",
        gif: "image1",
        GIF: "image1",
        svg: "image1",
        SVG: "image1",
        bmp: "image1",
        BMP: "image1",
        avi: "video",
        AVI: "video",
        mov: "video",
        MOV: "video",
        rmvb: "video",
        RMVB: "video",
        wav: "audio",
        WAV: "audio",
        mp3: "audio",
        MP3: "audio",
        yqhtml: "yqhtml",
        feishuhtml:"feishuhtml"
}
export {
    findTag,
    publishNews,
    unPublishNews,
    archiveNews,
    unArchiveNews,
    pinToTop,
    deleteNews,
    moveNews
}
<template>
    <div id="personal">
        <iframe class="personal-centent" :src="personalUrl" width="100%" height="100%" frameborder="0"
            scrolling="auto"></iframe>
        <div class="mask" v-if="maskDisable">
        </div>
    </div>
</template>

<script>
export default {
    name: "personalV2",
    data() {
        return {
            maskDisable: false
        };
    },
    computed: {
        personalUrl() {
          console.debug('process',process.env.VUE_APP_PERSONAL,process.env)
            return process.env.VUE_APP_PERSONAL + '#/personalContent?isOther=true'
        }
    },
    mounted() {
        window.addEventListener('message', (e) => {
            if(e.data.data == 'MASK') {
                this.maskDisable = !this.maskDisable
            }
            if(e.data.data == 'reload') {
                this.$emit('reload')
            }
        })
    }
};
</script>

<style lang="less" scoped>
#personal {
    width: 100%;
    height: calc(100% - 20px);
    margin: 8px 0;
    .personal-centent {
        position: relative;
        z-index: 1000;
        overflow: hidden;
        overflow-y: auto;
    }

    .mask {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        background: rgba(0, 0, 0, 0.3);
        animation: dialog-fade-in 0.3s ease-in;
    }
}
</style>

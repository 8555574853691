<template>
  <div class="ckeditorCom" id="ckeditorCom">
    <div class="ckeditorInput">
      <ckeditor
          :editor="ckeditor.editor"
          v-model="htmlContent"
          :config="{...editorConfig,placeholder:replyItem ? $t('versionManage.reply') : $t('versionManage.comment')}"
          @focus="focusCkeditor"
          @ready="readyCkeditor"
          @input="inputCkeditor"
          @blur="blurCkeditor"
          ref="ckeditor"
          id="ckeditorInput"
          :key="ckeditorKey"
      ></ckeditor>
      <i class="iconfont guoran-tongyichicun-write-25-tupian" @click="uploadImage"></i>
    </div>
    <div class=commentBtn v-if="htmlContent || replyItem" :class="!replyItem ? 'setCommentEnd' : ''">
      <div class="commentLeft" v-if="replyItem">
        <span>{{replyItem.commentatorName}}:</span>
        <span v-html="replyItem.htmlContent" class="htmlContent"></span>
        <span class="close-btn" @click="cancelInput">
          <i class="iconfont guoran-shanchu"></i>
        </span>
      </div>
      <div class="commentRight">
        <div class="cacel button" @click="cancelInput">{{$t('common.cancel')}}</div>
        <div class="confirm button" @click="sendComment">{{$t('common.send')}}</div>
      </div>
    </div>
    <div class="atUserPosition" v-if="atUserPopup">
      <at-user-list
          @selectUser="selectUser"
          ref="atUserList"
      ></at-user-list>
    </div>
  </div>
</template>

<script>
// import CKEDITOR from "ckeditor";
import BalloonEditor from 'ckeditor';
import { ossConfig,publicMultipartUpload } from "@/assets/js/AliyunIssUtil";
import AtUserList from "@/components/knowledgePreview/atUserList";

export default {
  name: "ckeditorInput",
  components: { AtUserList },
  data(){
    return{
      ckeditor: {
        editor: BalloonEditor,
      },
      editorConfig: {
        extraPlugins: [],
        toolbar: []
      },
      currentEditor:null,
      htmlContent:"",
      atUserPopup:false,
      editorSelection:null,
      ckPath:null,
      parentNode:null,
      showUser:true,
      ckeditorKey:0
    }
  },
  props:{
    knowledgeComment:{
      type:Boolean,
      default:false
    },
    parentCommentId:{
      type:String,
      default:""
    },
    replyItem:{
      type:Object,
      default:null
    }
  },
  mounted() {
    setTimeout(() => {
      let wrapper = document.getElementsByClassName('ck-body-wrapper')[0]
      if (wrapper){
        wrapper.removeChild(wrapper.children[0])
      }
    }, 10)
  },
  methods:{
    uploadImage(){
      let inputDom = document.createElement('input')
      inputDom.setAttribute('id', '_ef_file');
      inputDom.setAttribute('type', 'file');
      inputDom.setAttribute('accept', '.jpg,.jpeg,.png');
      inputDom.setAttribute('multiple', 'true');
      inputDom.setAttribute("style", 'visibility:hidden');
      document.body.appendChild(inputDom);
      inputDom.click();
      inputDom.onchange = () => {
        let files = Array.from(inputDom.files);
        this.handlerSameFileName(files);
        document.body.removeChild(inputDom)
      }
    },
    handlerSameFileName(files){
      console.log('files',files)
      for (let i=0;i<files.length;i++){
        this.upload(files[i])
      }

    },
    upload(file){
      let res = publicMultipartUpload(ossConfig,file);
      res.then(ress => {
        let url = ress.res.requestUrls[0];
        let index = url.lastIndexOf("?")
        if (index !== -1){
          url = url.substr(0, url.lastIndexOf("?"));
        }
        let command = this.currentEditor.commands.get("insertAskComponent");
        command.execute({
          tag: "img",
          options: {
            alt: '',
            src: url,
            filename: file.name
          },
        });
      })
    },
    cancelInput(){
      this.ckeditorKey = new Date().getTime();
      this.$emit('cancelInput')
    },
    sendComment(){
      console.log('htmlContent',this.htmlContent)
      if (!this.htmlContent){
        this.$message({
          message:this.$t('versionManage.comment'),
          type:"warning"
        })
        return
      }
      this.$emit('sendComment',this.htmlContent);
      this.ckeditorKey = new Date().getTime();
    },
    // 获取不同的ckeditor元素
    getCkeditorDom(){
      let ckeditorDom = null;
      ckeditorDom = document.querySelector('#ckeditorInput');
      return ckeditorDom;
    },
    readyCkeditor(editor){
      this.currentEditor = editor;
      let ckeditorDom = this.getCkeditorDom();
      this.parentNode = document.getElementById('ckeditorInput')
      let flag = false;
      ckeditorDom.onkeydown = (e) => {
        if (e.keyCode === 16){
          flag = true;
        }
      }
      ckeditorDom.onkeyup = (e) => {
        if (e.keyCode === 16){
          flag = true;
        }
        // 判读组合事件，shift 按下的时 输入 2 （@符）
        if (((e.keyCode === 229 || e.keyCode === 50) && flag) || e.keyCode === 8){
          if (this.showUser){
            this.atUserPopup = true;
          } else {
            this.atUserPopup = false;
          }
          flag = false;
        } else {
          // this.atUserPopup = false;
          // this.editorSelection = null
        }
      }
      ckeditorDom.onclick = () => {
        this.atUserPopup = false;
      }
    },
    inputCkeditor(){
      // 获取所有节点
      this.$nextTick(() => {
        if (this.parentNode){
          let childNodes = this.parentNode.childNodes;
          if (childNodes){
            for (let i=0;i<childNodes.length;i++){
              let text = Array.from(childNodes[i].childNodes).some(item => item.nodeName === '#text');
              //是否存在@
              let hasText = Array.from(childNodes[i].childNodes).some(item => item.nodeValue && item.nodeValue.indexOf('@') !== -1);

              if (text && !hasText){
                this.showUser = false;
              } else if (!hasText){
                this.showUser = false;
              } else {
                this.showUser = true;
              }
              let childrenNodes =  Array.from(childNodes[i].childNodes);
              childrenNodes.forEach((item) => {
                // 获取@后面的字符完成搜索
                if (item.nodeValue){
                  let value = item.nodeValue
                  let index = item.nodeValue.indexOf('@');
                  if (index !== -1) {
                    let newVal = value.substr(value.lastIndexOf("@") + 1,value.length);
                    if (this.atUserPopup){
                      this.$refs.atUserList.currentPage = 1;
                      if (newVal){
                        this.$refs.atUserList.searchDates(newVal)
                      } else {
                        let departId = this.$refs.atUserList.departId
                        this.$refs.atUserList.getDatas(departId);
                      }

                    }
                  }
                }
              })
            }
          }
        }
        if (this.knowledgeComment && this.htmlContent){
          let parent = document.getElementById('now-version');
          parent.scrollTo(0,  parent.scrollHeight);
        }
      })
    },
    focusCkeditor(zh,editor){
      this.currentEditor = editor;
    },
    blurCkeditor(){
      setTimeout(() => {
        // this.atUserPopup = false;
      },100)
    },
    selectUser(item){
      console.log('item',item);
      let path = this.editorSelection;
      console.log('path',path)
      path.path = [path.path[0], path.path[1] - 1 > 0 ? path.path[1] - 1  : 0]
      this.currentEditor.model.change(writer => {
        let range = writer.createRange(path, this.currentEditor.model.document.selection.getFirstPosition())
        writer.remove(range)
      })
      let command = this.currentEditor.commands.get("insertAskComponent");
      command.execute({
        tag: "span",
        options: {
          name: '@'+ (item.label || item.name),
          data: item.id,
        },
      });
      this.$nextTick(() => {
        this.currentEditor.editing.view.focus();
        this.atUserPopup = false;
      })
    }
  },
  watch: {
    atUserPopup(value) {
      if(value) {
        this.editorSelection = this.currentEditor.model.document.selection.getFirstPosition();
      } else {
        this.editorSelection = null
      }
    },
    replyItem:{
      handler(value){
        this.ckeditorKey = new Date().getTime();
        if (value){
          setTimeout(() => {
            this.currentEditor.editing.view.focus();
            let parent = document.getElementById('now-version');
            parent.scrollTo(0,  parent.scrollHeight);
          },100)
        }
      },
      immediate:true,
      deep:true
    }
  }
};
</script>

<style scoped lang="less">
.ckeditorCom{
  position: relative;
  .ckeditorInput{
    margin: 20px 0 14px 0;
    position: relative;
    /deep/.ck.ck-editor{
      .ck.ck-reset_all{
        height: 0;
        width: 0;
      }
      .ck-editor__editable{
        border-radius: 5px;
        border: 1px solid #E0E6F7;
        //background: #F9FAFE;
        background-color: #ffffff;
      }
      .ck-placeholder{
        color: #A9B3C6;
        font-size: 12px;
      }
      .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable){
        box-shadow: none;
      }
      p{
        font-size: 12px;
      }
    }
    .guoran-tongyichicun-write-25-tupian{
      position: absolute;
      right: 10px;
      bottom: 10px;
      color: #AAB9E1;
      cursor: pointer;
    }
    /deep/img{
      width: 30%;
    }
    /deep/.ck.ck-editor__editable_inline{
      border-radius: 5px;
      border: 1px solid #E0E6F7;
      background: #F9FAFE;
      p{
        font-size: 12px;
      }
    }
    /deep/.ask-component-placeholder{
      background-color: #366aff;
      display: inline-block;
      padding: 0px 2px;
      border-radius: 99px;
      margin-right: 6px;
      //margin-bottom: 8px;
      color: #ffffff;
    }
  }
  .commentBtn{
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .commentLeft{
      display: flex;
      align-items: center;
      height: 28px;
      padding: 0 4px;
      background-color: #ebf0ff;
      color: #616161;
      border-radius: 5px;
      margin-right: 10px;
      font-size: 12px;
      .htmlContent{
        max-width: 240px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-word;
        font-size: 12px;
        display: flex;
        align-items: center;
        /deep/p{
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
        }
      }
      .close-btn{
        margin-left: 6px;
        cursor: pointer;
        i{
          font-size: 14px;
        }
      }
    }
    .commentRight{
      display: flex;
      align-items: center;
    }
    .button{
      flex: none;
      width: 55px;
      height: 26px;
      border-radius: 13px;
      display:flex;
      align-items: center;
      justify-content: center;
      cursor:pointer;
    }
    .cacel{
      background: #FFFFFF;
      border: 1px solid #E0E6F7;
      color: #000000;
      margin-right: 10px;
      cursor: pointer;
    }
    .confirm{
      background: #366aff;
      color: #ffffff;
      border: 1px solid #366aff;
    }
  }
  .setCommentEnd{
    justify-content: flex-end!important;
  }
  .atUserPosition{
    position: absolute;
    bottom: 80px;
    left: 20px;
  }
}
</style>
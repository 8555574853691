<template>
    <ww-open-data :type="type" :openid="openid" />
</template>

<script>
    export default {
        name: "openData",
        props:["type","openid"],
        created() {
          /* eslint-disable */
          WWOpenData.bind(this.$el);
          /* eslint-enable*/
        },
        mounted() {
            this.$nextTick(() => {
              /* eslint-disable */
              WWOpenData.bind(this.$el);
              /* eslint-enable*/
            })
        },
      watch:{
        openid: {
          handler(){
            this.$nextTick(() => {
              /* eslint-disable */
              WWOpenData.bind(this.$el);
              /* eslint-enable*/
            })
          },
          immediate:true,
          deep:true
        }
      }
    };
</script>

<style scoped>

</style>
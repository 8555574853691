<template>
  <div class="elSelectTree">
    <el-select
        v-model="selectValue"
        ref="selectTree"
        filterable
        :placeholder="$t('knowledgeManagement.addNewsAnnounce.selectPlaceholder')"
        :popper-append-to-body="false"
        style="width: 100%"
        value-key="id"
    >
      <el-option :label="selectLabel" :value="selectValue" class="setStyle" :class="keyword ? 'has-create' : 'no-has-create' " disabled>
        <el-tree
            :data="rootObj"
            ref="tree"
            node-key="id"
            check-strictly
            :expand-on-click-node="false"
            check-on-click-node
            @node-click="nodeClick"
            :props="defaultProps"
        >
          <span class="custom-tree-node" slot-scope="{ data }">
            <div class="node-left">
              <i class="iconfont guoran-AI" v-if="data.aiCreate"></i>
              <span>{{ data.name }}</span>
            </div>
            <span class="node-right">
              <i class="iconfont guoran-tongyichicun-kaiqi1" v-if="data.id === selectValue"></i>
            </span>
          </span>
        </el-tree>
        <div class="loadTree"
             v-if="false"
             @click="loadMoreTreeData"
        >
          <span>{{$t('knowledgeManagement.newsAnnouncement.loadMore')}}</span>
        </div>
      </el-option>
    </el-select>
  </div>
</template>

<script>
// import { requestUrl } from "@/http/requestUrl";

import { requestUrl } from "@/http/requestUrl";

export default {
  name: "elSelectTree",
  data(){
    return{
      selectLabel: "",
      selectValue:"",
      list:[],
      rootObj:[{
        name:"根目录",
        id:null,
        isLeaf:true
      }],
      defaultProps:{
        children:"children",
        label:"name",
        isLeaf:'isLeaf',
        disabled:function(data){
          if(data.children && data.children.length>0){
            return true
          }else{
            return false
          }
        }
      },
      tagIds:[],
      checkTagList:[],
      keyword:"",
      treeData:null,
      resolve:null,
      isHasCreateBtn:false,
      defaultExpandedCids:[],
      folderTotal:0,
      pageIndex:0,
      loadTreePageSize:5
    }
  },
  props:{
    addNews:{
      type:Boolean,
      default:false
    },
    dirId:{
      type:String,
      default:""
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getFolderList();
    })
    if (this.addNews){
      this.rootObj = [];
    }
  },
  methods:{
    getFolderList(){
      if (!this.addNews){
        let url = requestUrl.newsDirectory.getDirectoryList
        url += '?id=&name=';
        this.$http.get(url).then(res => {
          if (res.data.code == 0 && res.data.data){
            let data = res.data.data;
            data = data.filter(item => item.id !==this.dirId);
            this.rootObj = this.rootObj.concat(data);
            this.folderTotal = res.data.data.count;
          }
        })
      } else {
        let url = requestUrl.newsDirectory.ddlTreeList;
        let obj = {
          "id": null
        }
        this.$http.post(url,obj).then(res => {
          if (res.data.code == 0 && res.data.data){
            let data = res.data.data
            this.rootObj = this.rootObj.concat(data);
            this.folderTotal = res.data.data.count;
          }
        })
      }
    },
    loadMoreTreeData(){
      this.pageIndex += 1;
      this.getFolderList();
    },
    loadNode(node, resolve) {
      let treeData =  node.data || {};
      let url = requestUrl.newsDirectory.loadTreeList;
      if (node.level === 0) {
        this.defaultExpandedCids = []
        this.treeData = node;
        this.resolve = resolve;
        let obj = {
          "pageIndex": 0,
          "pageSize": 10,
          "id": null
        }
        this.$http.post(url,obj).then(res => {
          console.log('=====',res)
          if (res.data.code == 0 && res.data.data){
            let data = res.data.data.rows;
            data = this.rootObj.concat(data);
            resolve(data)
          }
        })
      }
      if (node.level >= 1) {
        let obj = {
          "pageIndex": 0,
          "pageSize": 10,
          "id": treeData.id
        }
        this.$http.post(url,obj).then(res => {
          console.log('=====',res)
          if (res.data.code == 0 && res.data.data){
            let data = res.data.data.rows.filter(item => item.id !== this.filterId);
            data.forEach(item => {
              if (!item.hasChildren){
                item.isLeaf = true
              }
            })
            resolve(data);
          }
        })
      }
    },
    nodeClick(data){
      let bool = false;
      if (this.addNews){
        if (data.children && data.children.length > 0){
          bool = true
        } else {
          bool = false;
        }
      } else if ((!data.hasChild && data.newsCount > 0) || data.parentId){
        bool = true;
      }
      if (bool){
        return;
      }
      this.selectValue = "";
      this.selectLabel = "";
      this.selectLabel = data.name;
      this.selectValue = data.id;
      this.$refs.selectTree.blur();
      this.$emit('changeDir',this.selectValue)
    },
  }
};
</script>

<style scoped lang="less">
.elSelectTree{
  .setStyle {
    min-height: 250px;
    padding: 0 !important;
    margin: 0;
    //overflow-y: auto;
    cursor: default !important;
    background-color: #ffffff;
    overflow: hidden;

  }
  .has-create{
    /deep/.el-tree{
      height: 210px;
      overflow-y: scroll;
    }
  }
  .no-has-create{
    /deep/.el-tree{
      height: 250px;
      overflow-y: scroll;
    }
  }
  .custom-tree-node{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 10px);
    padding-right: 10px;
    .node-left{
      display: flex;
      align-items: center;
      .guoran-AI{
        color: #366aff;
        margin-right: 6px;
        font-size: 15px;
      }
    }
    .node-right{
      flex: none;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      i{
        font-size: 14px;
        color: #366aff;
      }
    }
  }
  /deep/.el-tree-node__content{
    height: 34px;
  }
  /deep/.el-tag--info{
    background-color: #366aff;
    border-color: #366aff;
    color: #ffffff;
    .el-tag__close{
      color: #ffffff;
      background-color: #366aff;
    }
  }
  /deep/.el-select-dropdown__item{
    height: 40px;
    line-height: 40px;
  }
  .el-select-dropdown__item.hover{
    background-color: #ffffff;
  }
  .keyword{
    display: flex;
    align-items: center;
    padding: 0 14px;
    color: #000000;
    border-top: 1px solid #E0E6F7;
    background-color: #ffffff;
    height: 40px;
    .create-tag{
      height: 30px;
      padding: 0 12px;
      color: #ffffff;
      background-color: #366aff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 6px;
      cursor: pointer;
      border-radius: 14px;
      font-size: 12px;
      flex: none;
    }
  }
}
</style>

let imageTypeObj = {
    PDF: 'pdf1',
    pdf: 'pdf1',
    TXT: 'txt1',
    txt: 'txt1',
    selfadd: 'selfadd1',
    md: "md2",
    html: 'selfadd1',
    mode: "mode",
    general: 'general',
    word: "word1",
    Word: "word1",
    docx: "word1",
    doc: "word1",
    docm: "word1",
    dotm: "word1",
    dotx: "word1",
    wpt: "word1",
    wps: "word1",
    WORD: "word1",
    Excel: 'excel1',
    EXCEL: 'excel1',
    excel: 'excel1',
    PPT: 'ppt1',
    ppt: 'ppt1',
    pptx: 'ppt1',
    img: "image1",
    image: "image1",
    video: "video",
    audio: 'audio',
    link: "link",
    wechat: "wechat",
    mp4: "video",
    MP4: "video",
    xlsx: "excel1",
    xls: "excel1",
    csv: "excel1",
    jpg: "image1",
    JPG: "image1",
    jpeg: "image1",
    JPEG: "image1",
    png: "image1",
    PNG: "image1",
    webp: "image1",
    WEBP: "image1",
    gif: "image1",
    GIF: "image1",
    svg: "image1",
    bmp: "image1",
    avi: "video",
    mov: "video",
    rmvb: "video",
    wav: "audio",
    mp3: "audio",
    MOV: "video",
    AIV: "video",
    aiv: "video",
}

function formatDocList(preDocList) {
    preDocList && preDocList.forEach(item => {
        item.date = new Date(item.updateTime).toLocaleDateString();
        item.week = new Date(item.updateTime).getDay();
        item.time = new Date(item.updateTime).toLocaleTimeString().substring(0, 5);
        // item.checked = false;
        // item.typeSrc = 'https://static.guoranbot.com/cdn-office-website/askbot_doc/' + imageTypeObj[item.carrier.type] + '.png';
        
        // 特殊处理 微信同步消息中链接类型消息
        // if ((item.carrier.type === "txt" || item.carrier.type === "html") && item.carrier.source === "WECHAT") {
        //     item.typeSrc = 'https://static.guoranbot.com/cdn-office-website/askbot_doc/wechat.png';
        // }
    })

    let docListObj = {};
    preDocList && preDocList.forEach(item => {
        if (docListObj[item.date]) {
            docListObj[item.date].push(item);
        } else {
            docListObj[item.date] = [item];
        }
    })

    let renderDocList = [];
    let todayStr = new Date().toLocaleDateString();
    let yesterdayStr = new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toLocaleDateString();
    let beforeYesterdayStr = new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000).toLocaleDateString();
    let before3 = new Date(new Date().getTime() - 3 * 24 * 60 * 60 * 1000).toLocaleDateString();
    let before4 = new Date(new Date().getTime() - 4 * 24 * 60 * 60 * 1000).toLocaleDateString();
    let before5 = new Date(new Date().getTime() - 5 * 24 * 60 * 60 * 1000).toLocaleDateString();
    let before6 = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000).toLocaleDateString();
    let weekStr = new Date().getDay();

    // 当前周：今天，昨天，前天，本周X
    // 今年非当前周：X月X日
    // 往年：XXXX年X月X日
    for (const key in docListObj) {
        if (key === todayStr) {
            renderDocList.push({ timelineTitle: "今天", list: docListObj[key] });
        } else if (key === yesterdayStr) {
            renderDocList.push({ timelineTitle: "昨天", list: docListObj[key] });
        } else if (key === beforeYesterdayStr) {
            renderDocList.push({ timelineTitle: "前天", list: docListObj[key] });
        } else {
            if (weekStr === 0 || weekStr > 3) {
                if (weekStr === 0) {
                    if (key === before3) {
                        renderDocList.push({ timelineTitle: "本周四", list: docListObj[key] });
                    } else if (key === before4) {
                        renderDocList.push({ timelineTitle: "本周三", list: docListObj[key] });
                    } else if (key === before5) {
                        renderDocList.push({ timelineTitle: "本周二", list: docListObj[key] });
                    } else if (key === before6) {
                        renderDocList.push({ timelineTitle: "本周一", list: docListObj[key] });
                    } else {
                        renderDocList.push({ timelineTitle: key, list: docListObj[key] });
                    }
                }
                if (weekStr === 6) {
                    if (key === before3) {
                        renderDocList.push({ timelineTitle: "本周三", list: docListObj[key] });
                    } else if (key === before4) {
                        renderDocList.push({ timelineTitle: "本周二", list: docListObj[key] });
                    } else if (key === before5) {
                        renderDocList.push({ timelineTitle: "本周一", list: docListObj[key] });
                    } else {
                        renderDocList.push({ timelineTitle: key, list: docListObj[key] });
                    }
                }
                if (weekStr === 5) {
                    if (key === before3) {
                        renderDocList.push({ timelineTitle: "本周二", list: docListObj[key] });
                    } else if (key === before4) {
                        renderDocList.push({ timelineTitle: "本周一", list: docListObj[key] });
                    } else {
                        renderDocList.push({ timelineTitle: key, list: docListObj[key] });
                    }
                }
                if (weekStr === 4) {
                    if (key === before3) {
                        renderDocList.push({ timelineTitle: "本周一", list: docListObj[key] });
                    } else {
                        renderDocList.push({ timelineTitle: key, list: docListObj[key] });
                    }
                }
            } else {
                renderDocList.push({ timelineTitle: key, list: docListObj[key] });
            }
        }
    }

    return renderDocList;
}
// 获取文件后缀名
function getFileSuffix(url,arr,isGetName) {
    let index1 = url.lastIndexOf(".")+1;
    let index2 = url.length;
    let postf = url.substring(index1, index2);//后缀名
    if (isGetName) {
        return postf;
    } else { 
        if (!arr || arr.indexOf(postf) == -1) {
            return false;
        } else {
            return true;
        }
    }
}
function formatFileTypeSrc(file) {
    let type = getFileSuffix(file.name,[],true)
    return 'https://static.guoranbot.com/cdn-office-website/askbot_doc/' + imageTypeObj[type] + '.png';
}

function formatUrlTypeSrc(src) {
    if(src && src.includes('?')) {
        src = src.split('?')[0]
    }
    let type = getFileSuffix(src,[],true)
    return 'https://static.guoranbot.com/cdn-office-website/askbot_doc/' + imageTypeObj[type] + '.png';
}

export default {
    formatDocList,
    formatFileTypeSrc,
    formatUrlTypeSrc,
    imageTypeObj
}


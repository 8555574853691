<template>
  <div id="newsFolderForm">
    <el-form label-position="top" label-width="80px">
      <el-form-item label="名称" v-if="!isMoveTo">
        <span slot="label">
          <span>{{ $t('knowledgeManagement.newsAnnouncement.newsForm.name') }}</span>
          <span style="color: red">*</span>
        </span>
        <el-input v-model="formFolder.name" style="width: 100%" :placeholder="$t('knowledgeManagement.newsAnnouncement.newsForm.placeholder')"></el-input>
      </el-form-item>
      <el-form-item label="父目录">
        <span slot="label">
          <span>{{ $t('knowledgeManagement.newsAnnouncement.newsForm.parentDir') }}</span>
          <span style="color: red">*</span>
        </span>
        <directory-tree @changeDir="changeDir" ref="directoryTree" :dirId="formFolder.id"></directory-tree>
      </el-form-item>
      <el-form-item label="可见范围" v-if="!isMoveTo">
        <span slot="label">
          <span>{{$t('knowledgeManagement.newsAnnouncement.visibleRange')}}</span>
          <span style="color: red">*</span>
        </span>
        <div class="visitScope" v-if="formFolder.parentId">
          <el-radio-group
              v-model="formFolder.visitScope"
              @change="clearPermission"
          >
            <el-radio label="INHERIT">{{$t('knowledgeManagement.addNewsAnnounce.followDir')}}</el-radio>
            <el-radio label="CUSTOMIZE">{{$t('knowledgeManagement.addNewsAnnounce.customize')}}</el-radio>
          </el-radio-group>
        </div>
        <div class="permissionList" @click="setPermission" v-if="!formFolder.parentId || formFolder.visitScope === 'CUSTOMIZE'">
          <template v-if="permissionList.length > 0">
            <div v-for="(item,index) in permissionList" :key="index"  class="permissionItem">
              <template v-if="item.type === 1">
                <div class="departAvatar">
                  <i class="iconfont guoran-chanpin"></i>
                </div>
              </template>
              <template v-else>
                <div class="avatar" v-if="item.name">
                  {{(item.name).substr(0,1)}}
                </div>
              </template>
              <span class="label aa" v-if="item.name">
                <open-data :openid="item.name" :type="item.type == 1 ? 'departmentName' :'userName'" v-if="isUseOpenData()"></open-data>
                <template v-else>
                  {{item.name}}
                </template>
              </span>
              <span class="close" @click.stop="closePermission(index,item)">
                <i class="iconfont guoran-shanchu"></i>
              </span>
            </div>
          </template>
          <template v-else>
            <span class="placeholder">{{$t('knowledgeManagement.addNewsAnnounce.selectPlaceholder')}}</span>
          </template>
        </div>
      </el-form-item>
    </el-form>
    <employee-structure
        ref="employeeStructure"
        :permissionList="permissionList"
        @confirmPermission="confirmPermission"
        @cancelPermission="cancelPermission"
        @onNodeClick="permissionNodeClick"
        v-if="permissionDialog"
    ></employee-structure>
  </div>
</template>

<script>
import DirectoryTree from "@/views/newsAnnouncement/components/directoryTree";
import OpenData from "@/components/openData";
import EmployeeStructure from "@/components/employeeStructure";
export default {
  name: "newsFolderForm",
  components: { EmployeeStructure, OpenData, DirectoryTree },
  data(){
    return{
      formFolder:{
        name:"",
        parentId:"",
        visitConfig:{
          users:[],
          departs:[]
        },
        visitScope:"CUSTOMIZE"
      },
      permissionList:[],
      permissionListCopy:[],
      permissionDialog:false
    }
  },
  props:{
    isMoveTo:{
      type:Boolean,
      default:false
    }
  },
  methods:{
    setPermission(){
      this.permissionDialog = true;
      this.$nextTick(() => {
        this.$refs.employeeStructure.permissionDialog = true;
        this.permissionListCopy = JSON.parse(JSON.stringify(this.permissionList))
      })
    },
    closePermission(index,item){
      this.permissionList.splice(index,1);
      this.permissionListCopy.splice(index,1);
      this.formFolder.visitConfig.users = this.formFolder.visitConfig.users.filter(items => items.memberId != item.id);
      this.formFolder.visitConfig.departs = this.formFolder.visitConfig.departs.filter(items => items.id != item.id);
    },
    confirmPermission(){
      this.permissionList = this.permissionListCopy;
      this.$refs.employeeStructure.permissionDialog = false;
      this.permissionDialog = false;
      this.formFolder.visitConfig.users = [];
      this.formFolder.visitConfig.departs = [];
      this.permissionList.forEach(item => {
        if ((item.type == 0 || item.memberId)){
          this.formFolder.visitConfig.users.push({
            memberId:item.id,
            name:item.name,
            mainId:sessionStorage.getItem('_mainId'),
            uid:sessionStorage.getItem('_clientUid')
          })
        } else if ((item.type == 1 || item.id)){
          this.formFolder.visitConfig.departs.push({
            id:item.id,
            name:item.name
          })
        }
      })
    },
    cancelPermission(){
      this.$refs.employeeStructure.permissionDialog = false;
      this.permissionDialog = false;
    },
    permissionNodeClick(data){
      console.log(data,this.permissionListCopy,'data');
      if (data.checked){
        let obj = {
          id:data.id,
          type:data.type !== undefined ? data.type : (data.source !== undefined ? 0 : 1),
          name:data.label ? data.label : data.name,
        }
        // obj.iframeUrl = `${this.currentUrl}?corpId=${corpId}&openid=${obj.name}&type=${obj.type == 1 ? 'departmentName' : 'userName'}&style=2&datas=${obj.id + ',' + obj.type + ',' + obj.label}`
        this.permissionListCopy.push(obj)
      } else {
        this.permissionListCopy = this.permissionListCopy.filter(item => {
          return item.id != data.id
        });
      }
    },
    changeDir(val){
      this.formFolder.parentId = val;
      console.log('val',val)
    },
    clearPermission(){
      this.permissionList = [];
      this.permissionListCopy = [];
      this.formFolder.visitConfig = {
        users:[],
        departs:[]
      }
    }
  }
};
</script>

<style scoped lang="less">
#newsFolderForm{
  width: 100%;
  /deep/.el-form-item__label{
    line-height: 20px;
  }
  .visitScope{
    margin-bottom: 4px;
  }
  .permissionList{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 15px;
    color: #606266;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    background-color: #FFFFFF;
    cursor: pointer;
    /deep/.el-tag{
      margin-right: 8px;
    }
    .permissionItem{
      display: flex;
      align-items: center;
      margin-right: 8px;
      margin-bottom: 4px;
      background-color: #E8EDF7;
      border-radius: 3px;
      padding: 0px 4px;
      font-size: 12px;
      color: #010101;
      height: 26px;
      .avatar{
        flex: none;
        width: 21px;
        height: 21px;
        background: #366AFF;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        font-size: 12px;
      }
      .departAvatar{
        flex: none;
        width: 21px;
        height:21px;
        background: #918EFF;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        i{
          color: #FFFFFF;
          font-size: 12px;
        }
      }
      .label{
        padding: 0px 4px;
        max-width: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 0;
        height: 26px;
        line-height: 26px;
      }
      iframe{
        height: 22px;
        cursor: pointer;
      }
    }
    .placeholder{
      line-height: 18px;
      color: #C0C4CC;
    }
  }
}
</style>
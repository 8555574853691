function handlerChannel(channel) { 
    switch (channel){
        case 1:
          return "企业微信";
        case 2:
          return "腾讯文档";
        case 3:
          return "钉钉钉盘";
        case 4:
          return "钉钉云文档";
        case 5:
          return "飞书-云文档";
        case 6:
          return "百度网盘企业版";
        case 7:
          return "百度网盘个人版";
        case 8:
          return "语雀";
      }
}
function handlerChannelImg(channel) { 
	switch (channel){
		case 1:
		return "企业微信";
		case 2:
		return "腾讯文档";
		case 3:
		return "钉钉钉盘";
		case 4:
		return "钉钉云文档";
		case 5:
		return require('../assets/images/feishu.png');
		case 6:
		return "百度网盘企业版";
		case 7:
		return "百度网盘个人版";
		case 8:
		return require('../assets/images/yuque.png');
		}
}


export {
	handlerChannel,
	handlerChannelImg
}

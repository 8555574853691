<template>
  <div :class="['ask-template-ckeditor',isShowGuide ? 'show-guide' : 'hidden-guide']">
    <div class="ask-template-ckeditor_left" :style="{ width: AigcVisiable ? 'calc(100% - 403px)' : '100%' }">
      <div :class="['ask-template-ckeditor-content ckeditor',isMax ? 'isMax' : 'isMin']">
        <div class="ckeditor-box">
          <ratelCkeditor
              :key="keyCode"
              :keyCode="keyCode"
              ref="contentCkeditor"
              ckeditorType="normal"
              :editorDisabledContent="editorDisabledContent"
              :ckeditorValue.sync="ckeditorValue"
              :toolbarConfig="editorConfig"
              :aiHelpWriteList="aiHelpWriteList"
              :optimizeList="optimizeList"
              :generateList="generateList"
              :isContinue.sync="isContinue"
              :AigcVisiable.sync="AigcVisiable"
              :evtSourceUid.sync="evtSourceUid"
              :evtSourceMessageDataList.sync="evtSourceMessageDataList"
              :evtSourceMessageDataListCopy.sync="evtSourceMessageDataListCopy"
              :isStop="isStop"
              :templateList="templateList"
              :isQuickTemplate.sync="isQuickTemplate"
              :isTemplateManage="isTemplateManage"
              @stopCreate="stopCreate"
              @ckeditorValueChange="ckeditorValueChange"
              @btnOperation="btnOperation"
              @isAigcCreate="isAigcCreate"
              @AigcInsert="AigcInsert"
              @continueCreate="continueCreate"
              @eventSourceParamsEmit="eventSourceParamsEmit"
              @handlerCanUploadEmit="handlerCanUploadEmit">
          </ratelCkeditor>
        </div>
      </div>
      <div class="ratel-ckeditor-bottom-btn-box" v-if="!editorDisabledContent">
        <div :class="['show-btn more',isMax ? 'isMax' : 'isMin']" @click.stop="showCkeditorPopup('clickBottomBtn')">
          <i class="iconfont guoran-shengchengAI more"></i>
          <span class="more" v-if="isMax"> 输入”/ ”或 ”、”使用AI生成内容；选中内容使用AI优化内容</span>
          <div v-else style="">
            <div> 输入”/ ”或 ”、”使用AI生成内容</div>
            <div> 选中内容使用AI优化内容</div>
          </div>
        </div>
      </div>
    </div>
    <div class="ask-template-ckeditor_right" v-show="AigcVisiable">
      <AiView ref="AIGC" :AigcConfig="AigcConfig" @AigcReplaceMessage="AigcReplaceMessage" @closeAi="closeAi"
              @aiginWrite="aiginWrite" @continueCreate="continueCreate"></AiView>
    </div>
    <!-- 弹框 标题.内容 -->

    <lighting-popup @closeEvent="closeEvent" v-if="saveTemplateVisible" class="save-template-popup">
      <div slot="lighting-popup-name">存为模板</div>
      <div slot="lighting-popup-tip">将内容存为模板</div>
      <div slot="lighting-popup-con">
        <div class="lighting-popup-box">
          <ratelCkeditorTitle
              :key="'titlePopup'"
              ref="titleCkeditorPopup"
              ckeditorType="popup"
              :editorDisabledTitle="editorDisabledTitle"
              :ckeditorValueTitle.sync="titleValuePopup"
              :toolbarConfigTitle="editorConfigTitle"
              @ckeditorEvent="ckeditorEvent"
              @ckeditorValueChange="ckeditorValueChange">
          </ratelCkeditorTitle>
          <ratelCkeditor
              :key="keyCode"
              :keyCode="keyCodePopup"
              ref="contentCkeditorPopup"
              ckeditorType="popup"
              :ckeditorValue.sync="ckeditorValuePopup"
              :toolbarConfig="editorConfigPopup"
              :aiHelpWriteList="aiHelpWriteList"
              :optimizeList="optimizeList"
              :generateList="generateList"
              :isContinue.sync="isContinue"
              :AigcVisiable.sync="AigcVisiable"
              :evtSourceUid.sync="evtSourceUid"
              :evtSourceMessageDataList.sync="evtSourceMessageDataList"
              :evtSourceMessageDataListCopy.sync="evtSourceMessageDataListCopy"
              :isStop="isStop"
              :templateList="templateList"
              :isQuickTemplate.sync="isQuickTemplate"
              :isTemplateManage="isTemplateManage"
              @stopCreate="stopCreate"
              @ckeditorValueChange="ckeditorValueChange"
              @btnOperation="btnOperation"
              @isAigcCreate="isAigcCreate"
              @AigcInsert="AigcInsert"
              @continueCreate="continueCreate"
              @eventSourceParamsEmit="eventSourceParamsEmit"
              @handlerCanUploadEmit="handlerCanUploadEmit">
          </ratelCkeditor>
        </div>
      </div>
      <div slot="lighting-popup-dialog-footer" class="save-template-popup-footer-btn">
        <el-button @click="closeEvent" plain round class="cancel-btn">取 消</el-button>
        <el-button class="confirm-btn" type="primary" @click="saveTemplateBtn('popup')" round
                   style="padding:0 !important;">保存
        </el-button>
      </div>
    </lighting-popup>
    <div class="edit-value" style="display:none"></div>
    <beyondLimit
        :canUploadDiaVisible.sync="canUploadDiaVisible"
        :canUploadDialogTitle="canUploadDialogTitle"
        :canUploadDialogTips="canUploadDialogTips"
        :exceedObj="exceedObj"
        :currentVersion="currentVersion"
        :showMorePopups.sync="showMorePopups"
        :showApplyInfoPopups.sync="showApplyInfoPopups">
    </beyondLimit>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters, mapState } from "vuex";
import ratelCkeditor from "./ratelCkeditor.vue";
import ratelCkeditorTitle from "@/components/ckeditor/ratelCkeditorTitle";
import { requestUrl } from "@/http/requestUrl";
import _ from "lodash";
import AiView from "./AiView.vue";
import deleteDialog from "@/components/deleteDialog";
import { MyCustomUploadAdapterPlugin } from "@/components/ckeditor/js/ckeditors";
import LightingPopup from "@/components/LightingPopup";
import beyondLimit from './beyondLimit.vue'
export default {
  data() {
    return {
      // ckeditorValue:"11",
      editorConfigTitle: {
        placeholder: "标题",
        blockToolbar: [],
        toolbar: {
          items: []
        }
      },
      editorConfig: {
        placeholder: "写点什么吧，如知识、笔记、计划、复盘等...输入“、”或“/”使用AI生成内容",
        extraPlugins: [MyCustomUploadAdapterPlugin],
        htmlSupport: {
          // allow: [
          // 	{
          // 		name: 'figure',
          // 		attributes: true,
          // 		classes: true,
          // 		styles: true
          // 	},{
          // 		name:'span' ,
          // 		attributes: true,
          // 		classes: true,
          // 		styles: true
          // 	},{
          // 		name:'img' ,
          // 		attributes: true,
          // 		classes: true,
          // 		styles: true
          // 	},
          // ]
        },
        blockToolbar: [
          "paragraph",
          // 'removeFormat',
          "heading1",
          "heading2",
          "heading3",
          {
            label: "",
            icon: "<svg t=\"1679733495980\" class=\"icon\" viewBox=\"0 0 1024 1024\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" p-id=\"32772\" width=\"16\" height=\"16\"><path d=\"M954.88 941.568h-26.624l-377.856-916.48c-9.728-21.504-34.816-30.72-55.808-21.504-9.216 4.096-16.896 11.776-21.504 21.504l-376.32 916.48h-28.672c-16.896 0-31.744 8.192-36.864 23.552-9.216 21.504 1.024 46.08 22.016 55.296 5.12 2.048 10.752 3.584 16.384 3.584h120.832c19.968 0 38.4-13.312 41.984-33.792 3.072-21.504-11.776-41.984-33.28-45.056L276.48 758.784h338.944l73.728 186.368c-21.504 3.584-36.864 23.552-33.792 45.056 3.584 19.968 22.016 33.792 41.984 33.792h257.024c16.896 0 31.744-8.192 36.864-23.552 9.728-19.968 1.536-44.544-18.432-54.784-5.12-2.56-11.776-4.096-17.92-4.096zM305.152 674.816l137.728-335.872 137.728 335.872H305.152z\" p-id=\"32773\" fill=\"#404040\"></path></svg>",
            items: ["fontColor", "fontBackgroundColor"],
            class: "fontcolor-bgc"
          },
          "numberedList",
          "bulletedList",
          "todoList",
          "alignment:left",
          "alignment:right",
          "alignment:center",
          "alignment:justify",
          "link",
          "insertUploadImgButton",
          "insertUploadFileButton"
          // 'imageUpload'
        ],
        toolbar: {
          items: [
            // 'removeFormat',
            "insertAiConcertButton",
            "|",
            {
              label: "",
              icon: "<svg t=\"1677848773135\" class=\"icon\" viewBox=\"0 0 1024 1024\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" p-id=\"32298\" width=\"200\" height=\"200\"><path d=\"M744.448 1024H279.552l-1.024-93.184h150.528l2.048-837.632H168.96l-29.184 32.256v177.152L34.816 314.368 32.768 0h956.416l2.048 314.368-105.472-11.776V125.44l-29.184-32.256h-261.632l-2.048 837.632h150.528l1.024 93.184z\" p-id=\"32299\" fill=\"#404040\"></path></svg>",
              items: ["paragraph", "heading1", "heading2", "heading3", "bulletedList", "numberedList", "todoList", "link", "blockQuote"]
            },
            "|",
            "alignment",
            "|",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "link",
            {
              label: "",
              icon: "<svg t=\"1679733495980\" class=\"icon\" viewBox=\"0 0 1024 1024\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" p-id=\"32772\" width=\"16\" height=\"16\"><path d=\"M954.88 941.568h-26.624l-377.856-916.48c-9.728-21.504-34.816-30.72-55.808-21.504-9.216 4.096-16.896 11.776-21.504 21.504l-376.32 916.48h-28.672c-16.896 0-31.744 8.192-36.864 23.552-9.216 21.504 1.024 46.08 22.016 55.296 5.12 2.048 10.752 3.584 16.384 3.584h120.832c19.968 0 38.4-13.312 41.984-33.792 3.072-21.504-11.776-41.984-33.28-45.056L276.48 758.784h338.944l73.728 186.368c-21.504 3.584-36.864 23.552-33.792 45.056 3.584 19.968 22.016 33.792 41.984 33.792h257.024c16.896 0 31.744-8.192 36.864-23.552 9.728-19.968 1.536-44.544-18.432-54.784-5.12-2.56-11.776-4.096-17.92-4.096zM305.152 674.816l137.728-335.872 137.728 335.872H305.152z\" p-id=\"32773\" fill=\"#404040\"></path></svg>",
              items: ["fontColor", "fontBackgroundColor"],
              class: "fontcolor-bgc"
            },
            "|",
            "insertSaveTemplateButton"
          ]
        }

      },
      collect: false,
      fileItem: {
        title: "这是一段标题"
      },
      changeStatus: "default",
      titleValue: "",
      ckeditorValue: "",
      editorDisabledTitle: false, // 是否开始只读模式
      editorDisabledContent: false, //是否开始只读模式
      headerTitle: "", //
      isSave: false, // 是否是已保存
      saveTemplateVisible: false,
      titleValuePopup: "",
      ckeditorValuePopup: "",
      editorConfigPopup: {},
      isSaveTemplate: true,
      keyCode: 1,
      contentType: "ckEditor",
      previewHref: "",
      userName: "",
      knowledgeItem: null,
      aiHelpWriteList: [],
      optimizeList: [],
      generateList: [],
      ckeditorValueCopy: "", //当前模版内容的copy 值，判断有没有内容更新
      AigcVisiable: false,
      AigcConfig: {},
      lastEventId: "",
      evtSourceMessageDataListCopy: [],
      evtSourceMessageDataList: [],
      messageTimer: null,
      isContinue: false,
      evtSourceUid: "",
      deleteBotPopup: false,
      eventSourceParam: {},
      onChangeSave: false,
      isStop: true,
      refData: null,
      previewFileType: "",
      onecEvent: "",
      templateIds: [],
      isQuickTemplate: false,
      headerTitlePopup: "",
      avtImageUrl: "",
      keyCodePopup: 1,
      exitStatus: false,
      titleValueCopy: "",
      usedAIGC: false, // 使用了ai生成能力的
      selectionRange: null,
      isDel: true,
      evtsourceId: "",
      AigcCreateBoo: false,
      canUploadDialogTitle: "",
      canUploadDialogTips: "",
      exceedObj: {},
      canUploadDiaVisible: false,
      deleteKnowledgeLoading: false,
      isAiginWrite: false,
      isMax: true,
      screenWidthTimer: false, // 监听页面宽度变化节流 timer
      showMorePopups: false,
      showApplyInfoPopups: false
    };
  },
  components: { LightingPopup, ratelCkeditor, AiView, deleteDialog, ratelCkeditorTitle,beyondLimit },
  props: {
    isShowGuide: {
      type: Boolean,
      default: false
    },
    isTemplateManage: {
      type: Boolean,
      default: false
    },
    isRecycle: {
      type: Boolean,
      default: false
    },
    templateConfigType: {
      type: String,
      default: "add"
    },
    activeMode: {
      type: Object,
      default() {
        return {};
      }
    },
    isHideHeader: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    titleValue(n) {
      let dom = document.createElement("div");
      dom.innerHTML = n;
      this.headerTitle = dom.innerText.trim();
    },
    titleValuePopup(n) {
      let dom = document.createElement("div");
      dom.innerHTML = n;
      this.headerTitlePopup = dom.innerText;
    },
    saveTemplateVisible: {
      handler(n) {
        this.keyCode++;
        this.$nextTick(() => {
          setTimeout(() => {
            // 	let dom = document.querySelector('.ask-template-ckeditor>.ask-template-ckeditor_left>.ask-template-ckeditor-content>.ckeditor-box .ratel-ckeditor-content .popup-floating-layer-ckeditor');
            // 	let popupDom = document.querySelector('.ask-template-ckeditor>.save-template-popup .ratel-ckeditor-content .popup-floating-layer-ckeditor');
            // 	let btnDomList = Array.from(document.querySelectorAll('.ck-body-wrapper .ck-reset_all .ck-block-toolbar-button'))
            if (n) {
              // 		this.$refs.titleCkeditorPopup.currentEditor.editing.view.focus();
              this.refData = this.$refs.contentCkeditorPopup;
              // 		btnDomList[3].style.zIndex = 2001;
              // 		dom.style.display = 'none';
              // 		popupDom.style.zIndex = 2600;
            } else {
              // 		btnDomList[1].style.zIndex = 1;
              // 		dom.style.zIndex = 2;
              // 		if(popupDom){
              // 			popupDom.style.zIndex = 0;
              // 		}
              this.refData = this.$refs.contentCkeditor;
            }
          }, 500);
        });
      },
      immediate: true
    },
    isTemplateManage: {
      handler(n) {
        if (n) {
          this.setCkeditorConfig(n);
        }
      },
      immediate: true
    },
    AigcVisiable: {
      handler(value) {
        if (!value) {
          this.stopCreate(true);
        }
      }
    },
    isRecycle(n) {
      if (n) {
        this.editorDisabledTitle = true;
        this.editorDisabledContent = true;
      }
    },
    knowledgeItem: {
      handler(n) {
        this.saveTemplateVisible = false;
      },
      deep: true
    },
    isSwitchCompany(n) {
      if (n) {
        this.beforeDestroyFn();
      }
    }
  },
  computed: {
    ...mapGetters("user", [
      "activeQuickCreateModeId"
    ]),
    ...mapGetters("user", {
      // 模板列表
      templateList: "totalModeList"
    }),
    ...mapGetters("apphandle", {
      // 是否触发顶部添加知识
      topAddDocFromRouteName: "getTopAddDocFromRouteName"
    }),
    ...mapGetters("user", {
      currentVersion: "getCurrentVersion"
    }),
    ...mapState({
      // 智能写作次数/容量 套餐使用信息
      combinationInfo: state => state.user.combinationInfo
    })

  },
  created() {
    if (this.activeQuickCreateModeId !== "") {
      this.QuickInsertTemplate()
    }
  },
  mounted() {
    this.getIsUseAi();
    this.evtSourceUid = "";
    this.isDel = false;
    this.saveTemplateVisible = false;
    this.usedAIGC = false;
    this.$nextTick(() => {
      this.isMax = document.querySelector(".ask-template-ckeditor_left").clientWidth < 680 ? false : true;
      console.log(this.isMax, "isMax");
      this.refData = this.$refs.contentCkeditor;
      this.avtImageUrl = sessionStorage.getItem("profilePhoto") == "null" ? "https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/2023/03/18/11/48/878f878d-6343-4f48-ada7-86f05211910a/askdefaltleftavt.png" : sessionStorage.getItem("profilePhoto");
      this.userName = sessionStorage.getItem("realname");
      this.getAigcCmdList(0);
      this.getAigcCmdList(2);
      // this.getUserHistoryCmd();
      // this.evtsourceFn();
      //  延迟2s 后开启自动保存
      setTimeout(() => {
        this.onChangeSave = true;
      }, 1000);
    });
    window.addEventListener("resize", this.screenWidth());
  },
  // 该情况触发的条件是没有点返回按钮保存，直接从新建/编辑 窗口切换了 navList（并）
  beforeDestroy() {
    this.beforeDestroyFn();
  },
  methods: {
    beforeDestroyFn() {
      this.evtSource && this.evtSource.close();
      this.evtSourceUid = "";
      window.removeEventListener("resize", this.screenWidth());
    },
    screenWidth() {
      if (!this.screenWidthTimer) {
        this.screenWidthTimer = true;
        setTimeout(() => {
          this.screenWidthTimer = false;
          // this.isMax = document.querySelector(".ask-template-ckeditor_left").clientWidth < 680 ? false : true;
          let dom = document.querySelector(".ask-template-ckeditor_left");
          if (dom){
            this.isMax = document.querySelector(".ask-template-ckeditor_left").clientWidth < 680 ? false : true;
          }
        }, 300);
      }
    },
    // 获取当前账号是否使用过AI协作
    getIsUseAi() {
      this.$http.get(requestUrl.aigc.queryUserUseAiCollaboration).then((res) => {
        this.isUseAiCollaborate = res.data;
        if (!this.isUseAiCollaborate) {
          document.body.setAttribute("use-ai-collaborate-data", true);
        } else {
          document.body.removeAttribute("use-ai-collaborate-data");
        }
      });
    },
    handlerCanUploadEmit(type, exceedType, exceedObj) {
      this.exceedObj = JSON.parse(JSON.stringify(exceedObj));
      this.canUploadDiaVisible = false;
      this.exceedObj.type = exceedType;
      this.exceedObj.beyondType = type;
      //vipType   0：免费版，1：超级会员，2：高级会员   3 企业版
      // flowLackType  0是个人  1是企业
      // this.currentVersion.vipType = 3; // 付费调试代码
      if (type === "capacity") {
        if (this.currentVersion.vipType === 0 || this.currentVersion.expire) {
          if (exceedType === "single") {
            this.canUploadDialogTitle = "上传的文件单文件超出限制";
            this.canUploadDialogTips = "您可以升级到高级会员或超级会员解锁单文件上传大小不限制";
          } else if (exceedType === "total") {
            this.canUploadDialogTitle = "超出总容量";
            this.canUploadDialogTips = "您可以升级到高级会员或超级会员解锁更大容量";
          }
        } else if (this.currentVersion.vipType === 1) {
          this.canUploadDialogTitle = "超出总容量";
          this.canUploadDialogTips = "您可以微信扫码联系我们解锁更大容量";
        } else if (this.currentVersion.vipType === 2) {
          this.canUploadDialogTitle = "超出总容量";
          this.canUploadDialogTips = "您可以升级到超级会员解锁更大容量";
        } else if (this.currentVersion.vipType === 3) {
          this.canUploadDialogTitle = "超出总容量";
          this.canUploadDialogTips = this.currentVersion.capacityLackType == 1 ? "企业剩余总容量不足，您可以联系企业管理员购买更大容量" : "剩余容量不足，您可以联系企业管理员申请更大容量";
        }
      } else {
        console.log('handlerCanUploadEmit',type,exceedType,this.currentVersion)
        if (this.currentVersion.vipType === 0 || this.currentVersion.expire) {
          this.canUploadDialogTitle = "当前版本暂不支持AI能力";
          this.canUploadDialogTips = "您可以升级到高级会员或超级会员使用AI能力";
        } else if (this.currentVersion.vipType === 1) {
          this.canUploadDialogTitle = "剩余智能问答与写作次数不足";
          this.canUploadDialogTips = "该功能需要消耗智能问答与写作次数，当前剩余次数不足您可以购买AI流量包";
        } else if (this.currentVersion.vipType === 2) {
          this.canUploadDialogTitle = "剩余智能问答与写作次数不足";
          this.canUploadDialogTips = "该功能需要消耗智能问答与写作次数，当前剩余次数不足您可以升级版本或购买AI流量包";
        } else if (this.currentVersion.vipType === 3) {
          this.canUploadDialogTitle = "剩余智能问答与写作次数不足";
          this.canUploadDialogTips = this.combinationInfo.flowLackType == 1 ? "该功能需要消耗智能问答与写作次数，当前企业剩余总次数不足" : "该功能需要消耗智能问答与写作次数，当前剩余次数不足";
        }
      }
      // this.currentVersion.vipType = 3;
      this.canUploadDiaVisible = true;
    },
    openPayVersion() {
      this.canUploadDiaVisible = false;
      this.$router.push({ name: "payVersionView" });
    },
    resetTemplate() {
      this.$refs.contentCkeditor.resetCkeditor();
    },
    ...mapActions("user", [
      "updateQuickCreateModeId"
    ]),
    ...mapActions("user", [
      "updateCombinationInfo"
    ]),
    focusCkeditorTitle() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.titleCkeditor.currentEditor.editing.view.focus();
          this.$nextTick(() => {
            this.$refs.titleCkeditor.currentEditor.model.change(writer => {
              writer.setSelection(writer.createPositionAt(this.$refs.titleCkeditor.currentEditor.model.document.getRoot(), "end"));
            });
          });

        }, 500);
      });
    },
    // 快捷插入模版
    QuickInsertTemplate(type) {
      this.isQuickTemplate = true;
      this.onChangeSave = true;
      this.templateIds.push(this.activeQuickCreateModeId);
      this.$http.get(`${requestUrl.template.getTemplateInfo}${this.activeQuickCreateModeId}`).then((res) => {
        if (res.data.code == 0) {
          this.headerTitle = res.data.data.name;
          this.titleValue = res.data.data.name;
          this.contentType = "ckEditor";
          this.editorDisabledTitle = false;
          this.editorDisabledContent = false;
          this.knowledgeItem = null;
          this.getCkeditorContent("", "", this.activeQuickCreateModeId);
          // this.replaceFileUrl(res.data.data.content,' ');
          setTimeout(() => {
            this.isQuickTemplate = false;
            this.updateQuickCreateModeId("");
          }, 2000);
        } else {
          this.messageFn("error", res.data.message);
        }
      });

    },
    showCkeditorPopup() {
      this.$refs.contentCkeditor.showBlockToolbar = false;
      let dom = document.querySelector(".ask-template-ckeditor>.ask-template-ckeditor_left>.ask-template-ckeditor-content>.ckeditor-box");
      dom.scrollTop = dom.scrollHeight;
      this.$refs.contentCkeditor.showCkeditorPopup("clickBottomBtn");
    },
    eventSourceParamsEmit(params) {
      console.log(params, "eventSourceParamsEmit");
      // start 插入测试代码
      // this.onecEvent = 'isOpen'
      // this.timeoutCreateData(true)
      // for (let index = 1; index < 200; index++) {
      // 	if(index % 10 == 0) {
      // 		this.evtSourceMessageDataList.push('<br/>');
      // 	} else {
      // 		this.evtSourceMessageDataList.push(index.toString());
      // 	}
      // 	if(index == 199) {
      // 		this.timeoutCreateData(false)
      // 		// this.isContinue = true;
      // 		// this.isStop = false;
      // 	}
      // }
      // end
      this.isStop = false;
      this.eventSourceParam = params;
      this.evtsourceFn();
    },
    // AIGC中的继续生成
    continueCreate() {
      let params = JSON.parse(JSON.stringify(this.eventSourceParam));
      this.evtSourceMessageDataListCopy.forEach((item) => {
        params.input += item;
      });
      delete params.textPlain;
      this.judgeCount(params, true);
    },
    // AIGC中的重写
    aiginWrite() {
      this.isAiginWrite = true;
      this.isStop = false;
      this.evtSourceMessageDataList = [];
      this.evtSourceMessageDataListCopy = [];
      let params = JSON.parse(JSON.stringify(this.eventSourceParam));
      params.input = params.textPlain;
      this.onecEvent = "isOpen";
      this.judgeCount(params, true, "aiginWrite");
    },
    judgeCount(params, flag, type) {
      this.$http.post(requestUrl.aigc.getCount, {
        mainId: sessionStorage.getItem("_mainId"),
        userId: sessionStorage.getItem("_clientUid"),
        vipType: this.currentVersion.vipType,
        expire: this.currentVersion.expire,
        userSource: 11
      }).then((countRes) => {
        console.log(countRes, "countRes");
        this.updateCombinationInfo(countRes.data);
        if (countRes.data.flowSurplus <= 0) {
          this.handlerCanUploadEmit("flow", "", {});
          this.onecEvent = "isClose";
          // this.refData.handlerCanUploadPopup('flow', '');
        } else {
          console.log("else");
          if (flag) {
            this.$http.post(requestUrl.aigc.streamCompletions, params).then((res) => {
              if (res.data.code == 505) {
                this.handlerCanUploadEmit("flow", "", {});
                // this.refData.handlerCanUploadPopup('flow', '');
              } else if (res.data.code == 0) {
                if (type === "aiginWrite") {
                  this.$refs.AIGC.concatList();
                }
                this.evtsourceFn();
              }
            });
          }
        }
      });
    },
    // 停止
    stopCreate(flag) {
      this.isStop = flag;
      console.debug(this.isStop, "停止--");
      if (flag) {
        this.evtSourceMessageDataList = [];
        this.evtSourceMessageDataListCopy = [];
        this.evtSourceUid = "";
        clearTimeout(this.messageTimer);
        this.messageTimer = null;
        this.onecEvent = "isClose";
        this.evtSource && this.evtSource.close();
      }
    },
    // eventSource处理
    evtsourceFn() {
      /*  id=uid，为第一次没有生成完成，下一次生成时带上uid，去做后续消息的关联
               id=finish，为标识当前这次是否生成完成，data不是stop，则出“继续生成”的按钮，参数带上uid
               id=[DONE]，标识这次请求结束，会关闭当前EventSource，前端可忽略，下次请求请重新调用
               id=chatcmpl-6rRcmIi9rShkKlQsjmhudO8dSfOh6类似值，为正常返回结果，需要将里面的content拼接起来显示。
           */
      this.evtSource && this.evtSource.close();
      this.evtSource = null;
      this.evtSourceMessageDataList = [];
      this.evtSourceUid = "";
      clearTimeout(this.messageTimer);
      this.messageTimer = null;
      let evtSourceUrl = requestUrl.aigc.getStreamv2 + "/" + this.eventSourceParam.uid;
      this.evtSource = new EventSource(evtSourceUrl, { withCredentials: true });
      // 开始处理数据
      // this.evtSourceUid = event.data;
      this.onecEvent = "isOpen";
      this.timeoutCreateData(true);
      this.evtSource.addEventListener("message", (event) => {
        console.log(event, "event");
        this.isContinue = false;
        if (event.lastEventId === "uid") {
          // this.evtSourceUid = event.data;
          // // 模拟事件轮训
          // this.onecEvent = 'isOpen'
          // this.timeoutCreateData(true)
        } else if (event.lastEventId === "[DONE]") {
          // if(this.evtSourceUid === JSON.parse(event.data).uid){
          // 接受到本次停止事件
          if (this.onecEvent !== "isClose") {
            this.evtSource.close();
            this.onecEvent = "isClose";
            this.timeoutCreateData(false);
            setTimeout(() => {
              this.getIsUseAi();
            }, 2000);
          }
          // }
        } else if (event.lastEventId === "finish") {
          let finishStatus = JSON.parse(event.data).content;
          console.log(finishStatus, "finishStatus");
          setTimeout(() => {
            this.getIsUseAi();
          }, 2000);
          // if(this.evtSourceUid === JSON.parse(event.data).uid){
          if (finishStatus !== "stop") {
            this.lastEventId = "continue";
            this.isContinue = true;
            this.isStop = false;
          } else {
            this.evtSource.close();
            this.lastEventId = "";
            this.isContinue = false;
            this.onecEvent = "isClose";
            this.timeoutCreateData(false);
          }
          // }
        } else if (event.lastEventId === "heartbeat") {
          console.debug("heartbeat");
        } else if (event.lastEventId === "exception") {
          clearTimeout(this.messageTimer);
          this.messageTimer = null;
          this.evtSourceUid = "";
          this.lastEventId = "";
          this.isContinue = false;
          this.onecEvent = "isClose";
          this.timeoutCreateData(false);
          this.messageFn("error", "请求异常,请重试!");

        } else {
          let data = JSON.parse(event.data).content || " ";
          // if(this.evtSourceUid === JSON.parse(event.data).uid){
          console.debug(data, "接收数据");
          this.lastEventId = "";
          this.usedAIGC = true;
          this.evtSourceMessageDataListCopy.push(data);
          let reg = /\n/g;
          let strList = data.match(reg);
          if (this.AigcVisiable && (this.isAiginWrite || (this.$refs.AIGC.messageList.length === 1 && this.$refs.AIGC.messageList[0].type === "loading"))) {
            this.$refs.AIGC.onMessage(this.isAiginWrite);
          }
          this.isAiginWrite = false;
          console.log(strList, "strList");
          // if(strList) {
          for (let index = 0; index < data.length; index++) {
            if (data[index] == "\n") {
              this.getMessageList("<br/>");
            } else {
              this.getMessageList(data[index]);
            }
          }
          // } else {
          // 	this.getMessageList(data);
          // }
          // }
        }
      });
      this.evtSource.onerror = this.errorFn;
    },
    errorFn(type) {
      console.log("evtSource error");
      // this.evtsourceFn();
      // if(this.evtSource) {
      // 	this.evtSource.close();
      // 	if(type === 'close'){
      // 		this.evtSource.close();
      // 		this.evtSource = null;
      // 		this.evtSourceMessageDataList = [];
      // 		this.evtSourceUid = '';
      // 		clearTimeout(this.messageTimer);
      // 		this.messageTimer = null;

      // 	} else {
      // 		console.log('AI生成失败,请稍后重试');
      // 	}
      // }
    },
    getMessageList(data) {
      if (!this.isStop) {
        this.evtSourceMessageDataList.push(data);
      } else {
        this.evtSourceMessageDataList = [];
        this.evtSourceMessageDataListCopy = [];
        this.evtSourceUid = "";
        clearTimeout(this.messageTimer);
        this.messageTimer = null;
      }
    },
    timeoutCreateData(flag) {

      // 开起接收数据轮询，此时创建定时器 轮询evtSourceMessageDataList 数据
      this.messageTimer = setTimeout(() => {
        // 因为可能存在后端推送时间的偏差，假如 前端本次轮训数据完毕，但实际后端正在推送
        // 直接清除会导致推送数据回显不准确。此时应有两个变量控制，后端推送开起轮训状态 & 前端接受数据轮训结束状态
        if (this.onecEvent == "isClose") {
          flag = false;
        }
        if (this.evtSourceMessageDataList.length != 0) {
          this.refData.getCreateData(...this.evtSourceMessageDataList.splice(0, 1));
          flag = true;
        } else {
          // start 插入测试代码
          // clearTimeout(this.messageTimer);
          // this.messageTimer = null;
          // if (this.AigcVisiable) {
          // 	this.$refs.AIGC.onceEnd()
          // }
          // if (this.lastEventId === 'continue') {
          // 	this.isContinue = true;
          // 	if (this.AigcVisiable) {
          // 		this.$refs.AIGC.continue(this.isContinue)
          // 	}
          // }
          // this.isStop = true;
          // return
          // 调试代码
          // end
        }
        if (!flag) {
          clearTimeout(this.messageTimer);
          this.messageTimer = null;
          if (this.AigcVisiable) {
            this.$refs.AIGC.onceEnd();
          }
          if (this.lastEventId === "continue") {
            this.isContinue = true;
            if (this.AigcVisiable) {
              this.$refs.AIGC.continue(this.isContinue);
            }
          } else {
            this.isStop = true;
            this.lastEventId = "";
            this.evtSourceUid = "";

          }
          return;
        }
        this.timeoutCreateData(flag);
      }, 80);
    },
    // Aigc接收插入数据
    AigcInsert(data) {
      // this.AigcConfig.insertText = data;
      this.$refs.AIGC.setMessageText(data);
    },
    isAigcCreate(flag, text) {
      this.AigcCreateBoo = flag;
      console.log('isAigcCreate',flag)
      if (!flag) {
        this.$refs.AIGC.messageList = [];
      } else {
        this.AigcCreate();
        this.$nextTick(() => {
          this.$refs.AIGC.init(text);
        });
      }
    },
    // Aigc开始生成
    AigcCreate(name) {
      this.AigcVisiable = true;
      this.isStop = false;
      this.evtSourceMessageDataList = [];
      this.evtSourceMessageDataListCopy = [];
      clearTimeout(this.messageTimer);
      this.messageTimer = null;
      // this.$nextTick(() =>{
      // this.$refs.AIGC.init(name)
      // 本地调试
      // this.$refs.AIGC.onMessage(false)
      // })
    },
    AigcReplaceMessage(data) {
      this.usedAIGC = true;
      this.refData.setSelectionCommon(data);
    },
    closeAi() {
      this.AigcVisiable = false;
      this.$refs.contentCkeditor.ckeditorFocus();
    },
    // 获取场景指令集
    getAigcCmdList(scene) {
      // scene：0：空文本 1：全文本 2：选中文本 3：文本长度>500
      this.$http.get(requestUrl.knowledge.getAigcCmdList + "?scene=" + scene)
          .then((res) => {
            if (res.data.code == 0) {
              res.data.data.forEach(item => {
                if (item.code === "write") {
                  this.aiHelpWriteList = item.nodes;
                } else if (item.code === "optimize") {
                  this.optimizeList = item.nodes;
                } else if (item.code === "generate") {
                  this.generateList = item.nodes;
                }
              });
            } else {
              this.messageFn("error", res.data.message);
            }
          });
    },
    getCkeditorContent(type, fileInOssPath, id) {
      // onlyText=模板传true，知识false
      let url = id !== "" ? requestUrl.knowledge.htmlTextDeal + "?onlyText=true" : requestUrl.knowledge.htmlTextDeal + "?onlyText=false";
      this.$http.post(url, { fileInOssPath: fileInOssPath, templateId: id }).then((res) => {
        if (res.data.code == 0) {
          if (type === "insert") {
            if (!this.saveTemplateVisible) {
              let viewFragment = this.$refs.contentCkeditor.currentEditor.data.processor.toView(res.data.data);
              let modelFragment = this.$refs.contentCkeditor.currentEditor.data.toModel(viewFragment);
              this.$refs.contentCkeditor.currentEditor.model.insertContent(modelFragment);
              this.titleValueCopy = this.titleValue;
              this.ckeditorValueCopy = this.$refs.contentCkeditor.currentEditor.getData();
              this.$refs.contentCkeditor.$refs.ckeditorDom.$el.blur();
            } else {
              let viewFragment2 = this.$refs.contentCkeditorPopup.currentEditor.data.processor.toView(res.data.data);
              let modelFragment2 = this.$refs.contentCkeditorPopup.currentEditor.data.toModel(viewFragment2);
              this.$refs.contentCkeditorPopup.currentEditor.model.insertContent(modelFragment2);
              this.$refs.contentCkeditorPopup.$refs.ckeditorDom.$el.blur();
            }
          } else {
            if (res.data.data && res.data.data !== "") {
              this.$refs.contentCkeditor.currentEditor.setData(res.data.data);
              this.titleValueCopy = this.titleValue;
              this.ckeditorValueCopy = this.$refs.contentCkeditor.currentEditor.getData();
            }
          }
        } else {
          this.messageFn("error", res.data.message);
        }
      });
    },
    clickDropDown(command) {
      // 存为模版  每次都为新模版
      if (command === "saveTemplate") {
        let headerTitleCopy = this.handlerTitle();
        this.saveTemplateFn(headerTitleCopy, this.ckeditorValue, "normal");
      } else {
        this.isDel = true;
        this.deleteBotPopup = true;
      }
    },
    // 弹框中保存模版
    saveTemplateBtn() {
      if (this.titleValuePopup === "") {
        this.messageFn("warning", "请填写模版标题");
        return false;
      }
      ;
      if (this.ckeditorValuePopup === "") {
        this.messageFn("warning", "请填写模版内容");
        return false;
      }
      this.saveTemplateFn(this.headerTitlePopup, this.ckeditorValuePopup, "popup");
    },
    // 新增模版接口处理
    saveTemplateFn(title, content, type) {
      // 新增保存
      this.$http.post(`${requestUrl.template.createTemplate}`, {
        content: content,
        icon: "iconfont guoran-tongyichicun-14-05-mobanshuxing",
        name: title,
        color: "#366AFF"
      }).then((res) => {
        if (res.data.code == 0) {
          this.$store.dispatch("user/updateUserModeInfo",this);
          this.messageFn("success", "模板保存成功！");
          if (type === "popup") {
            this.saveTemplateVisible = false;
          }
        } else {
          this.messageFn("error", res.data.message);
        }
      });
    },
    /**
     * 保存 来自 模板管理 模板
     * @method saveManageTemplate
     * @param {}
     * @return 调用父组件方法 保存模板管理 模板
     */
    saveManageTemplate(isSwitchActive) {
      console.log(this.headerTitle, "headerTitle");
      if (this.headerTitle === "") {
        this.messageFn("warning", "请填写模版标题");
        isSwitchActive = false;
        return false;
      }
      ;
      if (this.ckeditorValue === "") {
        this.messageFn("warning", "请填写模版内容");
        isSwitchActive = false;
        return false;
      }
      console.log(850);
      // templateConfigType add 模板管理新增模板 update 模板管理更新模板
      this.$emit("saveManageTemplate", this.templateConfigType, {
        content: this.ckeditorValue,
        icon: "iconfont guoran-tongyichicun-14-05-mobanshuxing",
        name: this.headerTitle,
        color: "#366AFF"
      }, isSwitchActive);
    },
    changeSuccess() {
      this.changeStatus = "default";
      this.$emit("changeSuccess");
    },
    // 接收富文本事件
    ckeditorEvent(data) {
      if (data === "Enter") {
        this.refData.currentEditor.editing.view.focus();
        this.$nextTick(() => {
          this.refData.currentEditor.model.change(writer => {
            writer.setSelection(writer.createPositionAt(this.refData.currentEditor.model.document.getRoot(), "end"));
          });
        });
      }
    },
    ckeditorValueChange() {
      if (!this.saveTemplateVisible && this.onChangeSave && !this.isTemplateManage) {
        if (!this.isSave) {
          if (this.ckeditorValueCopy && this.ckeditorValue && this.ckeditorValueCopy === this.ckeditorValue) {
            this.isSave = true;
          } else {
            this.isSave = false;
          }
        }
      }
    },
    // 富文本自定义按钮的操作
    btnOperation(type, clipboardData) {
      console.log('type',type)
      if (type === "saveTemplate") {

        this.setCkeditorConfig(false);
        this.titleValuePopup = "";
        this.ckeditorValuePopup = clipboardData;
        this.keyCodePopup++;
        this.saveTemplateVisible = true;
      }
    },
    setCkeditorConfig(isTemplateManage) {
      this.editorConfigPopup = JSON.parse(JSON.stringify(this.editorConfig));
      this.editorConfigTitle.placeholder = "模板名字";
      this.editorConfigPopup.placeholder = "模版内容";
      this.editorConfigPopup.toolbar.items = [
        {
          label: "",
          icon: "<svg t=\"1677848773135\" class=\"icon\" viewBox=\"0 0 1024 1024\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" p-id=\"32298\" width=\"200\" height=\"200\"><path d=\"M744.448 1024H279.552l-1.024-93.184h150.528l2.048-837.632H168.96l-29.184 32.256v177.152L34.816 314.368 32.768 0h956.416l2.048 314.368-105.472-11.776V125.44l-29.184-32.256h-261.632l-2.048 837.632h150.528l1.024 93.184z\" p-id=\"32299\" fill=\"#404040\"></path></svg>",
          items: ["paragraph", "heading1", "heading2", "heading3", "bulletedList", "numberedList", "todoList", "link", "blockQuote"]
        },
        "|",
        "alignment",
        "|",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "link",
        {
          label: "",
          icon: "<svg t=\"1679733495980\" class=\"icon\" viewBox=\"0 0 1024 1024\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" p-id=\"32772\" width=\"16\" height=\"16\"><path d=\"M954.88 941.568h-26.624l-377.856-916.48c-9.728-21.504-34.816-30.72-55.808-21.504-9.216 4.096-16.896 11.776-21.504 21.504l-376.32 916.48h-28.672c-16.896 0-31.744 8.192-36.864 23.552-9.216 21.504 1.024 46.08 22.016 55.296 5.12 2.048 10.752 3.584 16.384 3.584h120.832c19.968 0 38.4-13.312 41.984-33.792 3.072-21.504-11.776-41.984-33.28-45.056L276.48 758.784h338.944l73.728 186.368c-21.504 3.584-36.864 23.552-33.792 45.056 3.584 19.968 22.016 33.792 41.984 33.792h257.024c16.896 0 31.744-8.192 36.864-23.552 9.728-19.968 1.536-44.544-18.432-54.784-5.12-2.56-11.776-4.096-17.92-4.096zM305.152 674.816l137.728-335.872 137.728 335.872H305.152z\" p-id=\"32773\" fill=\"#404040\"></path></svg>",
          items: ["fontColor", "fontBackgroundColor"],
          class: "fontcolor-bgc"
        }];
      if (isTemplateManage) {
        this.editorConfig = JSON.parse(JSON.stringify(this.editorConfigPopup));
        this.editorConfigTitle = JSON.parse(JSON.stringify(this.editorConfigTitle));
      }
    },
    // 统计一个字符串中某个字符出现的次数
    getSum(str, a) {
      let b = str.indexOf(a);
      var num = 0;
      while (b !== -1) {
        num++;
        b = str.indexOf(a, b + 1);
      }
      return num;
    },
    // 只有一个图片/文档时; 多个附件时显示 '未知标题';  若无标题 , 取附件名字做标题
    handlerTitle() {
      let title = "", num = 0;
      if (this.titleValue === "") {
        let dom = document.createElement("div");
        dom.innerHTML = this.ckeditorValue;
        let innerText = dom.innerText.trim();
        if (innerText === "") {
          num = this.getSum(this.ckeditorValue, "ask-component-iframe-box");
          num += this.getSum(this.ckeditorValue, "ask-component-img-box");
          if (num > 0) {
            if (num !== 1) {
              title = "未知标题";
            } else {
              let iframeText = this.ckeditorValue.match(/<iframe[^>]*filename=['\"]([^'\"]+)['\"][^>]*>/g);
              let imgText = this.ckeditorValue.match(/<img[^>]*filename=['\"]([^'\"]+)['\"][^>]*>/g);
              console.log(iframeText, imgText);
              if (iframeText && iframeText.length > 0) {
                title = iframeText[0].match(/filename=[\'\"]?([^\'\"]*)[\'\"]?/i)[1];
              }
              if (imgText && imgText.length > 0) {
                title = imgText[0].match(/filename=[\'\"]?([^\'\"]*)[\'\"]?/i)[1];
              }
            }
          } else {
            title = "";
          }
        } else {
          title = innerText.substring(0, 21);
        }
      } else {
        title = this.headerTitle;
      }
      return title.trim();
    },
    closeEvent() {
      this.saveTemplateVisible = false;
    },
    ...mapActions("apphandle", [
      "updateIsTopAddDoc"
    ])
  }
};
</script>

<style lang="less" scoped>
.ask-template-ckeditor {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ask-template-ckeditor_left {
    height: 100%;
    width: 100%;
    // display: flex;
    // flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
    border-radius: 5px;
  }

  .ask-template-ckeditor_right {
    width: 387px;
    height: 100%;
    background: #F6F8FB;
    box-shadow: 0px 0px 10px 0px rgba(79, 82, 92, 0.17);
    border-radius: 5px;
  }

  .ask-template-ckeditor-header {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    background: #FFFFFF;
    box-sizing: border-box;
    border-bottom: 1px solid #F4F6FB;
    border-radius: 5px;

    .ct_head_back {
      margin-right: 15px;

      i {
        cursor: pointer;
      }
    }

    .ct_head_title {
      width: calc(100% - 131px);
      text-align: left;
      color: #000000;
      height: 40px;
      display: flex;
      align-items: center;
      margin-right: 20px;

      .ct_head_title_name {
        font-size: 14px;
        display: flex;
        align-items: center;

        .el-input {
          height: 100%;
          width: 90%;

          /deep/ .el-input__inner {
            padding: 0;
            border: none;
          }
        }

        i {
          font-size: 14px;
          margin-right: 10px;
        }
      }

      .ct_head_title_status {
        font-size: 14px;
        color: #366AFF;
        white-space: nowrap;

        i {
          font-size: 14px;
          margin-left: 15px;
        }
      }
    }

    .ct_head_btn {
      // width: 100px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .head_photo {
        width: 28px;
        height: 28px;
        background: #366AFF;
        border-radius: 50%;
        color: #FFFFFF;
        text-align: center;
        line-height: 28px;
        font-size: 12px;
      }

      .head_collect {
        i {
          cursor: pointer;
          font-size: 18px;

          &.guoran-shoucang2, &.guoran-shoucang {
            margin: 0 15px;
          }
        }

        .guoran-shoucang {
          color: #366AFF;
        }
      }

      .head_more {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #EAEFFF;
        cursor: pointer;
        line-height: 24px;

        .i {
          color: #366AFF;
          font-size: 12px;
          font-weight: 100;
        }
      }

      .tips {
        color: #A9B3C6;
        margin-right: 15px;
      }

      .iconfont {
        cursor: pointer;
      }

      .guoran-tongyichicun-18-13-shanchu {
        margin-right: 14px;
      }
    }
  }

  .ckeditor-box {
    height: 100%;
    // padding-bottom: 100px;
    overflow-y: auto;
    position: relative;
  }

  .ask-template-ckeditor-content {
    margin: 0 auto;
    //width: 80%;
    width: 90%;
    height: calc(100% - 60px);
    padding: 20px 0px 0px 0;
    overflow: hidden;

    /deep/ .ask-component-section-box {
      width: 100%;
      height: 6px;
      display: block;
    }

    // /deep/.ck-widget__type-around {
    // 	display: none;
    // }
    &.ckeditor {
      height: calc(100% - 90px);
      margin-bottom: 10px;
      overflow: hidden;
    }

    &.isMin {
      height: calc(100% - 145px);
    }

    .popup-floating-layer-ckeditor {
      z-index: 2200;
    }
  }

  /deep/ .save-template-popup {
    z-index: 2200;

    #lighting-popup-assembly {
      width: 880px;

      .lighting-popup-container > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .lighting-popup-box {
          padding-right: 40px;
          width: 85%;
          height: calc(80vh - 200px);
          overflow-x: hidden;
          overflow-y: auto;

          .ratel-ckeditor-content {
            // height: calc(80vh - 300px);
          }
        }
      }
    }

  }

  .ratel-ckeditor-bottom-btn-box {
    // position: fixed;
    // bottom: 25px;
    // left: 500px;
    // width: 100%;
    // margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
    // padding-bottom: 5px;
    .show-btn {
      // width: 257px;
      padding: 8px 14px;
      // height: 31px;
      background: #366AFF;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 14px;
      margin-bottom: 5px;
      cursor: pointer;
      text-align: left;

      .iconfont {
        margin-right: 10px;
        background: linear-gradient(to bottom, #FF3BFD 0%, #00F7FF 100%); //通过CSS3实现背景色渐变效果
        -webkit-background-clip: text; //规定背景的绘制区域为文字部分
        -webkit-text-fill-color: transparent; //文字填充颜色 一定要定义为transparent 否则会覆盖底部的背景色
      }

      &.isMax {
        border-radius: 19px;
      }

      &.isMin {
        border-radius: 28px;
      }
    }

    .tips {
      color: #A9B3C6;
    }
  }

}
</style>
<template>
  <div class="contractFieldManage">
    <div class="contractFieldManageTitle">
      <div class="left">
        <span class="contractFieldName">{{ $t('contractModule.contractFieldList.fieldList') }}</span>
      </div>
      <div class="addcontractFieldBtn">
        <i class="iconfont guoran-a-18-35"></i>
        <span @click="openAddContractDialog">{{ $t('contractModule.contractFieldList.addField') }}</span>
      </div>
    </div>
    <div class="contractFieldTable">
      <el-table :data="contractFieldTableData" style="width: 100%"
          :header-cell-style="{backgroundColor:'#F6F8FD',height:'50px',color:'#000000',fontWeight:400}">
        <el-table-column prop="name" :label="$t('contractModule.contractFieldList.fieldName')" width="180">
          <template slot-scope="scope">
            <div>{{ scope.row.name }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="description" :label="$t('contractModule.contractFieldList.description')">
          <template slot-scope="scope">
            <div >{{ scope.row.description }}</div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('contractModule.contractFieldList.operation')" align="center" width="280">
          <template slot-scope="scope">
            <el-button type="warning" size="mini" @click="rowClick(scope.row)">{{$t('contractModule.contractFieldList.allocation')}}</el-button>
            <el-button type="primary" size="mini" @click="editcontractTemplateField(scope.row)">{{$t('contractModule.contractFieldList.edit')}}</el-button>
            <el-button type="danger" size="mini" @click="deleteContractTemplateField(scope.row)">{{$t('contractModule.contractFieldList.del')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <popup-com @closeEvent="contractFieldDialog = false;" v-if="contractFieldDialog">
        <div slot="popup-name">{{ editContractFieldForm ? $t('contractModule.contractFieldList.editTitle') : $t('contractModule.contractFieldList.title') }}</div>
        <div slot="popup-tip"></div>
        <div slot="popup-con" class="contractDialogContainer">
          <el-form label-position="top" :model="addContractFieldForm">
            <el-form-item :label="$t('contractModule.contractTemplateList.name')">
              <span slot="label">
                {{ $t('contractModule.contractTemplateList.name') }}
                <span style="color: red">*</span>
              </span>
              <el-input v-model="addContractFieldForm.name" autocomplete="off" :placeholder="$t('contractModule.contractTemplateList.placeholder')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('contractModule.contractTemplateList.description')">
              <span slot="label">
                {{ $t('contractModule.contractTemplateList.description') }}
                <span style="color: red">*</span>
              </span>
              <el-input v-model="addContractFieldForm.description" autocomplete="off" :placeholder="$t('contractModule.contractTemplateList.placeholder')"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div slot="dialog-footer">
          <el-button plain size="small" @click="contractFieldDialog = false" class="cancel-btn">{{ $t('common.cancel') }}</el-button>
          <el-button type="primary" @click="editContractFieldForm ? confirmEditContractTemplateField() : addContractTemplateField()" class="confirm-btn">{{ $t('common.confirm') }}</el-button>
        </div>
      </popup-com>
      <popup-com @closeEvent="contractDialog = false;" v-if="contractDialog">
        <div slot="popup-name">{{ $t('contractModule.contractTemplateList.editTitle') }}</div>
        <div slot="popup-tip"></div>
        <div slot="popup-con" class="contractDialogContainer">
          <el-form label-position="top" :model="editContractTemplateForm">
            <el-form-item :label="$t('contractModule.contractTemplateList.name')">
              <span slot="label">
                {{ $t('contractModule.contractTemplateList.name') }}
                <span style="color: red">*</span>
              </span>
              <el-input v-model="editContractTemplateForm.name" autocomplete="off" :placeholder="$t('contractModule.contractTemplateList.placeholder')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('contractModule.contractTemplateList.description')">
              <span slot="label">
                {{ $t('contractModule.contractTemplateList.description') }}
                <span style="color: red">*</span>
              </span>
              <el-input v-model="editContractTemplateForm.description" autocomplete="off" :placeholder="$t('contractModule.contractTemplateList.placeholder')"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div slot="dialog-footer">
          <el-button plain size="small" @click="contractDialog = false" class="cancel-btn">{{ $t('common.cancel') }}</el-button>
          <el-button type="primary" @click="addContract" class="confirm-btn">{{ $t('common.confirm') }}</el-button>
        </div>
      </popup-com>
    </div>
  </div>
</template>

<script>
import { requestUrl } from "@/http/requestUrl";
import PopupCom from "@/components/popup";
import { mapActions } from 'vuex'
export default {
  name: "template_field",
  components: { PopupCom },
  data() {
    return {
      contractFieldTableData: [],
      contractFieldDialog: false,
      addContractFieldForm: {},
      editContractFieldForm: false,
      contractTemplate:{},
      editContractTemplateForm:{
        name:"",
        description: ""
      },
      contractDialog:false
    };
  },
  methods: {
    openAddContractDialog() {
      this.editContractFieldForm = false;
      this.addContractFieldForm = {
        name: "",
        description: ""
      };
      this.contractFieldDialog = true;
    },
    getAllTemplateField() {
      let url =
        requestUrl.contractTemplateField.contractTemplateField +
        "?mainId=" +
        sessionStorage.getItem("_mainId") +
        "&templateId=" +
        this.$route.query.templateId;
      this.$http.get(url).then((res) => {
        this.contractFieldTableData = res.data;
        console.log("获取到的数据:", this.contractFieldTableData);
      });
    },
    ...mapActions("modelFile", [
      "updateModel"
    ]),
    getTemplateDetail(){
      let url = '/tag-api/contract-template/' + this.$route.query.templateId
      this.$http.get(url).then(res => {
        if (res.data){
          this.updateModel(res.data);
          this.contractTemplate = res.data;
        }
      })
    },
    updateInfo() {
      this.contractDialog = true;
      this.$nextTick(() => {
        this.editContractTemplateForm.name = this.contractTemplate.name;
        this.editContractTemplateForm.description = this.contractTemplate.description;
      })
    },
    addContract(){
      this.$http
          .put(requestUrl.contractTemplate.addTemplate + this.$route.query.templateId, {
            name: this.editContractTemplateForm.name,
            description: this.editContractTemplateForm.description,
            mainId: sessionStorage.getItem('_mainId'),
            creatorId: sessionStorage.getItem('_uid'),
          })
          .then(res => {
            if (res.data) {
              this.contractDialog = false;
              this.getTemplateDetail();
              this.$message({
                message: this.$t('contractModule.contractFieldList.editSuccess'),
                type: "success"
              })
            } else {
              this.$message.error(res.message);
            }
          });
    },
    deleteNews(){
      this.$confirm(this.$t('contractModule.contractFieldList.deleteModel'), this.$t('contractModule.contractFieldList.deleteTitle'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: "warning",
      })
          .then(() => {
            // 用户确认删除后执行的操作
            let url = '/tag-api/contract-template/' + this.$route.query.templateId
            this.$http
                .delete(url)
                .then((res) => {
                  console.log('res',res)
                  this.$message({
                    type: "success",
                    message: this.$t('contractModule.contractFieldList.deleteSuccess'),
                  });
                  this.$router.go(-1);
                });
          })
          .catch(() => {
            // 用户取消删除操作
            this.$message({
              type: "info",
              message: this.$t('contractModule.contractFieldList.cancelDelete'),
            });
          });
    },
    addContractTemplateField() {
      this.$http
        .post(requestUrl.contractTemplateField.contractTemplateField, {
          name: this.addContractFieldForm.name,
          description: this.addContractFieldForm.description,
          mainId: sessionStorage.getItem('_mainId'),
          templateId: this.$route.query.templateId,
          creatorId: sessionStorage.getItem('_uid'),
        })
        .then(res => {
          if (res.data) {
            this.getAllTemplateField();
            this.contractFieldDialog = false;
          } else {
            this.$message.error(res.message);
          }
        });
    },
    editcontractTemplateField(item) {
      this.addContractFieldForm = item;
      this.editContractFieldForm = true;
      this.contractFieldDialog = true;
      console.log("this.editContractFieldForm:",this.editContractFieldForm);
      // PUT 请求放在确认按钮点击事件之后
    },

    confirmEditContractTemplateField() {
      this.$http
        .put(`${requestUrl.contractTemplateField.contractTemplateField}${this.addContractFieldForm._id}`, {
          name: this.addContractFieldForm.name,
          description: this.addContractFieldForm.description
        })
        .then(res => {
          if (res) {
            this.editContractFieldForm = false;
            this.contractFieldDialog = false;
            this.getAllTemplateField();
          } else {
            this.$message.error(res.message);
          }
        });
    },
    deleteContractTemplateField(item) {
      this.$confirm(this.$t('contractModule.contractFieldList.deleteField'), this.$t('contractModule.contractFieldList.deleteTitle'), {
          confirmButtonText: this.$t('common.confirm'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning'
        }).then(() => {
          console.log("item:",item)
          this.$http
            .delete(`${requestUrl.contractTemplateField.contractTemplateField}${item._id}`)
            .then(res => {
              if (res) {
                this.$message({
                  type: 'success',
                  message: this.$t('contractModule.contractFieldList.deleteSuccess')
                });
                this.getAllTemplateField();
              } else {
                this.$message.error(res.message);
              }
            });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: this.$t('contractModule.contractFieldList.cancelDelete')
          });          
        });
    },

    rowClick(item) {
      this.$router.push({
        path: "/template_field_configure",
        query: {
          fieldId: item._id,
          templateId:this.$route.query.templateId
        },
      });
    },
  },
  mounted() {
    this.getAllTemplateField();
    this.getTemplateDetail();
  },
};
</script>

<style scoped lang="less">
.contractFieldManage {
  padding: 20px;
  background: #ffffff;
  border-radius: 5px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;

  .contractFieldManageTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;

      .contractFieldName {
        color: #000000;
        font-size: 16px;
        margin-right: 10px;
      }

      .tips {
        color: #a9b3c6;
        font-size: 12px;
      }
    }

    .addcontractFieldBtn {
      flex: none;
      height: 32px;
      padding: 0 10px;
      background: #366aff;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: #ffffff;

      i {
        font-size: 14px;
        margin-right: 4px;
      }
    }
  }

  .contractFieldTable {
    margin-top: 10px;

    .contractFieldName {
      max-width: 360px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      i {
        margin-right: 8px;
        color: #366aff;
        cursor: move;
      }
    }
  }
}
</style>

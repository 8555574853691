<template>
    <div :class="['feishu-bind',accessStatus === 1 ? 'center' : '']">
        <!-- 第一步 扫码安装应用 -->
        <div v-if="accessStatus === 1" class="feishu-first-mark">
            <div class="title">{{$t('channel.components.FeiShuBindCom.feishuBind.firstStepTitle')}}</div>
            <img src="../../../assets/images/feishu-code.png" alt="" srcset="">
            <!-- <div class="refresh-mark" @click="refreshMark"> <i class="iconfont guoran-tongyichicun-16-12-shuaxin"></i>刷新二维码</div> -->
            <div>{{$t('channel.components.FeiShuBindCom.feishuBind.skipStepText')}}<span @click="skipStep" class="skip-step">{{$t('channel.components.FeiShuBindCom.feishuBind.skip')}}</span>{{$t('channel.components.FeiShuBindCom.feishuBind.step')}}</div>
        </div>
        <!-- 第一步 填写信息  -->
        <div v-else-if="accessStatus === 2"  class="feishu-second-info">
        <div class="title">{{$t('channel.components.FeiShuBindCom.feishuSecondInfo.title1')}}</div>
        <div class="title">{{$t('channel.components.FeiShuBindCom.feishuSecondInfo.title2')}}“ {{this.askBotLable}}”{{$t('channel.components.FeiShuBindCom.feishuSecondInfo.title4')}}</div>
        <div class="title">{{$t('channel.components.FeiShuBindCom.feishuSecondInfo.title3')}}</div>
        <el-form label-position="top"  :model="formFeishu">
            <el-form-item :label="$t('channel.components.FeiShuBindCom.feishuSecondInfo.spaceNameLabel')">
            <el-input v-model="formFeishu.spaceName" :placeholder="$t('channel.components.FeiShuBindCom.feishuSecondInfo.placeholder')"></el-input>
            </el-form-item>
            <el-form-item label="共享空间token">
            <div slot="label" class="label">
                <span>{{$t('channel.components.FeiShuBindCom.feishuSecondInfo.personalAccessTokenLabel')}}</span>
                <span class="get-token" @click="shareSpaceTokenPopup = true">{{$t('channel.components.FeiShuBindCom.feishuSecondInfo.getTokenText')}}</span>
            </div>
            <el-input v-model="formFeishu.personalAccessToken" :placeholder="$t('channel.components.FeiShuBindCom.feishuSecondInfo.placeholder')"></el-input>
            </el-form-item>
        </el-form>
        </div>
        <div :class="['footer',accessStatus === 1 ? 'first-step' : '']">
            <span class="lookDoc" v-if="false">{{$t('channel.components.FeiShuBindCom.footer.lookDocText')}}</span>
            <div class="saveBtn" v-if="accessStatus === 2">
                <el-button round type="primary" size="small" @click="accessBind">{{$t('channel.components.FeiShuBindCom.footer.saveBtnText')}}</el-button>
            </div>
        </div>
        <popup @closeEvent='shareSpaceTokenPopup = false' v-if="shareSpaceTokenPopup" class="shareSpaceTokenPopup">
            <div slot="popup-con-c" class="popup-con-c">
                <img src="https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/cdn-lightning-backstage/dev/img/feishu-example.gif" alt="">
            </div>
            <!-- <div slot="dialog-footer">
                <el-button  type="primary" size="small" @click="shareSpaceTokenPopup = false"  class="confirm-btn">确 定</el-button>
            </div> -->
        </popup>
    </div>
</template>

<script>
import popup from "@/components/popup";
import { requestUrl } from "@/http/requestUrl";
export default {
    data(){
        return{
            // 飞书
            formFeishu:{
                spaceName:"",
                personalAccessToken:""
            },
            accessStatus:1, // 接入步骤
            shareSpaceTokenPopup:false,
            isProduction:false,
            askBotLable:''
        }
    },
    components:{popup},
    props:['isAccess'],
    mounted(){
        this.isProduction = process.env.VUE_APP_NODE_ENV === 'production' ? true : false;
        if(window.location.hostname.includes('ebot.isheely.com') || window.location.hostname.includes('localhost')) {
            this.askBotLable = this.isProduction ? 'eBot员工助手' : 'eBot智能服务台'
        } else {
            this.askBotLable = this.isProduction ? 'AskBot员工助手' : 'AskBot智能服务台'
        }
    },
    methods:{
        // 刷新飞书二维码
        refreshMark(){

        },
        skipStep(){
            this.accessStatus = 2;
        },
        accessBind(){
            if (!this.formFeishu.personalAccessToken){
                this.$message({
                    message:this.$t('channel.components.FeiShuBindCom.message.fillTokenMessage'),
                    duration:2000,
                    type:"warning"
                })
                return
            }
            if (!this.formFeishu.spaceName){
                this.$message({
                    message:this.$t('channel.components.FeiShuBindCom.message.fillSpaceNameMessage'),
                    duration:2000,
                    type:"warning"
                })
                return
            }
            this.$http.post(requestUrl.channel.channelAccess,{
                corpId :sessionStorage.getItem('_corpId'),
                channel:5,
                name:this.formFeishu.spaceName,
                channelAccessDetailObj:{
                    spaceName:this.formFeishu.spaceName,
                    personalAccessToken:this.formFeishu.personalAccessToken,
                }
            }).then(res => {
                if (res.data.code == 0){
                    this.$emit('update:isAccess',true)
                    this.$message({
                        message:this.$t('channel.components.FeiShuBindCom.message.successMessage'),
                        duration:2000,
                        type:'success'
                    })
                } else if (res.data.code == -1){
                    this.$message({
                        message:res.data.msg,
                        duration:2000,
                        type:"warning"
                    })
                }
            })
        },
        getToken(){

        }
    }
}
</script>

<style lang="less" scoped>
.feishu-bind{
    width: 500px;
    margin: 0 auto 50px auto;
    padding: 20px;
    height: calc(100% - 110px);
    overflow-y: auto;
    &.center{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .title{
        margin-bottom: 14px;
        &:nth-child(2){
            margin-top: 14px;
            margin-bottom: 20px;
        }
    }
   
    .feishu-first-mark{
        font-size: 13px;
        img{
            width: 300px;
        }
        div{
            line-height:35px;
            text-align: center;
        }
        .skip-step{
            color: #366aff;
            cursor: pointer;
            margin: 0 5px;
        }
        .refresh-mark{
            cursor: pointer;
            .iconfont{
                margin-right: 6px;
                color: #616161f0;
            }
        }
    }
    .feishu-second-info{
        /deep/.el-form{
            .el-form-item__content{
                line-height: 20px;
            }
            .el-form-item__label{
                width: 100%;
                line-height: 20px;
                .label{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    text-transform: inherit;
                    font-family: Microsoft YaHei;
                    .get-token{
                        color: #366aff;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .footer{
        // position: absolute;
        // bottom: 40px;
        display: flex;
        align-items: center;
        // width: 400px;
        justify-content: center;
        margin-top: 60px;
        &.first-step{
            position:inherit;
            margin-top: 10px;
            // width: 260px;
        }
        .lookDoc{
            color: #366aff;
            cursor: pointer;
        }
        .saveBtn{
           // margin-left: 160px;
            width: 100%;
            .el-button{
               width: 100%; 
            }
        }
    }
    /deep/.shareSpaceTokenPopup{
        #popup-assembly{
            width: 1000px !important;
            .popup-container-c{
                padding: 0 50px !important;
            }
        }
    }
}
</style>
<template>
    <div id="contractTemplateList">
      <div class="contract-header">
        <div class="contract-title">{{ $t('contractModule.contractTemplateList.title') }}</div>
        <div class="add-contract-btn" @click="openAddContractDialog">
          <i class="iconfont guoran-a-16-13"></i>
          {{ $t('contractModule.contractTemplateList.addButton') }}
        </div>
      </div>
      <div class="contract-container">
        <div class="contract-main" v-if="templateList.length">
          <div v-for="(template, index) in templateList" :key="index" class="contract-card" @click="navigateToTemplateConfig(template._id)">
            <div class="contract-content">
              <el-image
                style="width: 60px; height: 60px; border-radius: 5px"
                :src="template.avatar ? template.avatar : 'https://static.guoranbot.com/bot.png'"
                fit="cover"
              ></el-image>
              <div class="contract-text">
                <div class="contract-text-top">{{ template.name }}</div>
                <div class="contract-text-bottom">
                  <div class="contract-description">{{ template.description }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="contract-empty" v-else>
          <img src="../../assets/images/empty_list.png" style="width: 400px">
          <div class="tips">
            {{ $t('contractModule.contractTemplateList.emptyMessage') }}
          </div>
        </div>
      </div>
      <popup-com @closeEvent="contractDialog = false;" v-if="contractDialog">
        <div slot="popup-name">{{ addContractForm.id ? $t('contractModule.contractTemplateList.editTitle') : $t('contractModule.contractTemplateList.newTitle') }}</div>
        <div slot="popup-tip"></div>
        <div slot="popup-con" class="contractDialogContainer">
          <el-form label-position="top" :model="addContractForm">
            <el-form-item :label="$t('contractModule.contractTemplateList.name')">
              <span slot="label">
                {{ $t('contractModule.contractTemplateList.name') }}
                <span style="color: red">*</span>
              </span>
              <el-input v-model="addContractForm.name" autocomplete="off" :placeholder="$t('contractModule.contractTemplateList.placeholder')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('contractModule.contractTemplateList.description')">
              <span slot="label">
                {{ $t('contractModule.contractTemplateList.description') }}
                <span style="color: red">*</span>
              </span>
              <el-input v-model="addContractForm.description" autocomplete="off" :placeholder="$t('contractModule.contractTemplateList.placeholder')"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div slot="dialog-footer">
          <el-button plain size="small" @click="contractDialog = false" class="cancel-btn">{{ $t('common.cancel') }}</el-button>
          <el-button type="primary" @click="addContract" class="confirm-btn">{{ $t('common.confirm') }}</el-button>
        </div>
      </popup-com>
    </div>
  </template>
  
  <script>
  import { requestUrl } from "@/http/requestUrl";
  import PopupCom from "@/components/popup";
  
  export default {
    components: { PopupCom },
    data() {
      return {
        templateList: [],
        contractDialog: false,
        addContractForm: {},
        currentUpdateId: 0
      };
    },
    methods: {
      openAddContractDialog() {
        this.addContractForm = {
          name: "",
          description: ""
        };
        this.contractDialog = true;
      },
      fetchContractTemplates() {
        this.$http.get(requestUrl.contractTemplate.fetchTemplates + "?mainId=" + sessionStorage.getItem('_mainId') ).then(res => {
          if (res.data) {
            this.templateList = res.data
          }
        });
      },
      addContract() {
        this.$http
          .post(requestUrl.contractTemplate.addTemplate, {
            name: this.addContractForm.name,
            description: this.addContractForm.description,
            mainId: sessionStorage.getItem('_mainId'),
            creatorId: sessionStorage.getItem('_uid'),
          })
          .then(res => {
            if (res.data) {
              this.fetchContractTemplates();
              this.contractDialog = false;
            } else {
              this.$message.error(res.message);
            }
          }).catch(res => {
            console.log('res',res)
            this.$message({
              message:this.$t('contractModule.serviceError'),
              type:"warning"
            })
        });
      },
      navigateToTemplateConfig(templateId) {
        this.$router.push({ name: "template_field", query: { templateId: templateId } });
      }
    },
    mounted() {
      this.fetchContractTemplates();
    }
  };
  </script>
  
<style lang="less" scoped>
#contractTemplateList{
    width: 100%;
}
.contract-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 26px 10px 20px;
  .contract-title {
    font-size: 16px;
    color: #366aff;
  }
  .add-contract-btn {
    //width: 140px;
    padding: 0 10px;
    height: 33px;
    background: #366aff;
    border-radius: 17px;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: #ffffff;
    cursor: pointer;
  }
}

.contract-container {
  padding: 0 20px;
  height: calc(100% - 65px);
  overflow-y: scroll;
}

.contract-main {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-top: 10px;
  .contract-card {
    display: flex;
    flex-direction: column;
    padding: 16px;
    background: #ffffff;
    border: 2px solid #ffffff;
    box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
    border-radius: 5px;
    margin-right: 16px;
    max-width: 324px;
    min-width: 272px;
    flex: 1;
    height: 150px;
    margin-bottom: 16px;
    cursor: pointer;
    .contract-content {
      display: flex;
      align-items: center;
      .contract-text {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        margin-top: 30px;
        .contract-text-top {
          font-size: 16px;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.contract-empty {
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .tips {
    color: #616161;
    font-size: 16px;
    margin-top: 30px;
  }
}
</style>

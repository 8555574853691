<template>
  <div class="vue-office-excel" ref="wrapperRef">
    <div class="vue-office-excel-main" id="vue-office-excel-main" ref="rootRef"></div>
  </div>
</template>

<script>
import Spreadsheet from 'x-data-spreadsheet';
import { getData, readExcelData, transferExcelToSpreadSheet } from "@/components/preview/excel/js/excel";
import { clearCache, renderImage } from "@/components/preview/excel/js/media";
import waterMark from "@/utils/common";
import { mapGetters } from 'vuex'
const defaultOptions = {
  minColLength: 20
};
export default {
  name: "vueExcel",
  data(){
    return{
      fileData:null,
      xs:null,
      mediasSource:[],
      sheetIndex:0,
      ctx:null,
      offset:null,
      workbookDataSource:{
        _worksheets:[]
      },
      observerCallback:null,
      observer:null,
      observerConfig:null
    }
  },
  props:{
    src:{
      type:String,
      default:"",
    },
    requestOptions:{
      type:Object,
      default(){
        return {}
      }
    },
    options: {
      type: Object,
      default: () => ({
        ...defaultOptions
      })
    }
  },
  computed:{
    ...mapGetters('knowledge', {
      textWatermarkStr: 'getTextWatermarkStr'
    }),
  },
  methods:{
    async init(){
      console.log('clientWidth',this.$refs.wrapperRef.clientHeight,this.$refs.wrapperRef.clientWidth)
      this.xs = new Spreadsheet(this.$refs.rootRef, {
        mode: 'read',
        showToolbar: false,
        showContextmenu:  false,
        view: {
          height: () => this.$refs.wrapperRef && this.$refs.wrapperRef.clientHeight || 300,
          width: () => this.$refs.wrapperRef && this.$refs.wrapperRef.clientWidth || 1200,
        },
        row: {
          height: 24,
          len: 40
        },
        col: {
          len: 26,
          width: 80,
          indexWidth: 60,
          minWidth: 60,
        },
        autoFocus: false
      }).loadData({});
      let swapFunc = this.xs.bottombar.swapFunc;
      this.xs.bottombar.swapFunc = (index) => {
        swapFunc.call(this.xs.bottombar, index);
        this.sheetIndex = index;
        setTimeout(() => {
          this.xs.reRender();
          renderImage(this.ctx, this.mediasSource, this.workbookDataSource._worksheets[this.sheetIndex],this.offset);
        });

      };
      let clear = this.xs.sheet.editor.clear;
      console.log('xs',this.xs)
      this.xs.sheet.editor.clear = (...args) => {
        clear.apply(this.xs.sheet.editor, args);
        setTimeout(() => {
          renderImage(this.ctx, this.mediasSource, this.workbookDataSource._worksheets[this.sheetIndex], this.offset);
        });
      };
      let setOffset = this.xs.sheet.editor.setOffset;
      this.xs.sheet.editor.setOffset = (...args) => {
        if (this.xs.sheet && this.xs.sheet.editor){
          setOffset.apply(this.xs.sheet.editor, args);
        }
        this.offset = args[0];
        renderImage(this.ctx, this.mediasSource, this.workbookDataSource._worksheets[this.sheetIndex], this.offset);
      };
      const canvas = this.$refs.rootRef.querySelector('canvas');
      this.ctx = canvas.getContext('2d');
      if (this.src) {
        getData(this.src, this.requestOptions).then(this.renderExcel).catch(e => {
          this.xs.loadData({});
          console.log('e',e)
        });
      }

    },
    renderExcel(buffer) {
      this.fileData = buffer;
      readExcelData(buffer).then(workbook => {
        if (!workbook._worksheets || workbook._worksheets.length === 0) {
          throw new Error('未获取到数据，可能文件格式不正确或文件已损坏');
        }
        let {workbookData, medias, workbookSource} = transferExcelToSpreadSheet(workbook, {...defaultOptions, ...this.options});
        if(this.options.transformData && typeof this.options.transformData === 'function' ){
          workbookData = this.options.transformData(workbookData);
        }
        this.mediasSource = medias;
        this.workbookDataSource = workbookSource;
        this.offset = null;
        this.sheetIndex = 0;
        clearCache()
        this.xs.loadData(workbookData);
        renderImage(this.ctx, this.mediasSource, this.workbookDataSource._worksheets[this.sheetIndex], this.offset);
        //涉及clear和offset
        if (this.textWatermarkStr){
          waterMark.newInitWaterMark('vue-office-excel-main',this.textWatermarkStr)
        }
      }).catch(e => {
        console.warn(e);
        this.mediasSource = [];
        this.workbookDataSource = {
          _worksheets:[]
        };
        clearCache();
        this.xs && this.xs.loadData({});

      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init()
    })
  },
  watch:{
    src:{
      handler(val){
        getData(val, this.requestOptions).then(this.renderExcel).catch(e => {
          this.xs.loadData({});
          console.log(e)
        });
      },
      immediate:true
    }
  }
};
</script>

<style scoped lang="less">
.vue-office-excel{
  height: 100%;
  .vue-office-excel-main{
    height: 100%;
    position: relative;
    /deep/input{
      padding: 0;
      width: 0;
      border: none!important;
    }
  }
  /deep/.x-spreadsheet-icon .x-spreadsheet-icon-img.add{
    display: none;
  }
  /deep/::-webkit-scrollbar-thumb{
    background-color: transparent!important;
  }
}
/deep/.x-spreadsheet-selector{
  .hide-input{
    display: none;
  }
}
</style>
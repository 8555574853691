const state = () => ({
    newsStatus:'', // 新闻的状态
    newsName:"", // 新闻名称
    pageStatus:"" , //新闻页面处于编辑还是阅读态
    newsInfo:{
        name:"",
        dirName:""
    }
})

const getters = {
    getIsShowTestWindow: (state) => {
        return state.isShowTestWindow;
    },
    getNewsName:(state) => {
        return state.newsName
    },
    getNewsStatus:(state) => {
        return state.newsStatus
    },
    getPageStatus:(state) => {
        return state.pageStatus
    },
    getNewsInfo:(state) => {
        return state.newsInfo
    },
}

const actions = {
    updateIsShowTestWindows({ commit }, isShowTestWindow) {
        commit("setIsShowTestWindow", isShowTestWindow);
    },
    updateNewsStatus({ commit }, newsStatus) {
        commit("setNewsStatus", newsStatus);
    },
    updateNewsName({ commit }, newsDir) {
        commit("setNewsName", newsDir);
    },
    updateNewsPageStatus({ commit }, pageStatus){
        commit("setPageStatus", pageStatus);
    }
}

const mutations = {
    setIsShowTestWindow(state, isShowTestWindow) {
        state.isShowTestWindow = isShowTestWindow;
    },
    setNewsStatus(state,status){
        state.newsStatus = status;
    },
    setNewsName(state,newsDir){
        state.newsInfo = newsDir
    },
    setPageStatus(state,pageStatus){
        state.pageStatus = pageStatus
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
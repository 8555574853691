<template>
  <div id="addNewsAnnouncement">
    <div class="pageContainer">
      <div class="newsTitle">
        <div class="newsCover">
          <el-upload
              class="avatar-uploader"
              :show-file-list="false"
              accept=".jpg,.png,jpeg"
              :on-success="handleAvatarSuccess"
              :http-request="aliyunOssRequest"
              :disabled="pageStatus === 'read'"
          >
            <img v-if="newDetail.cover" :src="newDetail.cover" class="avatar">
            <div v-else class="avatar-uploader-icon">
              <i class="el-icon-plus"></i>
              <span class="tips">{{ $t('knowledgeManagement.addNewsAnnounce.uploadCover') }}</span>
              <span>{{ $t('knowledgeManagement.addNewsAnnounce.imageScale') }}</span>
            </div>
          </el-upload>
        </div>
        <div class="newsSet">
          <div class="title">
            <template v-if="pageStatus === 'read'">
              {{ newDetail.title }}
            </template>
            <template v-else>
              <el-input v-model="newDetail.title" :placeholder="$t('knowledgeManagement.addNewsAnnounce.titlePlaceholder')" style="width: 100%"></el-input>
            </template>
          </div>
          <div class="visibility">
            <span class="left">{{$t('knowledgeManagement.addNewsAnnounce.visibleRange')}}</span>
            <div class="visibilitySet">
              <template v-if="pageStatus === 'read'">
                <div class="visibilityList" v-if="newDetail.visitConfig">
                  <template v-if="newDetail.visitConfig.users">
                    <div class="memberItem"
                         v-for="item in newDetail.visitConfig.users"
                         :key="item.memberId"
                    >
                      <el-tooltip placement="top" effect="dark">
                        <div slot="content">
                          {{ item.name }}
                        </div>
                        <el-avatar :size="30" :src="item.avatar" v-if="item.avatar"></el-avatar>
                        <span v-else-if="item.name" class="avatar">{{(item.name).substr(-2)}}</span>
                      </el-tooltip>
                    </div>
                  </template>
                  <template v-if="newDetail.visitConfig.departs">
                    <div class="departItem"
                         v-for="item in newDetail.visitConfig.departs"
                         :key="item.id"
                    >
                      <div class="departAvatar">
                        <i class="iconfont guoran-chanpin"></i>
                      </div>
                      <div class="departName">
                        <open-data :openid="item.id" :type="'departmentName'" v-if="isUseOpenData()"></open-data>
                        <template v-else>
                          {{item.name}}
                        </template>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="visibilityList" v-else> -- </div>
              </template>
              <template v-else>
                <div class="radioGroup">
                  <el-radio-group v-model="newDetail.visitScope">
                    <el-radio label="INHERIT">{{$t('knowledgeManagement.addNewsAnnounce.followDir')}}</el-radio>
                    <el-radio label="CUSTOMIZE">{{$t('knowledgeManagement.addNewsAnnounce.customize')}}</el-radio>
                  </el-radio-group>
                </div>
                <div class="permissionList" @click="setPermission" v-if="newDetail.visitScope === 'CUSTOMIZE'">
                  <template v-if="permissionList.length > 0">
                    <div v-for="(item,index) in permissionList" :key="index"  class="permissionItem">
                      <template v-if="item.type === 1">
                        <div class="departAvatar">
                          <i class="iconfont guoran-chanpin"></i>
                        </div>
                      </template>
                      <template v-else>
                        <div class="avatar" v-if="item.name">
                          {{(item.name).substr(0,1)}}
                        </div>
                      </template>
                      <span class="label aa" v-if="item.name">
                      <open-data :openid="item.name" :type="item.type == 1 ? 'departmentName' :'userName'" v-if="isUseOpenData()"></open-data>
                      <template v-else>
                        {{item.name}}
                      </template>
                    </span>
                      <span class="close" @click.stop="closePermission(index)">
                      <i class="iconfont guoran-shanchu"></i>
                    </span>
                    </div>
                  </template>
                  <template v-else>
                    <span class="placeholder">{{$t('knowledgeManagement.addNewsAnnounce.selectPlaceholder')}}</span>
                  </template>
                </div>
              </template>
            </div>
          </div>
          <div class="rowItem">
            <div class="rowLeft">
              <span class="left">{{$t('knowledgeManagement.addNewsAnnounce.directory')}}</span>
              <div v-if="pageStatus === 'read'" class="readValue">
                <i class="iconfont guoran-tongyichicun-wenjianjia-shouqizhuangtai-folderMinus"></i>
                <div v-for="(item,index) in newDetail.directory" :key="index">
                  {{ item.name }}{{ index < newDetail.directory.length - 1 ? '/' : '' }}
                </div>
              </div>
              <div class="checkBox" v-else>
                <directory-tree @changeDir="changeDir" ref="directoryTree" :addNews="true"></directory-tree>
              </div>
            </div>
            <div class="rowRight">
              <span class="tips">{{$t('knowledgeManagement.addNewsAnnounce.postTop')}}</span>
              <el-switch
                  v-model="newDetail.pinToTop"
                  active-color="#366aff"
                  :disabled="pageStatus === 'read'"
              >
              </el-switch>
            </div>
          </div>
          <div class="rowItem">
            <div class="rowLeft">
              <span class="left">{{$t('knowledgeManagement.addNewsAnnounce.effectiveTime')}}</span>
              <div class="right">
                <div class="readValue" v-if="pageStatus === 'read'">
                  <i class="iconfont guoran-dengdai"></i>
                  <template v-if="effectiveTime && effectiveTime.length">
                    {{ new Date(effectiveTime[0]).format('yyyy-MM-dd') }} ~
                    {{ new Date(effectiveTime[1]).format('yyyy-MM-dd') }}
                  </template>
                  <template v-else> {{$t('knowledgeManagement.addNewsAnnounce.permanentValidity')}} </template>
                </div>
                <template v-else>
                  <div class="radioGroup">
                    <el-radio-group v-model="effectiveTimeType">
                      <el-radio label="FOREVER">{{$t('knowledgeManagement.addNewsAnnounce.permanentValidity')}}</el-radio>
                      <el-radio label="CUSTOMIZE">{{$t('knowledgeManagement.addNewsAnnounce.customize')}}</el-radio>
                    </el-radio-group>
                  </div>
                  <template v-if="effectiveTimeType === 'CUSTOMIZE'">
                    <el-date-picker
                        v-model="effectiveTime"
                        type="daterange"
                        :range-separator="$t('knowledgeManagement.addNewsAnnounce.range')"
                        :start-placeholder="$t('knowledgeManagement.addNewsAnnounce.startDate')"
                        :end-placeholder="$t('knowledgeManagement.addNewsAnnounce.endDate')"
                        :default-time="['00:00:00', '23:59:59']"
                        style="width: 100%;margin-top: 14px"
                    >
                    </el-date-picker>
                  </template>
                </template>
              </div>
            </div>
            <div class="rowRight">
              <span class="tips">{{$t('knowledgeManagement.addNewsAnnounce.allowLikes')}}</span>
              <el-switch
                  v-model="newDetail.allowLike"
                  active-color="#366aff"
                  :disabled="pageStatus === 'read'"
              >
              </el-switch>
            </div>
          </div>
          <div class="rowItem">
            <div class="rowLeft">
              <span class="left">{{$t('knowledgeManagement.addNewsAnnounce.timedRelease')}}</span>
              <div class="readValue" v-if="pageStatus === 'read'">
                <i class="iconfont guoran-fabu"></i>
                {{ configPublishAt ? new Date(configPublishAt).format('yyyy-MM-dd hh:mm') : '--' }}
              </div>
              <template v-else>
                <el-date-picker
                    v-model="configPublishAt"
                    type="datetime"
                    :placeholder="$t('knowledgeManagement.addNewsAnnounce.dateTime')"
                    format="yyyy-MM-dd HH:mm"
                    style="width: 100%"
                    :picker-options="pickerOption"
                >
                </el-date-picker>
              </template>
            </div>
            <div class="rowRight">
              <span class="tips">{{$t('knowledgeManagement.addNewsAnnounce.allowComments')}}</span>
              <el-switch
                  v-model="newDetail.allowComment"
                  active-color="#366aff"
                  :disabled="pageStatus === 'read'"
              >
              </el-switch>
            </div>
          </div>
        </div>
      </div>
      <div class="newsHtmlContainer">
        <template-ckeditor
            ref="templateCkeditor"
            :isShowGuide="true"
        ></template-ckeditor>
      </div>
    </div>
    <employee-structure
        ref="employeeStructure"
        :permissionList="permissionList"
        @confirmPermission="confirmPermission"
        @cancelPermission="cancelPermission"
        @onNodeClick="permissionNodeClick"
        v-if="permissionDialog"
    ></employee-structure>
    <popup-com
        @closeEvent="popupNews = false"
        v-if="popupNews"
        class="folderPopupCom"
    >
      <div slot="popup-name">{{popupTips}}</div>
      <div slot="dialog-footer">
        <el-button  plain size="small" @click="popupNews = false" class="cancel-btn">{{$t('knowledgeManagement.addNewsAnnounce.cancelText')}}</el-button>
        <el-button  type="primary" size="small" @click="newsFun"  class="confirm-btn">{{$t('knowledgeManagement.addNewsAnnounce.confirmText')}}</el-button>
      </div>
    </popup-com>
    <delete-dialog @closeEvent="deleteNewsDialog = false" v-if="deleteNewsDialog">
      <div slot="popup-container" class="popup-container">
        <div class="delete-title">{{$t('knowledgeManagement.addNewsAnnounce.delNews')}}</div>
        <div class="prompt-text">
          {{$t('knowledgeManagement.addNewsAnnounce.delNewsTips')}}
        </div>
      </div>
      <div slot="dialog-footer">
        <el-button @click="deleteNewsDialog = false" size="small" plain class="cancel-btn">{{$t('knowledgeManagement.addNewsAnnounce.cancelText')}}</el-button>
        <el-button @click="confirmDeleteNews" style="background-color: #FE5965 ;color: white" size="small"  class="confirm-btn">{{$t('knowledgeManagement.addNewsAnnounce.del')}}
        </el-button>
      </div>
    </delete-dialog>
  </div>
</template>

<script>
import { ossConfig, publicMultipartUpload } from "@/assets/js/AliyunIssUtil";
import templateCkeditor from "@/components/ckeditor/templateCkeditor";
import DirectoryTree from "@/views/newsAnnouncement/components/directoryTree";
import OpenData from "@/components/openData";
import EmployeeStructure from "@/components/employeeStructure";
import { requestUrl } from "@/http/requestUrl";
import { mapActions,mapGetters } from 'vuex';
import PopupCom from "@/components/popup";
import { archiveNews, deleteNews, publishNews, unArchiveNews, unPublishNews } from "@/assets/js/common";
import DeleteDialog from "@/components/deleteDialog";
export default {
  name: "addNewsAnnouncement",
  data(){
    return{
      newDetail:{
        cover:"",
        title:"",
        visitScope:"INHERIT",
        configPublishAt:"",
        directoryId:"",
        fromDateTimeAt:"",
        toDateTimeAt:"",
        pinToTop:false,
        allowLike:true,
        allowComment:true,
        htmlContent:"",
        visitConfig:{
          users:[],
          departs:[]
        }
      },
      effectiveTime:[],
      configPublishAt:"",
      permissionList:[],
      permissionListCopy:[],
      permissionDialog:false,
      directoryId:"",
      effectiveTimeType:"FOREVER",
      popupTips:"",
      popupNews:false,
      newsTypes:"",
      deleteNewsDialog:false,
      appEvt: null,
      visitConfig:{
        users:[],
        departs: []
      },
      isSave:false,
      pickerOption:{
        disabledDate:time => {
          return time.getTime() < Date.now() - 3600 * 1000 * 24;
        }
      }
    }
  },
  components:{
    DeleteDialog,
    PopupCom,
    EmployeeStructure,
    OpenData,
    DirectoryTree,
    templateCkeditor
  },
  computed:{
    /*新闻是阅读态还是编辑态*/
    ...mapGetters('news', {
      pageStatus: 'getPageStatus'
    }),
    ...mapGetters('user', {
      currentVersion: 'getCurrentVersion'
    }),
  },
  methods:{
    aliyunOssRequest(data){
      let file = data.file;
      let res = publicMultipartUpload(ossConfig,file);
      console.debug('oss upload res', data, res);
      return res
    },
    onRemove(){

    },
    beforeUpload(){

    },
    onSuccess(){

    },
    onPreview(){

    },
    handleAvatarSuccess(res){
      console.log('res',res)
      let url = res.res.requestUrls[0];
      let index = url.lastIndexOf("?")
      if (index !== -1){
        url = url.substr(0, url.lastIndexOf("?"));
      }
      this.newDetail.cover = url;
    },
    getDirDetail(){
      let url = requestUrl.newsDirectory.directoryDetail + this.directoryId;
      this.$http.get(url).then(res => {
        if (res.data.code == 0 && res.data.data){
          if (this.$refs.directoryTree){
            this.$refs.directoryTree.selectValue = res.data.data.id;
            this.$refs.directoryTree.selectLabel = res.data.data.name;
          }
        }
      })
    },
    setPermission(){
      this.permissionDialog = true;
      this.$nextTick(() => {
        this.$refs.employeeStructure.permissionDialog = true;
        this.permissionListCopy = JSON.parse(JSON.stringify(this.permissionList))
      })
    },
    closePermission(index){
      this.permissionList.splice(index,1);
    },
    confirmPermission(){
      this.permissionList = this.permissionListCopy
      this.$refs.employeeStructure.permissionDialog = false;
      this.permissionDialog = false;
      this.visitConfig.users = [];
      this.visitConfig.departs = []
      this.permissionList.forEach(item => {
        if ((item.type == 0 || item.memberId)){
          this.visitConfig.users.push({
            memberId:item.id,
            name:item.name,
            mainId:sessionStorage.getItem('_mainId'),
            uid:sessionStorage.getItem('_clientUid')
          })
        } else if ((item.type == 1 || item.id)){
          this.visitConfig.departs.push({
            id:item.id,
            name:item.name
          })
        }
      })
    },
    cancelPermission(){
      this.$refs.employeeStructure.permissionDialog = false;
      this.permissionDialog = false;
    },
    permissionNodeClick(data){
      console.log(data,'data');
      if (data.checked){
        let obj = {
          id:data.id,
          type:data.type !== undefined ? data.type : (data.source !== undefined ? 0 : 1),
          name:data.label ? data.label : data.name,
        }
        // obj.iframeUrl = `${this.currentUrl}?corpId=${corpId}&openid=${obj.name}&type=${obj.type == 1 ? 'departmentName' : 'userName'}&style=2&datas=${obj.id + ',' + obj.type + ',' + obj.label}`
        this.permissionListCopy.push(obj)
      } else {
        this.permissionListCopy = this.permissionListCopy.filter(item => {
          return item.id !== data.id
        });
      }
    },
    changeDir(){

    },
    saveOrPublish(type){
      if (this.isSave){
        return;
      }
      let url = requestUrl.news.newsAnnouncement;
      if (this.effectiveTime && this.effectiveTime.length){
        this.newDetail.fromDateTimeAt = new Date(this.effectiveTime[0]).getTime();
        this.newDetail.toDateTimeAt = new Date(this.effectiveTime[1]).getTime();
      }
      if (this.configPublishAt){
        this.newDetail.configPublishAt = new Date(this.configPublishAt).getTime();
      }
      if (this.$refs.directoryTree){
        this.newDetail.directoryId = this.$refs.directoryTree.selectValue;
      }
      if (this.$refs.templateCkeditor){
        this.newDetail.htmlContent = this.$refs.templateCkeditor.ckeditorValue;
      }
      if (this.newDetail.visitScope === 'CUSTOMIZE' ){
        this.newDetail.visitConfig = this.visitConfig;
      }
      if (!this.newDetail.cover){
        this.$message({
          message:this.$t('knowledgeManagement.addNewsAnnounce.coverRequired'),
          type:"warning"
        })
        return
      }
      if (!this.newDetail.title){
        this.$message({
          message:this.$t('knowledgeManagement.addNewsAnnounce.titleRequired'),
          type:"warning"
        })
        return
      }
      if (this.newDetail.visitScope === 'CUSTOMIZE' && (!this.newDetail.visitConfig.users.length && !this.newDetail.visitConfig.departs.length)){
        this.$message({
          message:this.$t('knowledgeManagement.addNewsAnnounce.visibleRequired'),
          type:"warning"
        })
        return
      }
      this.isSave = true;
      if (this.newDetail.id){
        url += this.newDetail.id
        this.$http.put(url,this.newDetail).then(res => {
          if (res.data.code == 0){
            if (type === 'PUBLISHED'){
              publishNews([this.newDetail.id],() => {
                this.isSave = false;
                this.getNewsDetail(this.newDetail.id);
                this.popupTips = '';
                this.popupNews = false;
                this.newsTypes = ""
              },this)
            } else {
              this.isSave = false;
              this.$message({
                message:"保存成功",
                type:'success'
              })
              this.getNewsDetail(this.newDetail.id);
            }
          } else {
            this.isSave = false;
          }
        }).catch(() => {
          this.isSave = false;
        })
      } else {
        this.$http.post(url,this.newDetail).then(res => {
          if (res.data.code == 0){
            if (type === 'PUBLISHED'){
              publishNews([res.data.data],() => {
                this.isSave = false;
                this.getNewsDetail(res.data.data);
                this.popupTips = '';
                this.popupNews = false;
                this.newsTypes = ""
              },this)
            } else {
              this.isSave = false;
              this.$message({
                message:this.$t('knowledgeManagement.addNewsAnnounce.saveSuccess'),
                type:'success'
              })
              this.getNewsDetail(res.data.data);
            }
          } else {
            this.isSave = false;
          }
        }).catch(() => {
          this.isSave = false;
        })
      }
    },
    getNewsDetail(id){
      let status = "read"
      this.updateNewsPageStatus(status);
      let url = requestUrl.news.newsAnnouncementDetail + id;
      this.$http.get(url).then(res => {
        if (res.data.code == 0){
          this.newDetail = res.data.data;
          if (res.data.data.fromDateTimeAt){
            this.$set(this.effectiveTime,0,new Date(res.data.data.fromDateTimeAt))
          }
          if (res.data.data.toDateTimeAt){
            this.$set(this.effectiveTime,1,new Date(res.data.data.toDateTimeAt))
          }
          if (this.effectiveTime && this.effectiveTime.length){
            this.effectiveTimeType = "CUSTOMIZE"
          } else {
            this.effectiveTimeType = "FOREVER"
          }
          if (res.data.data.configPublishAt){
            this.configPublishAt = new Date(res.data.data.configPublishAt)
          }
          this.$nextTick(() => {
            this.$refs.templateCkeditor.ckeditorValue = res.data.data.htmlContent;
            this.$refs.templateCkeditor.editorDisabledContent = true;
          })
          if (this.newDetail.visitConfig){
            this.visitConfig = this.newDetail.visitConfig
            this.permissionList = this.newDetail.visitConfig.users.concat(this.newDetail.visitConfig.departs);
            this.permissionListCopy = this.newDetail.visitConfig.users.concat(this.newDetail.visitConfig.departs);
          }
          this.updateNewsStatus(res.data.data.state);
          this.updateNewsName({
            name:res.data.data.title,
            dirName:res.data.data.directory.map(item => {
              return item.name
            })
          });
        }
      })
    },
    ...mapActions('news', [
      'updateNewsStatus'
    ]),
    ...mapActions('news', [
      'updateNewsName'
    ]),
    ...mapActions('news', [
      'updateNewsPageStatus'
    ]),
    archiveNews(type){
      this.newsTypes = type;
      console.log('archiveNews',type)
      if (type === 'ARCHIVE'){
        this.popupTips = this.$t('knowledgeManagement.addNewsAnnounce.archiveNews')
      } else {
        this.popupTips = this.$t('knowledgeManagement.addNewsAnnounce.cancelArchiveNews')
      }
      this.$confirm(this.popupTips + '?', type === 'ARCHIVE' ?
          this.$t('knowledgeManagement.addNewsAnnounce.documentation') :
          this.$t('knowledgeManagement.addNewsAnnounce.cancelDocumentation'), {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
       this.newsFun();
      }).catch(() => {

      });
      // this.popupNews = true
    },
    unPublishNews(type){
      this.newsTypes = type;
      this.popupTips = this.$t('knowledgeManagement.addNewsAnnounce.cancelPublishNews');
      // this.popupNews = true
      this.$confirm(this.popupTips + '?', this.$t('knowledgeManagement.addNewsAnnounce.unPublish'), {
        confirmButtonText: this.$t('knowledgeManagement.addNewsAnnounce.confirmText'),
        cancelButtonText: this.$t('knowledgeManagement.addNewsAnnounce.cancelText'),
        type: 'warning'
      }).then(() => {
        this.newsFun();
      }).catch(() => {

      });
    },
    newsFun(){
      if (this.newsTypes === 'UNARCHIVE'){
        unArchiveNews([this.newDetail.id],() => {
          this.getNewsDetail(this.newDetail.id);
          this.popupTips = '';
          this.popupNews = false;
          this.newsTypes = ""
        },this)
      } else if (this.newsTypes === 'ARCHIVE'){
        archiveNews([this.newDetail.id],() => {
          this.getNewsDetail(this.newDetail.id);
          this.popupTips = '';
          this.popupNews = false;
          this.newsTypes = ""
        },this)
      } else if (this.newsTypes === 'UNPUBLISHED'){
        unPublishNews([this.newDetail.id],() => {
          this.getNewsDetail(this.newDetail.id);
          this.popupTips = '';
          this.popupNews = false;
          this.newsTypes = ""
        },this)
      }
    },
    deleteNews(){
      this.deleteNewsDialog = true;
    },
    confirmDeleteNews(){
      deleteNews([this.newDetail.id],() => {
        this.deleteNewsDialog = false;
        this.$router.go(-1);
      },this)
    },
    ...mapActions('user', [
      'updateCombinationInfo'
    ]),
    ...mapActions('user', [
      'updateCurrentVersionInfo'
    ]),
    ...mapActions('user', [
      'updatePayStatus'
    ]),
    // 获取容量和使用次数信息
    //  查询用户会员信息、流量包、空间容
    selectUserBuyAbility(){
      let clientUid = sessionStorage.getItem('_clientUid')
      this.$http.get(requestUrl.knowledge.selectUserBuyAbility + '?userId=' + clientUid).then((res) => {
        if (res.data.code == 0) {
          this.updateCurrentVersionInfo(res.data.data);
          this.getVerInfo();
        }
      })
    },
    async getVerInfo() {
      let param = {
        mainId: sessionStorage.getItem("_mainId"),
        userId: sessionStorage.getItem("_clientUid"),
        vipType:this.currentVersion.vipType,
        expire:this.currentVersion.expire,
        userSource:11
      }
      await this.$http
          .post(requestUrl.aigc.getCapacity)
          .then((res) => {
            if (res.data.code == 0) {
              this.updateCombinationInfo(res.data.data);
            } else {
              this.messageFn('error', res.data.message);
            }
          });
      this.$http
          .post(requestUrl.knowledge.getCount, param)
          .then((res) => {
            this.updateCombinationInfo(res.data);
          });

    },
    initAskAppEventSource() {
      setTimeout(() => {
        console.log("initAskAppEventSource", this.appEvt);
        this.appEvt && this.appEvt.close();
        this.appEvt = new EventSource('/knowledge-api/global/sse-emitter?userId=' + sessionStorage.getItem("_clientUid"));
        this.appEvt.addEventListener("message", (e) => {
          let resObj = JSON.parse(e.data);
          if (resObj.type === 'USER_PAY_SUCCESS') {
            console.log('支付成功');
            this.updatePayStatus('success');

          } else if (resObj.type === 'USER_PAY_FAIL') {
            console.log('支付失败');
            this.updatePayStatus('fail');
          }
        })
        this.appEvt.onerror =  (e) => {
          console.log("[MainView] 166: ", e);
          this.appEvt.close();
          this.initAskAppEventSource();
        }
      }, 2000);

      // setTimeout(() => {
      //     this.$refs.appNotice.closeUploadInfoVisible(true);
      // }, 15000);
    },
  },
  mounted() {
    this.directoryId = this.$route.query.directoryId;
    if (this.$route.query.id){
      this.getNewsDetail(this.$route.query.id)
    } else {
      this.getDirDetail();
    }
    this.selectUserBuyAbility();
    this.initAskAppEventSource();
    this.$store.dispatch('user/updateUserModeInfo',this); // 更新模版列表
  },
  beforeDestroy(){
    this.appEvt && this.appEvt.close();
    this.updateNewsPageStatus('')
    this.updateNewsName({
      name:"",
      dirName:""
    })
    this.updateNewsStatus('')
  },
  watch:{
    pageStatus:{
      handler(val){
        if (val === 'edit'){
          this.getDirDetail();
          this.$refs.templateCkeditor.editorDisabledContent = false;
        } else if (val === 'read'){
          this.$nextTick(() => {
            if (this.$refs.templateCkeditor){
              this.$refs.templateCkeditor.editorDisabledContent = true;
            }
          })
        }
      },
      immediate:true
    }
  }
};
</script>

<style scoped lang="less">
#addNewsAnnouncement{
  width: 100%;
  overflow-y: scroll;
  .pageContainer{
    width: 1000px;
    margin: 0 auto;
    height: 100%;
    //overflow-y: scroll;
    .newsTitle{
      background-color: #ffffff;
      margin-bottom: 12px;
      border-radius: 5px;
      padding: 20px;
      display: flex;
    }
    .newsCover{
      flex: none;
      margin-right: 20px;
      .avatar-uploader{
        .el-upload {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }
        .el-upload:hover {
          border-color: #409EFF;
        }
        .avatar-uploader-icon {
          color: #8c939d;
          width: 240px;
          height: 240px;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border: 1px dashed #d9d9d9;
          .el-icon-plus{
            font-size: 28px;
            //color: #366aff;
            margin-bottom: 10px;
          }
          .tips{
            margin-bottom: 6px;
          }
        }
        .avatar {
          width: 240px;
          height: 240px;
          display: block;
        }
      }
    }
    .newsSet{
      width: 100%;
      .title{
        font-size: 20px;
        color: #000000;
        max-width: 650px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
      }
      .visibility{
        display: flex;
        align-items: center;
        margin-top: 16px;
        .left{
          flex: none;
          width: 80px;
          color:#A9B3C6;
        }
        .visibilitySet{
          display: flex;
          flex-direction: column;
          width: calc(100% - 80px);
          .visibilityList{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .memberItem{
              flex: none;
              display: flex;
              align-items: center;
              font-size: 12px;
            }
            .departItem{
              display: flex;
              align-items: center;
              font-size: 12px;
              margin-right: 6px;
            }
            .avatar{
              flex: none;
              width: 31px;
              height: 31px;
              background: #366AFF;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #ffffff;
              margin-right: 6px;
              font-size: 12px;
            }
            .departAvatar{
              flex: none;
              width: 31px;
              height: 31px;
              background: #918EFF;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 1;
              i{
                color: #FFFFFF;
              }
            }
            .departName{
              flex: none;
              padding: 0 12px 0 20px;
              height: 24px;
              line-height: 24px;
              background: #E6E5FF;
              border-radius: 11px;
              //display: flex;
              //align-items: center;
              //justify-content: center;
              margin-left: -14px;
              z-index: 0;
              max-width: 140px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              iframe,.iframe{
                // width: 100%;
                height: 24px;
                line-height: 24px;
              }
              &.user-name{
                background: #366AFF;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 0;
                width: 60px;
                padding: 0;
                height: 24px;
                border-radius: 14px;
                i{
                  color: #fff;
                }
                iframe{
                  height: 24px;
                  line-height: 24px;
                  background: #366AFF;
                  color: #fff;
                  // margin-left: 6px;
                  width: 100%;
                  text-align: center;
                  body{
                    text-align: center;
                  }
                }
              }
            }
            /deep/.el-avatar{
              margin-right: 6px;
            }
          }
        }
        .permissionList{
          margin-top: 10px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          padding: 10px 15px;
          color: #606266;
          border: 1px solid #dcdfe6;
          border-radius: 4px;
          background-color: #FFFFFF;
          cursor: pointer;
          /deep/.el-tag{
            margin-right: 8px;
          }
          .permissionItem{
            display: flex;
            align-items: center;
            margin-right: 8px;
            margin-bottom: 4px;
            background-color: #E8EDF7;
            border-radius: 3px;
            padding: 0px 4px;
            font-size: 12px;
            color: #010101;
            height: 26px;
            .avatar{
              flex: none;
              width: 21px;
              height: 21px;
              background: #366AFF;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #FFFFFF;
              font-size: 12px;
            }
            .departAvatar{
              flex: none;
              width: 21px;
              height:21px;
              background: #918EFF;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 1;
              i{
                color: #FFFFFF;
                font-size: 12px;
              }
            }
            .label{
              padding: 0px 4px;
              max-width: 70px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-top: 0;
              height: 26px;
              line-height: 26px;
            }
            iframe{
              height: 22px;
              cursor: pointer;
            }
          }
          .placeholder{
            line-height: 18px;
            color: #C0C4CC;
          }
        }
      }
      .radioGroup{
        background-color: #FBFCFD;
        border-radius: 5px;
        height: 38px;
        display: flex;
        align-items: center;
        padding: 0 14px;
        width: 100%;
        box-sizing: border-box;
      }
      .rowItem{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 16px;
        .rowLeft{
          display: flex;
          align-items: center;
          flex: 0.9;
          .left{
            flex: none;
            width: 80px;
            color:#A9B3C6;
          }
          .checkBox{
            width: 100%;
          }
          .right{

          }
        }
        .readValue{
          line-height: 30px;
          display: flex;
          align-items: center;
          i{
            color: #96AFFF;
            margin-right: 10px;
          }
        }
      }
      .rowRight{
        display: flex;
        align-items: center;
        flex: none;
        width: 130px;
        justify-content: flex-end;
        .tips{
          margin-right: 6px;
          color: #616161;
        }
      }
    }
    .newsHtmlContainer{
      height: calc(100% - 320px);
    }
  }
}
</style>
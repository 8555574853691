import axios from 'axios';

// import { redirect2LoginPage } from "./redirectUri";
import { doCheckAndRestStorageInfo } from "./auth";
axios.defaults.timeout = 60000;

var instance = axios.create();

instance.interceptors.request.use(
    config => {
        doCheckAndRestStorageInfo();
        let token = sessionStorage.getItem('token');
        config.headers.common.Authorization = 'Bearer ' + token;
        config.headers.common.mainId = sessionStorage.getItem('_mainId');
        config.headers.common.uid = sessionStorage.getItem('_uid');
        if (sessionStorage.getItem('_mainId') == null || sessionStorage.getItem('_mainId') == '') {
            location.hash = "/";
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

//axios response 拦截器
instance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status === 403 || error.response.code == '5000') {
            // alert(JSON.stringify(error))
            // redirect2LoginPage();
        } else if (error.response.status == 510) {
            console.log("============== exe 510 action",window.location.href);
            location.href = process.env.VUE_APP_EXPIRED_URL;
        } else if (error.response.status == 400 && error.response.data.error == "Bad Request") {
            console.log("============== exe 400 action", error.response)
            return Promise.reject(error.response.data)
        } else {
            return Promise.reject(error)
        }
    }
);

export default instance;
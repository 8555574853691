function adapterMessage(msg, isHistory) {
  try{
    return adapterMessage_(msg, isHistory);
  }catch (e) {
    // console.warn("adapterMessage error",e);
  }

}

function adapterMessage_(msg) {
  let messages = [];
  if (
    msg.type == "user_action_to_satisfaction" ||
    msg.type == "user_action_to_unsatisfactory"
  ) {
    let content = JSON.parse(msg.content);
    messages.push({
      type: msg.type,
      content: content,
      speaker: "user",
      time: msg.time,
      sessionId: msg.sessionId,
      id: msg.id,
    });
    messages.push({
      type: msg.type,
      content: content,
      time: msg.time,
      sessionId: msg.sessionId,
      id: msg.id,
    });
  } else if (msg.type == "TEMPLATE_CARD_BUTTON") {
    messages.push({
      type: msg.type,
      content: msg.content,
      time: msg.time,
      sessionId: msg.sessionId,
      speaker: "user",
      id: msg.id,
    });
  } else if (msg.type == "IMAGE" || msg.type == "VIDEO"
    || msg.type == "USER_ACTION_TO_SATISFACTION_V2" || msg.type == "USER_ACTION_TO_DISSATISFACTION_V2") {
    messages.push({
      type: msg.type,
      content: JSON.parse(msg.content),
      time: msg.time,
      sessionId: msg.sessionId,
      speaker: "user",
      id: msg.id,
    });
  }
  else if (msg.type == "FAST_SELECT") {
    if (JSON.parse(msg.content).action.scope == 'LINK') {
      return false;
    } else {
      messages.push({
        type: "answer_text",
        content: JSON.parse(msg.content).text,
        speaker: "user",
        time: msg.time,
        sessionId: msg.sessionId,
        id: msg.id,
      });
    }
  }
  else if (msg.type == "INTELLIGENT") {
    messages.push({
      type: "answer_text",
      content: JSON.parse(msg.content).name,
      time: msg.time,
      sessionId: msg.sessionId,
      speaker: "user",
      id: msg.id,
    });
  }
  else if (
    msg.type != "user_action_to_satisfaction" &&
    msg.type != "user_action_to_unsatisfactory" &&
    msg.type != "TEMPLATE_CARD_BUTTON" &&
    msg.type != "VALUE_CONFIRM" &&
    msg.type != "FORM_SUBMIT" &&
    msg.type != "IMAGE" &&
    msg.type != "VIDEO" &&
    msg.type != "FEEDBACK" &&
    msg.type != "START_SESSION" && msg.type != "END_SESSION" &&
    msg.type != "GOTO" && msg.type != "FAST_SELECT"
  ) {
    let cText = "";
    if(msg.type == "RADIO"){
      if (msg.content.split("_").length === 4) {
        cText = msg.content.split("_")[msg.content.split("_").length - 1];
      } else {
        cText = msg.content.split("_")[msg.content.split("_").length - 2];
      }
    }else {
      cText = msg.content;
    }
    messages.push({
      type: "answer_text",
      content: cText,
      speaker: "user",
      time: msg.time,
      sessionId: msg.sessionId,
      id: msg.id,
    });
  }
  for (let len = messages.length-1;len>=0;len--){
    messages[len].send_status = msg.send_status;
  }
  // console.debug('adapterMessage',msg);
  return messages;
}


function adapterMessageForPhoneNum(item) {
  switch (item.type) {
    case "answer_card_template":
      if (item.content.cards) {
        item.content.cards.forEach((cell) => {
          if (cell.content.htmlList) {
            cell.content.htmlList.forEach((cellI) => {
              if (cellI.list) {
                cellI.list.forEach(
                  (cellIn, cellInIndex) => {
                    cellI.list[
                      cellInIndex
                    ] = replacePhone(
                      cellIn
                    );
                  }
                );
              }
            });
          }
          cell.content.htmlList = [
            ...cell.content.htmlList,
          ];
        });
      }

      break;
    case "answer_text":
      item.content = replacePhone(item.content);
      break;
    case "answer_rich_text":
      item.content.html = replacePhone(
        item.content.html
      );
      break;
    case "user_action_to_satisfaction":
      item.content.reply_text = replacePhone(
        item.content.reply_text
      );
      break;
    case "user_action_to_unsatisfactory":
      item.content.reply_text = replacePhone(
        item.content.reply_text
      );
      break;
    case "action_satisfaction":
      item.content.satisfactionDes = replacePhone(
        item.content.satisfactionDes
      );
      break;
    case "answer_voice":
        item.content.extInfos.forEach(item => {
          item.avCurrentTime = new Date().getTime() + Math.floor( Math.random() * 10001)
        })
        break;
    default:
      break;
  }
  return item;
}

function replacePhone(str) {
  if (str == null) {
    return null;
  }
  // 座机 移动电话
  let phone = str.match(/\d{3}-\d{7,8}|\d{4}-\d{7,8}/g) || [];

  let mphone = "";
  mphone = str.match(/1(\d{2})\d{4}(\d{4})/g) || [];

  let arr = phone.concat(mphone);
  // 去重
  let set = new Set(arr);
  let newArr = Array.from(set);
  // 替换
  let isWin =
    navigator.platform == "Win32" ||
    navigator.platform == "Windows";

  !isWin &&
    newArr.forEach((item) => {
      str = formStr(str, item);
    });
  return str;
}
function formStr(str, subStr) {
  let result = [];
  let start = 0;
  let end = str.length;
  while ((end = str.indexOf(subStr)) != -1) {
    // result.push(str.substring(start,end));
    let endPos = end + subStr.length;
    if (
        !/[0-9@]/g.test(
            str.substring(end > 0 ? end - 1 : end, end)
        ) &&
        !/[a-zA-Z0-9@]/g.test(
            str.substring(
                endPos,
                endPos + 1 > str.length ? str.length : endPos + 1
            )
        )
    ) {
      result.push(str.substring(start, end));
      result.push("<a style='color:blue;text-decoration:underline' href=tel:");
      result.push(subStr);
      result.push(">");
      result.push(subStr);
      result.push("</a>");
    } else {
      result.push(str.substring(start, end + subStr.length));
    }
    str = str.substring(end + subStr.length, str.length);
  }
  result.push(str);
  return result.join("");
}

export { adapterMessage, adapterMessageForPhoneNum, replacePhone };

<template>
    <div id="delete-dialog">
        <div class="delete-dialog-container">
            <div id="delete-popup-assembly">
                <div class="popup-header">
                    <i class="iconfont guoran-a-18-16"></i>
                </div>
                <slot class="popup-container" name="popup-container">

                </slot>
                <div class="add-automtated-closeBtn" @click="closeEvent">
                    <i class="arsenal_icon arsenalcuo1"></i>
                </div>
                <div  class="dialog-footer">
                    <slot name="dialog-footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "deleteDialog",
        data(){
            return{
                header:'删除客户'
            }
        },
        props:['content'],
        methods:{
            closeEvent(){
                this.$emit('closeEvent')
            }
        }
    }
</script>

<style scoped lang="less">
#delete-dialog{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 3000;
    /*display: flex;
    align-items: center;
    justify-content: center;*/
    animation: dialog-fade-in 0.2s ease-in;
    /deep/.el-button{
        padding: 8px 30px!important;
    }
    .delete-dialog-container{
        position: relative;
        margin:  0 auto;
        margin-top:30vh;
        #delete-popup-assembly{
            background-color: white;
            border-radius: 5px;
            position: relative;
            margin:  0 auto;
            width: 480px;
            .popup-header{
                padding: 30px 64px 26px 64px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                .guoran-a-18-16{
                    font-size: 50px;
                    color: #FE5965;
                }
                .popup-tip{
                    font-size: 14px;
                    color: #A9B3C6;
                    padding-top: 18px;
                }
            }
            .popup-container{
                overflow-y: hidden;
                padding: 0 64px 30px 64px;
                text-align: center;
                .delete-title{
                    color: #000000;
                    text-align: center;
                    font-size: 16px;
                    margin-bottom: 12px;
                }
                .prompt-text{
                    color: #FE5965;
                    text-align: center;
                }
                .popup-con{
                    /*font-size: 14px;*/
                    color: #616161;
                    /deep/.order-field-list{
                        display: flex;
                        flex-wrap: wrap;
                    }
                    /deep/.field-item-class:nth-child(1){
                        .field-name{
                            padding-left: 0px!important;
                        }
                        .el-cascader{
                            margin-left: 0px!important;
                        }
                        .el-select{
                            margin-left: 0px!important;
                        }
                    }
                    /deep/.field-item-class{
                        .el-cascader{
                            line-height: 0;
                        }
                    }
                    /deep/.field-item-class:nth-child(2){
                        .field-name{
                            padding-left: 30px;
                            line-height: 20px;
                        }
                        .el-cascader{
                            margin-left: 30px;
                        }
                        .el-select{
                            margin-left: 30px;
                        }
                    }
                    /*/deep/.field-item-class:nth-child(even){
                        .field-name{
                            padding-left: 30px;
                            line-height: 20px;
                        }
                        .el-cascader{
                            margin-left: 30px;
                        }
                        .el-select{
                            margin-left: 30px;
                        }
                    }*/
                    /deep/.radio-class{
                        background: #FBFCFD;
                        border-radius: 5px;
                        padding-left: 12px;
                        min-height: 40px;
                        display: flex;
                        align-items: center;
                    }
                    /deep/.field-name{
                        padding-bottom: 8px;
                        line-height: 20px;
                    }
                    /deep/.field-item-class{
                        padding-bottom: 20px;
                        width:calc(50% - 30px);
                        padding-right: 30px;
                    }
                    /deep/.field-item-class-c{
                        width: 100%;
                        padding-bottom: 20px;
                    }
                }

            }
            .popup-container-c{
                padding: 0 64px;

                .popup-con-c{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .popup-con-panel{
                        background: #FFFFFF;
                        border: 1px solid #E0E6F7;
                        border-radius: 5px;
                        width: 248px;
                        flex: none;
                        position: relative;

                        .popup-con-panel-title{
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: 1px solid #E0E6F7;
                            border-top-left-radius: 5px;
                            border-top-right-radius: 5px;
                        }
                        .left-title{
                            background-color:#F5F7FB ;
                        }
                        .right-title{
                            background-color:#759FFF ;
                            color: white;
                        }
                        .popup-con-panel-con{
                            height: calc(84vh - 300px);
                            min-height: 340px;
                            overflow-y: scroll;
                            overflow-x: hidden;
                            padding: 20px 0;
                            #filterResort{
                                padding-bottom: 30px;
                            }
                            .popup-con-panel-con-item{
                                display: flex;
                                height: 38px;
                                align-items: center;
                                justify-content: space-between;
                                padding:0 20px;
                                /* font-size: 14px;*/
                                color: #616161;
                                .iconfont{
                                    display: none;
                                }
                                .handle{
                                    cursor: move;
                                }
                            }
                            .popup-con-panel-con-item:hover{
                                background-color:#F6F8FD;
                                .iconfont{
                                    display: block;
                                    color: #366AFF;
                                    cursor: pointer;
                                }
                            }
                        }
                        .popup-con-panel-tip{
                            position: absolute;
                            bottom: 0;
                            height:38px;
                            width: 100%;
                            background: #F6F8FD;
                            border-radius: 3px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #616161;
                            font-size: 12px;
                        }
                    }
                }


            }
            .add-automtated-closeBtn{
                width: 30px;
                height: 30px;
                background: #FFFFFF;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.21);
                border-radius: 50%;
                position: absolute;
                right: -8px;
                top: -10px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                i{
                    font-size: 18px;
                    color: #616161;
                }
            }
            .dialog-footer{
                padding-bottom: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                .cancel-btn{
                    min-width: 99px;
                    height: 35px;
                    background: #FFFFFF;
                    border: 2px solid #E0E6F7;
                    border-radius: 19px;
                    padding: 0 !important;
                    line-height: 31px;
                    border-radius: 19px;
                    font-size: 14px;
                }
                .confirm-btn{
                    height: 35px;
                    min-width: 99px;
                    background: #366AFF;
                    padding: 0 !important;
                    line-height: 31px;
                    border-radius: 19px;
                    font-size: 14px;
                    &.is-loading{
                        border-color: #7aa3ff;
                    }
                }
            }
        }
    }
}
</style>
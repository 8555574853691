const state = () => ({
    chatBased:{
        name:""
    }
})
const getters = {
    getChatBased:(state) => {
        return state.chatBased
    },
}

const actions = {
    updateChatBased({ commit }, chatBased) {
        commit("setChatBased", chatBased);
    },
}

const mutations = {
    setChatBased(state,based){
        state.chatBased = based
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
import { render, staticRenderFns } from "./elSelectTree.vue?vue&type=template&id=6490c89c&scoped=true"
import script from "./elSelectTree.vue?vue&type=script&lang=js"
export * from "./elSelectTree.vue?vue&type=script&lang=js"
import style0 from "./elSelectTree.vue?vue&type=style&index=0&id=6490c89c&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6490c89c",
  null
  
)

export default component.exports
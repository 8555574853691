
const state = () => ({
    isShowTestWindow:'', // 'success' 成功   'fail' 失败   '' 初始值
})

const getters = {
    getIsShowTestWindow: (state) => {
        return state.isShowTestWindow;
    },
}

const actions = {
    updateIsShowTestWindows({ commit }, isShowTestWindow) {
        commit("setIsShowTestWindow", isShowTestWindow);
    },
    
}

const mutations = {
    setIsShowTestWindow(state, isShowTestWindow) {
        state.isShowTestWindow = isShowTestWindow;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
const state = () => ({
    knowledgeDetail:{
        status:"UNPUBLISHED",
        name:"",
        dirName:"",
        currentVersion:"",
        previousPubVersion:{
            state:"",
            no:""
        },
        pendingApproval:false,
        managePermission:[],
        editPermission:[]
    },
    showDownloadBtn:false,
    textWatermarkStr:"",
    fullScreen:false,
    isReadStatus:true,
    previousEditVersion:null
})

const getters = {
    getKnowledge: (state) => {
        return state.knowledgeDetail;
    },
    getDownLoadBtn:(state) => {
        return state.showDownloadBtn;
    },
    getTextWatermarkStr:(state) => {
        return state.textWatermarkStr;
    },
    getFullScreen: (state) => {
        return state.fullScreen
    },
    getDocumentStatus:(state) => {
        return state.isReadStatus
    },
    getPreviousEditVersion:(state) => {
        return state.previousEditVersion
    }
}

const actions = {
    updateKnowledge({ commit }, knowledge) {
        commit("setKnowledge", knowledge);
    },
    updateDownLoadBtn({ commit }, downLoad) {
        commit("setDownLoadBtn", downLoad);
    },
    updateTextWatermarkStr({ commit }, textValue) {
        commit("setTextWatermarkStr", textValue);
    },
    updateFullScreen({ commit }, fullScreen) {
        commit("setFullScreen", fullScreen);
    },
    updateDocumentStatus({ commit },status){
        commit('setDocumentStatus',status)
    },
    updatePreviousEditVersion({ commit },previousVersion){
        commit('setPreviousEditVersion',previousVersion)
    }
}

const mutations = {
    setKnowledge(state, knowledge) {
        state.knowledgeDetail = knowledge;
    },
    setDownLoadBtn(state,downLoadBtn){
        state.showDownloadBtn = downLoadBtn;
    },
    setTextWatermarkStr(state,textValue){
        state.textWatermarkStr = textValue;
    },
    setFullScreen(state, fullScreen) {
        state.fullScreen = fullScreen
    },
    setDocumentStatus(state,status){
        state.isReadStatus = status;
    },
    setPreviousEditVersion(state,previousVersion){
        state.previousEditVersion = previousVersion
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
<template>
	<div :class="['ratel-ckeditor-page','ratel-ckeditor-title']">
		<ckeditor 
			:class="['ratel-ckeditor',]"
			:editor="editorTitle" 
			:disabled="editorDisabledTitle"
			v-model="ckeditorValueTitles" 
			@ready="ckeditorReadyTitle" 
			@blur="ckeditorBlurTitle"
			@focus="ckeditorFocusTitle"
			:config="editorConfigTitle" 
			@input="changeDataTitle">
		</ckeditor>
	</div>
</template>

<script>
/* eslint-disable */
import BalloonEditor from 'ckeditor';
import { requestUrl } from "@/http/requestUrl";

export default {
	data(){
		return {
			editorTitle: BalloonEditor,
			editorConfigTitle:{
				placeholder: "标题",
				blockToolbar: [],
				toolbar: {
					items: []
				},
		    },
			currentEditor: null, // 当前富文本对象
		}
	},

	props: {
		ckeditorValueTitle: {
			type: String,
			default: ""
		},
		editorDisabledTitle: {
			type: Boolean,
			default: false // 是否开始只读模式
		},
		toolbarConfigTitle:{
			type: Object,
			default(){
				return {}
			}
		},
		ckeditorType:{
			type:String,
			default:""
		}
	},
	computed:{
		ckeditorValueTitles: {
			get() {
				return this.ckeditorValueTitle
			},
			set(value) {
				this.$emit('update:ckeditorValueTitle', value)
				// this.ckeditorValueTitle = value
			}
		}
	},
	created(){
		// 当前行为空时
		// this.editorConfig.blockToolbar = [
		// 	'heading1',
		// 	'heading2',
		// 	'heading3', 
		// 	'numberedList',
		// 	'bulletedList',
		// 	'todoList',
		// 	'link',
		// 	'insertUploadImgButton',
		// 	'insertUploadFileButton',
		// ]
		console.log(this.toolbarConfigTitle);
        this.editorConfigTitle.placeholder = this.toolbarConfigTitle.placeholder;
        this.editorConfigTitle.blockToolbar = this.toolbarConfigTitle.blockToolbar;;
        this.editorConfigTitle.toolbar.items = this.toolbarConfigTitle.toolbar.items;
		
	},
	mounted(){
		
	},
	methods: {
		ckeditorReadyTitle(editor){
			this.currentEditor = editor;
			let dom = null
			if(this.ckeditorType === 'normal'){
				dom = document.querySelector('.ask-template-ckeditor_left .ratel-ckeditor-title .ratel-ckeditor');
			} else {
				dom = document.querySelector('.save-template-popup .ratel-ckeditor-title .ratel-ckeditor')
			}
			dom.onkeydown = (e) => {
				// 用户按下Esc
				if (e.code === 'Escape'){
				} else if (e.key === 'Enter'){
					this.$emit('ckeditorEvent','Enter');
					return false
				} 
            }
			// 在 editor ready 时建立对文本的 监听 粘贴事件，处理 特殊类型
			this.currentEditor.editing.view.document.on('clipboardInput', ( evt,data ) => {
				
				const dataTransfer = data.dataTransfer;
				let rtfContent = '';
				if(dataTransfer.getData('text/html')) {
					rtfContent = dataTransfer.getData('text/html')
					rtfContent = rtfContent.replace( /<!--[\s\S]*?-->/gmi, '')
					rtfContent = rtfContent.replace(/ style=".*?"/g, '')
					rtfContent = rtfContent.replace(new RegExp("<br>",'g'),'')
					// if(rtfContent.includes('div.Section0{page:Section0;}')) {
						let viewFragment = this.currentEditor.data.processor.toView(rtfContent);
						let modelFragment = this.currentEditor.data.toModel( viewFragment );
						this.currentEditor.model.insertContent( modelFragment );
						evt.stop()
					// }
				} else if(dataTransfer.getData('text/plain')) {
					rtfContent = dataTransfer.getData('text/plain')
				} else {
					if(dataTransfer._files.length > 0) {
						let fileList = dataTransfer._files
						for (let index = 0; index < fileList.length; index++ ) {
							const file = fileList[index];
							if(!file.type.includes('image')) {
								this.upload(file).then(res => {
									this.$http.post(requestUrl.knowledge.getTemporaryCertificate,{fileInOssPath:res.default}).then((urlRes) => {
										let url = urlRes.data.data;
										let command = this.currentEditor.commands.get("insertAskComponent");
										let openUrl = process.env.VUE_APP_NODE_ENV == 'production' ? 'https://kkfileview.askbot.cn/onlinePreview?url=' + url : 'https://test.open.askbot.cn/kkfileview/onlinePreview?url=' + url;
										let iframeUrl = 'https://portal.test.askbot.cn/#/viewFile?fileUrl=' + openUrl + '&fileName=' + res.name
										// 需要回调通知父组件 上传成功
										command.execute({
											tag: "iframe",
											options: {
												width:"100%",
												height:'400px',
												customaryurl:res.default,
												src: iframeUrl,
												filename: res.name,
											},
										});
									})
								})
							}
						}
					}
				}
				let str = rtfContent.replace(/ style=".*?"/g, '')
				let str2 =  str.replaceAll('&lt;br/&gt;', '<p>')
				console.log(str2,'str2');
				data.content = this.currentEditor.data.htmlProcessor.toView(str2)
				// let viewFragment2 =this.currentEditor.data.htmlProcessor.toView(str2);
				// let modelFragment2 = this.currentEditor.data.toModel( viewFragment2 );
				// data.content = modelFragment2;
			})
		},
		ckeditorBlurTitle(){
			console.debug('失去焦点');
		},
		// 富文本聚焦事件
		ckeditorFocusTitle(zh, editor){
			
			console.debug(zh, editor);
		},
		// 富文本的值发生改变时
		changeDataTitle(value) {
			
			this.$emit('this.hideAigc();','title')
		},
		hideAigc(){
			if(this.ckeditorValues.includes('<span style=')) {
				let value = this.ckeditorValues.replace(/style=\"(.*)\"/gi, ' ')
				console.log(value,'value');
				this.currentEditor.setData(value)
			}
		},
		
	}
}
</script>

<style lang="less" scoped>
@import "./less/ratelCkeditor";
</style>
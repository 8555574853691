<template>
    <div id="weboffice-container"></div>
  </template>
  
  <script>
  // 引入 WebOffice SDK
  import WebOfficeSDK from '../assets/js/web-office-sdk-solution-v2.0.6.es';
  import { mapGetters } from 'vuex'
  import { v4 as uuidv4 } from "uuid";
  export default {
    name: 'WebOffice',
    props: {
      officeType: {
        type: String,
        required: true,
      },
      fileId: {
        type: String,
        required: true,
      },
      userId: {
        type: String,
        default: '',
      },
      currentVersion: {
        type: String,
        required: true,
      },
      editType:{
        type: String,
        default: '',
      },
      knowledgeDetail:{
        type:Object,
        default: null
      },
      previousVersion:{
        type:Object,
        default: null
      }
    },
    data(){
      return{
        instance:null
      }
    },
    computed:{
      ...mapGetters('knowledge',{
        isReadStatus:"getDocumentStatus"
      }),
      ...mapGetters('knowledge', {
        knowledgeInfo: 'getKnowledge'
      }),
    },
    mounted() {
      this.initializeWebOffice();
    },
    methods: {
      initializeWebOffice() {
        const token = localStorage.getItem('_token')
        console.log("initializeWebOffice-token:",token)
        console.log("initializeWebOffice-currentVersion:",this.officeType,this.currentVersion,this.editType)
        let officeType;
        switch (this.officeType) {
            case 'Writer':
                officeType = WebOfficeSDK.OfficeType.Writer;
                break;
            case 'Spreadsheet':
                officeType = WebOfficeSDK.OfficeType.Spreadsheet;
                break;
            case 'Presentation':
                officeType = WebOfficeSDK.OfficeType.Presentation;
                break;
            case 'Pdf':
                officeType = WebOfficeSDK.OfficeType.Pdf;
                break;
            case 'Otl':
                officeType = WebOfficeSDK.OfficeType.Otl;
                break;
            case 'Dbt':
                officeType = WebOfficeSDK.OfficeType.Dbt;
                break;
            case 'KSheet':
                officeType = WebOfficeSDK.OfficeType.KSheet;
                break;
            default:
                console.error('Unsupported office type:', this.officeType);
                return;
        }

        let uuid = uuidv4()
        console.log("officeType:", officeType,uuid);
        console.log("this.currentVersion:",this.currentVersion)
        console.log("this.previousVersion:",this.previousVersion)
        console.log("this.editType:",this.editType)
        // 拼接 currentVersion 到 fileId 后面，确保不同版本也能有不同的文档ID号
        const versionedFileId = `${this.fileId}_v${this.currentVersion}`;
        console.log("versionedFileId:", versionedFileId);
        this.instance = WebOfficeSDK.init({
          officeType: officeType,
          appId: 'SX20231114GCCZYP', //测试环境
          fileId: versionedFileId,
          headers: {
                'X-WebOffice-Token': token // 将 token 作为请求头传递
          },
          customArgs: {
            userID: sessionStorage.getItem('_uid'),
            currentVersion: this.currentVersion,
            knowledgeOwnerType: 'enterprise',
            type:this.editType,
            previousVersion:this.previousVersion ? this.previousVersion.version : null,
            upgradedVersion:this.knowledgeInfo.currentVersion
          },
        });
        if (this.isReadStatus){
          this.instance.destroy();
          return;
        }
        // 监听文档打开事件
        this.instance.on('fileOpen', (data) => {
          console.log('文档打开成功', data);
        });

        this.instance.on('fileStatus',(data) => {
          if (data.status == 1 || data.status == 7){
            this.updateDocumentOssPath()
          }
        })
        // 监听其他事件（如需要）
        this.instance.on('error', (err) => {
          console.error('发生错误', err);
        });

      },
      updateDocumentOssPath(){
        this.$emit('updatePreviousVersion','updateOssPath')
      },
    },
    destroyed() {
      this.instance.destroy();
    }
  };
  </script>
  
<template>
    <!-- eslint-disable -->
    <div class="pdf_view" ref="pdfView">
        
        <!-- <iframe :src="perviewUrl" frameborder="0"></iframe> -->
        <div id="pagination">
            <div class="rect-btn submit_btn">
                <!-- <section class="rect-get-btn" @click="checkText">切换文字层级</section> -->
                <section
                    class="rect-get-btn"
                    @click="setRectParma('add')">
                    保存当前标注信息
                </section>
            </div>
            <el-pagination :current-page="currentPage + 1" @current-change="currentChange" @prev-click="prev"
                    @next-click="next" layout="slot, prev, pager, next, jumper" :page-size="1" :total="withLocationMark.pageInfos.length">
            </el-pagination>
        </div>
        <div class="fabricDetail" v-if="fabricDetailFlag">
            <div class="empty" v-if="Object.keys(selectedRect).length == 0">
                <img src="../assets/images/empty.png" alt="">
                <p>当前暂无选中区域，请在右侧标注</p>
            </div>
            <div class="rect-select"  v-else>
                <div class="rect-head">
                    <span>标注</span>
                    <span>双击可删除标注信息</span>
                    <!-- <i class="iconfont guoran-tongyichicun-18-13-shanchu" @click="deleteRect"></i> -->
                </div>
                <div class="rect-content" >
                    <div class="rect-card">
                        <p>标注类型</p>
                        <el-select style="width:100%" v-model="rectParma.type" @change="changeRect" placeholder="请选择">
                            <el-option value="TEXT" label="文本"></el-option>
                            <el-option value="TABLE" label="表格"></el-option>
                            <el-option value="IMAGE" label="图片"></el-option>
                            <el-option value="FLOWCHART" label="流程图"></el-option>
                        </el-select>
                    </div>
                    <div class="rect-btn">
                        <section 
                            class="rect-get-btn" 
                            v-if="rectParma.data && Object.keys(rectParma.data).length == 0" 
                            @click="getRectParma('TEXT')">
                            提取文字
                        </section>
                        <section 
                            class="rect-get-btn"
                            v-if="rectParma.data && Object.keys(rectParma.data).length == 0 && rectParma.type == 'TABLE'" 
                            @click="getRectParma('TABLE')">
                            提取表格
                        </section>
                        <section
                            class="rect-get-btn" 
                            @click="getRectParma('TEXT')">
                            重新提取文字
                        </section>
                        <section
                            class="rect-get-btn"
                            v-if="rectParma.type == 'TABLE'"
                            @click="getRectParma('TABLE')">
                            重新提取表格
                        </section>
                    </div>
                    <div class="rect-card" v-if="Object.keys(rectParma.data).length !== 0 && (rectParma.type == 'TEXT' || rectParma.data.originText)">
                        <p>原始数据</p>
                        <el-input
                            type="textarea"
                            :rows="5"
                            placeholder="请输入内容"
                            @change="changeRect"
                            v-model="rectParma.data.originText">
                        </el-input>
                    </div>
                    <div class="rect-card" v-if="Object.keys(rectParma.data).length !== 0 && rectParma.data.table">
                        <p>原始数据</p>
                        <!-- <el-table
                            :data="rectParma.data.table"
                            style="width: 100%">
                            <el-table-column
                                prop="date"
                                v-for="(column, index) in rectParma.data.table[0]"
                                :key="index"
                                >
                                <template slot-scope="scope">
                                    <span >{{ scope }}</span>
                                </template>
                            </el-table-column>
                        </el-table> -->
                        <table class="table">
                            <tr>
                                <td>
                                    <el-tooltip class="item" effect="dark" content="在首行插入" placement="top-start">
                                        <i class="iconfont guoran-tianjiadao" @click="changeTable(0,'row', 'add')"></i>
                                    </el-tooltip>
                                    <el-tooltip class="item" effect="dark" content="在首列插入" placement="top-start">
                                        <i class="iconfont guoran-tianjiadao" @click="changeTable(0, 'col', 'add')"></i>
                                    </el-tooltip>
                                </td>
                                <td v-for="(column,columnIndex) in rectParma.data.table[0]" :key="columnIndex">
                                    <el-tooltip class="item" effect="dark" content="删除当前列" placement="top-start">
                                        <i class="iconfont guoran-tongyichicun-16-09-shanchu2" @click="changeTable(columnIndex, 'col', 'del')"></i>
                                    </el-tooltip>
                                    <el-tooltip class="item" effect="dark" content="在当前列插入" placement="top-start">
                                        <i class="iconfont guoran-tianjiadao"  @click="changeTable(columnIndex + 1, 'col', 'add')"></i>
                                    </el-tooltip>
                                </td>
                            </tr>
                            <tr v-for="(rows, rowsIndex) in rectParma.data.table" :key="rowsIndex">
                                <td>
                                    <el-tooltip class="item" effect="dark" content="删除当前行" placement="top-start">
                                        <i class="iconfont guoran-tongyichicun-16-09-shanchu2" @click="changeTable(rowsIndex, 'row', 'del')"></i>
                                    </el-tooltip>
                                    <el-tooltip class="item" effect="dark" content="在当前行插入" placement="top-start">
                                        <i class="iconfont guoran-tianjiadao" @click="changeTable(rowsIndex + 1, 'row', 'add')"></i>
                                    </el-tooltip>
                                </td>
                                <td v-for="(column,columnIndex) in rows" :key="columnIndex">
                                    <el-input @change="changeRect" v-model="rows[columnIndex]" size="mini"></el-input>
                                </td>
                            </tr>
                            
                        </table>
                    </div>
                    <div class="rect-card" v-if="Object.keys(rectParma.data).length !== 0">
                        <p>标注描述</p>
                        <el-input
                            type="textarea"
                            :rows="5"
                            placeholder="请输入内容"
                            @change="changeRect"
                            v-model="rectParma.data.indexText">
                        </el-input>
                    </div>
                    <div class="rect-card" v-if="Object.keys(rectParma.data).length !== 0">
                        <p>标注颜色</p>
                        <div class="rect-color">
                            <section v-for="color in colorList" :key="color" 
                                :class="color == rectParma.color ? 'active' : ''" 
                                :style="{background: color}"
                                @click="changeColor(color)"
                            ></section>
                        </div>
                    </div>
             
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import _ from 'lodash'
// import * as pdfjsLib from 'pdfjs-dist'
// pdfjsLib.GlobalWorkerOptions.workerSrc = 'pdfjs-dist/build/pdf.worker';
// import { TextLayerBuilder } from "pdfjs-dist/web/pdf_viewer";
const pdfjsLib = window['pdfjsLib']
if(pdfjsLib) {
    pdfjsLib.GlobalWorkerOptions.workerSrc = window['pdfjs-dist/build/pdf.worker'] 
    // 'pdfjs-dist/build/pdf.worker';
}
const { TextLayerBuilder } = window['pdfjs-dist/web/pdf_viewer']
import { fabric } from 'fabric'
import { v4 as uuidv4 } from "uuid";
// EventBus pdf_viewer 支持绑定自定义事件，一版不做
// import 'pdfjs-dist/web/pdf_viewer.css'
export default {
    name: 'pdfView',
    props: ['tagIds','withLocationMark'],
    data () {
        return {
            url: '',
            // pdfjsLib: window['pdfjs-dist/build/pdf'],
            // pdfjsLib: pdfjsLib,
            pages: [],
            pageLoadStatus: {
                WAIT: 0,
                LOADED: 1,
            },
            scale: 1,
            rotation: 0,
            pageSize: {},
            PAGE_INTVERVAL: 15,
            SLICE_COUNT: 5,
            contentView: null,
            fisrtLoad: true,
            // TextLayerBuilder: null,
            totalPageCount: 0,
            identifyTextPostion: {
                top: 0,
                left: 0,
                width: 100,
                height: 0,
                page: 1,
                pageHeight: 0,
                pageWidth: 0,
                extractInfo: {},
                currentPageAllLine: []
            },
            currentPageAllLine: [],
            pdfUrl: '',
            cachePdf: [],
            newViewer: null,
            currentPage: 0,
            changetoolbar: false,
            allTr:[],
            preViewType: 'pdf',
            mark:false,
            canvasFabric:[],
            fabricDetailFlag:false,
            selectedRect: {},
            rectParma: {
                type:'TEXT',
                color:'#366AFF',
                data:{}
            },
            colorList:['#366AFF', '#FFFF09', '#FD595F', '#FC7306', '#25B356'],
            rectDomList:[],
            markPageCopy:[],
            dataTime: null,
            debounClickTime: null,
            loadingSet:false
        }
    },
    methods: {
        getpdfResloutePage (pdfResloute) {
            // 根据当前页面宽度设置缩放比例
            this.scale = this.$refs.pdfView.clientWidth / pdfResloute.pageWidth
            // if(this.withLocationMark) {
                // this.scale = 1
            // }
            // 从后端获取到当前分片后所有的pdf页码，初始化数组，数组下{} 对应每页pdf文件
            this.pdfUrl = pdfResloute.publicPageFileUrl.substring(0, pdfResloute.publicPageFileUrl.lastIndexOf('/') + 1)
            this.initPages(pdfResloute.total)
            // 定位功能，加载对应页码位置
            this.loadPdfData(pdfResloute.page)
        },
        async loadPdfData (loadPage) {
            // pdfjsLib.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/legacy/build/pdf.worker.entry.js");
            // 拿到第一个分片
            const { startPage, url } = await this.fetchPdfFragment(loadPage);
            let loadingTask = pdfjsLib.getDocument(url)
            loadingTask.promise.then((pdfDoc) => {
                // 将已经下载的分片保存到 pages 数组中
                for (let i = 0; i < pdfDoc.numPages; i += 1) {
                    const pageIndex = startPage + i;
                    const page = this.pages[0];
                    this.pages[0].url = url
                    // 不在缓存列表内，重新获取本页pdf
                    if (page.loadStatus !== this.pageLoadStatus.LOADED) {
                        pdfDoc.getPage(i + 1).then((pdfPage) => {
                            page.pdfPage = pdfPage;
                            page.loadStatus = this.pageLoadStatus.LOADED;
                            // 通知可以进行渲染了
                            this.startRenderPages(pdfPage, page, pageIndex)
                        });
                    } else {
                        if (this.changetoolbar) {
                            this.$nextTick(() => {
                                this.renderHighlights()
                            })
                            this.changetoolbar = false
                        }
                    }
                    // console.log(this.withLocationMark.pageInfos[], pageIndex - 1);
                }
            });

        }, 
        initPages (totalPage) {
            this.totalPageCount = totalPage
            this.pages = [{
                loadStatus: this.pageLoadStatus.WAIT,
                pdfPage: null,
                url:'',
                dom: null,
                c:null,
            }]
        },
        async fetchPdfFragment (pageIndex) {
            // 置换加签后的文件地址。
            let obj = {}
            await this.$http.post(
                '/knowledge-api/temporary-certificate/or-origin?expired=30',
                this.pdfUrl + pageIndex + '.pdf',
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }).then(async res => {
                    if (res.data) {
                        // 最后返回一个 包含这4个参数的对象
                        obj = await {
                            "startPage": pageIndex, // 分片的开始页码
                            "endPage": pageIndex + 5, // 分片结束页码
                            "totalPage": this.totalPageCount, // pdf 总页数
                            "url": res.data // 分片内容下载地址
                        }
                    }
                })
            return obj
        },
        startRenderPages (pdfPage, page, pageIndex) {
            const viewport = pdfPage.getViewport({
                scale: this.scale, // 缩放的比例
                rotation: this.rotation, // 旋转的角度
            });
            // 记录pdf页面高度
            const pageSize = {
                width: viewport.width,
                height: viewport.height,
            }
            this.pageSize = pageSize
            // 创建内容绘制区，并设置大小
            this.contentView.style.width = `${pageSize.width}px`;
            this.contentView.style.height = `${pageSize.height}px`;
            this.contentView.style.margin = '0 auto 0'
            this.contentView.style.position = 'relative'
            this.contentView.setAttribute('id','contentView')
            // contentView.style.overflowY = 'auto'
            this.$refs.pdfView.appendChild(this.contentView);
            this.renderPages(pageIndex)
        },
        renderPageContent (page, pageIndex) {
            const { pdfPage, pageNo, dom } = page;
            // dom 元素已存在，无须重新渲染，直接返回
            if (dom && dom.children.length != 0) {
                return;
            }
            const viewport = pdfPage.getViewport({
                scale: this.scale,
                rotation: this.rotation,
            });
            // 创建新的canvas
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.height = this.pageSize.height;
            canvas.width = this.pageSize.width;
            const c = document.createElement('canvas');
            c.width = this.pageSize.width
            c.height = this.pageSize.height 
            c.setAttribute('id', `canvas${pageIndex}`);
            c.setAttribute('class', 'fabricCanvas');
            // 创建渲染的dom
            const pageDom = document.createElement('div');
            pageDom.style.position = 'absolute';
            pageDom.style.top = `${((pageNo - 1) * (this.pageSize.height + this.PAGE_INTVERVAL)) + this.PAGE_INTVERVAL}px`;
            pageDom.style.width = `${this.pageSize.width}px`;
            pageDom.style.height = `${this.pageSize.height}px`;
            pageDom.appendChild(c)
            pageDom.appendChild(canvas);
            // 渲染内容
            let renderContext = {
                canvasContext: context,
                viewport: viewport,
            }
            pdfPage.render(renderContext).promise.then(() => {
                console.log(pdfPage.getTextContent(), 'getTextContent');
                return pdfPage.getTextContent()
            }).then((textContent) => {
                const textLayerDiv = document.createElement('div');
                textLayerDiv.setAttribute('class', 'textLayer');
                // 将文本图层div添加至每页pdf的div中
                // 创建新的TextLayerBuilder实例
                let textLayer = new TextLayerBuilder({
                    textLayerDiv: textLayerDiv,
                    pageIndex: pdfPage._pageIndex,
                    viewport: viewport,
                });
                // let findPage = this.currentPageAllLine.find(l => { return l.page == pageIndex })
                // if (findPage) {
                //     let AllLines = findPage.allLines
                //     // setTimeout(() => {
                //     if (AllLines.length > 0) {
                //         for (let j = 0; j < AllLines.length; j++) {
                //             let lines = AllLines[j].lines
                //             let rectdom = document.createElement('div')
                //             rectdom.setAttribute('react-count', AllLines[j].pageCount);
                //             rectdom.style.position = 'absolute';
                //             rectdom.style.top = 0
                //             rectdom.style.left = 0
                //             rectdom.classList.add('rectdom')
                //             for (let index = 0; index < lines.length; index++) {
                //                 if (!/^\s+$/g.test(lines[index].content)) {
                //                     let postionArr = lines[index].location
                //                     let div = document.createElement('div')
                //                     div.style.position = 'absolute';
                //                     div.style.left = postionArr[0] * this.scale + 'px',
                //                         // 后端返回的坐标有基线对齐的问题，top 值是后端算好（基线top - 文字高度），在此加上文字高度的 1/9 （大致比例）为实际展示出文字的top值
                //                         div.style.top = (postionArr[1] + postionArr[3] / 9) * this.scale + 'px'
                //                     div.style.height = postionArr[3] * this.scale + 'px';
                //                     div.style.width = postionArr[2] * this.scale + 'px'
                //                     div.style.backgroundColor = 'rgba(54, 106, 255, 0.3)'
                //                     div.classList.add('lineHeight')
                //                     rectdom.appendChild(div)
                //                 }
                //             }
                //             if (rectdom.children.length > 0) {
                //                 pageDom.appendChild(rectdom)
                //             }
                //         }
                //     }
                //     // }, 0)
                // }
                textLayer.setTextContent(textContent);
                textLayer.render()
                pageDom.appendChild(textLayer.textLayerDiv);
                page.dom = pageDom;
                this.contentView.appendChild(pageDom);
                page.c = new fabric.Canvas('canvas' + pageIndex)
                this.fabricRectDom(page.c, page)
                if(this.mark) {
                    setTimeout(() =>{
                        this.canvasFabric = Array.from(document.getElementsByClassName('canvas-container'))
                        this.canvasFabric.forEach(c =>{
                            c.style.zIndex = 99999999
                        })
                        this.fabricDetailFlag = true
                    }, 500)
                }
                if (this.changetoolbar) {
                    setTimeout(() => {
                        console.log('========');
                        this.renderHighlights()
                        this.changetoolbar = false
                    }, 100)
                }
                if (this.fisrtLoad) {
                    setTimeout(() => {
                        // if (this.$refs.pdfView.clientHeight - (this.pageSize.height + this.PAGE_INTVERVAL) > (this.pageSize.height + this.PAGE_INTVERVAL)) {
                        //     const height = this.$refs.pdfView.clientHeight;
                        //     let startNum = 0 
                        //     let endNum = 0
                        //     startNum = Math.ceil(this.$refs.pdfView.scrollTop / (this.pageSize.height + this.PAGE_INTVERVAL))
                        //     endNum =  startNum +  Math.ceil(height / (this.pageSize.height + this.PAGE_INTVERVAL))
                        //     // for (let pageIndex = startNum; pageIndex <= endNum; pageIndex++) {
                        //     //     if(pageIndex > 0 && pageIndex <= this.pages.length) {
                        //     //         this.loadPdfData(pageIndex)
                        //     //     }
                        //     // }
                        // }
                        if (this.$refs.pdfView.scrollTop == Math.floor((pageNo - 1) * (this.pageSize.height + this.PAGE_INTVERVAL))) {
                            this.fisrtLoad = false
                        } else {
                            this.$refs.pdfView.scrollTop = `${((pageNo - 1) * (this.pageSize.height + this.PAGE_INTVERVAL))}`
                            this.fisrtLoad = false
                        }
                    }, 100)
                }
            })

        },
        // 监听容器的滚动事件，触发 scrollPdf 方法
        // 这里加了防抖保证不会一次产生过多请求
        debounceScrollPdf: _.debounce(function (e, that) {
            if (this.fisrtLoad) {
                this.fisrtLoad = false
                return
            }
            const scrollTop = e.target.scrollTop;
            const height = e.target.clientHeight;
            let startNum = 0 
            let endNum = 0
            startNum = Math.ceil(scrollTop / (that.pageSize.height + that.PAGE_INTVERVAL))
            endNum =  startNum +  Math.ceil(height / (that.pageSize.height + that.PAGE_INTVERVAL))
            for (let pageIndex = startNum; pageIndex <= endNum; pageIndex++) {
                console.log(pageIndex,pageIndex >= 0 && pageIndex <= that.pages.length, 'pageIndexpageIndexpageIndex');
                if(pageIndex >= 0 && pageIndex <= that.pages.length) {
                    that.loadPdfData(pageIndex)
                }
            }
        }, 200),
        directScrolling (e, that) {
            if (this.fisrtLoad) {
                this.fisrtLoad = false
                return
            }
            const scrollTop = e.target.scrollTop;
            const height = e.target.clientHeight;
            // 根据内容可视区域中心点计算页码, 没有滚动时，指向第一页
            const pageIndex = scrollTop > 0 ?
                Math.ceil((scrollTop + (height / 2)) / (that.pageSize.height + that.PAGE_INTVERVAL)) :
                1;
            this.loadPdfData(pageIndex)
        },
        pdfScroll (e) {
            if(this.preViewType !== 'pdf') {
                return
            }
            this.debounceScrollPdf(e, this)
        },
        // 分片每次只做一次处理，所以不考虑多片情况
        loadBefore (pageIndex) {
            this.loadPdfData(pageIndex)
        },
        loadAfter (pageIndex) {
            this.loadPdfData(pageIndex)
        },
        // 首先我们获取到需要渲染的范围
        // 根据当前的可视范围内的页码，我们前后只保留 8 页
        getRenderScope (pageIndex) {
            const pagesToRender = [];
            let i = pageIndex - 1;
            let j = pageIndex + 1;
            // pageIndex - 1 表示当前页码数 对应的下标位置
            pagesToRender.push(this.pages[0]);
            while (pagesToRender.length < 10 && pagesToRender.length < this.pages.length) {
                if (i > 0) {
                    pagesToRender.push(this.pages[i - 1]);
                    i -= 1;
                }
                if (pagesToRender.length >= 10) {
                    break;
                }
                if (j <= this.pages.length) {
                    pagesToRender.push(this.pages[j - 1]);
                    j += 1;
                }
            }
            return pagesToRender;
        },
        // 渲染需要展示的页面，不需展示的页码将其清除
        renderPages (pageIndex) {
            const pagesToRender = this.getRenderScope(pageIndex);
            for (const i of this.pages) {
                if (pagesToRender.includes(i)) {
                    i.loadStatus === this.pageLoadStatus.LOADED ?
                        this.renderPageContent(i, pageIndex) :
                        this.renderPageLoading(i);
                } else {
                    this.clearPage(i);
                }
            }
        },
        // 清除页面 dom
        clearPage (page) {
            if (page.dom) {
                this.contentView.removeChild(page.dom);
                page.loadStatus = 0
                page.dom = undefined;
            }
        },
        // 页面正在下载时渲染loading视图
        renderPageLoading (page) {
            const { pageNo, dom } = page;
            if (dom && dom.children.length != 0) {
                return;
            }
            const pageDom = document.createElement('div');
            pageDom.style.width = `${this.pageSize.width}px`;
            pageDom.style.height = `${this.pageSize.height}px`;
            pageDom.style.position = 'absolute';
            pageDom.style.top = `${((pageNo - 1) * (this.pageSize.height + this.PAGE_INTVERVAL)) + this.PAGE_INTVERVAL
                }px`;
            pageDom.style.backgroundImage = `url('https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/cdn-common/images/loading.gif')`
            pageDom.style.backgroundPosition = 'center'
            pageDom.style.backgroundRepeat = 'no-repeat'
            pageDom.style.backgroundColor = '#FFF'
            page.dom = pageDom;
            this.contentView.appendChild(pageDom);
        },
        prev () {
            this.currentPage--
            if (this.currentPage < 0) {
                // this.currentPage = this.tagIds.length - 1
                this.currentPage = 0
            }
            this.contentView.remove()
            this.markLoad(this.withLocationMark, this.currentPage + 1)
            // if(this.preViewType == 'pdf') {
            //     this.scrollToUplaodePage(this.currentPage)
            // } else {
            //     this.scrollToExcalTop(this.currentPage)
            // }
            // this.getpdfResloutePage(this.cachePdf[this.currentPage - 1])
        },
        next () {
            this.currentPage++
            if (this.currentPage >= this.tagIds.length) {
                this.currentPage = 0
            }
            this.contentView.remove()
            this.markLoad(this.withLocationMark, this.currentPage + 1)
            // if(this.preViewType == 'pdf') {
            //     this.scrollToUplaodePage(this.currentPage)
            // } else {
            //     this.scrollToExcalTop(this.currentPage)
            // }
        },
        currentChange (value) {
            this.currentPage = value - 1
            // if(this.preViewType == 'pdf') {
            //     this.scrollToUplaodePage(this.currentPage)
            // } else {
            //     this.scrollToExcalTop(this.currentPage)
            // }
            this.contentView.remove()
            this.markLoad(this.withLocationMark, this.currentPage + 1)
        },
        scrollToUplaodePage (currentPage) {
            this.changetoolbar = true
            if(this.preViewType !== 'pdf') {
                return
            }
            let pdfResloute = this.cachePdf[currentPage]
            this.identifyTextPostion.extractInfo = pdfResloute.extractInfo
            this.identifyTextPostion.left = pdfResloute.extractInfo.location[0]
            this.identifyTextPostion.top = pdfResloute.extractInfo.location[1]
            this.identifyTextPostion.width = pdfResloute.extractInfo.location[2]
            this.identifyTextPostion.height = pdfResloute.extractInfo.location[3]
            this.identifyTextPostion.page = pdfResloute.page
            this.identifyTextPostion.pageHeight = pdfResloute.pageHeight
            this.identifyTextPostion.pageWidth = pdfResloute.pageWidth
            // console.log( Math.floor((pdfResloute.page - 1) * (this.pageSize.height + this.PAGE_INTVERVAL)) + (this.pageSize.height + this.PAGE_INTVERVAL * 2) ,this.$refs.pdfView.scrollHeight);
            // if(this.$refs.pdfView.scrollTop == Math.floor((pdfResloute.page - 1) * (this.pageSize.height + this.PAGE_INTVERVAL)) ||
            //     Math.floor((pdfResloute.page - 1) * (this.pageSize.height + this.PAGE_INTVERVAL)) + (this.pageSize.height + this.PAGE_INTVERVAL) >= this.$refs.pdfView.scrollHeight) {
            //     this.renderHighlights()
            // } else {
            // 在当前段落在最后一页pdf时，根据计算的高度并不能触发滚动，在此执行重新渲染方法，非次情况会执行两次，待优化
            this.$nextTick(() => {
                this.renderHighlights()
            })
            this.$refs.pdfView.scrollTop = `${((pdfResloute.page - 1) * (this.pageSize.height + this.PAGE_INTVERVAL))}`
            // }
        },
        scrollToExcalTop(currentPage) {
            for (let index = 0; index < this.allTr.length; index++) {
                if(index == currentPage) {
                    this.allTr[index].children.forEach(item =>{
                        item.style.background = 'rgba(255, 136, 0, 0.6)'
                    })
                    this.$refs.pdfView.scrollTop = this.allTr[index].offsetTop
                } else {
                    this.allTr[index].children.forEach(item =>{
                        item.style.background = 'rgba(54, 106, 255, 0.6)'
                    })
                }
            }
        },
        // pdf是否需要重新渲染高亮位置
        renderHighlights () {
            let lineHeightDom = Array.from(document.getElementsByClassName('rectdom'))
            console.log(lineHeightDom, this.currentPage, 'this.currentPage');
            if (lineHeightDom) {
                lineHeightDom.forEach((d) => {
                    for (let i = 0; i < d.children.length; i++) {
                        if (d.getAttribute('react-count') == this.currentPage) {
                            d.children[i].style.backgroundColor = 'rgba(255, 136, 0, 0.3)'
                        } else {
                            d.children[i].style.backgroundColor = 'rgba(54, 106, 255, 0.3)'
                        }
                    }
                })
            }
        },
        displayHiglight (pageIndex) {
            let lineHeightDom = Array.from(document.getElementsByClassName('rectdom'))
            if (lineHeightDom) {
                lineHeightDom.forEach((d) => {
                    if (d.getAttribute('page-index') == pageIndex) {
                        d.style.display = 'none'
                    }
                })
            }
        },
        // 前端暂时缓存多页
        autoLoadMore (pageIndex) {
            let pdfResloute = this.cachePdf.find(cache => {
                return cache.page == pageIndex
            })
            if (pdfResloute) {
                this.getpdfResloutePage(pdfResloute)
            } else {
                this.loadPdfData(pageIndex)
            }
        },
        setPageAllLine (arr) {
            this.currentPageAllLine = []
            arr.forEach((item, index) => {
                let i = this.currentPageAllLine.findIndex(l => { return l.page && l.page == item.page })
                if (i != -1) {
                    // this.currentPageAllLine[i].allLines.lines.push(item.extractInfo.lines)
                    this.currentPageAllLine[i].allLines.push({
                        pageCount: index,
                        lines: item.extractInfo.lines ? item.extractInfo.lines  : [{
                            content:"测试内容填充，不必理会",
                            location:item.extractInfo.location
                        }]
                    })
                } else {
                    this.currentPageAllLine.push({
                        page: item.page,
                        allLines: [{
                                pageCount: index,
                                lines: item.extractInfo.lines ? item.extractInfo.lines : [{
                                content:"测试内容填充，不必理会",
                                location:item.extractInfo.location
                            }]
                        }],
                    })
                }
            })
        },
        openMark() {
            this.mark = !this.mark
            if(this.mark) {
                this.canvasFabric = Array.from(document.getElementsByClassName('canvas-container'))
                this.canvasFabric.forEach(c =>{
                    c.style.zIndex = 99999999
                })
                this.fabricDetailFlag = true
            } else {
                this.canvasFabric = Array.from(document.getElementsByClassName('canvas-container'))
                this.canvasFabric.forEach(c =>{
                    c.style.zIndex = 0
                })
                this.fabricDetailFlag = false
            }
        },
        fabricRectDom(c , {pageNo, url}) {
            let downPoint = null
            let rectSelect = false
            let selectedFn = (s) =>{
                setTimeout(() =>{
                    rectSelect = true
                    this.$set(this.selectedRect,'sorcue', c)
                    this.$set(this.selectedRect,'target', s.target)
                    if(this.selectedRect.target.rectParma) {
                        this.rectParma = JSON.parse(JSON.stringify(this.selectedRect.target.rectParma))
                    } else {
                        this.rectParma.type = 'TEXT'
                        this.rectParma.data = {}
                        this.rectParma.color = '#366AFF'
                    }
                    // this.$set(this.selectedRect.target, 'rectParma', this.rectParma)
                    this.$set(this.selectedRect,'Index', pageNo)
                    this.$set(this.selectedRect,'url', url)
                    console.log(this.selectedRect,'this.selectedRect');
                    if(this.selectedRect.target.id) {
                        // this.rectParma.data = this.selectedRect.target.
                        let block = null
                        if(this.cachePdf.find(cachePdf => { return cachePdf.id == this.selectedRect.target.id && !this.selectedRect.target.changeFlag})) {
                            block = this.cachePdf.find(cachePdf => { return cachePdf.id == this.selectedRect.target.id}).block
                        }  
                        if(block) {
                            this.rectParma.type = block.type
                            this.rectParma.color = block.color
                            if(block.type == 'TABLE') {
                                this.rectParma.data = block.table
                                this.$set(this.rectParma.data, 'indexText',  block.table.title)
                            } else if(block.type == 'IMAGE') {
                                this.rectParma.data = {
                                    indexText: block.image ? block.image.desc : ''
                                }
                            } else if(block.type == 'TEXT') {
                                this.rectParma.data = block.text
                            } else {
                                this.rectParma.data = {
                                    indexText: block.flowChart ? block.flowChart.content : ''
                                }
                            }
                        }
                    }
                }, 20)
            }
            this.cachePdf.forEach(cache =>{
                if(cache.block && cache.block.location) {
                    let location =  cache.block.location
                    let rect = new fabric.Rect({
                            left: location[0] * this.scale ,
                            top: location[1] * this.scale,
                            fill: cache.block.color ? cache.block.color : '#366AFF',
                            opacity: 0.1,
                            width: location[2] * this.scale,
                            height: location[3] * this.scale,
                            perPixelTargetFind: true
                    });
                    rect.setControlsVisibility({
                        'mtr': false
                    })
                    rect.on('selected', selectedFn)
                    rect.on('scaling', () =>{
                        // rect.setWidth(s.transform.target.width * s.transform.scaleX)
                        // rect.setHeight(s.transform.target.height * s.transform.scaleY)
                        // rect.set({
                        //     width:s.transform.target.width * s.transform.scaleX,
                        //     height:s.transform.target.height * s.transform.scaleY
                        // })
                        // c.requestRenderAll()
                        // s.transform.target.width = s.transform.target.width * s.transform.scaleX
                        // s.transform.target.height = s.transform.target.height * s.transform.scaleY
                    })
                    // 监听矩形移动
                    this.$set(rect,'id', cache.id)                  
                    let block = cache.block
                    if(block.type == 'TABLE') {
                        this.$set(rect,'rectParma',  {
                            type: block.type,
                            color: block.color,
                            data:block.table
                        })
                    } else if(block.type == 'IMAGE') {
                        this.$set(rect,'rectParma',  {
                            type: block.type,
                            color: block.color,
                            data: {
                                indexText: block.image ? block.image.desc : ''
                            }
                        })
                    } else if(block.type == 'TEXT') {
                        this.$set(rect,'rectParma',  {
                            type: block.type,
                            color: block.color,
                            data: block.text
                        })
                    } else {
                        this.$set(rect,'rectParma',  {
                            type: block.type,
                            color: block.color,
                            data: {
                                indexText: block.flowChart ? block.flowChart.content : ''
                            }
                        })
                    }
                    // this.$set(rect,'rectParma', cache.id)
                    c.add(rect)
                }
                
            })
            c.on('mouse:down', (e) =>{
              
                let d  = this.dataTime
                this.dataTime = new Date().getTime()
                if(this.dataTime - d < 300) {
                    let arr = c.getActiveObjects()
                    if(arr.length > 0) {
                        this.deleteRect(arr, c)
                        // if(e.perPixelTargetFind) {
                        //     c.remove(e)
                        // }
                        rectSelect = true
                    }
                    // downPoint = e.absolutePointer
                    return
                }
                if(!rectSelect) {
                    downPoint = e.absolutePointer
                }
            })
            c.on('mouse:up', ({pointer,target}) =>{
                // if(target) {
                //     target.width = target.cacheWidth
                //     target.height = target.cacheHeight
                // }
                console.log(JSON.stringify(downPoint) === JSON.stringify(pointer), rectSelect );
                if (JSON.stringify(downPoint) === JSON.stringify(pointer) || rectSelect || c.getActiveObjects().length > 0) {
                    if(target == null) {
                        this.selectedRect = {}
                    }
                    rectSelect = false
                    return
                }
                let top = Math.min(downPoint.y, pointer.y)
                let left = Math.min(downPoint.x, pointer.x)
                let width = Math.abs(downPoint.x - pointer.x)
                let height = Math.abs(downPoint.y - pointer.y)
                let rect = new fabric.Rect({
                        left: left,
                        top: top,
                        fill: '#366AFF',
                        opacity: 0.1,
                        width: width,
                        height: height
                });
                
                this.$set(rect, 'id', uuidv4())
                c.add(rect)
                this.pages[0].c = c
                rect.setControlsVisibility({
                    'mtr': false
                })
                rect.on('selected', selectedFn)
                rect.on('scaling', () =>{
                    // rect.set({
                    //     width:s.transform.target.width * s.transform.scaleX,
                    //     height:s.transform.target.height * s.transform.scaleY
                    // })
                    // c.requestRenderAll()
                })
                rectSelect = false
                // rect.on('moving', (s) =>{
                //     console.log(s,'=======');
                // })
                this.rectDomList.push(rect)
                c.requestRenderAll()
                // downPoint = null
            })

        },
        changeData() {

        },
        changeColor(color) {
            this.rectParma.color = color
            this.selectedRect.target.set({
                fill: color
            })
            console.log(this.selectedRect.target);
            this.selectedRect.sorcue.renderAll()
        },
        // 根据当前位置 获取文档识别内容
        getRectParma(type) {
            let url = ''
            if(this.rectParma.type == 'TABLE' && type == 'TABLE') {
                url = '/knowledge-api/correction/extract-pdf-tabel'
            } else {
                url = '/knowledge-api/correction/extract-pdf-text'
            }
            console.log(this.selectedRect.target, '======');
            this.$http.post(url, {
                url: this.selectedRect.url,
                location: [this.selectedRect.target.left / this.scale, 
                    this.selectedRect.target.top / this.scale,
                    this.selectedRect.target.width *  this.selectedRect.target.scaleX / this.scale,
                    this.selectedRect.target.height * this.selectedRect.target.scaleY / this.scale
                ]
            }).then(res =>{
                if(res.data.code == 0) {
                    this.$set(this.rectParma,'data', res.data.data)
                    this.$set(this.selectedRect.target,'rectParma', this.rectParma)
                    this.pages[0].c._objects.forEach(item =>{
                        if(item.id == this.selectedRect.target.id) {
                            this.$set(item,'changeFlag', true)
                            this.$set(item,'rectParma', JSON.parse(JSON.stringify(this.rectParma)))
                        }
                    })
                }
            })
        },
        setRectParma() {
            let block = []
            if(this.loadingSet) {
                this.$message.warning('正在保存请稍候')
                return
            }
            this.loadingSet = true
            this.cachePdf.map(b =>{
                return b.block
            })
            console.log(this.pages[0].c._objects);
            this.pages[0].c._objects.forEach(item =>{
                if(item.rectParma && item.rectParma.type) {
                    let b = this.createSubmitBlock(item.rectParma.type, item)
                    block.push(b)
                }
            })
            let url = `/knowledge-api/correction/${this.withLocationMark.id}/${this.currentPage + 1}`
            let params = {
                total: this.totalPageCount,
                pageHeight: this.pageSize.height / this.scale,
                pageWidth: this.pageSize.width / this.scale,
                pageFileUrl: this.withLocationMark.pageInfos[this.currentPage].url,
            }
            // todo  当前canvas 下的所有rect 包括对应的 selectedRect
            params.blocks = block
            this.$http.put(url, params).then(res =>{
                if(res.data.code == 0) {
                    this.$message.success('标注成功')
                    this.contentView.remove()
                    this.markLoad(this.withLocationMark, this.currentPage + 1)
                    // this.$nextTick(() =>{
                    //     this.canvasFabric = Array.from(document.getElementsByClassName('canvas-container'))
                    //     this.canvasFabric.forEach(c =>{
                    //         c.style.zIndex = 99999999
                    //     })
                    //     this.fabricDetailFlag = true
                    this.loadingSet = false
                    // })
                    return
                } else {
                    this.$message.error(res.data.msg)
                    this.loadingSet = false
                }
            }).catch(err =>{
                this.$message.error(err)
                this.loadingSet = false
            })
        },
        markLoad(value,index) {
            console.log(index);
            let url = '/knowledge-api/knowledge/knowledge-part-location-info/list-by-knowledge' + `?knowledgeId=${value.id}&page=${index ? index : 1}`
            // 在 pdf_view 下创建 所有canvs的容器
            this.contentView = document.createElement('div')
            this.selectedRect = {}
            this.$http(url, {
                ...value.pageInfos[index ? index : 1]
            }).then(res => {
                if (res.data.code == 0) {
                    // 缓存拿到的所有数据
                    this.cachePdf = res.data.data
                    let publicPageFileUrl = res.data.data[0].publicPageFileUrl
                    if (publicPageFileUrl.substring(publicPageFileUrl.lastIndexOf('.')) === '.pdf') {
                        this.preViewType = 'pdf'
                        // this.setPageAllLine(this.cachePdf)
                        this.getpdfResloutePage(res.data.data[0])
                    }
                } else {
                    let div = document.createElement('div')
                    div.innerText = '文件加载异常'
                    this.contentView.appendChild(div)
                    this.$refs.pdfView.appendChild(this.contentView)
                }
            })
        },
        deleteRect(arr, c) {
            // if (this.selectedRect.sorcue) {
            //     if (this.selectedRect.target && this.selectedRect.target.id) {
            //         // this.cachePdf = this.cachePdf.filter(cache =>{
            //         //     return cache.id != this.selectedRect.target.id
            //         // })
            //         let arr = []
            //         this.cachePdf.forEach(cache => {
            //             console.log(cache.id, this.selectedRect.target.id);
            //             if (cache.id != this.selectedRect.target.id) {
            //                 arr.push(cache)
            //             }
            //         })
            //         this.cachePdf = arr
            //     }
            //     this.selectedRect.sorcue.remove(this.selectedRect.target)
            //     this.$nextTick(() => {
            //         this.selectedRect = {}
            //     })
            // }
            for (let index = 0; index < arr.length; index++) {
                if(arr[index].id) {
                    this.cachePdf = this.cachePdf.filter(cache =>{
                        return cache.id != arr[index].id
                    })
                }
                c.remove(arr[index])
            }
            this.$nextTick(() => {
                this.selectedRect = {}
            })
            // this.setRectParma('delete')
        },
        createSubmitBlock(type, item) {
            let b = {
                "type": type,
                "location": [item.left / this.scale, 
                        item.top  / this.scale,
                        item.width * item.scaleX / this.scale,
                        item.height * item.scaleY / this.scale
                ],
                color: item.rectParma.color,
                "flowChart": {
                    "content": ""
                },
                "image": {
                    "desc": ""
                },
                "table": {
                },
                "text": {
                }
            }
            if(type == "TEXT") {
                b.text = item.rectParma.data
            } else if(type == "TABLE") {
                b.table = {
                    table: item.rectParma.data.table,
                    title: item.rectParma.data.indexText
                }
                if(item.rectParma.data.indexText) {
                    b.text = item.rectParma.data
                }
            } else if(type == "IMAGE") {
                b.image = {
                    desc: this.rectParma.data.indexText
                }
            } else {
                b.flowChart = {
                    content: this.rectParma.data.indexText
                }
            }
            return b
        },
        changeRect() {
            this.pages[0].c._objects.forEach(item =>{
                if(item.id == this.selectedRect.target.id) {
                    this.$set(item, 'changeFlag', true)
                    this.$set(item, 'rectParma', JSON.parse(JSON.stringify(this.rectParma)))
                }
            })
        },
        // 更改表格数据
        changeTable(index, insterType, type) {
            if(type == 'add') {
                if(insterType == 'col') {
                    this.rectParma.data.table.forEach(t =>{
                        t.splice(index, 0 , '')
                    })
                } else {
                    this.rectParma.data.table.splice(index, 0 , this.rectParma.data.table[0].map(item =>{ return ' ' }))
                }
            }
            if(type == 'del') {
                if(insterType == 'col') {
                    this.rectParma.data.table.forEach(t =>{
                        t.splice(index, 1 )
                    })
                } else {
                    this.rectParma.data.table.splice(index, 1)
                }
            }
            this.changeRect()
        },
        checkText() {
            this.canvasFabric = Array.from(document.getElementsByClassName('canvas-container'))
            this.canvasFabric.forEach(c =>{
                c.style.zIndex =  c.style.zIndex == 99999999  ?  0 : 99999999
            })
        }
    },
    computed: {
        perviewUrl () {
            return '/web/viewer.html?file=' + '/pdflist/pdf4split-1.pdf'
        }
    },
    watch: {
        withLocationMark: {
            handler(value) {
                this.markLoad(value)
            },
            deep: true,
            immediate: true  
        },
        fisrtLoad: {
            handler (value) {
                if (!value) {
                    this.$nextTick(() => {
                        this.renderHighlights()
                    })
                }
            }
        }
    },
    mounted () {
        document.addEventListener('keydown', (e) =>{
            if(e.code == 'AltRight') {
                this.checkText()
            }
        })
        document.addEventListener('keyup', (e) =>{
            if(e.code == 'AltRight') {
                this.checkText()
            }
        })
    },
    destroyed() {
    }
}
</script>

<style lang="less" scoped>
.pdf_view {
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #f5f7fb;
    padding-bottom: 50px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    // > div {
    //     width: 100%;
    //     height: 100%;
    //     overflow: hidden;
    //     overflow-y: auto;
    //     position: relative;
    // }
    >iframe {
        width: 100%;
        height: 100%;
    }

    .doc-handle-box {
        background: rgba(52, 68, 115, 0.8);
        border-radius: 5px;
        width: 260px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: white;
        height: 36px;
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 100;

        .dhb-cell {
            height: 14px;
            line-height: 14px;
            font-size: 13px;
            flex: 1;
            border-right: solid 1px lightgrey;
            cursor: pointer;
        }

        .dhb-cell:hover {
            font-weight: bolder;
        }

        .dhb-cell-with-number {
            height: 14px;
            line-height: 14px;
            font-size: 13px;
            flex: none;
            width: 90px;
            cursor: pointer;

            .dhb-cell-count {
                font-size: 12px;
                margin-left: 6px;
                height: 20px;
                background: #ECF1FF;
                border-radius: 10px;
                padding: 0 6px;
                font-weight: bolder;

                .delimiter {
                    color: #366aff;
                    margin: 0 3px;
                }

                .num {
                    color: #366aff;
                }
            }
        }

        .dhb-cell-with-number:hover {
            font-weight: 700;
        }
    }

    #pagination {
        .total-class {
            margin-right: 13px;
            font-weight: 400;
        }

        position: absolute;
        bottom: 0px;
        right: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        background-color: white;
        box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
        border-radius: 5px;
        z-index: 1000;

        // /deep/.el-pagination {
        //     margin-right: 110px;
        // }

        /deep/.el-pager {
            background: #EDF0F6;
            border-radius: 15px;
        }

        /deep/.el-pagination.is-background .btn-next {
            width: 30px;
            height: 30px;
            background: #EDF0F6;
            border-radius: 50%;
        }

        /deep/.el-pagination .btn-next {
            width: 30px;
            height: 30px;
            background: #EDF0F6;
            border-radius: 50%;
            padding-left: 0;
            margin-left: 5px;
        }

        /deep/.el-pagination .btn-prev {
            width: 30px;
            height: 30px;
            background: #EDF0F6;
            border-radius: 50%;
            padding-right: 0;
            margin-right: 5px;
        }

        /deep/.el-pagination button {
            padding: 0;
            min-width: 30px;
        }

        /deep/.el-pager li {
            background: #EDF0F6;
            height: 30px;
            min-width: 30px;
            line-height: 30px;
            font-size: 12px;
            color: #717b90;
        }

        /deep/.el-pager li:first-child {
            border-bottom-left-radius: 15px !important;
            border-top-left-radius: 15px !important;
        }

        /deep/.el-pager li:last-child {
            border-top-right-radius: 15px !important;
            border-bottom-right-radius: 15px !important;
        }

        /deep/.el-pager li.active {
            width: 30px;
            height: 30px;
            min-width: 30px;
            background: #366AFF;
            border: 3px solid #A1B9FF;
            border-radius: 50%;
            line-height: 24px;
            color: white;
        }

        /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
            background: #366AFF;

        }
    }
    /deep/.canvas-container {
        position: absolute !important;
    }
    .fabricDetail {
        width: 500px;
        height: 100vh;
        position: fixed;
        top: 0;
        right: 801px;
        // background: #f5f7fb;
        background: #ffffff;
        box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
        .rect-select {
            width: 100%;
            height: 100%;
            .rect-head {
                width: 100%;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 15px;
                box-sizing: border-box;
                i {
                    cursor: pointer;
                }
            }
            .rect-content {
                width: 100%;
                height: calc(100% - 40px);
                background: #f5f7fb;
                .rect-card {
                    width: 100%;
                    // margin-bottom: 10px;
                    padding: 10px;
                    box-sizing: border-box;
                    max-height: 50%;
                    overflow: auto;
                    p {
                        font-size: 14px;
                        line-height: 36px;
                        text-align: left;
                    }
                    .rect-color {
                        width: 100%;
                        height: 40px;
                        background: #f5f7fb;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        section {
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            cursor: pointer;
                        }
                        .active {
                            border: 2px solid #000000;
                        }
                    }
                }
            }
            .rect-btn {
                width: 100%;
                display: flex;
                align-items: center;
                padding: 5px 10px;
                box-sizing: border-box;
                .rect-get-btn {
                    padding: 5px 8px;
                    color: #ffffff;
                    background: #366AFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 40px;
                    cursor: pointer;
                    margin-right:15px;
                }
            }

        }
        .empty {
            width: 100%;
            height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            p {
                margin-top: 20px;
                font-size: 16px;
            }
        }
        .table {
            width: 100%;
            background: #ffffff;
            // tr {

            // }
            td {
                min-width: 80px;
                border: 1px solid #ccc;
                padding: 0 8px;
                /deep/.el-input {
                    .el-input__inner {
                        border: none !important;
                        padding: 0 !important;
                    }
                }
            }
            i {
                color: #366AFF;
                font-size: 14px;
                margin-right: 15px;
                cursor: pointer;
            }
        }
        
    }
    .submit_btn {
            .rect-get-btn {
                    padding: 5px 8px;
                    color: #ffffff;
                    background: #366AFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 40px;
                    cursor: pointer;
                    margin-right:15px;
                }
        }
}
</style>
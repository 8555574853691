<template>
    <div class="loadingIcon">
        <!-- <i class="el-icon-loading" v-if="message.send_status==='sending'"></i> -->
        <!-- <i class="el-icon-warning" v-else-if="message.send_status==='send_fail'"></i> -->
    </div>
</template>
<script>

export default ({
    name:"MessageStatus",
    props:['message'],
    watch:{
        message(message){
            console.debug('message change',message);
        }
    }
})
</script>
<style lang='less'>
.loadingIcon{
    display: flex;
    i{
        display: flex;
        align-items: center;
    }
}
</style>

<template>
	<div :class="['ratel-ckeditor-page', 'ratel-ckeditor-content']">
		<ckeditor
			ref="ckeditorDom"
			:class="['ratel-ckeditor']"
			id="ratel-ckeditor"
			:editor="editor"
			:disabled="editorDisabledContent"
			v-model="ckeditorValues" 
			@ready="ckeditorReady" 
			@blur="ckeditorBlur"
			@focus="ckeditorFocus"
			:config="editorConfig"
			@input="changeData">
		</ckeditor>
		<div 
			v-show="showBlockToolbar"
			:class="['popup-floating-layer-ckeditor',ckeditorType === 'normal' ? 'popup-ckeditor-normal' : 'popup-ckeditor-popup','popup-'+keyCode]" 
			:style="{'left':popupStyle.left+'px','top':popupStyle.top+'px'}">
			<div class="content">
				<div class="popup-floating-layer-ckeditor-top-content">
					<div class="top-title">
						<span>AI生成内容</span>
						<!-- <span class="look-example"> <i class="iconfont guoran-tongyichicun-dianjichakantuli"></i> 查看示例</span> -->
					</div>
					<div :class="['ai-help-write-btn','active-dom active-dom1', 'quickKeys_ai']" @click.stop="aiHelpWriteFn({},'helpWriteBtn')">
						<div class="iconfont guoran-shengchengAI2"></div>
						<span class="ai-help-write-btn-btn">让AI帮我写</span>
					</div>
					<div class="ai-help-write-list">
						<div 
							:class="['item ai-help-write-item','active-dom active-dom'+item.selfIndex, 'quickKeys_help']"
							v-for="(item,index) in aiHelpWriteListThree" 
							:key="index"
							:data-tips="item.tips"
							@click.stop="aiHelpWriteFn(item,'helpWrite')">
							<template v-if="item.type !== 'more'">
								<i :class="['iconfont ai-help-write-iconfont',item.icon]"></i>
								<span class="ai-help-write-label">{{item.name}}</span>
							</template>
							<template v-else>
								<el-popover v-model="aiHelpWriteVisible" visible-arrow="true" ref="showMoreWrite" :close-delay="1" popper-class="show-more-write" placement="right" width="180" trigger="manual">
									<div 
										:class="['item ai-help-write-item','active-dom active-dom'+item.selfIndex, 'quickKeys_more']" 
										v-for="(item,index) in aiHelpWriteListThreeMore" 
										:key="index"
										:data-tips="item.tips"
										@click.stop="aiHelpWriteFn(item,'helpWrite')">
										<i :class="['iconfont',item.icon]"></i>
										<span>{{item.name}}</span>
									</div>
									<div @click.stop="aiHelpWriteFn(item,'helpWrite')" v-if="aiHelpWriteListThreeMore.length > 0" slot="reference"  :class="['item more is-show-more-popup']"> <i class="iconfont guoran-gengduo"></i> 更多 ></div>
								</el-popover>
							</template>
						</div>
					</div>
				</div>
				<div class="popup-floating-layer-ckeditor-middle-content">
					<div class="title">基础</div>
				</div>
				<div :class="['popup-floating-layer-ckeditor-bottom-content', isHaveContent ? 'have-content' : '']">
					<div class="label">模版</div>
					<div class="template-list">
						<div :class="['item','active-dom active-dom'+index+6, 'quickKeys_template',item.type === 'more' ? 'more' : '']" @click="insertTemplate(item)" v-for="(item,index) in templateBofore" :key="item.id">
							<template v-if="item.type !== 'more'">
								<i :class="[item.icon]" :style="{'color':item.color}"></i>
								<span class="template-name one-column-ellipsis">{{item.name}}</span>
							</template>
							<template v-else>
								<el-popover v-model="templateMoreVisible" visible-arrow="true" :close-delay="1" popper-class="show-more-template" placement="right" width="240" trigger="manual">
										<div class="template-list">
											<div :class="['item','active-dom active-dom'+index+6]" @click.stop="insertTemplate(item)" v-for="(item,index) in templateMore" :key="item.id">
												<i :class="[item.icon]" :style="{'color':item.color}"></i>
												<span class="template-name one-column-ellipsis">{{item.name}}</span>
											</div>
										</div>
									<div v-if="templateMore.length > 0" slot="reference"  :class="['item more is-show-more-popup']"> <i class="iconfont guoran-gengduo"></i> 更多 > </div>
								</el-popover>
							</template>
						</div>
					</div>
				</div>
			</div>
		</div>
		<AiCreatePopup 
			ref="AiCreatePopup"
			:ckeditorType="ckeditorType"
			:popupPositionObj="popupPositionObj"
			:evtSource="evtSource"
			:aiCreateContentPopup.sync="aiCreateContentPopup" 
			:aiHelpWriteList="aiHelpWriteList"
			:optimizeList="optimizeList"
			:generateList="generateList"
			:popupType="popupType"
			:evtSourceUid="evtSourceUid"
			:aiCreateContentValue.sync="aiCreateContentValue"
			:evtSourceMessageDataListCopy="evtSourceMessageDataListCopy"
			:textPlain="textPlain"
			:positionBtn="positionBtn"
			@handlerQuickDel="handlerQuickDel"
			@isAigcCreate="isAigcCreate"
			@continueCreate="continueCreate"
			@stopCreate="stopCreate"
			@eventSourceParams="eventSourceParams"
			@handlerCanUpload="handlerCanUpload">
		</AiCreatePopup>
			<!-- @AigcCreate="AigcCreate" -->
			<!-- @getCreateData="getCreateData" -->

		<div class="hidden-dom"  tabindex="-1"></div>
        <div v-if="maskFlag" class="mask-ckeditor" @click.stop="showBlockToolbar = false"></div>
		
	</div>
</template>

<script>

import Clickoutside from 'element-ui/src/utils/clickoutside'
import BalloonEditor from 'ckeditor';
import { v4 as uuidv4 } from "uuid";
import { multipartUpload,ossConfig,ossFileUrl } from "@/assets/js/AliyunIssUtil";
import AiCreatePopup from "@/components/ckeditor/components/ai-create-popup";
import { getOptimalPosition } from '@ckeditor/ckeditor5-utils/src/dom/position';
import Rect from '@ckeditor/ckeditor5-utils/src/dom/rect';
import { requestUrl } from "@/http/requestUrl";
import { mapGetters, mapState } from 'vuex';
import _ from 'lodash'
import { MyCustomUploadAdapterPlugin } from "@/components/ckeditor/js/ckeditors";

export default {
	data(){
		return {
			editor: BalloonEditor,
			editorConfig:{
				extraPlugins: [MyCustomUploadAdapterPlugin],
				placeholder: "写点什么吧，如知识、笔记、计划、复盘等...输入“、”或“/”使用AI生成内容",
				htmlSupport: {
					// allow: [
					// 	{
					// 		name: 'figure',
					// 		attributes: true,
					// 		classes: true,
					// 		styles: true
					// 	},{
					// 		name:'span' ,
					// 		attributes: true,
					// 		classes: true,
					// 		styles: true
					// 	},{
					// 		name:'img' ,
					// 		attributes: true,
					// 		classes: true,
					// 		styles: true
					// 	},
					// ]
				},
				blockToolbar: [],
				toolbar: {items: []},
				fontColor: {
					colors: [
						{
							color: '#404040',
							label: '#404040',
						},
						{
							color: '#959494',
							label: '#959494',
						},
						{
							color: '#FF7272',
							label: '#FF7272',
						},
						{
							color: '#FF9555',
							label: '#FF9555',
						},
						{
							color: '#FFBF6B',
							label: 'FFBF6B',
						},
						{
							color: '#25BD69',
							label: '#25BD69',
						},
						{
							color: '#7597FF',
							label: '#7597FF',
						},
						{
							color: '#AFA8F8',
							label: '#AFA8F8',
						},
					]
				},
				fontBackgroundColor: {
					colors: [
						{
							color: '#FFFFFF',
							label: '#FFFFFF',
							hasBorder: true
						},
						{
							color: '#E3E5E7',
							label: '#E3E5E7',
							// hasBorder: true
						},
						{
							color: '#FFD1D1',
							label: '#FFD1D1',
							// hasBorder: true
						},
						{
							color: '#FFD3B8',
							label: '#FFD3B8',
							// hasBorder: true
						},
						{
							color: '#FFF9B2',
							label: '#FFF9B2',
							// hasBorder: true
						},
						{
							color: '#ACE8C7',
							label: '#ACE8C7',
							// hasBorder: true
						},
						{
							color: '#D2DDFF',
							label: '#D2DDFF',
							// hasBorder: true
						},
						{
							color: '#E4E1FF',
							label: '#E4E1FF',
							// hasBorder: true
						},
						{
							color: '#F5F7FB',
							label: '#F5F7FB',
							// hasBorder: true
						},
						{
							color: '#C8C6C6',
							label: '#C8C6C6',
							// hasBorder: true
						},
						{
							color: '#FF7272',
							label: '#FF7272',
							// hasBorder: true
						},

						{
							color: '#FF9555',
							label: '#FF9555',
							// hasBorder: true
						},
						{
							color: '#FCEB2B',
							label: '#FCEB2B',
							// hasBorder: true
						},
						{
							color: '#25BD69',
							label: '#25BD69',
							// hasBorder: true
						},
						{
							color: '#7597FF',
							label: '#7597FF',
							// hasBorder: true
						},
						{
							color: '#AFA8F8',
							label: '#AFA8F8',
							// hasBorder: true
						},
					]
				},
				askPluginListener: [
					{
						event: "BUTTONUPLOADIMG",
						process: this.uploadImgButton, // '上传图片' 按钮点击事件
					}, 
					{
						event: "BUTTONIPLOADFILE",
						process: this.uploadFileButton, // '上传附件' 按钮点击事件
					},
					{
						event: "BUTTON",
						process: this.uploadImgButton, // '上传图片' 按钮点击事件
					},
					{
						event: "BUTTONAICONCERT",
						process: this.aiCollabortionButton, //' AI协作 ' 按钮点击事件
					},
					{
						event: "BUTTONSAVETEMPLATE",
						process: this.saveTemplateButton, //' 保存模版 ' 按钮点击事件
					},
					
				], // 注册监听
			},
			currentEditor: null, // 当前富文本对象
			showBlockToolbar: false, // 富文本侧边按钮显隐
			popupStyle: { // 点击富文本侧边按钮后出现的弹框  样式数据
				top: 0,
				left: 0,
			},
			ckHiddenDom: null,
			aiHelpWriteListThree: [],
			aiHelpWriteListThreeMore: [],
			aiCreateContentPopup: false, // AI生成内容弹框显隐
			isShowMoreHelpWrite: false, // 是否显示帮我写
			popupHeight: 0,
			AiMadePopup: false, // AI制作弹框显隐
			popupType: "", // AiCreate  AI生成    AIMade  AI制作
			aiCreateContentValue: "",
			isQuickKeys: false, // 是否输入快捷键
			isHaveOperate: false, // 是否点击了弹框中的操作 , 包含富文本自带的操作和点击模板的操作
			buttonView: null,
			ckPath: null,
			getNowDate:'',
			knowledgeItem: null,
			isHaveContent:true, // 判断当前有无内容
			keyCodeIndex: -1,
			maxNum:5,
			ckeditorPosition:{},
			setSelectionParams:{},
			textPlain:'',
			selectionPosition: null, // 记录的是当前坐标的位置
			cursorRect:{},
			positionBtn:{},
			AiPopupPosition:{},
			lineFeedStr: '<span class="ask-component-section-box ck-widget" _alt="undefined" _width="undefined" _height="undefined" _src="undefined" _dynamic="undefined" contenteditable="false"><span class="ask-component-section" alt="undefined" width="undefined" height="undefined" src="undefined" dynamic="undefined"></span></span>',
			AIMadeSurplus:0,
			AICrteateSurplus:0,
			ckPathOld:[],
			popupPositionObj:{
				top:0,
				left:0
			},
			maskFlag:false,
			templateBofore:[],
			templateMore:[],
			isShowMoreTmplate:false,
			isScroll:false,
			ckeditorBtn:null,
			scrollDom:null,
			showPopupType:"",
			changeFlag:false,
			templateMoreVisible:false,
			aiHelpWriteVisible:false,
			ckeditorValuesOld:'',
			isDblclick:false,
			focusImade:false,
			mouseDownFlag:false,
			selectionRange:null,
			scrollTopValue: 0,
			countPositionStatus: false,
			triggerType:"",
			scrollDomHeight:0,
			isClickBtn:false,
			isCreateing:false,
		}
	},
	components: { AiCreatePopup },
	directives: { Clickoutside },
	props: {
		evtSource:{
			type:Object,
			default(){
				return{}
			}
		},
		ckeditorValue: {
			type: String,
			default: ""
		},
		placeholder: {
			type: String,
			default: "写点什么吧，如知识、笔记、计划、复盘等...输入”或”、”使用AI生成内容d"
		},
		editorDisabledContent: {
			type: Boolean,
			default: false // 是否开始只读模式
		},
		toolbarConfig:{
			type: Object,
			default(){
				return {}
			}
		},
		ckeditorType: {
			type: String,
			default: '' //normal 普通类型的富文本    title 弹框类型的富文本
		},
		keyCode: {
			type:Number,
			default:1
		},
		aiHelpWriteList: {
			type: Array,
			default(){
				return []
			}
		},
		templateList: {
			type: Array,
			default(){
				return []
			}
		},
		optimizeList: {
			type: Array,
			default(){
				return []
			}
		},
		generateList: {
			type: Array,
			default(){
				return []
			}
		},
		isContinue:{
			type:Boolean,
			default:false
		},
		evtSourceUid:{
			type:String,
			default:""
		},
		evtSourceMessageDataListCopy:{
			type: Array,
			default(){
				return []
			}
		},
		AigcVisiable: {
			type:Boolean,
			default:false
		},
		isStop:{
			type:Boolean,
			default:false
		},
		evtSourceMessageDataList:{
			type: Array,
			default(){
				return []
			}
		},
		isQuickTemplate:{
			type:Boolean,
			default:false
		},
		isTemplateManage:{
			type:Boolean,
			default:false
		},
		// historyList:{
		// 	type: Array,
		// 	default(){
		// 		return []
		// 	}
		// },
		// totalElements:{
		// 	type:Number,
		// 	default:0
		// },
	},
	watch: {
		aiHelpWriteList: {
			handler(n){
				if (n && n.length > 0){
					
					let aiHelpWriteListThree = n.slice(0,3);
					let aiHelpWriteListThreeMore = n.slice(3,n.length);
					aiHelpWriteListThree.forEach((item,index) => {
						item.selfIndex = index+2
					})
					aiHelpWriteListThreeMore.forEach((item,index) => {
						item.selfIndex = index+1
					})
					this.aiHelpWriteListThree = [...aiHelpWriteListThree,...[{type:'more',name:'更多',icon:'iconfont guoran-gengduo'}]];
					this.aiHelpWriteListThreeMore = aiHelpWriteListThreeMore;
					this.$forceUpdate()
				}
			},
			deep: true,
			immediate: true
		},
		showBlockToolbar(n){
			this.keyCodeIndex = -1;
			// this.selectionPosition = this.currentEditor.model.document.selection.getFirstPosition()
			if (n){
				this.resetHistoryData();
				this.isHaveOperate = false;
				this.hideCheckedPopup();
				this.isShowMoreHelpWrite = false;
				// this.hiddenTooltips();
			} else {
				this.hiddenCkeditorPopup();
				if(this.isHaveOperate){
					this.$nextTick(() => {
						this.handlerQuickDel();
					})
				}
			}
		},
		isContinue:{
			handler(n){
				if(n){
					this.$refs.AiCreatePopup.isShowContinueBtn = true;
					this.$refs.AiCreatePopup.isCreateing = false;
				}	
			},
			immediate:true
		},
		isStop:{
			handler(n){
				if(n){
					this.aiCreateContentPopup = false;
					this.$refs.AiCreatePopup.isShowContinueBtn = false;
					this.$refs.AiCreatePopup.isCreateing = false;
					this.ckeditorBtn.style.opacity = 1
				} 
			},
			// immediate:true
		},
		aiCreateContentPopup(n){
			if(this.popupType == 'AiCreate') {
                this.maskFlag = n
            }
			if(!n){
				this.isClickBtn = false;
				this.isDblclick = false;
				this.isQuickKeys = false;
				this.maskFlag = false;
				// if(this.popupType === 'AiCreate'){
				// 	this.$refs.ckeditorDom.$el.blur()
				// 	this.getScrollDom().style.height = this.scrollDomHeight + 'px';
				// }
				this.$refs.ckeditorDom.$el.blur()
				this.getScrollDom().style.height = this.scrollDomHeight + 'px';
				// this.handlerScroll(false);
			} else {
				// thispopupPositionObj = this.getPositionData();
			}
		},
		ckeditorType:{
			handler(n){
				this.$nextTick(() => {
					setTimeout(() => {
						if(n !== ''){
							this.blickToolbarClickFn(true); // blockToolbar按钮的点击事件
						}
					},1000)
				})
			},
			immediate:true
		},
		templateList:{
			handler(n){
				if(n.length > 5){
					this.templateBofore = [...n.slice(0,4),...[{type:'more',name:'更多',icon:'iconfont guoran-gengduo'}]];
				} else if (n.length === 5) {
					this.templateBofore = [...n.slice(0,5)]
				} else {
					this.templateBofore = [...n.slice(0,4)]
				}
				this.templateMore = n.slice(4,n.length);
			},
			deep:true,
			immediate:true
		},
		ckPath:{
			handler(value,oldVal) {
				if(value && oldVal && value[0] !== oldVal[0]) {
					let ckeditorDom = this.getCkeditorDom();
					let pList = this.doopChildren(Array.from(ckeditorDom.children));
					this.$nextTick(() => {
						if(ckeditorDom.children[value[0]] && this.isStop){
							let top =  pList[value[0]].getBoundingClientRect().top;
							this.ckeditorBtn.style.top = top - 2 + 'px'
							this.ckeditorBtn.style.opacity = 1
						}
					})
				}
			},
			deep:true
		},
	},
	computed:{
		ckeditorValues: {
			get() {
				return this.ckeditorValue
			},
			set(value) {
				// this.ckeditorValue = value
				this.$emit('update:ckeditorValue', value)
			}
		},
		...mapGetters('user', {
            currentVersion: 'getCurrentVersion'
        }),
		...mapState({
            // 智能写作次数/容量 套餐使用信息
            combinationInfo: state => state.user.combinationInfo,
        }),
	},
	beforeDestroy(){
		document.body.onclick = null;
		window.onkeydown = null;
		this.ckeditorBtn.onclick = null;
		if(this.scrollDom){
			this.scrollDom.onscroll = null;
		}
		this.judgeCursorStatus(false);

	},
	created(){
		this.editorConfig.htmlSupport = this.toolbarConfig.htmlSupport;
		this.editorConfig.placeholder = this.toolbarConfig.placeholder;
		this.editorConfig.blockToolbar = this.toolbarConfig.blockToolbar;
		this.editorConfig.toolbar.items = this.toolbarConfig.toolbar.items;
	},
	mounted(){
		this.resetCkeditor();
	},
	methods: {
		// 重置历史指令数据
		resetHistoryData(){
			this.$refs.AiCreatePopup.historyList = [];
			this.$refs.AiCreatePopup.firstColumn = [];
			this.$refs.AiCreatePopup.secondColumn = [];
			this.$refs.AiCreatePopup.historyMore = [];
            this.$refs.AiCreatePopup.getUserHistoryCmd('AiCreate');
		},
		handlerCanUpload(type,exceedType){
			this.$emit('handlerCanUploadEmit',type,exceedType,{})
		},
		resetCkeditor(){
			this.getScrollDom().scrollTop = 0;
			this.showBlockToolbar = false;
			this.isHaveOperate = false;
			this.scrollDomHeight = this.getScrollDom().clientHeight;
			this.getScrollDom().scrollTop = 0;
			setTimeout(() => {
				if(!this.editorDisabledContent){
					this.bodyCLickFn(); // 点击body
					this.ckeditorClickFn(); // 富文本的所有基础操作增加点击事件监测 
					this.keydownFn();
					this.judgeCursorStatus(true)
				}
				// isTemplateManage
				if(this.isTemplateManage){
					let list = Array.from(document.querySelectorAll('.ck-reset_all'));
					list[1].classList.add('template-manage')
				}
			}, 1000)
		},
		// 上传附件/图片后立即执行AI生成,需要在新的一行内插入
		removeCursor(){
			if(this.isClickBtn){
				let ckeditorDom = this.getCkeditorDom();
				let children = Array.from(ckeditorDom.children)
				if(children[this.ckPath[0]].innerHTML.indexOf('img') !== -1 || children[this.ckPath[0]].innerHTML.indexOf('iframe') !== -1){
					let command = this.currentEditor.commands.get("insertAskComponent");
					// 需要回调通知父组件 上传成功
					command.execute({
						tag: "section",
						options: {
							className:'self-p-section',
							data: 'self-p-section'
						},
					});
				}
				// this.currentEditor.editing.view.focus();
			}
		},
		handleCopy() {
            const range = document.createRange();
            range.selectNode(document.getElementById('drag-upload-phone')); //获取复制内容的 id 选择器
            const selection = window.getSelection();  //创建 selection对象
            if (selection.rangeCount > 0) selection.removeAllRanges(); //如果页面已经有选取了的话，会自动删除这个选区，没有选区的话，会把这个选取加入选区
            selection.addRange(range); //将range对象添加到selection选区当中，会高亮文本块
            document.execCommand('copy'); //复制选中的文字到剪贴板
			this.messageFn('success', '复制成功');
            selection.removeRange(range); // 移除选中的元素
        },
		// 隐藏选中时的AI写作弹框
		hideCheckedPopup(){
			// let domList = Array.from(document.querySelectorAll('.ck-body-wrapper .ck-reset_all'));
			let dom = this.currentEditor.ui.view.body._bodyCollectionContainer.children[0];
			// if(this.ckeditorType === 'normal'){
			// 	dom = Array.from(domList[1].querySelectorAll('.ck-balloon-panel'))[1]
			// } else {
			// 	dom =  Array.from(domList[3].querySelectorAll('.ck-balloon-panel'))[1]
			// }
			dom && dom.classList.remove('ck-balloon-panel_visible')
		},
		// 隐藏操作栏弹框
		hiddenCkeditorPopup(){
			// let domList = Array.from(document.querySelectorAll('.ck-body-wrapper .ck-reset_all'))
			let dom = this.currentEditor.ui.view.body._bodyCollectionContainer.children[0];
			// if(this.ckeditorType === 'normal'){
			// 	dom = domList[1].querySelector('.ck-toolbar-container');

			// } else {
			// 	dom = domList[3].querySelector('.ck-toolbar-container');
			// }
			dom.classList.remove('ck-balloon-panel_visible');
			dom.classList.remove('ck-balloon-panel_visible_self');
			this.templateMoreVisible = false;
			this.aiHelpWriteVisible = false;
			// this.$refs.ckeditorDom.$el.blur()
		},
		// 点击帮我写/模版中更多时的处理
		clickMore(type){
			let dom = this.getPanelDom();
			dom.classList.add('ck-balloon-panel_visible');
			dom.classList.add('ck-balloon-panel_visible_self');
			if(type === 'template'){
				this.templateMoreVisible = !this.templateMoreVisible;
				this.aiHelpWriteVisible = false;
			} else {
				this.aiHelpWriteVisible = !this.aiHelpWriteVisible;
				this.templateMoreVisible = false;
			}
		},
		// 隐藏tooltips
		hiddenTooltips(flag){
			let tooltipDom = document.querySelector('.ck-tooltip');
			if(tooltipDom){
				if(tooltipDom.innerText === '更多功能'){
					tooltipDom.style.display = 'none';
				} else {
					tooltipDom.style.display = 'block';
				}
				if(flag){
					tooltipDom.style.display = 'none';
				}
			}
		},
		// 获取不同的ckeditor元素
		getCkeditorDom(){
			let ckeditorDom = null;
			if(this.ckeditorType === 'normal'){
				ckeditorDom = document.querySelector('.ask-template-ckeditor>.ask-template-ckeditor_left>.ask-template-ckeditor-content>.ckeditor-box>.ratel-ckeditor-content>.ck-content');
				if(document.querySelector('.ask-template-ckeditor>.save-template-popup .ratel-ckeditor-content>.ck-content')){
					document.querySelector('.ask-template-ckeditor>.save-template-popup .ratel-ckeditor-content>.ck-content').onkeydown = null;
					document.querySelector('.ask-template-ckeditor>.save-template-popup .ratel-ckeditor-content>.ck-content').onmousedown = null;
				}
			} else {
				ckeditorDom = document.querySelector('.ask-template-ckeditor>.save-template-popup .ratel-ckeditor-content>.ck-content');
				document.querySelector('.ask-template-ckeditor>.ask-template-ckeditor_left>.ask-template-ckeditor-content>.ckeditor-box>.ratel-ckeditor-content>.ck-content').onkeydown = null;
				document.querySelector('.ask-template-ckeditor>.ask-template-ckeditor_left>.ask-template-ckeditor-content>.ckeditor-box>.ratel-ckeditor-content>.ck-content').onmousedown = null;
			}
			return ckeditorDom;
		},
		// 获取操作栏面板元素
		getPanelDom(){
			// let domList = Array.from(document.querySelectorAll('.ck-body-wrapper .ck-reset_all'));
			let dom = this.currentEditor.ui.view.body._bodyCollectionContainer.children[0];
			// if(this.ckeditorType === 'normal'){
			// 	dom = domList[1].querySelector('.ck-balloon-panel')
			// } else {
			// 	dom =  domList[3].querySelector('.ck-balloon-panel')
			// }
			return dom
		},
		// 获取滚动元素
		getScrollDom(){
			let scrollDom = null;
			if(this.ckeditorType === 'normal'){
				scrollDom = document.querySelector('.ask-template-ckeditor>.ask-template-ckeditor_left>.ask-template-ckeditor-content>.ckeditor-box');
				if(	document.querySelector('.ask-template-ckeditor>.save-template-popup .lighting-popup-box')){
					document.querySelector('.ask-template-ckeditor>.save-template-popup .lighting-popup-box').onscroll = null;
				}
			} else {
				scrollDom = document.querySelector('.ask-template-ckeditor>.save-template-popup .lighting-popup-box');
				document.querySelector('.ask-template-ckeditor>.ask-template-ckeditor_left>.ask-template-ckeditor-content>.ckeditor-box').onscroll = null;
			}
			this.scrollDom = scrollDom;
			return scrollDom
		},
		// 插入模版
		insertTemplate(item){
			if(item.type !== 'more'){
				this.templateMoreVisible = false;
				if(this.ckeditorType === 'normal'){
					this.$parent.templateIds = [...this.$parent.templateIds,...[item.id]];
					this.$parent.getCkeditorContent('insert','',item.id);
				} else {
					this.$parent.$parent.templateIds = [...this.$parent.$parent.templateIds,...[item.id]];
					this.$parent.$parent.getCkeditorContent('insert','',item.id);
				}
				this.isHaveOperate = true;
				this.showBlockToolbar = false;
			} else {
				this.clickMore('template');
			}
		},
		eventSourceParams(params){
			this.$emit('eventSourceParamsEmit',params)
			this.ckeditorBtn.style.opacity = 0;
		},
		// 停止创建
		stopCreate(flag){
			if(this.ckeditorType === 'normal'){
				this.$parent.stopCreate(flag);
			} else {
				this.$parent.$parent.stopCreate(flag);
			}
		},
		continueCreate(flag){
			// this.isQuickKeys = isQuickKeys;
			this.$emit('update:isContinue',flag)
		},
		isAigcCreate(flag,text){
			this.$emit('isAigcCreate', flag,text)
		},
		// AigcCreate(name) {
		// 	this.$emit('AigcCreate', name)
		// },
		// 获取富文本所有元素
		doopChildren(list) {
			let flatArr = [];
			let filterTagNames = ['UL','OL', 'IMG', 'IFRAME' , 'BR', 'A' , 'SVG' ,'PATH' , 'DIV' ,'SPAN', 'STRONG','H1' ,'H2' ,'H3' ,'I', 'U', 'S','BLOCKQUOTE']
			list.forEach((item) => {
				if(!filterTagNames.includes(item.tagName)) {
					flatArr.push(item)
				}
				if (item.children && item.tagName != 'SPAN') {
					flatArr = [...flatArr, ...this.doopChildren(Array.from(item.children))];
				}
			})
			return flatArr
		},
		// 设置balloon工具栏
		setCkeditorBalloonbarItemDispaly(type) {
			let d = this.getPanelDom();
			let ckeditorDom = this.getCkeditorDom();
			let pList = this.doopChildren(Array.from(ckeditorDom.children));
			let activeDom = pList[this.ckPath[0]];
			if(!activeDom){
				this.isHaveContent = false;
			} else {
				if((activeDom.children && activeDom.children.length === 1 && (activeDom.firstChild.tagName === 'BR' || activeDom.firstChild.tagName === 'IMG' || activeDom.firstChild.tagName === 'IFRAME')) || (activeDom.children.length === 0 && activeDom.innerText === '')){
					this.isHaveContent = false
				} else {
					this.isHaveContent = true
				}
			}
			if(type === 'clickBottomBtn'){
				if(activeDom.innerText === '/'){
					this.isHaveContent = false
				} else {
					this.isHaveContent = true
				}
			}
			let dom =  d.children[0]
			let toolbar = [] 
			if (this.isHaveContent) {
				toolbar = [
					'paragraph',
					'heading1',
					'heading2',
					'heading3',
					{
						label: '',
						icon: '<svg t="1679733495980" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="32772" width="16" height="16"><path d="M954.88 941.568h-26.624l-377.856-916.48c-9.728-21.504-34.816-30.72-55.808-21.504-9.216 4.096-16.896 11.776-21.504 21.504l-376.32 916.48h-28.672c-16.896 0-31.744 8.192-36.864 23.552-9.216 21.504 1.024 46.08 22.016 55.296 5.12 2.048 10.752 3.584 16.384 3.584h120.832c19.968 0 38.4-13.312 41.984-33.792 3.072-21.504-11.776-41.984-33.28-45.056L276.48 758.784h338.944l73.728 186.368c-21.504 3.584-36.864 23.552-33.792 45.056 3.584 19.968 22.016 33.792 41.984 33.792h257.024c16.896 0 31.744-8.192 36.864-23.552 9.728-19.968 1.536-44.544-18.432-54.784-5.12-2.56-11.776-4.096-17.92-4.096zM305.152 674.816l137.728-335.872 137.728 335.872H305.152z" p-id="32773" fill="#404040"></path></svg>',
						items: ['fontColor','fontBackgroundColor'],
						class: 'fontcolor-bgc'
					},
					'numberedList',
					'bulletedList',
					'todoList',
					'alignment:left',
					'alignment:right',
					'alignment:center',
					'alignment:justify',
				]
				
			} else {
				toolbar = [
					'paragraph',
					'heading1',
					'heading2',
					'heading3', 
					'numberedList',
					'bulletedList',
					'todoList',
					'link',
					'insertUploadImgButton',
					'insertUploadFileButton',
				]
			}
			let indexList = []
			for (let index = 0; index < toolbar.length; index++) {
				let t = toolbar[index]
				if(Object.prototype.toString.call(t) == '[object Object]') {
					indexList.push(this.editorConfig.blockToolbar.findIndex(bar => { return Object.prototype.toString.call(bar) == '[object Object]' }))
				} else {
					indexList.push(this.editorConfig.blockToolbar.findIndex(bar => { return  bar == t }))
				}
			}
			for (let index = 0; index < dom.children[0].children.length; index++) {
				let barItem =  dom.children[0].children[index]
				barItem.style.display = indexList.includes(index) ? 'flex' : 'none'
			}
		},
		judgeCursorStatus(flag){
			let fn = () => {
				if (document.hidden) {
					console.log('系统检测到您有切屏行为！！！---');
					this.showBlockToolbar = false;
				}
			}
			if(flag){
				window.onmouseout = (event) => {
					if (event.toElement === null) {
						console.log('警告，鼠标离开浏览器！！！');
						this.showBlockToolbar = false;
					}
				}
				window.addEventListener("visibilitychange",  fn);
			} else {
				window.onmouseout = null;
				window.removeEventListener("visibilitychange",  fn);
			}
			
		},
		
		// blockToolbar +按钮的点击事件
		blickToolbarClickFn(flag){
			let dom = this.ckeditorBtn;
			let scrollDom = this.getScrollDom();
			dom.onclick = () => {
				this.isQuickKeys = false;
				if(this.showBlockToolbar ){
					this.showBlockToolbar = false;
				} else {
					this.isClickBtn = true;
					this.getCkeditorButtonPosition('btn',true);
				}
				return false
			}
			this.scrollDom = scrollDom;
			if(flag){
				this.isHaveOperate = false;
				scrollDom.onscroll = (e) => {
					// this.setCkeditorFocusPate()
					if(!this.countPositionStatus) {
						this.showBlockToolbar = false;
					}
					let ckeditorBox = document.getElementsByClassName('ckeditor-box')[0]
					let scrollTop = e.target.scrollTop
					// 这是一个经典的阈值问题，当回车触发滚动的时候，固定的增加或者删除的值是32
					// 32 是 26 的行高加上了 顶部和底部 的margin 6px（这块另外的知识点不啰嗦。。。）
					// 然后再自然滚轮触发的时候，这个值固定等于 32 的几率几乎为0，所以32可以视为是一定为true（回车触发）的情况
					if(scrollTop - this.scrollTopValue == 32) {
						this.scrollTopValue = scrollTop;
						return
					} else if( scrollTop - this.scrollTopValue == -32) {
						return
					} else if( scrollTop - this.scrollTopValue == 400) {
						this.scrollTopValue = scrollTop;
						return
					}
					console.log(this.scrollTopValue,'this.scrollTopValue',scrollTop);
					// let ckeditorDom = this.getCkeditorDom()
					let scroll = scrollTop !== 0 ? scrollTop - this.scrollTopValue : scrollTop;
					// let scroll = scrollTop;
					this.scrollTopValue = scrollTop;
					// let domList =  this.getAllDom(Array.from(ckeditorDom.children))
					// let activeDom = domList[this.ckPath[0]];
					// this.ckeditorBtn.style.top = activeDom.getBoundingClientRect().top;
					this.ckeditorBtn.style.top = Number(this.ckeditorBtn.style.top.replace('px','')) - scroll + 'px';
					console.log(Number(this.ckeditorBtn.style.top.replace('px','')) < ckeditorBox.offsetTop , Number(this.ckeditorBtn.style.top.replace('px','')) > ckeditorBox.offsetTop + ckeditorBox.clientHeight);
					if(Number(this.ckeditorBtn.style.top.replace('px','')) < ckeditorBox.offsetTop || Number(this.ckeditorBtn.style.top.replace('px','')) > ckeditorBox.offsetTop + ckeditorBox.clientHeight) {
						this.ckeditorBtn.style.opacity = 0
					} else {
						this.ckeditorBtn.style.opacity = 1
					}
				}
			} else {
				dom.onclick = null;
				scrollDom.onscroll = null;
			}
		},
		// 点击body
		bodyCLickFn(){
			document.body.onclick = (e) => {
				if(this.aiCreateContentPopup){
					console.log('点击body');
				}
				this.setCkeditorFocusPate()
				let flag = true;
				let className = e.target.className || '';
				console.log(className,'className');
				// 点击左上角返回按钮时
				// if(typeof(className) === 'string'&&className.indexOf('go-back-knowledge') !== -1) {
				// 	return false
				// }
				let domList = Array.from(document.querySelectorAll('.ck-body-wrapper .ck-reset_all'));
				if(domList.length == 0) {
					return
				}
				if(typeof(className) === 'object'){
					if(className.baseVal.indexOf('ck-button') !== -1){
						flag = true;
					}
					if(className.baseVal.indexOf('heading-h-ask') !== -1){
						flag = false;
					}
				} else {
					// if(className.indexOf('ck-block-toolbar-button') !== -1 ||  className.indexOf('is-show-more-popup') !== -1  || className.indexOf('more') !== -1 || className.indexOf('ck-toolbar__nested-toolbar-dropdown') !== -1 || className.indexOf('ck-dropdown__button') !== -1) {
					if(className.indexOf('ck-block-toolbar-button') !== -1 || className.indexOf('ck-toolbar__item') !== -1 || className.indexOf('is-show-more-popup') !== -1  || className.indexOf('more') !== -1 || className.indexOf('ck-toolbar__nested-toolbar-dropdown') !== -1 || className.indexOf('ck-dropdown__button') !== -1) {
						flag = true
					} else {
						flag = false;
					}
				}
				// 选中时的富文本操作弹框显示 , 点击富文本的操作时 , 左侧弹框隐藏
				if(this.ckeditorType === 'normal'){
          let dom = this.getPanelDom()
					let ckBalloonPanelList = Array.from(dom.querySelectorAll('.ck-reset_all>.ck-balloon-panel'));
					if(ckBalloonPanelList[1] && this.getStyle(ckBalloonPanelList[1],'display') === 'block'){
						flag = false;
						// ckBalloonPanelList[0].style.display = 'none';
					}
					if(typeof(className) === 'string' && className.indexOf('ck-block-toolbar-button') !== -1){
						flag = true;
					}
				}
				if(typeof(className) === 'string' && className.indexOf('ck-toolbar__items') !== -1){
					flag = false;
				}
				// 获取点击元素对象
				let element = document.elementFromPoint(e.clientX,e.clientY);
				let parentNode = element.parentNode;
				if((parentNode.getAttribute('class')&&parentNode.getAttribute('class').indexOf('heading-h-ask') !== -1) || (element.getAttribute('class')&&element.getAttribute('class').indexOf('heading-h-ask') !== -1)){
					flag = false;
				}
				if(flag){
					// // this.getCkeditorButtonPosition('body',flag);
				} else {
					this.showBlockToolbar = false;
				}
				console.log(className,flag,'className',e.target);

			}
		},
		//封装方法进行兼容
        getStyle(element,attribute){
            //先获取需要获取样式的元素对象
            let style = window.getComputedStyle?window.getComputedStyle(element):element.currentStyle
            //再获取元素属性
            return style[attribute]
        },
		// 全局keydown事件
		keydownFn(){
			window.onkeydown = (e) => {
				// 用户按下Esc
				if (e.code === 'Escape'){
					if(this.aiCreateContentPopup){
						this.stopCreate(true);
						this.aiCreateContentPopup = false;
					} else {
						this.$parent.AigcVisiable = false;
					}
					// 阻止浏览器的ctrl+s默认行为
				} else {
					if ((e.ctrlKey  == true && e.keyCode == 83) || e.keyCode == 83 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)){
						e.preventDefault();
						if(this.ckeditorType === 'normal'){
							this.$emit('saveKnowledge','normal')
						}
					}
					if(this.showBlockToolbar) {
						// 上下左右的keyCode
						// 37 left
						// 38 up
						// 39 right
						// 40 down
						if(e.keyCode == '37' || e.keyCode == '38' || e.keyCode == '39' || e.keyCode == '40') {
							// this.handlerQuickKeys(e, e.keyCode);
							// e.preventDefault();
							// return false
						}  else if(e.keyCode == '13' && this.keyCodeIndex != -1) {
							// this.handlerQuickKeys(e, e.keyCode);
							// e.preventDefault();
						}
					} else {
						// if([13,37,38,39,40].includes(e.keyCode)) {
						// 	this.handlerQuickPopupKeys(e, e.keyCode);
						// 	e.preventDefault();
						// }
					}
					
				}
			}
		},
		setStyle(item, reset) {
			if(reset) {
				item.style.background = '#fff'
				item.style.color = '#2c3e50'
				item.style.borderRadius = 'none'
				return
			}
			item.style.background = '#EAEFFF'
			// item.style.color = '#366AFF'
			item.style.borderRadius = '5px'
			
		},
		decreaseProgressively(count, helpList, domList) {
			for (let index = 0; index < helpList.length; index++) {
				const className =  helpList[index].className
				// 包含当前值
				if(this.keyCodeIndex - count <= 0) {
					return 0
				} else if(domList[this.keyCodeIndex - count].className == className) {
					return this.keyCodeIndex - count
				}
			}
			return this.keyCodeIndex - 1
		},
		progressivelyIncrease(count,  helpList, domList){
			for (let index = 0; index < helpList.length; index++) {
				const className =  helpList[index].className
				// 包含当前值
				if(domList[this.keyCodeIndex + count].className == className) {
					return this.keyCodeIndex + count
				}
			}
			return this.keyCodeIndex + 1
		},
		// 富文本的所有基础操作增加点击事件监测 
		ckeditorClickFn(){
			// let domList = Array.from(document.querySelectorAll('.ck-balloon-panel'));
			// console.log(domList,'domListdomListdomList');
			let dom = this.currentEditor.ui.view.body._bodyCollectionContainer.children[0];
			// if(this.ckeditorType === 'normal'){
			// 	dom = domList[1]
			// } else {
			// 	dom = domList[3]
			// }
			// domList.forEach(() => {f
			dom.querySelector('.ck-toolbar_grouping>.ck-toolbar__items').onclick = (e) => {
				console.log(1111111);
				e.preventDefault();
				this.isHaveOperate = true;
				let flag = false;
				//  || this.showPopupType === 'btn'
				if(this.showPopupType === 'clickBottomBtn' || this.showPopupType === 'quickCode'){
					flag = false;
				} else {
					flag = Array.from( dom.classList).some((item) => {
						if(item.indexOf('ck-balloon-panel_visible') !== -1){
							return true;
						}
						if(item.indexOf('ck-balloon-panel_visible-self') !== -1){
							return true;
						}
					})
				}
				if(!flag){
					// this.hiddenTooltips(true);
				}
				this.showBlockToolbar = flag;
				return false;
			}
			// })
		},
		ckeditorReady(editor){
			console.log('editor=====', editor);
			this.currentEditor = editor;
			let ckeditorDom = this.getCkeditorDom();
			ckeditorDom.onkeydown = (e) => {
				this.isQuickKeys = false;
				if(((e.keyCode === 191 || e.keyCode === 111) && e.key=== '/') || ( e.keyCode === 229 && ((e.code ===  'Slash' && !e.shiftKey)|| e.code ===  'Backslash')) || ( e.keyCode === 220 && e.key === '、')){
					this.showBlockToolbar = false;
					this.showCkeditorPopup('quickCode');
					// return false; // false 快捷键不显示    true 快捷键显示
				} else if(e.keyCode == '37' || e.keyCode == '38' || e.keyCode == '39' || e.keyCode == '40') {
					// 上下左右的code
					// 37 left
					// 38 up
					// 39 right
					// 40 down
					// this.handlerQuickKeys(e, e.keyCode);
					// return false
				}  else if(e.keyCode == '13' && this.keyCodeIndex != -1 && this.showBlockToolbar) {
					// this.handlerQuickKeys(e, e.keyCode);
				} else if (e.code === 'Escape'){
					if(this.aiCreateContentPopup){
						this.stopCreate(true);
						this.aiCreateContentPopup = false;
					} else {
						this.$parent.AigcVisiable = false;
					}
				} else {
					this.showBlockToolbar = false;
					// this.resetData('AiCreate',false,false);
				}
            }
			// 在 editor ready 时建立对文本的 监听 粘贴事件，处理 特殊类型
			this.currentEditor.editing.view.document.on('clipboardInput', ( evt,data ) => {
				const dataTransfer = data.dataTransfer;
				let rtfContent = '';
				if(dataTransfer.getData('text/html')) {
					rtfContent = dataTransfer.getData('text/html')
					rtfContent = rtfContent.replace(/<!--[\s\S]*?-->/gmi, '');
					rtfContent = rtfContent.replace(/ style=".*?"/g, '')
					// if(rtfContent.includes('div.Section0{page:Section0;}')) {
						let viewFragment = this.currentEditor.data.processor.toView(rtfContent);
						let modelFragment = this.currentEditor.data.toModel( viewFragment );
						console.log(modelFragment,'modelFragment');
						this.currentEditor.model.insertContent( modelFragment );
						evt.stop()
					// }
				} else if(dataTransfer.getData('text/plain')) {
					rtfContent = dataTransfer.getData('text/plain')
				} else {
					if(dataTransfer._files.length > 0) {
						let fileList = dataTransfer._files
						for (let index = 0; index < fileList.length; index++ ) {
							const file = fileList[index];
							if(!file.type.includes('image')) {
								this.upload(file).then(res => {
									this.$http.post(requestUrl.knowledge.getTemporaryCertificate,{fileInOssPath:res.default}).then((urlRes) => {
										let url = urlRes.data.data;
										let command = this.currentEditor.commands.get("insertAskComponent");
										let openUrl = process.env.VUE_APP_NODE_ENV == 'production' ? 'https://kkfileview.askbot.cn/onlinePreview?url=' + url : 'https://test.open.askbot.cn/kkfileview/onlinePreview?url=' + url;
										let iframeUrl = 'https://portal.test.askbot.cn/#/viewFile?fileUrl=' + openUrl + '&fileName=' + res.name
										// 需要回调通知父组件 上传成功
										command.execute({
											tag: "iframe",
											options: {
												width:"100%",
												height:'400px',
												customaryurl:res.default,
												src: iframeUrl,
												filename: res.name,
											},
										});
									})
								})
							}
						}
					}
				}
				let str = rtfContent.replace(/ style=".*?"/g, '')
				let str2 =  str.replaceAll('&lt;br/&gt;', '<p>')
				data.content = this.currentEditor.data.htmlProcessor.toView(str2)
				// let viewFragment2 =this.currentEditor.data.htmlProcessor.toView(str2);
				// let modelFragment2 = this.currentEditor.data.toModel( viewFragment2 );
				// data.content = modelFragment2;
			})
			// ckeditorDom.onpaste = (e)=>{
			// 	console.log(e);
			// }
			// let domList = Array.from(document.querySelectorAll('.ck-body-wrapper .ck-reset_all'));
			// let dom = null;
			// let CollectionContainer = editor.ui.view.body._bodyCollectionContainer
			// let dom = this.currentEditor.ui.view.body._bodyCollectionContainer.children[1]
			this.ckeditorBtn = this.currentEditor.ui.view.body._bodyCollectionContainer.children[1];
			ckeditorDom.ondblclick = () => {
				this.isDblclick = true;
				return false;
			}
			//
			ckeditorDom.onmousedown = (e) => {
				this.mouseDownFlag = true;
				let className = e.target.className || '';
				if(className === ''){
					this.showBlockToolbar = false;
				}
			}
			ckeditorDom.onmouseup = () => {
				this.mouseDownFlag = false;
				// this.ckeditorBtn.classList.remove('ck-hidden')
			}
			
			console.log(this.currentEditor.ui.view,'this.currentEditor');
			this.currentEditor.model.document.on('change:data', () => {
				ckeditorDom.onkeyup = (e) => {
					console.log(e,'键盘抬起');
				}
			})
		},
		ckeditorBlur(){
			this.selectionPosition = this.currentEditor.model.document.selection.getFirstPosition();
			console.log('失焦事件',this.selectionPosition);
		},
		// 富文本聚焦事件
		ckeditorFocus(){
			this.getScrollDom().style.height = this.scrollDomHeight + 'px';
			// this.ckeditorBtn.classList.remove('ck-hidden')
			if(this.ckeditorBtn){
				this.ckeditorBtn.style.opacity = 1
				// this.ckeditorBtn.style.display = 'block'
			}
			// focusImade 是一个暴力解决问题的方式，往往在对富文本执行一些操作的时候首先需要 foucs，此时不希望执行以下代码
			if(this.focusImade) {
				return
			}
			if(this.popupType === 'AIMade'){
				this.aiCreateContentPopup = false;
			}
			this.hideAigc();
			// +按钮位置超出内容区域 , 不显示
			setTimeout(() => {
				console.log('+按钮位置超出内容区域',this.ckeditorBtn.style.top);
				if(Number(this.ckeditorBtn.style.top.replace('px','')) < 110){
					this.ckeditorBtn.classList.add('ck-hidden')
				}
			},100)
		},
		hideAigc(){
			if(this.ckeditorValues.includes('<span style="background-color:#CDDCFF')) {
				// 这是一个兼容的方法，selectionRange 是记录滑过选中内容的 节点位置，
				// removeAttribute 第一个参数是要删除的属性值，第二个参数是 节点位置
				// 存在一种情况，选中后刷新页面因为无法阻止，因此会有背景色被保留的问题，此时selectionRange 对象又为null，所以需要正则匹配字符串给截取掉，这种方法会有性能问题所以尽量少用
				// if(this.selectionRange) {
				// 	console.log(this.selectionRange);
				// 	this.currentEditor.model.change(writer =>{
				// 		writer.removeAttribute('fontBackgroundColor', this.selectionRange)
				// 	})
				// } else {
					let reg = new RegExp('<span style="background-color:#CDDCFF','g')
					let value = this.ckeditorValues.replace(reg, '<span style="')
					// .replaceAll('<br>','<p>')
					this.currentEditor.setData(value)
				// }
				this.$parent.AigcVisiable = false
				this.selectionRange = null
			}
		},
		// 富文本的值发生改变时
		changeData() {
			console.log(this.ckeditorValues,'this.ckeditorValues');
			this.changeFlag = true;
			this.setCkeditorFocusPate();
			this.$emit('ckeditorValueChange','content')
			if(this.ckeditorValues !== ''){
				if(!this.isQuickTemplate){
					this.ckeditorAutoScroll('changeData')
				}
			}
			this.updateChangeStatus();
		},
		updateChangeStatus: _.debounce(function() {
			this.changeFlag = false;
			
		}, 100),
		// 基础操作的弹框  初始化
		resetData(type,showBlockToolbar,aiCreateContentPopup){
			this.popupType = type;
			this.showBlockToolbar = showBlockToolbar;
			if(aiCreateContentPopup){
				this.setAIPopupPosition('showPopup');
				this.$refs.AiCreatePopup.getPopupWidth();
				if(type === 'AIMade'){
					this.$refs.AiCreatePopup.getUserHistoryCmd('AIMade');
				}
			}
			this.aiCreateContentPopup = aiCreateContentPopup;
		},
		// 获取ckeditor的按钮的位置信息
		getCkeditorButtonPosition(type,flag){
			this.triggerType = type;
			// 这个方法涉及到了部分时间复杂度的问题。做一个变量记录是否是在代码的处理过程中
			this.countPositionStatus = true
			this.$nextTick(() => {
				this.setCkeditorFocusPate()
				this.setCkeditorBalloonbarItemDispaly(type);
				if(type === 'clickBottomBtn'){
					this.restCursorPosition()
				}
				const view = this.currentEditor.editing.view;
				const modelTarget = Array.from( this.currentEditor.model.document.selection.getSelectedBlocks() )[ 0 ];
				const domTarget = view.domConverter.mapViewToDom( this.currentEditor.editing.mapper.toViewElement( modelTarget ) );
				const contentStyles = window.getComputedStyle( domTarget );
				const editableRect = new Rect( this.currentEditor.ui.getEditableElement() );
				const contentPaddingTop = parseInt( contentStyles.paddingTop, 10 );
				const contentLineHeight = parseInt( contentStyles.lineHeight, 10 ) || parseInt( contentStyles.fontSize, 10 ) * 1.2;
				// 获取 '+' 按钮的位置
				const position = getOptimalPosition( {
					element: document.querySelector('.ck-block-toolbar-button'),
					target: domTarget,
					positions: [
						( contentRect, buttonRect ) => {
							let left;

							if ( this.currentEditor.locale.uiLanguageDirection === 'ltr' ) {
								left = editableRect.left - buttonRect.width;
							} else {
								left = editableRect.right;
							}

							return {
								top: contentRect.top + contentPaddingTop + ( contentLineHeight - buttonRect.height ) / 2,
								left
							};
						}
					]
				} );
				let rect = {}
				// AI生成过程中有鼠标点击事件时会改变光标的位置,所以需要根据蒙层显隐判断取值
				if(this.maskFlag){
					rect = {}
				} else {
					let selection = window.getSelection()
					let range = selection.getRangeAt(0) // 是用于管理选择范围的通用对象
					// 获取光标的位置
					rect = range.getClientRects()[0] || {} // 择一些文本并将获得所选文本的范围
				}
				// 450 高度
				// let popupHeight = 450;
				// let popupHeight = document.querySelector('.popup-floating-layer-ckeditor').clientHeight + 50;
				let popupDom =  null;
				if(this.ckeditorType === 'normal'){
					popupDom =  document.querySelector('.popup-floating-layer-ckeditor');
				} else {
					popupDom =  document.querySelector('.save-template-popup .popup-floating-layer-ckeditor');
				}
				popupDom.style.display = 'block';
				popupDom.style.position = 'fixed';
				popupDom.style.zIndex = '-1000';
				let popupHeight = popupDom.clientHeight + 20;
				popupDom.style.display = 'none';
				if(this.ckeditorType === 'normal'){
					popupDom.style.zIndex = 2;
				} else {
					popupDom.style.zIndex = 2300;
				}
				this.positionBtn = {
					top:Math.ceil( position._positioningFunctionCorrdinates.top),
					left:Math.ceil( position._positioningFunctionCorrdinates.left)
				};
				this.cursorRect = {
					top:Math.ceil(rect.top),
					left:Math.ceil(rect.left)
				};

				// 操作栏距离弹框顶部高度是165px   弹框高度是420px
				// console.debug(this.positionBtn.top,this.positionBtn.left,'按钮');
				// console.debug(this.cursorRect.top,this.cursorRect.left,'光标');
				// 点击按钮   和   当前行无内容时却使用快捷键时
				this.popupStyle = {
					top: this.positionBtn.top + 20,
					left:this.positionBtn.left - 51
				}
				this.ckeditorPosition = {
					top: this.positionBtn.top + 185,
					left: this.positionBtn.left - 41
				}
				let totalHeight = document.body.clientHeight;
				let totalWidth = document.body.clientWidth;
				if(this.ckeditorType === 'normal'){
					totalHeight = document.body.clientHeight
					totalWidth =  document.body.clientWidth;
				} else {
					totalHeight = document.querySelector('.defaultDialog').clientHeight;
					totalWidth =  document.querySelector('.defaultDialog').clientWidth;
				}
				// console.log(popupHeight,'popupHeight');
				// console.log(this.positionBtn.top,this.cursorRect.top,'位置');
				// console.log(this.popupStyle.top,'弹框位置');
				if(type === 'quickCode' || type === 'clickBottomBtn') {
					if(this.cursorRect.top){
						this.popupStyle = {
							top: this.cursorRect.top + 35,
							left:this.cursorRect.left - 51
						}
						this.ckeditorPosition = {
							top: this.cursorRect.top + 200,
							left: this.cursorRect.left - 41
						}
						// 当右侧距离过小时 , 弹框整体往左移;
						if(this.popupStyle.left + 300 > totalWidth){
							this.popupStyle.left = this.popupStyle.left - 300;
							this.ckeditorPosition.left = this.ckeditorPosition.left - 300;
						}
					}
				} else if (type === 'getPositionData' || type === 'aiCollabortion'){
					this.setAIPopupPosition(type);
				}
				if(type !== 'getPositionData'){
					// console.log(this.popupStyle.top,this.ckeditorPosition.top,'第一次处理后的数据' );
					// console.log(this.popupStyle.top + 420 > (document.body.clientHeight),'是否在上面');

					if(this.popupStyle.top + popupHeight > totalHeight){
						this.popupStyle.top = this.popupStyle.top - popupHeight-20;
						this.ckeditorPosition.top = this.popupStyle.top+165;
						if(type !== 'btn'){
							if(this.cursorRect.top){
								this.popupStyle.top = this.cursorRect.top - popupHeight-20;
								this.ckeditorPosition.top = this.popupStyle.top+165;
							}
						}
						// console.log(this.popupStyle.top,'最终的位置');
					}
					if(this.popupStyle.top < 0){
						if(this.isTemplateManage){
							this.popupStyle = {
								top:150,
								left:360
							}
							this.ckeditorPosition = {
								top:315,
								left:370
							}
						} else {
							this.popupStyle = {
								top:150,
								left:20
							}
							this.ckeditorPosition = {
								top:315,
								left:30
							}
						}
					}
				this.setCkeditorBaseSettionPosition(type,flag);
				}
			})
		},
		// 设置弹框中关于富文本的基础操作的位置
		setCkeditorBaseSettionPosition(type,flag){
			let dom = this.getPanelDom()
			if (flag){
				this.showPopupType = type;
				dom.classList.add('ck-balloon-panel_visible');
				dom.classList.add('ck-balloon-panel_visible_self');
				dom.style.top = this.ckeditorPosition.top + 'px';
				dom.style.left = this.ckeditorPosition.left + 'px';
				let toolbarDom = dom.querySelector('.ck-toolbar_floating>.ck-toolbar__items');
        if (toolbarDom){
          Array.from(toolbarDom.children).forEach((item,index) => {
            if(index !== 4) {
              item.classList.add('heading-h-ask');
              if(item.firstChild.tagName === 'svg'){
                item.firstChild.classList.add('heading-h-ask');
              }
            }
          })
        }
			}
			this.$nextTick(() => {
				this.showBlockToolbar = flag;
				setTimeout(() => {
					this.countPositionStatus = false
				}, 0)
				this.$forceUpdate()
			})
		},
		// 设置AI生成/AI协作位置
		setAIPopupPosition(type){
			console.debug(type);
			if(this.popupType === 'AiCreate'){
				if(this.triggerType === 'btn'){
					this.AiPopupPosition = {
						top:this.positionBtn.top + 50,
						left:this.positionBtn.left
					}
				} else {
					if(this.cursorRect.top){
						this.AiPopupPosition = {
							top:this.cursorRect.top + 50,
							left:this.positionBtn.left
						}
					} else {
						this.AiPopupPosition = {
							top:this.positionBtn.top + 25,
							left:this.positionBtn.left
						}
					}
				}
			} 
			let def = 140;
			let ckeditorDom = this.getCkeditorDom();
			let scrollDom = this.getScrollDom();
			let pDomList = ckeditorDom.children;
			let index = this.ckPath[0];
			let activePDom = pDomList[index];
			let activePDomHeight = activePDom && activePDom.clientHeight ? activePDom.clientHeight : 50;
			// element.getBoundingClientRect() 返回元素的大小及其相对于视口的位置。
			// console.log(activePDom,'activePDom');
			let coordinateY = activePDom &&  activePDom.getBoundingClientRect() && activePDom.getBoundingClientRect().top ? activePDom.getBoundingClientRect().top : 0;
			if(coordinateY === 0){
				coordinateY = this.AiPopupPosition;
			}
			let lastTop = Math.ceil(coordinateY + activePDomHeight);
			let postion = {
				// top:this.triggerType === 'btn' ? lastTop + 5 : this.AiPopupPosition.top,
				top:lastTop + 5,
				left:this.positionBtn.left
			}
			if(this.popupType === 'AIMade'){
				if(this.$refs.AiCreatePopup.firstColumn.length > 0 && this.$refs.AiCreatePopup.secondColumn.length > 0){
					def = 305
				} else if((this.$refs.AiCreatePopup.firstColumn.length > 0 || this.$refs.AiCreatePopup.secondColumn.length === 0) || (this.$refs.AiCreatePopup.firstColumn.length === 0 || this.$refs.AiCreatePopup.secondColumn.length > 0)){
					def = 270
				} else {
					def = 225
				}
				postion = {
					top:lastTop + 5,
					left:this.positionBtn.left
				}
			} else {
				if(this.$refs.AiCreatePopup.firstColumn.length > 0 && this.$refs.AiCreatePopup.secondColumn.length > 0){
					def = 240
				} else if((this.$refs.AiCreatePopup.firstColumn.length > 0 || this.$refs.AiCreatePopup.secondColumn.length === 0) || (this.$refs.AiCreatePopup.firstColumn.length === 0 || this.$refs.AiCreatePopup.secondColumn.length > 0)){
					def = 215
				} else {
					def = 160
				}
			}
			if(this.$refs.AiCreatePopup.isCreateing){
				def = 100;
			}
			// if(this.popupType === 'AiCreate'){
				let totalHeight = document.body.clientHeight - 20;
				if(this.ckeditorType === 'normal'){
					totalHeight = document.body.clientHeight - 20;
				} else {
					totalHeight = document.querySelector('.defaultDialog').clientHeight;
				}
				// if(this.AiPopupPosition.top > totalHeight){
					// console.log(this.AiPopupPosition.top,def,'this.AiPopupPosition.top');
					// console.log(scrollDom.style.height);
					// scrollDom.scrollTop = scrollDom.scrollTop + Array.from(ckeditorDom.children)[this.ckPath[0]].clientHeight;
					// this.AiPopupPosition.top = scrollDom.clientHeight;
				// }
				// console.log(totalHeight,'总体高度');
				// console.log(postion.top,'postion.top');
				// console.log(activePDomHeight,'当前元素高度');
				// console.log(scrollDom.clientHeight,'scrollDom.clientHeight');
				// console.log(scrollDom.scrollTop,'---------初始的scroll');
				let oldScrollTop = scrollDom.scrollTop
				if(postion.top +  def > totalHeight){
					// console.log('大于了--');
						// 有滚动条
					if(scrollDom.scrollTop > 0){
						// console.log('本身有滚动条');
						scrollDom.scrollTop += def;
						// 处于底部时,设置scrollTop也无效  判断是否处于底部 
						// 如果是底部, 减小高度,增加设置scrollTop
						if(scrollDom.scrollTop === oldScrollTop){
							// console.log(scrollDom.scrollTop,scrollDom.style.height,'在底部了----');
							scrollDom.style.height = scrollDom.clientHeight - def + 'px';
							
							scrollDom.scrollTop += def;
							// console.log(scrollDom.scrollTop,scrollDom.style.height,'在底部了')
							postion.top = postion.top - def;
						} else {
							postion.top = postion.top - activePDomHeight;
							scrollDom.scrollTop += activePDomHeight;
						}
					} else {
						console.log(scrollDom.scrollTop,'无滚动条');
					// 	// 无滚动条
						scrollDom.scrollTop = 10;
						if(scrollDom.scrollTop > 0){
							scrollDom.style.height = scrollDom.clientHeight - 10 + 'px';
						}
						scrollDom.scrollTop += activePDomHeight;
						postion.top = postion.top - activePDomHeight;
					}
				}
			// }
			let difference = postion.top + def - totalHeight;
			if(difference > 0){
				scrollDom.style.height = scrollDom.clientHeight - difference - activePDomHeight  + 'px';
				scrollDom.scrollTop += difference + activePDomHeight;
				postion.top = postion.top - difference + activePDomHeight;
			}
			this.popupPositionObj = postion;
			this.AiPopupPosition = postion
			// if(type !== 'aiCollabortion'){
				this.handlerScroll(true);
			// } 
		},
		//判断页面是否存在滚动条 返回true:存在滚动条 | false:不存在滚动条
        hasScrollbar(dom) {
            return dom.scrollHeight > dom.clientHeight;
        },
		// AI生成时,位置处理
		async handlerScroll(){
			let ckeditorDom = await this.getCkeditorDom();
			let scrollDom = await this.getScrollDom();
			let totalHeight = document.body.clientHeight - 10;
			if(this.ckeditorType === 'normal'){
				totalHeight = document.body.clientHeight;
			} else {
				totalHeight = document.querySelector('.defaultDialog').clientHeight;
			}
			if(this.popupType === 'AiCreate') {
				let paddingBottom = 140,Dvalue = 100;
				if(this.AiPopupPosition.top + paddingBottom > totalHeight){
						scrollDom.style.height = (this.scrollDomHeight - Dvalue) + 'px';
						// scrollDom.scrollTop = scrollDom.scrollTop + Array.from(ckeditorDom.children)[this.ckPath[0]].clientHeight;
						this.popupPositionObj.top = Number(scrollDom.style.height.replace('px','')) + 100
				} else {
					// console.log('AiCreate没有----超出');
				}
			} else if (this.popupType === 'AIMade'){
				let paddingBottom2 = 240,Dvalue2 = 200;
				if(this.AiPopupPosition.top + paddingBottom2 > totalHeight){
					// console.debug('AIMade超出了');
						scrollDom.style.height = (this.scrollDomHeight - Dvalue2) + 'px';
						ckeditorDom.style.paddingBottom = Dvalue2 + 'px';
						this.popupPositionObj.top = this.AiPopupPosition.top - Dvalue2 + 40;
				} else {
					// console.debug('AIMade没有----超出');
				}
			}
		},
		// 当光标是在最后一行时,ckeditor需要自动向上滚动一行的距离
		ckeditorAutoScroll(){
			this.$nextTick(() => {
				// this.setCkeditorFocusPate();
				let ckeditorDom = this.getCkeditorDom();
				// let selection = window.getSelection()
				// let range = selection.getRangeAt(0) // 是用于管理选择范围的通用对象
				// let rect = range.getClientRects()[0] || {} // 择一些文本并将获得所选文本的范围
				// let rectObj = {
				// 	top:Math.ceil(rect.top),
				// 	left:Math.ceil(rect.left)
				// }
				// let topValue = 0;
				// if(!rectObj.top){
				// 	topValue = this.positionBtn.top
				// } else {
				// 	topValue = rectObj.top
				// }
				// if(type === 'changeData' && !this.aiCreateContentPopup){
				// 	let paddingBottom = 40;
				// 	if(((topValue + 80) - 220) > (document.body.clientHeight - 220) ){
				// 	// if((topValue + 40) > (document.body.clientHeight - paddingBottom - 66) ){
				// 		ckeditorDom.scrollTop = ckeditorDom.scrollTop + paddingBottom;
				// 		ckeditorDom.style.paddingBottom = paddingBottom + 'px';
				// 		console.log(ckeditorDom.scrollTop,'scrollTopscrollTop');
					
				// 	}
				// }
				// 当前是最后一行
				
				let scrollDom = this.getScrollDom();
				
				// console.debug(scrollDom.scrollTop,'ckeditorDom-判断最后一行的');
				// console.debug(Array.from(ckeditorDom.children).length,this.ckPath,'判断最后一行的');
				if(!this.isDblclick){
					if(scrollDom.scrollTop > 0){
						if(Array.from(ckeditorDom.children).length === this.ckPath[0]+1){
							this.focusImade = true;
							setTimeout(() => {
								this.ckeditorBtn.style.top = scrollDom.offsetTop + scrollDom.clientHeight - ckeditorDom.style.paddingBottom - ckeditorDom.children[ckeditorDom.children.length - 1].clientHeight - 3 + 'px'
								this.focusImade = false;
							}, 0);
						}
					}
				}
			})
		},
		updataPosition() {
			let scrollDom = this.getScrollDom();
			let ckeditorDom = this.getCkeditorDom();
			this.ckeditorBtn.style.top = scrollDom.offsetTop + scrollDom.clientHeight - ckeditorDom.style.paddingBottom - ckeditorDom.children[ckeditorDom.children.length - 1].clientHeight - 3 + 'px'
		},
		showCkeditorPopup(type){
			this.isQuickKeys = true;
			if(type === 'clickBottomBtn'){
				this.hideAigc()
				this.$nextTick(() => {
					// this.currentEditor.editing.view.focus();
					this.currentEditor.model.change((writer) => {
						writer.setSelection( writer.createPositionAt(this.currentEditor.model.document.getRoot(), 'end' ) ); 
					});
					let ckeditorDom = this.getCkeditorDom();
					let children = Array.from(ckeditorDom.children)
					if(children[this.ckPath[0]].innerHTML.indexOf('img') !== -1 || children[this.ckPath[0]].innerHTML.indexOf('iframe') !== -1){
						let command = this.currentEditor.commands.get("insertAskComponent");
						// 需要回调通知父组件 上传成功
						command.execute({
							tag: "section",
							options: {
								className:'self-p-section',
								data: 'self-p-section'
							},
						});
					}
					this.currentEditor.model.change((writer) => {
						this.currentEditor.model.insertContent(writer.createText('/'));
					});
					this.currentEditor.editing.view.focus();
					this.setCkeditorFocusPate();
					this.selectionPosition = this.currentEditor.model.document.selection.getFirstPosition()
					let scrollDom = this.getScrollDom()
					scrollDom.scrollTop = scrollDom.scrollHeight
					this.getCkeditorButtonPosition('clickBottomBtn',true);
				})
			} else {
				this.getCkeditorButtonPosition('quickCode',true);
			}
		},
		// ' AI协作 ' 按钮点击事件
		aiCollabortionButton(event, callback) {
			console.debug(event, callback,'AI写作');
			console.log(this.currentEditor,'this.currentEditor');
			this.textPlain =  this.setSelections();
			this.selectionRange = this.currentEditor.model.document.selection._ranges[0]

			if(this.textPlain.length > 1000){
				this.messageFn('warning', '当前选择内容过多,请重新选择');
				return false;
			}
			this.getCkeditorButtonPosition('aiCollabortion',false)
			this.resetData('AIMade',false,true);
			this.$emit('btnOperation','aiCollabortion');
		},
		// ' 存为模板 ' 按钮点击事件
		async saveTemplateButton(event, callback) {
			console.debug(event, callback);
			// 获取选中的内容
			let range = window.getSelection().getRangeAt(0)
			let fragment = range.cloneContents()
			let div = document.createElement('div')
			div.appendChild(fragment)
			this.$emit('btnOperation','saveTemplate',div.innerHTML)
			div = null
			// 获取选中的内容 并设置到剪切板中 , 再从剪切板中获取内容
			// document.execCommand('copy',false,null);
			// await this.pasteHandler().then((contnet) => {
			// 	console.log(range.commonAncestorContainer.innerHTML);
			// 	console.log(contnet);
			// })
		},
		setSelectionCommon(content) {
			this.$nextTick(() => {
				this.currentEditor.model.change(writer => {
					writer.remove(this.selectionRange)
					let arr = content.split('<br/>')
					let start = null
					let end = null
					for (let index = 0; index < arr.length; index++) {
						if(index == 0) {
							start = this.currentEditor.model.document.selection.getFirstPosition()
						}
						writer.insertText(arr[index],{fontBackgroundColor: '#CDDCFF'},this.currentEditor.model.document.selection.getFirstPosition())
						if(index == arr.length - 1) {
							end = this.currentEditor.model.document.selection.getFirstPosition()
						} else {
							this.currentEditor.execute('enter')
						}
					}
					this.selectionRange = writer.createRange(start, end)
				})
			})
		},
		getSelectionCommon() {
			let div = document.createElement('div')
			div.innerHTML = this.ckeditorValues
			let text = ''
			let serchTextFn = (nodeList) => {
				for (let index = 0; index < nodeList.length; index++) {
					let node = nodeList[index]
					if(node.style && node.style.backgroundColor == 'rgb(205, 220, 255)') {
						text += node.innerText
					}
					if(node.children && node.children.length != 0) {
						serchTextFn(node.children)
					}
				}
			}
			serchTextFn(div.children)
			return text
		},
		// AI生成后 删除快捷键 并移动光标位置
		handlerQuickDel(){
			if(this.isQuickKeys) {
				console.log('AI生成后 删除快捷键 并移动光标位置');
				this.currentEditor.editing.view.focus();
				this.$nextTick(() => {
					let path = this.selectionPosition
					path.path = [path.path[0], path.path[1] - 1]
					this.currentEditor.model.change(writer => {
						let range = writer.createRange(path, this.currentEditor.model.document.selection.getFirstPosition())
						writer.remove(range)
						this.isQuickKeys = false;
						this.isHaveOperate = false;
					})
				})
			} else {
				this.restCursorPosition()
			}
		},
		// ckeditor 失去焦点前会记录当前光标位置，在需要时重新定位回去
		restCursorPosition(){
			this.currentEditor.editing.view.focus();
			if(this.selectionPosition){
				this.$nextTick(() => {
					this.currentEditor.model.change(writer => writer.setSelection(this.selectionPosition))
				})
			}	
		},
		// 获取富文本所有元素
		getAllDom(list) {
			let flatArr = [];
			list.forEach((item) => {
				if(item.tagName !== 'UL' && item.tagName !== 'OL' && item.tagName !== 'BR' && item.tagName !== 'svg'  && item.tagName !== 'path' && item.tagName !== 'DIV'  && item.tagName !== 'SPAN') {
					flatArr.push(item)
				}
				if (item.children && (item.tagName != 'svg' || item.tagName !== 'UL' || item.tagName !== 'OL')) {
					flatArr = [...flatArr, ...this.getAllDom(Array.from(item.children))];
				}
			})
			return flatArr
		},
		handlerImgAndIframe(){
			// let ckeditorDom = this.getCkeditorDom();
			// let list = this.getAllDom(Array.from(ckeditorDom.children))
			// console.log(list,this.ckPath);
			if(!this.ckPath[1]){
				this.selectionPosition.path = JSON.parse(JSON.stringify([this.ckPath[0],0]));
			} else {
				this.selectionPosition.path = JSON.parse(JSON.stringify(this.ckPath));
			}
			this.restCursorPosition();
		},
		// 获取AI协作的生成内容
		getCreateData(data){
			if(data !== '' && data !== undefined) {
				if(this.popupType === 'AIMade'){
					this.$emit('AigcInsert',data);
				} else {
						// this.currentEditor.editing.view.focus();
						if(data.indexOf('<br/>') !== -1){
							let command = this.currentEditor.commands.get("insertAskComponent");
							// 需要回调通知父组件 上传成功
							command.execute({
								tag: "section",
								options: {
									className:'self-p-section',
									data: 'self-p-section'
								},
							});
							// this.currentEditor.execute('enter') // 会产生多余的空格
						} else {
							this.currentEditor.model.change((writer) => {
								this.currentEditor.model.insertContent(writer.createText(data));
							});
						}
						this.$nextTick(() => {
							this.setCkeditorFocusPate('getPositionData');
							let scrollDom = this.getScrollDom();
							let ckeditorDom = this.getCkeditorDom();
							if(Array.from(ckeditorDom.children).length === this.ckPath[0]+1){
								scrollDom.scrollTop = scrollDom.scrollHeight
							}
						})
					
				}	
			}
		},
		setCkeditorFocusPate(type) {
			if(this.currentEditor) {
				this.ckPath = this.currentEditor.model.document.selection.focus.path
			} else {
				this.ckPath = [0, 0]
			}
			if(type === 'getPositionData'){
				this.getCkeditorButtonPosition('getPositionData');
			}
		},
		// 选择 'AI帮我写' 中的类型 或 点击AI帮我写按钮
		aiHelpWriteFn(item,type){
			console.log(item,'点击AI帮我写按钮');
			if(item.type === 'more') {
				this.clickMore();
			} else {
				let scrollDom = this.getScrollDom();
				if(scrollDom.scrollTop > 0){
					setTimeout(() => {
						this.fn(type,item);
					},100)
				} else {
					this.fn(type,item);
				}
			}
		},
		fn(type,item){
			this.resetData('AiCreate', false, true);
			this.aiCreateContentValue = '';
			if (type === 'helpWrite'){
				// this.aiCreateContentValue = '帮我写一份' + item.name + '关于'
				this.aiCreateContentValue = item.tips;
			} else {
				this.aiCreateContentValue = ''
			}
		},
		getSelections() {
			this.currentEditor.model.change(() => {
				const documentSelection = this.currentEditor.model.document.selection;
				this.setSelectionParams = {
					start: documentSelection._selection._ranges[0].start,
					end: documentSelection._selection._ranges[0].end
				}
            });
		},
		setSelections(){
			let text = ''
			let range = window.getSelection().getRangeAt(0)
			let fragment = range.cloneContents()
			let div = document.createElement('div')
			div.appendChild(fragment)
			text = div.innerText
			this.currentEditor.execute('fontBackgroundColor', { value: '#CDDCFF' });
			return text
		},
		// 是否显示帮我写的更多
		showMoreHelpWrite(){
			this.isShowMoreHelpWrite = true;
			this.popupHeight = document.querySelector('.popup-floating-layer-ckeditor').clientHeight - 135;
		},
		// 根据当前value创建dom 节点，并循环所有children，根据富文本的聚焦位置做替换处理
		getValuesCreatNode(replaceStr, type) {
			if(!this.isQuickKeys) return;
			let div = document.createElement('div')
			let flag = true
			div.innerHTML = this.ckeditorValues
			// 声明随机id 记录位置。
			let uuid = uuidv4()
			let nodeCount  = 0
			for (let index = 0; index < div.children.length; index++) {
				// let count = -1
				let element = div.children[index];
				if(element.tagName == 'UL' || element.tagName == 'OL') {
					for (let j = 0; j < element.children.length; j++) {
						let nodeDate =  element.children[j];
						if(nodeCount == this.ckPath[0]) {
							if(type == 'replace') {
								let strList = nodeDate.innerText.split('')
								strList.splice(this.ckPath[1], 1 , uuid)
								nodeDate.innerText = strList.join('')
								// let strList = nodeDate.innerHTML.split('<br>')
								// nodeDate.innerHTML = strList.map(s =>{
								// 	let l = s.split('')
								// 	for (let index = 0; index < l.length; index++) {
								// 		count = count + 1
								// 		if(count == this.ckPath[1]) {
								// 			l[index] = uuid
								// 		}
								// 	}
								// 	count = count + 1
								// 	return l.join('')
								// }).join('<br/>')
							} else {
								if(nodeDate.textContent == " " || element.textContent.match(/^\s*$/)) {
									// 当前鼠标所在节点为 空
									flag = false
									break
								} else {
									flag = true
								}
							}
						}
						nodeCount += 1
					}
				} else {
					if(nodeCount == this.ckPath[0]) {
						if(type == 'replace') {
							let strList = element.innerText.split('')
							strList.splice(this.ckPath[1], 1 , uuid)
							element.innerText = strList.join('')
							// let strList = element.innerHTML.split('<br>')
							// element.innerHTML = strList.map(s =>{
							// 	let l = s.split('')
							// 	for (let index = 0; index < l.length; index++) {
							// 		count = count + 1
							// 		if(count == this.ckPath[1]) {
							// 			l[index] = uuid
							// 		}
							// 	}
							// 	count = count + 1
							// 	return l.join('')
							// }).join('<br/>')
						} else {
							if(element.textContent == " " || element.textContent.match(/^\s*$/)) {
								// 当前鼠标所在节点为 空
								flag = false
								break
							} else {
								flag = true
							}
						}
					}
					nodeCount += 1
				}
			}
			if(type == 'replace') {
				// 用AIGC 的文本替换之前的uuid标记
				return div.outerHTML.replace(uuid , replaceStr)
			} else {
				// 获取当前行有没有内容
				return flag
			}
		},
		// 获取粘贴板上的内容
		async pasteHandler () {
			const clipboardItems = await window.navigator.clipboard.read();
			let textHtml, textPlain;
			for (const clipboardItem of clipboardItems) {
				for (const type of clipboardItem.types) {
					const item = await clipboardItem.getType(type);
					if (item && item.type == 'text/html') {
						textHtml = await item.text();
					}
					if (item && item.type == 'text/plain') {
						textPlain = await item.text();
					}
				}
			}
			textHtml = textHtml.replace('<meta charset=\"utf-8\">','')
			return { textHtml, textPlain }
		},
		// 判断单文件文件大小/总容量判断
		// 模版不计算总容量,但是需要限制单文件大小 
		//  自建知识和自建知识汇总附近都需要限制大小
		handlerFileSize(file,beyondType){
			let exceedObj = {
				flag: false, // // 是否超出
				type:'', //  超出类型  single 单文件超出   total 总容量超出  
				beyondType:beyondType, // flow 次数超出  capacity容量超出
			}
			// 单文件上传限制：0:25M，1：无限制
			let size = file.size;
			if(this.currentVersion.uploadAstrict === 0){
				exceedObj = {
					flag: !(size <= 25*1024*1024),
					type: 'single'
				}
			} else {
				if(!this.isTemplateManage){
					// 总容量判断
					//  企业 : 11 12
					let isCanUpload = true;
          if(this.combinationInfo.companyCapacitySurplus - size < 0 || this.combinationInfo.capacitySurplus - size < 0){
            isCanUpload = false;
          }
					if(!isCanUpload){
						exceedObj = {
							flag: true,
							type: 'total'
						}
					}
				}
			}
			return exceedObj
		},
		// 上传图片
		uploadImgButton(event, callback) {
			console.debug(event, callback);
			this.isHaveOperate = true;
			var inputObj = document.createElement('input')
			inputObj.setAttribute('id', '_ef_img');
			inputObj.setAttribute('type', 'file');
			inputObj.setAttribute('accept', 'image/jpeg,image/png,image/gif,image/bmp,image/webp,image/tiff')
			inputObj.setAttribute("style", 'visibility:hidden');
			document.body.appendChild(inputObj);
			inputObj.click();
			inputObj.onchange = () => {
				// 循环上传文件
				let files = inputObj.files;
				for (let index = 0; index < files.length; index++ ) {
					let filed = files[index];
					console.log(filed,'filed');
					let imgName = filed.name;
					let uid = uuidv4()
					if (this.currentEditor.uploadImg) {
						this.currentEditor.uploadImg.push(uid)
					} else {
						this.currentEditor.uploadImg = []
						this.currentEditor.uploadImg.push(uid)
					}
					let command = this.currentEditor.commands.get("insertAskComponent");
					let type = this.getFileSuffix(filed.name,[],true)
					// 修改文件名字
					Object.defineProperty(filed, 'name', {
						writable: true,
						value: uuidv4()+'.'+type
					});
					let exceedObj =  this.handlerFileSize(filed,'capacity')
					if(exceedObj.flag){
						this.$emit('handlerCanUploadEmit','capacity',exceedObj.type,exceedObj)
						// this.canUploadDiaVisible = true;
						// this.handlerCanUploadPopup('capacity',exceedObj.type)
					} else {
						this.upload(filed).then(res => {
							this.$http.post(requestUrl.knowledge.getTemporaryCertificate+"?needEncrypt=false",{fileInOssPath:res.default}).then((urlRes) => {
									command.execute({
									tag: "img",
									options: {
										alt: '',
										src: urlRes.data.data,
										filename:imgName,
										filesize:filed.size
									},
								});
								// let viewFragment =  this.currentEditor.data.processor.toView(`<figure 
								// 						class="ask-component-img-box" 
								// 						src="${urlRes.data.data}" 
								// 						filename="${imgName}" 
								// 						contenteditable="false">
								// 						<img 
								// 							class="ask-component-img" 
								// 							src="${urlRes.data.data}" 
								// 							filename="${imgName}" 
								// 							alt="" />
								// 					</figure>`);
								// let modelFragment = this.currentEditor.data.toModel( viewFragment );
								// this.currentEditor.model.insertContent( modelFragment );
								this.$nextTick(() => {
									this.currentEditor.execute('insertParagraph', {
										position: this.currentEditor.model.document.selection.focus
									})
								})
								this.showBlockToolbar = false;
							})
						})
					}
					
				}
			}
		},
		// 上传附件
		uploadFileButton(event, callback) {
			this.isHaveOperate = true;
			console.debug(event, callback);
			var inputObj = document.createElement('input')
			inputObj.setAttribute('id', '_ef_file');
			inputObj.setAttribute('type', 'file');
			// doc docx txt xls xlsx pdf  ppt
			inputObj.setAttribute('accept','application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf,application/vnd.ms-powerpoint')
			inputObj.setAttribute("style", 'visibility:hidden');
			document.body.appendChild(inputObj);
			inputObj.click();
			inputObj.onchange = () => {
				// 循环上传文件
				let files = inputObj.files;
				for (let index = 0; index < files.length; index++ ) {
					const filed = files[index];
					console.log(filed,'filed');
					// 需要回调通知父组件 上传成功
					let command = this.currentEditor.commands.get("insertAskComponent");
					let exceedObj =  this.handlerFileSize(filed,'capacity')
					if(exceedObj.flag){
						this.$emit('handlerCanUploadEmit','capacity',exceedObj.type,exceedObj)
					}  else {
						this.upload(filed).then(res => {
							this.$http.post(requestUrl.knowledge.getTemporaryCertificate,{fileInOssPath:res.default}).then((urlRes) => {
								// let url = Base64.encode(urlRes.data.data);
								let url = urlRes.data.data;
								let openUrl = process.env.VUE_APP_NODE_ENV == 'production' ? 'https://kkfileview.askbot.cn/onlinePreview?url=' + url : 'https://test.open.askbot.cn/kkfileview/onlinePreview?url=' + url;
								let portalUrl = process.env.VUE_APP_NODE_ENV == 'production' ? 'https://portal.askbot.cn/#/viewFile?fileUrl=' : 'https://portal.test.askbot.cn/#/viewFile?fileUrl='
								let iframeUrl = portalUrl + openUrl + '&fileName=' + res.name
								// 需要回调通知父组件 上传成功
								command.execute({
									tag: "iframe",
									options: {
										width:"100%",
										height:'400px',
										customaryurl:res.default,
										src: iframeUrl,
										filename: res.name,
										filesize:filed.size
									},
								});
								// let viewFragment =  this.currentEditor.data.processor.toView(`<span  
								// 						class="ask-component-iframe-box" 
								// 						src="${iframeUrl}" 
								// 						filename="${res.name}" 
								// 						contenteditable="false">
								// 						<iframe 
								// 							class="ask-component-img" 
								// 							src="${iframeUrl}" 
								// 							customaryurl:"${res.default}"
								// 							filename="${res.name}">
								// 						</iframe>
								// 					</span>`);
								// let modelFragment = this.currentEditor.data.toModel( viewFragment );
								// this.currentEditor.model.insertContent( modelFragment );
								this.$nextTick(() => {
									this.currentEditor.execute('insertParagraph', {
										position: this.currentEditor.model.document.selection.focus
									})
								})
								this.showBlockToolbar = false;
							})
						})
					}
				}
			}
		},
		upload(file){
			return new Promise((resolve) => {
				/* this._initRequest();
				this._initListeners( resolve, reject, file );
				this._sendRequest( file );*/
				this.uploadFile(file, resolve)
			})
		},
		uploadFile(file, resolve){
			let imgInfo = {
				url: '',
			}
			let res = multipartUpload(
				ossConfig,
				file,
				null,
				imgInfo
			);
			res.then(resp => {
				let nameList = resp.name.split('/')
				imgInfo.url = ossFileUrl(ossConfig, resp.name)
				resolve({
					default: imgInfo.url,
					name: nameList[nameList.length - 1],
				})
			})
		},
		// 获取文件后缀名
		getFileSuffix(url,arr,isGetName) {
			let index1 = url.lastIndexOf(".")+1;
			let index2 = url.length;
			let postf = url.substring(index1, index2);//后缀名
			if (isGetName) {
				return postf;
			} else { 
				if (!arr || arr.indexOf(postf) == -1) {
					return false;
				} else {
					return true;
				}
			}
		},
		// 快捷键操作
		handlerQuickKeys(e, keyCode){
			if(this.showBlockToolbar){
				let aiList = Array.from(document.querySelectorAll('.quickKeys_ai'));
				let helpList = Array.from(document.querySelectorAll('.quickKeys_help'));
				let temList = Array.from(document.querySelectorAll('.quickKeys_template'));
				let moreList =  Array.from(document.querySelectorAll('.quickKeys_more'));
				let domlist = this.isShowMoreHelpWrite ? [...aiList,...helpList,...temList, ...moreList] : [...aiList,...helpList,...temList]
				if(keyCode == '13') {
					setTimeout(() => {
						domlist[this.keyCodeIndex].click()
					}, 200)
					return false
				}
				domlist.forEach((item) => {
					this.setStyle(item, true)
				})
				if(this.keyCodeIndex == -1) {
					this.keyCodeIndex = 0;
				} else {
					if(domlist[this.keyCodeIndex].className.includes('quickKeys_ai')) {
						if(keyCode == 37) {
							this.keyCodeIndex = this.keyCodeIndex + 1
						} else if(keyCode == 38) {
							this.keyCodeIndex = domlist.length - 1
						} else if (keyCode == 39) {
							this.keyCodeIndex = this.keyCodeIndex + 2
						} else if (keyCode == 40) {
							this.keyCodeIndex = this.keyCodeIndex + 1
						}
					} else if(domlist[this.keyCodeIndex].className.includes('quickKeys_help')) {
						if(keyCode == 37) {
                            this.keyCodeIndex = this.decreaseProgressively(1, helpList, domlist)
                        } else if(keyCode == 38) {
                            this.keyCodeIndex = this.decreaseProgressively(2, helpList, domlist)
                        } else if (keyCode == 39) {
							if(domlist[this.keyCodeIndex + 1].className.includes('is-show-more-popup')) {
								domlist[this.keyCodeIndex].focus()
								this.keyCodeIndex = [...aiList,...helpList,...temList].length + 1
								this.$nextTick(() => {
									this.handlerQuickKeys(e, e.keyCode)
								})
								e.preventDefault()
							} else {
								this.keyCodeIndex = this.progressivelyIncrease(1, helpList, domlist)
							}
                        } else if (keyCode == 40) {
                            this.keyCodeIndex = this.keyCodeIndex + 2
							// this.progressivelyIncrease(2, helpList, domlist)
                        }
					} else if(domlist[this.keyCodeIndex].className.includes('quickKeys_template')) {
						if(keyCode == 38) {
                            this.keyCodeIndex =  this.keyCodeIndex - 1
                        } else if (keyCode == 40) {
							this.keyCodeIndex = this.keyCodeIndex + 1
							if(this.keyCodeIndex >= domlist.length) {
								this.keyCodeIndex = 0
							}
                        }
					} else if(domlist[this.keyCodeIndex].className.includes('quickKeys_more')) {
						if(keyCode == 37) {
							this.keyCodeIndex = [...aiList,...helpList].length - 2
							this.isShowMoreHelpWrite = false
						} else if(keyCode == 38) {
							if(this.keyCodeIndex == [...aiList,...helpList,...temList].length ) {
								this.keyCodeIndex = domlist.length - 1
							} else if(this.keyCodeIndex == domlist.length - 1){
								this.keyCodeIndex = [...aiList,...helpList,...temList].length
							} else {
								this.keyCodeIndex = this.keyCodeIndex - 1
							}
                        } else if(keyCode == 39) {
							this.keyCodeIndex = [...aiList,...helpList,...temList].length
						} else if (keyCode == 40) {
							// this.keyCodeIndex = this.keyCodeIndex + 1
							if(this.keyCodeIndex == [...aiList,...helpList,...temList].length) {
								this.keyCodeIndex = this.keyCodeIndex + 1
							} else if(this.keyCodeIndex == domlist.length - 1){
								this.keyCodeIndex = [...aiList,...helpList,...temList].length
							} else {
								this.keyCodeIndex = this.keyCodeIndex + 1
							}
                        }
					}
				}
				
				this.keyCodeIndex >= 0 && domlist[this.keyCodeIndex] && this.setStyle(domlist[this.keyCodeIndex])
				// if(e.code === 'ArrowDown' || e.code === 'ArrowUp' ){
				// 	// this.showBlockToolbar = true;
				// }
				// if(e.code === 'ArrowDown'){
				// 	if(this.keyCodeIndex >= this.maxNum){
				// 		this.keyCodeIndex = 1;
				// 	} else {
				// 		this.keyCodeIndex++
				// 	}
				// } else if (e.code === 'ArrowUp'){
				// 	if(this.keyCodeIndex <= 1){
				// 		this.keyCodeIndex = this.maxNum;
				// 	} else {
				// 		this.keyCodeIndex--
				// 	}
				// } else if (e.code === 'ArrowRight'){
				// 	if(document.querySelector('.active-dom'+this.keyCodeIndex).innerText.indexOf('更多') !== -1){
				// 		this.keyCodeIndex = 1;
				// 		this.isShowMoreHelpWrite = true;
				// 		this.popupHeight = document.querySelector('.popup-floating-layer-ckeditor').clientHeight - 135;
						
				// 	}
				// } else if (e.code === 'ArrowLeft'){
				// 	if(this.isShowMoreHelpWrite){
				// 		this.isShowMoreHelpWrite = false;
				// 		this.maxNum = 5;
				// 		this.keyCodeIndex = 1;
				// 		document.querySelector('.is-show-more-popup').style.background = '#fff';
				// 	}
				// } else if (e.code === 'Enter'){
				// 	this.resetData('AiCreate',false,true);
				// 	if(this.keyCodeIndex <= this.aiHelpWriteListThreeMore.length ){
				// 		if(this.isShowMoreHelpWrite){
				// 			this.aiCreateContentValue = document.querySelector('.show-more-popup .active-dom'+this.keyCodeIndex).getAttribute('data-tips')
				// 		} else {
				// 			this.aiCreateContentValue = '';
				// 		}
				// 	} else {
				// 		this.aiCreateContentValue = document.querySelector('.active-dom'+this.keyCodeIndex).getAttribute('data-tips')
				// 	}
				// }
				// if(this.isShowMoreHelpWrite){
				// 	document.querySelector('.is-show-more-popup').style.background = '#EAEFFF';
				// 	this.$nextTick(() => {
				// 		this.maxNum = this.aiHelpWriteListThreeMore.length;
				// 		if(document.querySelector('.show-more-popup .active-dom'+this.keyCodeIndex)){
				// 			document.querySelector('.show-more-popup .active-dom'+this.keyCodeIndex).style.background = '#EAEFFF';
				// 		}
				// 	})
				// } else {
				// 	document.querySelector('.active-dom'+this.keyCodeIndex).style.background = '#EAEFFF';
				// }
				
			}
			// if(this.showBlockToolbar) {
			// 	this.keyCodeIndex++
			// 	let activeDom = Array.from(document.querySelectorAll('.active-dom'))
			// 	console.log(activeDom ,e ,keyCode);
			// 	for (let index = 0; index < activeDom.length; index++) {
			// 		if(index == this.keyCodeIndex) {
			// 			activeDom[index].classList.add('activehight')
			// 		} else {
			// 			activeDom[index].classList.remove('activehight')
			// 		}
			// 	}
			// }
		},
		handlerQuickPopupKeys(e, keyCode) {
			if (this.popupType == 'AiCreate') {
				this.$refs.AiCreatePopup.$refs.aiCreateContentInput.blur()
				let domList = Array.from(document.querySelectorAll('.quickKeys_aicreate'))
				domList.forEach((item) => {
					this.setStyle(item, true)
				})
				if (this.keyCodeIndex == -1 && keyCode != '13') {
					this.keyCodeIndex = 0;
				} else {
					if (keyCode == '13') {
						if (this.keyCodeIndex != -1) {
							this.$refs.AiCreatePopup.$refs.aiCreateContentInput.focus()
							domList[this.keyCodeIndex].click()
							this.keyCodeIndex = -1
							return
						} else {
							this.$refs.AiCreatePopup.nowCreateFn()
						}
					}
					if (keyCode == '37') {
						this.keyCodeIndex = this.keyCodeIndex - 1
					}
					if (keyCode == '39') {
						this.keyCodeIndex = this.keyCodeIndex + 1
					}
				}
				this.keyCodeIndex = this.keyCodeIndex < 0 ? domList.length - 1 : this.keyCodeIndex
				this.keyCodeIndex = this.keyCodeIndex >= domList.length ? 0 : this.keyCodeIndex
				this.setStyle(domList[this.keyCodeIndex])
			}
			if(this.popupType == 'AIMade') {
				this.$refs.AiCreatePopup.$refs.aiCreateContentInput.blur()
				let domList = Array.from(document.querySelectorAll('.quickKeys_made'))
				let hideList = Array.from(document.querySelectorAll('.quickKeys_hideMade'))
				domList.forEach((item) => {
					this.setStyle(item, true)
				})
				if(hideList.length == 0) {
					if (this.keyCodeIndex == -1 && keyCode != '13') {
						this.keyCodeIndex = 0;
					} else {
						if (keyCode == '13') {
							if (this.keyCodeIndex != -1) {
								this.$refs.AiCreatePopup.$refs.aiCreateContentInput.focus()
								domList[this.keyCodeIndex].click()
								this.keyCodeIndex = -1
								return
							} else {
								this.$refs.AiCreatePopup.nowCreateFn()
							}
						}
						if (keyCode == '37') {
							this.keyCodeIndex = this.keyCodeIndex - 1
						}
						if (keyCode == '39') {
							this.keyCodeIndex = this.keyCodeIndex + 1
						}
					}
					this.keyCodeIndex = this.keyCodeIndex < 0 ? domList.length - 1 : this.keyCodeIndex
					this.keyCodeIndex = this.keyCodeIndex >= domList.length ? 0 : this.keyCodeIndex
					this.setStyle(domList[this.keyCodeIndex])
				} else {
					hideList.forEach((item) => {
						// item.style.background = '#fff';
						this.setStyle(item, true)
					})
					if (keyCode == '37') {
						this.$refs.AiCreatePopup.isShowMoreOptimize = false;
						this.$refs.AiCreatePopup.isShowMoreGenerate = false;
						this.keyCodeIndex = 0
						this.setStyle(domList[this.keyCodeIndex])
						return
					}
					if (keyCode == '38') {
						this.keyCodeIndex = this.keyCodeIndex - 1
					}
					if(keyCode == '39') {
						this.$refs.AiCreatePopup.isShowMoreOptimize = false;
						this.$refs.AiCreatePopup.isShowMoreGenerate = false;
						this.keyCodeIndex = domList.length - 1 
						this.setStyle(domList[this.keyCodeIndex])
						return
					}
					if (keyCode == '40') {
						this.keyCodeIndex = this.keyCodeIndex + 1
					}
					this.keyCodeIndex = this.keyCodeIndex < 0 ? hideList.length - 1 : this.keyCodeIndex
					this.keyCodeIndex = this.keyCodeIndex >= hideList.length ? 0 : this.keyCodeIndex
					// hideList[this.keyCodeIndex].style.background = '#EAEFFF'
					this.setStyle(hideList[this.keyCodeIndex])
				}

			}
		},
	}
}
</script>

<style lang="less" scoped>
@import "./less/ratelCkeditor";

</style>
<style lang="less">
.ck-body-wrapper{
	.ck-reset_all{
		.ck.ck-block-toolbar-button {
			// transform: translateX( -5px );
			z-index: 1;
			width: 27px;
			height: 27px;
			background: url(./images/editor-btn.png) no-repeat center/cover;
			.ck-icon_inherit-color{
				display: none;
			}
			&:hover{
				background: url(./images/editor-btn.png) no-repeat center/cover !important;
			}
			&.scroll-btn{
				display: none !important;
			}
				
		}
		.ck.ck-block-toolbar-button.ck-on{
			background: url(./images/editor-btn.png) no-repeat center/cover !important;
		}
		.ck-toolbar-container{
			// z-index: 2600;
			border: none;
			background: #FFFFFF;
			box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.08),0 0 10px rgba(0,0,0,0.08), 0 0 10px rgba(0,0,0,0.08);
			border-radius: 5px;
			&::before{
				display: none;
				background-color: transparent;
				border-color: transparent;
				background-image: none;
			}
			
			.ck-toolbar_grouping{
				border: none;
				background: transparent;
				>.ck-toolbar__items{
					width: 460px;
					display: flex;
					flex-wrap: wrap;
					.ck-button{
						margin: 0;
						text-align: center;
						line-height: 20px;
						height: 20px;
						color: #404040;
						padding: 0;
						border: none;
						display: flex;
						align-items: center;
						justify-content: center;
						// width: 20px;
						// height: 20px;
						&:nth-child(4){
							margin-right: 3px;
						}
						&:focus{
							border: none;
							box-shadow: none;
						}
						&:hover{
							background-color: #EAEFFF;
							color: #366AFF;
							.ck-icon{
								color: #366AFF;
							}
						}
						&.ask-upload-img,&.ask-upload-file,&.ask-ai-concert,&.ask-save-template {
							display: flex;
							align-items: center;
							justify-content: center;
							cursor: pointer;
							.ck-icon{
								font-size: 14px;
								font-weight: 400;
								color:#404040 ;
								transform: scale(.7);
								margin-left: 2px;
							}
						}
						&.ask-upload-file{
							.ck-icon{
								font-size: 14px;
								transform: scale(.6);
							}
						}
						
						&.ask-save-template{
							padding-right: 4px;
							.ck-icon{
								transform: scale(.6);
							}
						}
						&.ask-ai-concert{
							margin-right: 5px !important;
							padding-right: 10px !important;
							padding-left: 2px !important;
							background:linear-gradient(to bottom right, #366AFF, #F65C57); // 从左上到右下 对角线渐变
							border-radius: 19px;
							color: #FFFFFF !important;
							position: relative;
							.ck-icon{
								color:#ffff;
								font-size: 12px;
							}
							&:hover{
								color:#fff !important;
								.ck-icon{
									color:#fff !important;
								}
							}
						}
						
					}
					>.ck-dropdown{
						width: 30px;
						.ck-button{
							.ck-button__icon{
								// width: 25px;
								// font-size: 25px;
							}
							.ck-dropdown__arrow{
								width: 12px;
							}
						}
						// &:nth-child(3){
						// 	background-color: red;
						// }
					}
				}
				
			}
			&:first-child{
				border: none;
				box-shadow: none;
				background-color: transparent;
				border-radius: 0;	
				.ck-toolbar_grouping{
					border: none;
					background: transparent;
					padding: 0;
					>.ck-toolbar__items{
						width: 260px;
						>.ck-dropdown{
							.ck-button{
								.ck-button__icon{
									width: 14px;
								}
							}
							.ck-dropdown__arrow{
								margin-left: 2px;
							}
						}
					}
				}
			}

			// &:last-child{
			// 	border: none;
			// 	background: #FFFFFF;
			// 	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.08);
			// 	border-radius: 5px;
			// 	.ck-toolbar_grouping{
			// 		>.ck-toolbar__items{
			// 			width: 460px !important;
			// 		}
			// 	}
			// }
		}
		.ck-balloon-panel_with-arrow{
			// z-index: 2600;
			.ck-balloon-rotator__content{
				>.ck-toolbar_grouping{
					box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.08),0 0 6px rgba(0,0,0,0.08), 0 0 6px rgba(0,0,0,0.08);
					>.ck-toolbar__items{
						>.ck-button{
							&:nth-child(11){
								// margin-right: 5px;
							}
						}
						>.ck-toolbar__nested-toolbar-dropdown{
							margin-left: 5px;
							.ck-dropdown__panel_se{
								.ck-toolbar__items{
									display: flex;
									flex-direction: column;
									// >.ck-dropdown:first-child{
									// 	>.ck-button:first-child{
									// 		.ck-dropdown__arrow{
									// 			// margin-left: 10px;
									// 		}
									// 	}
									// }
								}
							}
						}
						.ck-alignment-dropdown{
							>.ck-reset{
								left: -5px;
								border: none;
								width: 40px;
							}

						}


					}
				}
			}
		}
		.ck.ck-balloon-panel.ck-toolbar-container[class*=arrow_n]:after{
			display: none;
		}
		&:first-child{
			position: absolute;
			left: 0;
			top: 0;
			// z-index: 4444;
			.ck.ck-block-toolbar-button,.ck-balloon-panel_with-arrow{
				// z-index: 1;
			}
		}
		// .ck .ck-balloon-panel{
		// 	display: none;
		// }
		.ck-balloon-panel_visible{
			display: block;
		}
		.ck-balloon-panel_visible_self{
			display: block !important;
		}
	}
	.ck-reset_all:nth-child(4){
		.ck.ck-block-toolbar-button,.ck-toolbar-container {
			z-index: 2201;
		}
		.ck-balloon-panel_with-arrow{
			.ck-balloon-rotator__content{
				>.ck-toolbar_grouping{
					>.ck-toolbar__items{
						width: 270px !important;
						>.ck-button{
							&:nth-child(9){
								margin-right: 0px;
							}
						}
						>.ck-dropdown{
							&:nth-child(1){
								margin-left: 2px;
							}
						}
					}
				}
			}
		}
	}
	.template-manage{
		.ck-balloon-panel_with-arrow{
			.ck-balloon-rotator__content{
				>.ck-toolbar_grouping{
					>.ck-toolbar__items{
						width: 280px;
						>.ck-button{
							&:nth-child(8){
								margin-right: 5px;
							}
						}
					}
				}
			}
		}
	}

}
body[use-ai-collaborate-data=true]{
	.ck-body-wrapper .ck-reset_all .ask-ai-concert{
		&::before{
			width: 8px;
			height: 8px;
			background-color: red;
			border-radius: 50%;
			content: "";
			position: absolute;
			left: -1px;
			top: 1px;
		}
	}
}
.show-more-write{
	// z-index: 2700 !important;
	max-height: 600px;
	overflow-x: hidden;
	overflow-y: auto;
    .item{
		padding: 10px 12px;
		width: 100%;
		display: flex;
		box-sizing: border-box;
		justify-content: flex-start;
		align-items: center;
		height: 35px;
		color: #2c3e50;
		font-weight: 400;
		cursor: pointer;
		// margin-bottom: 5px;
		.iconfont{
			margin-right: 6px;
		}
	}
}
.show-more-template{
	// z-index: 2700 !important;
	.template-list{
		max-height: 600px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	.item{
		color: #2c3e50;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-weight: 400;
		cursor: pointer;
		margin-top: 2px;
		padding: 0 5px;
		.iconfont{
			color: #458FFF;
			margin-right: 6px;
		}
		&:hover{
			background-color: #EAEFFF;
			border-radius: 5px;
		}
		.is-show-more-popup {
			padding: 0;
		}
	}
}
</style>
<template>
  <div id="KnowledgeAnalysis">
    <div class="header">
      <time-range
          @selectTime="selectTime"
      ></time-range>
      <template v-if="false">
        <div class="knowledgeTab"
             v-for="(item,index) in tabList"
             :key="index"
             :class="activeTab === item.value ? 'activeKnowledgeTab' : ''"
        >
          {{ item.name }}
        </div>
      </template>
    </div>
    <div class="KnowledgeAnalysisContainer">
      <div class="cardList">
        <div class="oneRow">
          <div class="cardItem" v-for="item in oneRowCard" :key="item.key">
            <div class="cardLeft">
              <div class="cardNum" v-if="item.key !== 'newCapacity'">
                <span class="number" v-if="item.key !== 'UPDATE'">{{ item.value }}</span>
                <span class="number" v-else>{{ updateFrequency }} <span v-if="updateFrequency > 0">{{$t('statistic.article')}}</span></span>
                <div class="newAdd" v-if="item.newAddNum">
                  <span>+{{item.newAddNum}}</span>
                  <div class="newAddBtn">{{$t('statistic.newAdditionsToday')}}</div>
                </div>
              </div>
              <div class="cardNum" v-else>
                <span class="number">{{ handlerUnit(item.value) }}</span>
                <div class="newAdd" v-if="item.newAddNum">
                  <span>+{{ handlerUnit(item.newAddNum)}}</span>
                  <div class="newAddBtn">{{$t('statistic.newAdditionsToday')}}</div>
                </div>
              </div>
              <div class="cardName">{{ $t('statistic.' + item.key) }}</div>
            </div>
            <div class="cardIcon" :style="{backgroundColor:item.bgColor,color:item.color}">
              <i :class="item.icon"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="analysisCard">
        <div class="title">
          <span>{{cardData.name}}</span>
          <div class="setBtn" @click="setKnowledgeCard">
            <i class="iconfont guoran-shezhi1"></i>
            <span>{{$t('statistic.set')}}</span>
          </div>
        </div>
        <div class="empty-image" v-show="knowledgeAnalysis.knowledgeClassification.length === 0">
          <img src="https://static.guoranbot.com/cdn-arsenal/dev/img/no-data.8a0ef808.png">
          <span class="empty-text">{{$t('statistic.empty')}}</span>
        </div>
        <div id="knowledgeClassification" class="analysisCardLine"></div>
      </div>
      <div class="legend">
        <div class="analysisCard">
          <div class="title">
            <span>{{$t('statistic.newSourceProportion')}}</span>
          </div>
          <div class="empty-image" v-show="knowledgeAnalysis.knowledgeSources.length === 0">
            <img src="https://static.guoranbot.com/cdn-arsenal/dev/img/no-data.8a0ef808.png">
            <span class="empty-text">{{$t('statistic.empty')}}</span>
          </div>
          <div id="knowledgeSources" class="analysisCardLine"></div>
        </div>
        <div class="analysisCard">
          <div class="title">
            <span>{{$t('statistic.newTypeProportion')}}</span>
          </div>
          <div class="empty-image" v-show="knowledgeAnalysis.knowledgeType.length === 0">
            <img src="https://static.guoranbot.com/cdn-arsenal/dev/img/no-data.8a0ef808.png">
            <span class="empty-text">{{$t('statistic.empty')}}</span>
          </div>
          <div id="knowledgeType" class="analysisCardLine"></div>
        </div>
        <div class="analysisCard">
          <div class="title">
            <span>{{$t('statistic.newAdd')}}</span>
          </div>
          <div class="empty-image" v-show="knowledgeAnalysis.newKnowledgeAdd.length === 0">
            <img src="https://static.guoranbot.com/cdn-arsenal/dev/img/no-data.8a0ef808.png">
            <span class="empty-text">{{$t('statistic.empty')}}</span>
          </div>
          <div id="newKnowledgeAdd" class="analysisCardLine"></div>
        </div>
        <div class="analysisCard">
          <div class="title">
            <span>{{$t('statistic.knowledgeRetrievalRanking')}}</span>
          </div>
          <div class="empty-image" v-show="knowledgeAnalysis.knowledgeRetrieval.length === 0">
            <img src="https://static.guoranbot.com/cdn-arsenal/dev/img/no-data.8a0ef808.png">
            <span class="empty-text">{{$t('statistic.empty')}}</span>
          </div>
          <div id="knowledgeRetrieval" class="analysisCardLine"></div>
        </div>
        <div class="analysisCard">
          <div class="title">
            <span>{{$t('statistic.KnowledgeReviewRanking')}}</span>
          </div>
          <div class="empty-image" v-show="knowledgeAnalysis.knowledgeReview.length === 0">
            <img src="https://static.guoranbot.com/cdn-arsenal/dev/img/no-data.8a0ef808.png">
            <span class="empty-text">{{$t('statistic.empty')}}</span>
          </div>
          <div id="knowledgeReview" class="analysisCardLine"></div>
        </div>
        <div class="analysisCard">
          <div class="title">
            <span>{{$t('statistic.rankingOfKnowledgeContributors')}}</span>
          </div>
          <div class="empty-image" v-show="knowledgeAnalysis.knowledgeContributors.length === 0">
            <img src="https://static.guoranbot.com/cdn-arsenal/dev/img/no-data.8a0ef808.png">
            <span class="empty-text">{{$t('statistic.empty')}}</span>
          </div>
          <div id="knowledgeContributors" class="analysisCardLine"></div>
        </div>
      </div>
      <div class="analysisCard">
        <div class="title">
          {{$t('statistic.newTagAssociationRanking')}}
        </div>
        <div class="empty-image" v-show="knowledgeAnalysis.labelAssociation.length === 0">
          <img src="https://static.guoranbot.com/cdn-arsenal/dev/img/no-data.8a0ef808.png">
          <span class="empty-text">{{$t('statistic.empty')}}</span>
        </div>
        <div id="labelAssociation" class="analysisCardLine"></div>
      </div>
    </div>
    <popup-com @closeEvent="cancelConfig" v-if="knowledgeCardDialog">
      <div slot="popup-name">{{$t('statistic.statisticalCard')}}</div>
      <div slot="popup-tip"></div>
      <div slot="popup-con" class="knowledgeCardContainer">
        <div class="card-name">
          <div class="title">{{$t('statistic.statisticalCardName')}}<span style="color: red">*</span></div>
          <el-input v-model="cardData.name" placeholder="请输入"></el-input>
        </div>
        <div class="statistics" v-for="(item,index) in cardData.list" :key="index">
          <div class="statistics-left">
            <div class="top">{{$t('statistic.statisticalIndicatorsName')}}</div>
            <div class="value">
              <el-input v-model="item.name" placeholder="请输入指标名称" maxlength="10" show-word-limit></el-input>
            </div>
          </div>
          <div class="statistics-center">
            <div class="top">{{$t('statistic.knowledgeDir')}}</div>
            <div class="value">
              <el-dropdown
                  trigger="click"
                  id="el-dropdown-dir"
                  ref="messageDrop"
                  @visible-change="elDropdownvisibleChange($event)"
                  :hide-on-click="true">
                <span
                    :class="['el-dropdown-link',
                    'el-dropdown-link-dir',
                    parentDirectorySelect
                    ? 'active-el-dropdown-link': '',]">
                  <span
                      v-if="item.catalogueName"
                      class="el-dropdown-dir-content"
                  >
                    {{ item.catalogueName }}
                  </span>
                  <span v-else class="el-dropdown-placeholder">请选择</span>
                  <span class="el-icon">
                    <em v-show="!parentDirectorySelect" class="el-icon-arrow-down"></em>
                    <em v-show="parentDirectorySelect" class="el-icon-arrow-up"></em>
                  </span>
                </span>
                <el-dropdown-menu
                    id="dir-dropdown"
                    slot="dropdown"
                >
                  <el-dropdown-item
                      class="dir-dropdown-item"
                  >
                    <div class="dir-tree">
                      <el-tree
                          :data="folderTree"
                          node-key="id"
                          :default-expanded-keys="[-1]"
                          :expand-on-click-node="false"
                          check-strictly
                          :props="typeTreeDefaultProps"
                          @node-click="nodeClick($event,item)"
                          :load="loadNode"
                          lazy
                      >
                        <span class="custom-tree-node" slot-scope="{ data }">
                          <div class="custom-tree-node-left">
                            <span class="custom-tree-node-label">
                              <span class="folder-name">{{ data.name }}</span>
                            </span>
                          </div>
                          <span v-if="data.id == item.catalogueId"><i class="el-icon-check"></i></span>
                        </span>
                      </el-tree>
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <div class="delete-icon" @click="removeStatistics(index)">
            <i class="iconfont guoran-tongyichicun-16-09-shanchu2"></i>
          </div>
        </div>
        <div class="add-statistics" @click="addStatistics">
          <div class="add-btn">
            <i class="iconfont guoran-tongyichicun-16-13-xinjian"></i>
          </div>
          <span>{{$t('statistic.addStatisticalIndicators')}}</span>
        </div>
      </div>
      <div slot="dialog-footer">
        <el-button  plain size="small" @click="cancelConfig" class="cancel-btn">{{$t('common.cancel')}}</el-button>
        <el-button type="primary" @click="saveConfig" class="confirm-btn">{{$t('common.confirm')}}</el-button>
      </div>
    </popup-com>
  </div>
</template>

<script>
import * as echarts from "echarts";
import TimeRange from "@/views/statisticalAnalysis/components/timeRange";
import { getDateRange } from "@/utils/getDataRange";
import { requestUrl } from "@/http/requestUrl";
import PopupCom from "@/components/popup";
import { GetQueryString } from "@/assets/js/filterTree";
export default {
  name: "KnowledgeAnalysis",
  data(){
    return{
      tabList:[
        {
          name:"全部知识",
          value:"all"
        }
      ],
      activeTab:"all",
      oneRowCard:[
        {
          label:"新增知识数",
          value:0,
          key:"NEWTOTAL",
          icon:"iconfont guoran-tongyong",
          bgColor:"#ECF1FF",
          color:"#366AFF"
        },
        {
          label:"新增知识占用总量",
          value:0,
          key:"newCapacity",
          icon:"iconfont guoran-guanlirongliang",
          bgColor:"#EEECFD",
          color:"#766BF0"
        },
        {
          label:"知识更新频率",
          value:0,
          key:"UPDATE",
          icon:"iconfont guoran-tongyichicun-shuaxin",
          bgColor:"#ECF1FF",
          color:"#366AFF"
        },
        {
          label:"知识总查看次数",
          value:0,
          key:"LOOK",
          icon:"iconfont guoran-tongyichicun-chakan",
          bgColor:"#E1FAF9",
          color:"#09DDD5"
        },
        {
          label:"知识总预览次数",
          value:0,
          key:"CONSULT",
          icon:"iconfont guoran-tongyichicun-zixuncishu",
          bgColor:"#ECF1FF",
          color:"#366AFF"
        }
      ],
      classificationMyChart:null,
      sourcesMyChart:null,
      typeMyChart:null,
      associationMyChart:null,
      addMyChart:null,
      retrievalMyChart:null,
      reviewMyChart:null,
      contributorsMyChart:null,
      params:{
        mainId:"",
        corpId:"",
        userId:"",
        indicator:1,
        startTime:null,
        endTime:null,
        apiKey:""
      },
      cardData:{
        name:"新增知识分类排名",
        list:[
          {
            name:"",
            catalogueId:"",
            catalogueName:""
          }
        ]
      },
      cardDataCopy:{
        name:"新增知识分类排名",
        list:[
          {
            name:"",
            catalogueId:"",
            catalogueName:""
          }
        ]
      },
      knowledgeCardDialog:false,
      folderTree:[],
      typeTreeDefaultProps:{
        label:'name',
        isLeaf:'leaf'
      },
      parentDirectorySelect:false,
      updateFrequency:0,
      knowledgeAnalysis:{
        knowledgeClassification:[],
        knowledgeSources:[],
        knowledgeType:[],
        newKnowledgeAdd:[],
        knowledgeRetrieval:[],
        knowledgeReview:[],
        knowledgeContributors:[],
        labelAssociation:[]
      },
      dateKey:"Last30days",
      companyAuditTime:null
    }
  },
  components:{
    PopupCom,
    TimeRange
  },
  methods:{
    getCompanyAudit(){
      let url = requestUrl.statistical.companyAudit + '?mainId=' + this.params.mainId;
      this.$http.get(url).then(res => {
        console.log('===',res)
        if (res.data){
          this.companyAuditTime = res.data.update_time;
        }
      })
    },
    initEcharts(){
      this.initKnowledgeNumber();
      this.initKnowledgeVolume();
      this.initKnowledgeSources();
      this.initKnowledgeType();
      this.initLabelAssociation();
      this.initNewKnowledgeAdd();
      this.initKnowledgeRetrieval();
      this.initKnowledgeReview();
      this.initKnowledgeContributors();
      this.getConfig();
    },
    initKnowledgeNumber(){
      let url = requestUrl.statistical.knowledge;
      let obj = {
        mainId:this.params.mainId,
        corpId:this.params.corpId,
        userId:this.params.userId,
        indicator:1,
        startTime:this.params.startTime,
        endTime:this.params.endTime,
      };
      this.$http.post(url,obj).then(res => {
        if (res.data){
          this.oneRowCard[0].value = res.data.totalNum;
          if (res.data.totalNum > 0){
            // this.updateFrequency = Math.ceil(res.data.totalNum / this.updateNumber());
            if (this.dateKey === 'upToNow' && !this.params.startTime){
              this.updateFrequency = 0;
            } else {
              this.updateFrequency = Math.round((res.data.totalNum / this.updateNumber()) * 100) / 100;
            }
          } else {
            this.updateFrequency = 0
          }
        }
      })
    },
    initKnowledgeVolume(){
      let url = requestUrl.statistical.knowledge;
      let obj = {
        mainId:this.params.mainId,
        corpId:this.params.corpId,
        userId:this.params.userId,
        indicator:2,
        startTime:this.params.startTime,
        endTime:this.params.endTime,
      };
      this.$http.post(url,obj).then(res => {
        if (res.data){
          this.oneRowCard[1].value = res.data.volume;
        }
      })
    },
    //知识分类
    initKnowledgeClassification(){
      var chartDom = document.getElementById('knowledgeClassification');
      this.classificationMyChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: 'item', // 触发类型，可以是 'axis'（坐标轴触发）或 'item'（数据项触发）
          axisPointer: { // 坐标轴指示器配置
            type: 'shadow' // 坐标轴指示器类型，柱状图会有阴影效果
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            formatter: function (value) {
              // 根据需要格式化value，例如使用换行或省略
              if (value.length > 5) {
                // 假设只显示前10个字符，并在末尾添加省略号
                return value.slice(0, 5) + '...';
              }
              return value;
            }
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar',
            itemStyle:{
              color:'#458FFF',
              borderRadius: [7, 7, 0, 0]
            },
            barWidth:"15px"
          }
        ]
      };
      let url = requestUrl.statistical.knowledge;
      console.log('cardData',this.cardData)
      let statistics = this.cardData.list.filter(item => item.name && item.catalogueId)
      let catalogueIds = statistics.map(res => {
        return res.catalogueId
      })
      this.$http.post(url,{
        mainId:this.params.mainId,
        corpId:this.params.corpId,
        userId:this.params.userId,
        indicator:6,
        startTime:this.params.startTime,
        endTime:this.params.endTime,
        catalogueIds:catalogueIds
      }).then(res => {
        if (res.data){
          option.xAxis.data = statistics.map(res => {
            return res.name
          })
          let objData =  res.data;
          let data = []
          statistics.forEach(item => {
            for (const id in objData){
              if (item.catalogueId == id){
                data.push(objData[id])
              }
            }
          })
          option.series[0].data = data;
          this.knowledgeAnalysis.knowledgeClassification = option.series[0].data
          option && this.classificationMyChart.setOption(option);
        }
      })
    },
    initKnowledgeSources() {
      var chartDom = document.getElementById('knowledgeSources');
      this.sourcesMyChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: 'item',
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          x:'center',
          y:'bottom',
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            padAngle: 5,
            itemStyle: {
              borderRadius: 10
            },
            data: []
          }
        ]
      };

      let url = requestUrl.statistical.knowledge;
      let obj = {
        mainId:this.params.mainId,
        corpId:this.params.corpId,
        userId:this.params.userId,
        indicator:7,
        startTime:this.params.startTime,
        endTime:this.params.endTime,
      };
      this.$http.post(url,obj).then(res => {
        if (res.data){
          for (let i=0;i<res.data.length;i++){
            let item = res.data[i];
            if (item.source == 1){
              item.sourceName = '本地上传'
            } else if (item.source == 2){
              item.sourceName = '企微微盘同步'
            } else if (item.source == 3){
              item.sourceName = '钉盘同步'
            } else if (item.source == 4){
              item.sourceName = '系统内添加'
            } else if (item.source == 5){
              item.sourceName = '语雀同步'
            } else if (item.source == 6){
              item.sourceName = '飞书同步'
            } else if (item.source == 7){
              item.sourceName = '立邦素材库同步'
            }
          }
          let data = res.data.map(res => {
            return {
              value:res.count,
              name:res.sourceName
            }
          })
          option.series[0].data = data
          this.knowledgeAnalysis.knowledgeSources = option.series[0].data
          option && this.sourcesMyChart.setOption(option);
        }
      })
    },
    initKnowledgeType(){
      var chartDom = document.getElementById('knowledgeType');
      this.typeMyChart = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: 'item',
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          x:'center',
          y:'bottom',
          type: 'scroll', // 使用滚动布局
          orient: 'horizontal', // 水平布局
          left: 'center', // 居中显示
          bottom: 10, // 距离底部 10px
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            padAngle: 2,
            itemStyle: {
              borderRadius: 10
            },
            data: []
          }
        ]
      };
      let url = requestUrl.statistical.knowledge;
      let obj = {
        mainId:this.params.mainId,
        corpId:this.params.corpId,
        userId:this.params.userId,
        indicator:8,
        startTime:this.params.startTime,
        endTime:this.params.endTime,
      };
      this.$http.post(url,obj).then(res => {
        if (res.data){
          option.series[0].data = res.data;
          this.knowledgeAnalysis.knowledgeType = option.series[0].data
          option && this.typeMyChart.setOption(option);
        }
      })
    },
    initLabelAssociation(){
      var chartDom = document.getElementById('labelAssociation');
      this.associationMyChart = echarts.init(chartDom);

      var option;
      option = {
        tooltip: {
          trigger: 'item', // 触发类型，可以是 'axis'（坐标轴触发）或 'item'（数据项触发）
          axisPointer: { // 坐标轴指示器配置
            type: 'shadow' // 坐标轴指示器类型，柱状图会有阴影效果
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            formatter: function (value) {
              // 根据需要格式化value，例如使用换行或省略
              if (value.length > 5) {
                // 假设只显示前10个字符，并在末尾添加省略号
                return value.slice(0, 5) + '...';
              }
              return value;
            }
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar',
            itemStyle:{
              color:'#458FFF',
              borderRadius: [7, 7, 0, 0]
            },
            barWidth:"15px"
          }
        ]
      };
      let url = requestUrl.statistical.knowledge;
      let obj = {
        mainId:this.params.mainId,
        corpId:this.params.corpId,
        userId:this.params.userId,
        indicator:13,
        startTime:this.params.startTime,
        endTime:this.params.endTime,
      };
      this.$http.post(url,obj).then(res => {
        if (res.data){
          // option.series[0].data = res.data.data;
          option.xAxis.data = res.data.map(res => {
            return res.tagName
          })
          option.series[0].data = res.data.map(res => {
            return res.count
          })
          this.knowledgeAnalysis.labelAssociation = option.series[0].data
          option && this.associationMyChart.setOption(option);
        }
      })
    },
    initNewKnowledgeAdd(){
      var chartDom = document.getElementById('newKnowledgeAdd');
      this.addMyChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: 'axis', // 设置触发类型为坐标轴触发
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'line',
            itemStyle: {
              normal: {
                color: '#FF9555', // 点的颜色
                lineStyle: {
                  color: '#FF9555' // 线的颜色
                }
              }
            }
          }
        ]
      };
      let url = requestUrl.statistical.knowledge;
      let obj = {
        mainId:this.params.mainId,
        corpId:this.params.corpId,
        userId:this.params.userId,
        indicator:9,
        startTime:this.params.startTime,
        endTime:this.params.endTime,
      };
      this.$http.post(url,obj).then(res => {
        if (res.data && res.data.data){
          console.log('obj',res.data.data)
          // option.series[0].data = res.data.data;
          option.xAxis.data = res.data.data.map(res => {
            return res.date
          })
          option.series[0].data = res.data.data.map(res => {
            return res.count
          })
          this.knowledgeAnalysis.newKnowledgeAdd = option.series[0].data
          option && this.addMyChart.setOption(option);
        }
      })
    },
    initKnowledgeRetrieval(){
      var chartDom = document.getElementById('knowledgeRetrieval');
      this.retrievalMyChart = echarts.init(chartDom);
      var option;
      option = {
        dataset: {
          source: [
            ['score', 'amount', 'product'],
            ['1', 90, 'Matcha Latte'],
            ['2', 86, 'Milk Tea'],
            ['3', 84, 'Cheese Cocoa'],
            ['4', 77, 'Cheese Brownie'],
            ['5', 72, 'Matcha Cocoa'],
            ['6', 65, 'Tea'],
            ['7', 53, 'Orange Juice'],
            ['8', 47, 'Lemon Juice'],
            ['9', 29, 'Walnut Brownie']
          ]
        },
        grid: { containLabel: true },
        xAxis: {
          name: '',
          axisLine: {
            show: false // 不显示x轴线
          },
          splitLine:{
            show:false
          },
          position:"top"
        },
        yAxis: {
          type: 'category',
          axisLine: {
            show: false // 不显示y轴线
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            type: 'bar',
            itemStyle:{
              color:'#458FFF',
              borderRadius: [7, 7, 7, 7]
            },
            label:{
              normal:{
                show:true,
                color:"#ffffff"
              }
            }
          }
        ]
      };

      option && this.retrievalMyChart.setOption(option);
    },
    initKnowledgeReview(){
      var chartDom = document.getElementById('knowledgeReview');
      this.reviewMyChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: 'item', // 设置触发类型为坐标轴触发
        },
        grid: { containLabel: true },
        xAxis: {
          name: '',
          axisLine: {
            show: false // 不显示x轴线
          },
          splitLine:{
            show:false
          },
          position:"top"
        },
        yAxis: {
          type: 'category',
          axisLine: {
            show: false // 不显示y轴线
          },
          axisTick: {
            show: false
          },
          data:[],
          axisLabel:{
            color:"#A9B3C6"
          }
        },
        series: [
          {
            type: 'bar',
            data:[],
            itemStyle:{
              color:'#09DDD5',
              borderRadius: [7, 7, 7, 7]
            },
            barMaxWidth: '20px',
            label:{
              normal:{
                show:true,
                color:"#ffffff"
              }
            }
          }
        ]
      };
      let url = requestUrl.statistical.knowledge;
      let obj = {
        mainId:this.params.mainId,
        corpId:this.params.corpId,
        userId:this.params.userId,
        indicator:11,
        startTime:this.params.startTime,
        endTime:this.params.endTime,
      };
      this.$http.post(url,obj).then(res => {
        if (res.data){
          option.yAxis.data = res.data.map(res => {
            return res.knowledgeName
          })
          option.series[0].data = res.data.map(res => {
            return res.count
          })
          this.knowledgeAnalysis.knowledgeReview = option.series[0].data
          option && this.reviewMyChart.setOption(option);
        }
      })
    },
    initKnowledgeContributors(){
      var chartDom = document.getElementById('knowledgeContributors');
      this.contributorsMyChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: 'item', // 设置触发类型为坐标轴触发
        },
        grid: { containLabel: true },
        xAxis: {
          name: '',
          axisLine: {
            show: false // 不显示x轴线
          },
          splitLine:{
            show:false
          },
          position:"top",
        },
        yAxis: {
          type: 'category',
          axisLine: {
            show: false // 不显示y轴线
          },
          axisTick: {
            show: false
          },
          data:[],
          axisLabel:{
            color:"#A9B3C6"
          }
        },
        series: [
          {
            type: 'bar',
            data:[],
            itemStyle:{
              color:'#766BF0',
              borderRadius: [7, 7, 7, 7]
            },
            barMaxWidth: '20px',
            label:{
              normal:{
                show:true,
                color:"#ffffff"
              }
            }
          }
        ]
      };
      let url = requestUrl.statistical.knowledge;
      let obj = {
        mainId:this.params.mainId,
        corpId:this.params.corpId,
        userId:this.params.userId,
        indicator:12,
        startTime:this.params.startTime,
        endTime:this.params.endTime,
      };
      this.$http.post(url,obj).then(res => {
        if (res.data){
          console.log('obj',res.data)
          // option.series[0].data = res.data.data;
          option.yAxis.data = res.data.map(res => {
            return res.name
          })
          option.series[0].data = res.data.map(res => {
            return res.count
          })
          this.knowledgeAnalysis.knowledgeContributors = option.series[0].data
          option && this.contributorsMyChart.setOption(option);
        }
      })
    },
    resizeFun(){
      this.sourcesMyChart.resize();
      this.typeMyChart.resize();
      this.associationMyChart.resize();
      this.addMyChart.resize();
      this.retrievalMyChart.resize();
      this.reviewMyChart.resize();
      this.contributorsMyChart.resize();
      if (this.classificationMyChart){
        this.classificationMyChart.resize();
      }
    },
    clearFun(){
      this.sourcesMyChart.clear();
      this.typeMyChart.clear();
      this.associationMyChart.clear();
      this.addMyChart.clear();
      this.retrievalMyChart.clear();
      this.reviewMyChart.clear();
      this.contributorsMyChart.clear();
      if (this.classificationMyChart){
        this.classificationMyChart.clear();
      }
    },
    selectTime(key,value){
      if (key === 'today'){
        this.params.startTime = getDateRange().todayDateStart;
        this.params.endTime = getDateRange().todayDateEnd;
      } else if (key === 'yesterday'){
        this.params.startTime = getDateRange().startOfYesterday;
        this.params.endTime = getDateRange().endOfYesterday;
      } else if (key === 'Last30days'){
        this.params.startTime = getDateRange().this30dayStart;
        this.params.endTime = getDateRange().this30dayEnd;
      } else if (key === 'Last3days'){
        this.params.startTime = getDateRange().this3dayStart;
        this.params.endTime = getDateRange().this3dayEnd;
      } else if (key === 'Last7days'){
        this.params.startTime = getDateRange().this7dayStart;
        this.params.endTime = getDateRange().this7dayEnd;
      } else if (key === 'Last15days'){
        this.params.startTime = getDateRange().this15dayStart;
        this.params.endTime = getDateRange().this15dayEnd;
      } else if (key === 'thisWeek'){
        this.params.startTime = getDateRange().thisWeekStart;
        this.params.endTime = getDateRange().thisWeekEnd;
      } else if (key === 'lastWeek'){
        this.params.startTime = getDateRange().lastWeekStart;
        this.params.endTime = getDateRange().lastWeekEnd;
      } else if (key === 'thisMonth'){
        this.params.startTime = getDateRange().thisMonthStart;
        this.params.endTime = getDateRange().thisMonthEnd;
      } else if (key === 'lastMonth'){
        this.params.startTime = getDateRange().lastMonthStart;
        this.params.endTime = getDateRange().lastMonthEnd;
      } else if (key === 'upToNow'){
        this.params.startTime = this.companyAuditTime;
        if (this.params.startTime){
          this.params.endTime = getDateRange().todayDateEnd
        } else {
          this.params.endTime = null;
        }
      } else if (key === 'dateRange'){
        if (value){
          this.params.startTime = value[0];
          this.params.endTime = value[1];
        }
      }
      this.dateKey = key;
      this.clearFun();
      this.initEcharts();
    },
    updateNumber(){
      console.log('daysDiff',this.dateKey)
      if (this.dateKey === 'today'){
        return 1;
        // eslint-disable-next-line no-dupe-else-if
      } else if (this.dateKey === 'yesterday'){
        return 2;
        // eslint-disable-next-line no-dupe-else-if
      } else if (this.dateKey === 'Last3days'){
        return 3;
        // eslint-disable-next-line no-dupe-else-if
      } else if (this.dateKey === 'Last7days' || this.dateKey === 'thisWeek' || this.dateKey === 'lastWeek'){
        return 7;
        // eslint-disable-next-line no-dupe-else-if
      } else if (this.dateKey === 'Last15days'){
        return 15;
        // eslint-disable-next-line no-dupe-else-if
      } else if (this.dateKey === 'Last30days' || this.dateKey === 'thisMonth' || this.dateKey === 'lastMonth'){
        return 30;
        // eslint-disable-next-line no-dupe-else-if
      } else if (this.dateKey === 'dateRange'){
        const startTime = new Date(this.params.startTime);
        const endTime = new Date(this.params.endTime);

        // 计算时间间隔（单位为毫秒）
        const timeDiff = endTime.getTime() - startTime.getTime();

        // 将时间间隔转换为天数
        const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        return daysDiff;

        // eslint-disable-next-line no-dupe-else-if
      } else if (this.dateKey === 'upToNow'){
        if (!this.params.startTime){
          return 1
        }
        const startTime = new Date(this.params.startTime);
        const endTime = new Date(this.params.endTime);

        // 计算时间间隔（单位为毫秒）
        const timeDiff = endTime.getTime() - startTime.getTime();

        // 将时间间隔转换为天数
        const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        return daysDiff;
        // eslint-disable-next-line no-dupe-else-if
      }
    },
    handlerUnit(value){
      return value/1024/1024/1024>=1 ? (value/1024/1024/1024).toFixed(1) + 'GB' : (value/1024/1024).toFixed(1) + 'MB'
    },
    setKnowledgeCard(){
      this.knowledgeCardDialog = true;
    },
    saveSuccess(){
      this.knowledgeCardDialog = false;
      this.initKnowledgeClassification();
    },
    elDropdownvisibleChange(value) {
      this.parentDirectorySelect = value;
    },
    loadNode(node, resolve) {
      let corpId = sessionStorage.getItem('_corpId');
      let  mainId = sessionStorage.getItem('_mainId');
      let url = '';
      let isAskLightning = GetQueryString('isAskLightning') == 1;
      if(isAskLightning){
        url = requestUrl.knowledgeBase.clientSelectKnowledgeBaseStructureTree;
      } else {
        url = requestUrl.knowledgeBase.knowledgeBaseTreeLazy;
      }
      // let url =requestUrl.knowledgeBase.knowledgeBaseTreeLazy;
      url += "?corpId=" + corpId + '&mainId=' + mainId;
      url += '&parentId=' + (node.data.id ? node.data.id : -1)
      url += '&keyWord='
      let axiosDatas = [];
      if (node.level === 0) {
        this.$http.get(url).then(res => {
          if (res.data.code == 0 && res.data.data){
            axiosDatas = res.data.data;
            axiosDatas.forEach(element => {
              element.leaf = false;
            });
            return resolve(axiosDatas);
          }
        })
      }
      if (node.level >= 1) {
        this.$http.get(url).then(res => {
          console.debug('res',res.data.data)
          if (res.data.code == 0 && res.data.data){
            axiosDatas = res.data.data
            axiosDatas.forEach(element => {
              element.leaf = false;
              if (!element.haveChildren){
                element.leaf = true
              }
            });
            node.data.children = axiosDatas;
            return resolve(axiosDatas);
          }
        })
      }
    },
    nodeClick(node,item){
      this.$set(node, "checked", true);
      item.catalogueId = node.id;
      item.catalogueName = node.name;
      let messageDrop = this.$refs.messageDrop;
      if (messageDrop && messageDrop[0]) {
        for (let i=0;i<messageDrop.length;i++){
          messageDrop[i].visible = false;
        }
      }
    },
    addStatistics(){
      this.cardData.list.push( {
        name:"",
        catalogueId:"",
        catalogueName:""
      })
    },
    removeStatistics(index){
      this.cardData.list.splice(index,1);
    },
    getConfig(){
      let url = requestUrl.statistical.configuration;
      let mainId = sessionStorage.getItem('_mainId');
      let corpId = sessionStorage.getItem('_corpId');
      let userId = sessionStorage.getItem('_uid');
      url += '?mainId=' + mainId + '&corpId=' + corpId + '&userId=' + userId + '&type=range'
      this.$http.get(url).then(res => {
        if (res.data && res.data.length > 0){
          this.cardData = res.data[0];
          this.cardDataCopy = JSON.stringify(res.data[0]);
          this.initKnowledgeClassification();
        }
      })
    },
    cancelConfig(){
      this.knowledgeCardDialog = false;
      this.cardData = JSON.parse(this.cardDataCopy);
    },
    saveConfig(){
      let url = requestUrl.statistical.configuration;
      let mainId = sessionStorage.getItem('_mainId');
      let corpId = sessionStorage.getItem('_corpId');
      let userId = sessionStorage.getItem('_uid');
      if (!this.cardData.name){
        this.$message({
          message:"请填写统计卡片名称",
          type:"warning"
        })
        return
      }
      let list = this.cardData.list.filter(res => res.name && res.catalogueId);
      let indexName = this.cardData.list.findIndex(res => !res.name);
      let indexId = this.cardData.list.findIndex(res => !res.catalogueId);
      if (indexName !== -1){
        this.$message({
          message:"请输入统计指标名称",
          type:"warning"
        })
        return;
      }
      if (indexId !== -1){
        this.$message({
          message:"请选择知识目录",
          type:"warning"
        })
        return;
      }
      let obj = {
        name:this.cardData.name,
        list:list,
        mainId,
        userId,
        corpId,
        type:"range"
      }
      if (this.cardData._id){
        url += '/' + this.cardData._id;
        this.$http.put(url,{
          name:this.cardData.name,
          list:list
        }).then(res => {
          console.log('put',res)
          if (res.data){
            this.$message({
              message:"保存成功",
              type:"success"
            })
            this.saveSuccess()
          }

        })
      } else {
        this.$http.post(url,obj).then(res => {
          console.log('post',res)
          if (res.data){
            this.$message({
              message:"保存成功",
              type:"success"
            })
            this.saveSuccess()
          }
        })
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.params = {
        mainId:sessionStorage.getItem('_mainId'),
        corpId:sessionStorage.getItem('_corpId'),
        userId:sessionStorage.getItem('_uid'),
        indicator:1,
        startTime:getDateRange().this30dayStart,
        endTime:getDateRange().this30dayEnd,
      }
      this.initEcharts();
      this.getCompanyAudit();
    })
    window.addEventListener("resize", this.resizeFun);
  },
  beforeDestroy() {
    window.removeEventListener('resize',this.resizeFun)
  }
};
</script>

<style scoped lang="less">
#KnowledgeAnalysis{
  width: 100%;
  overflow-x: hidden;
  .header{
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    .knowledgeTab{
      flex: none;
      padding: 0 14px;
      height: 30px;
      border-radius: 19px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .activeKnowledgeTab{
      background: #366AFF;
      color: #ffffff;
    }
  }
  .KnowledgeAnalysisContainer{
    height: calc(100% - 50px);
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 10px;
    .cardList{
      //margin-bottom: 16px;
      .oneRow,.twoRow{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .cardItem{
          flex: 1;
          margin-right: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 120px;
          padding: 0 20px;
          background: #FFFFFF;
          box-shadow: 0px 0px 18px 0px rgba(29,55,129,0.07);
          border-radius: 5px;
          .cardLeft{
            display: flex;
            flex-direction: column;
            .cardNum{
              margin-bottom: 8px;
              display: flex;
              align-items: center;
              .number{
                color: #010101;
                font-size: 20px;
                margin-right: 4px;
              }
              .newAdd{
                display: flex;
                align-items: center;
                font-size: 12px;
                color: #366aff;
                .newAddBtn{
                  flex: none;
                  //width: 50px;
                  padding: 0 6px;
                  height: 16px;
                  background: #366AFF;
                  border-radius: 3px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 12px;
                  margin-left: 4px;
                  color: #ffffff;
                }
              }
            }
            .cardName{
              color: #000000;
            }
          }
          .cardIcon{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 46px;
            height: 46px;
            border-radius: 50%;
            flex: none;
            i{
              font-size: 20px;
            }
          }
        }
        .cardItem:last-child{
          margin-right: 0!important;
        }
      }
      .oneRow{
        .cardItem{
          margin-bottom: 16px;
        }
      }
      .twoRow{
        .list{
          flex: 1;
          height:0px;
          padding: 0 20px;
        }
      }
    }
    .analysisCard{
      height: 400px;
      background: #FFFFFF;
      box-shadow: 0px 0px 18px 0px rgba(29,55,129,0.07);
      border-radius: 5px;
      margin-bottom: 16px;
      padding: 20px 0;
      overflow-y: hidden;
      .title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        color: #000000;
        margin-bottom: 14px;
        .setBtn{
          flex: none;
          height: 26px;
          padding: 0 10px;
          background: #366AFF;
          border-radius: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          cursor: pointer;
          i{
            padding-right: 4px;
            font-size: 14px;
          }
        }
      }
      .analysisCardLine{
        height: calc(100% - 30px);
      }
      .empty-image{
        height: calc(100% - 30px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .empty-text{
          font-size: 16px;
          color: #616161;
          margin-top: 30px;
        }
        /deep/img{
          width: 251px;
          height: 182px;
        }
      }
    }
    .legend{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .analysisCard{
        flex: none;
        width: calc(50% - 10px);
      }
    }
  }
}
/deep/.popup-floating-layer{
  #popup-assembly{
    width: 800px;
  }
}
.knowledgeCardContainer{
  .card-name{
    padding-bottom: 14px;
    .title{
      padding-bottom: 10px;
    }
  }
  .statistics{
    display: flex;
    align-items: center;
    padding-bottom: 14px;
    position: relative;
    .statistics-left{
      flex: none;
      width: 260px;
      margin-right: 20px;
      .top{
        padding-bottom: 10px;
      }
    }
    .statistics-center{
      flex: 1;
      margin-right: 30px;
      .top{
        padding-bottom: 10px;
      }
    }
    .delete-icon{
      flex: none;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #366aff;
      position: absolute;
      right: 0;
      top: 40px;
      i{
        cursor: pointer;
      }
    }
  }
  .add-statistics{
    margin-top: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .add-btn{
      flex: none;
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      background-color: #366aff;
      margin-right: 14px;
      border-radius: 5px;
      i{
        font-size: 14px;
      }
    }
  }
}
#el-dropdown-dir{
  width: 100%;
  .el-dropdown-link-dir{
    display: flex;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    .el-dropdown-placeholder{
      flex: auto;
      display: flex;
      align-items: center;
      height: 38px;
      padding: 0 18px;
      color: #C0C4CC;
    }
    .el-dropdown-dir-content {
      flex: auto;
      height: 38px;
      padding: 0 18px;
      max-width: calc(100% - 40px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;
      align-items: center;
      .el-tag {
        margin: 2px 5px 0 0;
      }
    }
    .el-icon{
      flex: none;
      width: 30px;
      text-align: center;
      display: flex;
      align-items: center;
    }
  }
}
/deep/.el-dropdown-menu__item:not(.is-disabled):hover{
  background-color: #FFFFFF;
}
/deep/.dir-dropdown-item{
  width: 500px!important;
  padding: 0!important;
  .dir-tree{
    max-height: 300px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    ///deep/.el-tree-node__expand-icon{
    //  display: none;
    //}
    .el-tree-node__content{
      height: 32px!important;
    }
  }
  .custom-tree-node {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;

    .custom-tree-node-left{
      display: flex;
      align-items: center;
      flex: none;
      width: calc(100% - 20px);
      .custom-tree-node-label{
        display: flex;
        align-items: center;
        width: 100%;
        flex: none;
        .folder-name{
          flex: none;
          max-width: calc(100% - 10px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        img{
          margin-right: 6px;
        }
      }
    }
    i {
      font-weight: 600;
      color: #616161;
      font-size: 14px;
    }
    .el-icon-caret-right{
      padding: 0 6px;
      color: #c0c4cc;
      display: flex;
      align-items: center;
      font-size: 12px;
    }
    .el-icon-caret-right{
      &.down{
        transform: rotate(90deg);
      }
      &.right{
        transform: rotate(0);
      }
    }
  }
  .el-tree{
    max-height: 300px;
  }
}
</style>
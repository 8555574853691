<template>
    <div class="file_pre" id="file_pre">
        <template  v-if="withLocationMark">
          <markPdf ref="pdf_view" :withLocationMark="withLocationMark"></markPdf>
        </template>
        <template v-else>
          <template v-if="fileType == 'VIDEO'">
            <video class="video" :src="url" controls></video>
          </template>
          <template v-else-if="fileType == 'HTML'">
              <div class="preview_wechat" id="preview_wechat" style="text-align:left;" v-html="fileText"></div>
          </template>
          <template v-else>
              <iframe
                  class="preview_iframe"
                  :src="previewUrl"
                  style="border:none;"
                  :style="{height:iframeHeight}"
                  :key="key"
              ></iframe>
          </template>
        </template>
        <div class="loading_img" v-show="loading">
          <img src="../assets/images/loading.gif" alt="">
        </div>
    </div>
</template>
<script>

import { requestUrl } from "@/http/requestUrl";
import markPdf from "./markPdf.vue";
import waterMark from "@/utils/common";
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from "uuid";
export default {
    data () {
        return {
          fileText:"",
          loading: false,
          withLocationMark:null,
          iframeHeight:"100%",
          key:uuidv4()
        }
    },
    props: {
        url: {
            type: String,
            required: true,
        },
        fileType: {
          type: String,
          default: '',
        },
        hiddenToolbar:{
          type: String,
          required: false,
        },
        knowledgeItem:{
          type:Object,
          default(){
            return {}
          }
        },
        textWatermarkStr:{
          type: String,
          default: '',
        }
    },
    mounted() {
      let iframe = document.getElementsByClassName('preview_iframe')
      if (iframe && iframe[0]){
        iframe[0].onload = () => {
          iframe[0].style.backgroundImage = `url('')`
        }
      }
      // if(iframe) {
      //   // this.loading = true
      //   console.log('1111111');
      //   iframe.onload = () => {
      //     this.loading = false
      //   }
      // }
      // if(this.fileType == 'VIDEO') {
      //   this.loading = false
      // }
      if (this.fileType === 'IMAGE'){
        document.getElementById('file_pre').style.height = '600px'
      }
      this.loadIframe();
      window.addEventListener('message',this.handleIframeMessage,false)
    },
    components:{
      markPdf
    },
    computed: {
        ...mapGetters('knowledge', {
          textWatermarkStr: 'getTextWatermarkStr'
        }),
        ...mapGetters('knowledge', {
          fullScreen: 'getFullScreen'
        }),
        previewUrl () {
            let url = process.env.VUE_APP_NODE_ENV === 'production' ? 'https://kkfileview.askbot.cn/onlinePreview' :'https://test.open.askbot.cn/kkfileview/onlinePreview'
            url += '?url=' + this.url;
            if (this.fileType == 'PPT') {
              url += '&officePreviewType=pdf'
            }
            if (this.textWatermarkStr){
              url += '&textWatermarkValue=' + this.textWatermarkStr
            }
            url += '&timestamp=' + new Date().getTime();
            return url
        },
    },
    methods: {
      getPreviewContent(fileInOssPath){
        this.fileText = "";
        let url = requestUrl.preview.previewContent;
        this.$http.post(url,{
          fileInOssPath: fileInOssPath,
        }).then(res => {
          if (res.data.data){
            this.fileText = res.data.data;
            this.loading = false;
          }
        })
      },
      loadIframe () {
        if (this.fileType == 'VIDEO' || this.fileType == 'WECHAT') {
          this.loading = false
        } else {
          let iframe = document.getElementsByClassName('preview_iframe');
          console.log('iframe',iframe)
          if (iframe){
            Array.from(iframe).forEach(item => {
              item.onload = () => {
                this.loading = false;
                console.log('iframe',item)
                item.style.backgroundImage = "none";
              }
            })
          }
          if (this.fileType === 'HTML'){
            setTimeout(() => {
              waterMark.newInitWaterMark('preview_wechat',this.textWatermarkStr)
            },500)
          }
        }
      },
      getInnermostIframe() {
        let iframes = document.querySelectorAll('iframe');
        for (let i = 0; i < iframes.length; i++) {
          let innerIframes = iframes[i].querySelectorAll('iframe');
          if (innerIframes.length > 0) {
            return innerIframes[0];
          }
        }
        return null;
      },
      openMark() {
        this.$refs.pdf_view.openMark()
      },
      newInitWaterMark(){
        //默认设置
        var defaultSettings = {

          watermark_txt: "",

          visitorWatermark_txt:"",

          watermark_x: 0, //水印起始位置x轴坐标

          watermark_y: 0, //水印起始位置Y轴坐标

          watermark_rows: 0, //水印行数

          watermark_cols: 0, //水印列数

          watermark_x_space: 40, //水印x轴间隔

          watermark_y_space: 60, //水印y轴间隔

          watermark_color: 'black', //水印字体颜色

          watermark_alpha: 0.3, //水印透明度

          watermark_fontsize: '12px', //水印字体大小

          watermark_font: '微软雅黑', //水印字体

          watermark_width: 100, //水印宽度

          watermark_height: 60, //水印长度

          watermark_angle: 20 //水印倾斜度数

        };
        let userInfo = sessionStorage.getItem('userInfo') || "{}"
        let textWatermarkValue = this.knowledgeItem.textWatermark ? (this.knowledgeItem.textWatermarkValue ? this.knowledgeItem.textWatermarkValue : '') : '';
        let visitorWatermarkValue = this.knowledgeItem.visitorWatermark ? JSON.parse(userInfo).realName : '';
        if(textWatermarkValue){
          defaultSettings.watermark_txt = textWatermarkValue;
        }
        if (visitorWatermarkValue){
          defaultSettings.visitorWatermark_txt = visitorWatermarkValue;
        }
        if (!textWatermarkValue && !visitorWatermarkValue){
          return
        }

        //采用配置项替换默认值，作用类似jquery.extend

        if (arguments.length === 1 && typeof arguments[0] === "object") {

          // 获取参数配置

          var src = arguments[0];

          for (let key in src) {

            if (src[key] && defaultSettings[key] && src[key] === defaultSettings[key])

              continue;

            else if (src[key])

              defaultSettings[key] = src[key];

          }

        }

        var oTemp = document.createDocumentFragment();

        //获取页面最大宽度

        var page_width = Math.max(document.getElementById("file_pre").scrollWidth, document.getElementById("file_pre").clientWidth);

        var cutWidth = page_width * 0.0150;

        page_width = page_width - cutWidth;

        //获取页面最大高度
        var page_height = document.getElementById("file_pre").scrollHeight;
        console.log(page_height,page_width)

        // var page_height = Math.max(document.body.scrollHeight, document.body.clientHeight);

        // var page_height = document.body.scrollHeight+document.body.scrollTop;

        //如果将水印列数设置为0，或水印列数设置过大，超过页面最大宽度，则重新计算水印列数和水印x轴间隔

        if (defaultSettings.watermark_cols == 0 || (parseInt(defaultSettings.watermark_x + defaultSettings

            .watermark_width * defaultSettings.watermark_cols + defaultSettings.watermark_x_space * (

            defaultSettings.watermark_cols - 1)) > page_width)) {

          defaultSettings.watermark_cols = parseInt((page_width - defaultSettings.watermark_x + defaultSettings

              .watermark_x_space) / (defaultSettings.watermark_width + defaultSettings

              .watermark_x_space));

          defaultSettings.watermark_x_space = parseInt((page_width - defaultSettings.watermark_x -

              defaultSettings

                  .watermark_width * defaultSettings.watermark_cols) / (defaultSettings.watermark_cols - 1));

        }

        //如果将水印行数设置为0，或水印行数设置过大，超过页面最大长度，则重新计算水印行数和水印y轴间隔

        if (defaultSettings.watermark_rows == 0 || (parseInt(defaultSettings.watermark_y + defaultSettings

            .watermark_height * defaultSettings.watermark_rows + defaultSettings.watermark_y_space * (

            defaultSettings.watermark_rows - 1)) > page_height)) {

          defaultSettings.watermark_rows = parseInt((defaultSettings.watermark_y_space + page_height -

              defaultSettings

                  .watermark_y) / (defaultSettings.watermark_height + defaultSettings.watermark_y_space));

          defaultSettings.watermark_y_space = parseInt(((page_height - defaultSettings.watermark_y) -

              defaultSettings

                  .watermark_height * defaultSettings.watermark_rows) / (defaultSettings.watermark_rows - 1));

        }

        var x;
        var y;
        for (var i = 0; i < defaultSettings.watermark_rows; i++) {
          y = defaultSettings.watermark_y + (defaultSettings.watermark_y_space + defaultSettings.watermark_height) * i;
          for (var j = 0; j < defaultSettings.watermark_cols; j++) {
            x = defaultSettings.watermark_x + (defaultSettings.watermark_width + defaultSettings.watermark_x_space) * j;
            var mask_div = document.createElement('div');

            mask_div.id = 'mask_div' + i + j;
            mask_div.className = 'mask_div';
            mask_div.style.webkitTransform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
            mask_div.style.MozTransform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
            mask_div.style.msTransform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
            mask_div.style.OTransform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
            mask_div.style.transform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
            mask_div.style.visibility = "";
            mask_div.style.position = "absolute";
            //奇偶行错开，这样水印就不对齐，显的不呆板
            if (i % 2 != 0) {
              mask_div.style.left = x + 'px';
              if (j % 2 != 0 && visitorWatermarkValue ){
                mask_div.appendChild(document.createTextNode(defaultSettings.visitorWatermark_txt));
              } else {
                if (!textWatermarkValue){
                  mask_div.appendChild(document.createTextNode(defaultSettings.visitorWatermark_txt));
                } else {
                  mask_div.appendChild(document.createTextNode(defaultSettings.watermark_txt));
                }
              }
            } else {
              mask_div.style.left = x + 'px';
              if (j % 2 != 0 && textWatermarkValue){
                mask_div.appendChild(document.createTextNode(defaultSettings.watermark_txt));
              } else {
                if (!visitorWatermarkValue){
                  mask_div.appendChild(document.createTextNode(defaultSettings.watermark_txt));
                } else {
                  mask_div.appendChild(document.createTextNode(defaultSettings.visitorWatermark_txt));
                }
              }
            }

            mask_div.style.top = y + 'px';

            mask_div.style.overflow = "hidden";

            mask_div.style.pointerEvents = 'none'; //让水印不遮挡页面的点击事件

            mask_div.style.opacity = defaultSettings.watermark_alpha;

            mask_div.style.fontSize = defaultSettings.watermark_fontsize;

            mask_div.style.fontFamily = defaultSettings.watermark_font;

            mask_div.style.color = defaultSettings.watermark_color;

            mask_div.style.textAlign = "center";

            mask_div.style.width = defaultSettings.watermark_width + 'px';

            mask_div.style.height = defaultSettings.watermark_width + 'px';

            mask_div.style.display = "block";

            oTemp.appendChild(mask_div);
          }
        }
        document.getElementById('file_pre').appendChild(oTemp);
      },
      handleIframeMessage(res){
        if (res.data.type == 'getViewerHeight'){
          let iframeHeight = this.iframeHeight.replace('px','')
          if (res.data.data && iframeHeight != (res.data.data + 50)){
            this.iframeHeight = res.data.data + 50 + 'px'
          }
        }
      }
    },
    watch:{
      withLocationMark(value) {
        if(value) {
          this.loading = false
        }
      },
      fullScreen(value) {
        if (this.fileType === 'IMAGE'){
          let file_pre = document.getElementById('file_pre')
          if(value) {
            file_pre.style.height = '85vh'
          } else {
            file_pre.style.height = '600px'
          }
        }
      }
    },
    beforeDestroy() {
      window.removeEventListener('message', this.handleIframeMessage);
    }
}
</script>
<style lang="less" scoped>
.file_pre {
    width: 100%;
    //height: calc(100% - 40px);
    //height: 100%;
    min-height: 400px;
    position: relative;
    visibility: inherit !important;
    .preview_iframe {
        width: 100%;
        //height: 100%;
        background-image: url('../assets/images/loading.gif');
        background-position: center;
        background-size: 50px;
        background-repeat: no-repeat;
    }
    .video {
        width: 80%;
        height: 50%;
    }
    .preview_wechat {
        width: 100%;
        //height: calc(100% - 32px);
        //height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        overflow-y: auto;
        //overflow-x: scroll;
        padding: 16px;
      background-color: #ffffff;
      border-radius: 5px;
    }
  /deep/img{
    width: 100%;
  }
  /deep/.ne-table{
    width: 100% !important;
  }
}
.loading_img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #FFF;
  img {
    width: 50px;
    height: 50px;
  }
}
</style>

<template>
  <div id="commentComponents">
    <div class="replyItem"
         :class="[index < commentListCopy.length - 1 && type !== 'children' ? 'setReplayItemBorder' : '']"
         v-for="(item,index) in commentListCopy"
         :key="item.commentId"
    >
      <div class="items">
        <div class="topHeader">
          <div class="topLeft">
            <el-avatar :size="24" :src="item.commentatorAvatar" v-if="item.commentatorAvatar"></el-avatar>
            <div class="avatar" v-else-if="item.commentatorName">{{item.commentatorName.substr(-2)}}</div>
            <span class="replayName">{{item.commentatorName ? item.commentatorName : '--'}}</span>
            <template v-if="item.parentUserName && item.parentId !== parentCommentId">
              <span style="color: #616161">回复</span>
              <span class="parentUserName">{{item.parentUserName}}</span>
            </template>
            <span class="commentTime">{{ item.updatedAt | timeAgoFun}}</span>
            <span class="isEditComment" v-if="new Date(item.createdAt).getTime() !== new Date(item.updatedAt).getTime()">(编辑过)</span>
            <span class="expandReply"
                  v-if="type !== 'children' && item.comments && item.comments.totalCount > 0 && retract[item.commentId]"
                  @click="expandAndCollapse('expand',item.commentId)"
            >
              展开{{item.comments.totalCount}}条回复
              <i class="el-icon-arrow-down"></i>
            </span>
          </div>
          <div class="topRight" :style="{display:visible[item.commentId] ? 'flex' : ''}">
            <i class="iconfont guoran-dianzan" @click.stop="likeCommit(item)"></i>
            <i class="iconfont guoran-write-21" style="font-size: 18px" @click.stop="replyComment(item,parentCommentId)"></i>
            <template v-if="item.commentatorUid == userId || (clientUser && clientUser.id == item.commentatorUid)">
              <el-popover
                  placement="bottom"
                  width="80"
                  trigger="hover"
                  popper-class="commitLoadMore"
                  v-model="visible[item.commentId]"
                  content="">
                <div class="popoverContainer">
                  <div class="popoverItem" @click="operate('edit',item,parentCommentId)">
                    <i class="iconfont guoran-tongyichicun-16-15-lianjibianji"></i>
                    <span>编辑</span>
                  </div>
                  <div class="popoverItem" @click="operate('delete',item,parentCommentId)">
                    <i class="iconfont guoran-tongyichicun-18-13-shanchu"></i>
                    <span>删除</span>
                  </div>
                </div>
                <i slot="reference" class="iconfont guoran-gengduo"></i>
              </el-popover>
            </template>
          </div>
        </div>
        <div class="container" @click="getImg($event,item.htmlContent)" v-html="item.htmlContent" v-if="item.view">
        </div>
        <ckeditor-input
            v-else-if="item.editing"
            :ref="'ckeditor' +  item.commentId"
            @cancelInput="cancelInput(item)"
            @sendComment="sendComment($event,item)"
        ></ckeditor-input>
        <div class="likeList" v-if="item.likes.length">
          <div class="likeItem" v-for="(like,likeIndex) in item.likes" :key="likeIndex">
            <i class="iconfont guoran-dianzan"></i>
            {{like.name ? like.name : '--'}}
          </div>
        </div>
      </div>
      <comment-com
          v-if="item.comments && item.comments.rows"
          :commentList="item.comments.rows"
          :type="'children'"
          class="childrenComment"
          :id="!retract[item.commentId] ? 'expandComment'+ item.commentId : 'collapseComment' + item.commentId "
          @replyComment="replyComment"
          @updateComment="sendComment"
          @deleteReply="deleteReply"
          @loadMore="loadMore"
          @expandAndCollapse="newRetractComment"
          :parentCommentId="item.commentId"
          :clientUser="clientUser"
      ></comment-com>
      <div class="loadMore"
           v-if="item.comments && item.comments.rows && type !== 'children' && !retract[item.commentId] && (item.comments.totalCount - ((replyPageIndex[item.commentId] + 1) * replyPageSize) > 0)"
           @click="loadMore(item,item.commentId)"
      >
        <span>查看更多</span>
        <i class="el-icon-arrow-down"></i>
      </div>
      <div class="collapseReply"
           v-else-if="item.comments && item.comments.rows && item.comments.rows.length > 0 && type !== 'children' && !retract[item.commentId]"
           @click="expandAndCollapse('collapse',item.commentId)"
      >
        <span>收起</span>
        <i class="el-icon-arrow-up"></i>
      </div>
    </div>
    <img-view :url-list="imgList" @closeViewer="closeViewer" v-if="previewImage"></img-view>
  </div>
</template>

<script>
import { requestUrl } from "@/http/requestUrl";
import { timeAgo } from "@/assets/js/common";
import CkeditorInput from "@/components/knowledgePreview/ckeditorInput";
import ImgView from "@/components/imgView";
export default {
  name: "commentCom",
  components: { ImgView, CkeditorInput },
  data(){
    return{
      commentId:"",
      commentListCopy:[],
      visible:{},
      retract:{},
      userId:'',
      imgList:[],
      previewImage:false
    }
  },
  props:{
    commentList:{
      type:Object,
      default(){
        return {}
      }
    },
    type:{
      type:String,
      default:""
    },
    parentCommentId:{
      type:String,
      default:""
    },
    replyPageIndex:{
      type:Object,
      default(){
        return{}
      }
    },
    replyPageSize:{
      type:Number,
      default:5
    },
    clientUser:{
      type:Object,
      default:null
    }
  },
  filters:{
    timeAgoFun(val){
      return timeAgo(val);
    }
  },
  methods:{
    likeCommit(comment){
      let url = requestUrl.comment.likeComment + comment.commentId;
      let message = comment.like ? this.$t('versionManage.cancelLike') : this.$t('versionManage.like');
      this.$http.put(url).then(res => {
        if (res.data.code == 0 && res.data.data){
          this.$message({
            message:message,
            type:"success"
          })
          this.commentListCopy.forEach((item,index) => {
            if (this.commentListCopy[index].commentId === comment.commentId){
              this.commentListCopy[index].likes = res.data.data.likes;
              this.commentListCopy[index].like = res.data.data.like;
              this.commentListCopy[index].likeCount = res.data.data.likeCount;
            }
          })
        }

      })
    },
    operate(type,item,parentCommentId){
      if (type === 'edit'){
        item.view = false;
        item.editing = true;
        this.$nextTick(() => {
          let ref = 'ckeditor' + item.commentId
          if (this.$refs[ref] && this.$refs[ref][0]){
            this.$refs[ref][0].htmlContent = item.htmlContent;
            // this.$refs[ref][0].currentEditor.editing.view.focus();
          }
        })
      } else {
        this.$emit('deleteReply',item,parentCommentId)
      }
      this.visible[item.commentId] = false;
    },
    deleteReply(item,parentCommentId){
      this.$emit('deleteReply',item,parentCommentId)
    },
    replyComment(item,parentCommentId){
      this.$emit('replyComment',item,parentCommentId)
    },
    cancelInput(item){
      item.view = true;
      item.editing = false;
    },
    sendComment(htmlContent,item){
      this.$emit('updateComment',htmlContent,item)
    },
    loadMore(item,parentCommentId){
      console.log('parentCommentId',item,parentCommentId)
      this.$emit('loadMore',item,parentCommentId)
    },
    expandAndCollapse(type,parentCommentId){
      console.log('type',type,parentCommentId)
      this.$set(this.retract,parentCommentId,!this.retract[parentCommentId])
      this.$emit('expandAndCollapse',type,parentCommentId)
      setTimeout(() => {
        if (type === 'expand'){
          this.expandComment(parentCommentId);
        } else {
          this.collapseComment(parentCommentId)
        }
      },50)
    },
    expandComment(parentCommentId){
      let id = 'expandComment' + parentCommentId
      const element = document.getElementById(id);
      let expandTimer = null;
      let offsetHeight = 0;
      // 获取元素总高度
      element.style.display = 'block';
      let height = 0;
      // 先将 display 设置为 block，获取到的 offsetHeight 才是正确的高度，之后才能设置元素高度
      offsetHeight = element.offsetHeight;
      const stepHeight = offsetHeight / 30;
      element.style.height = height + 'px';
      expandTimer = setInterval(() => {
        height += stepHeight;
        if (height >= offsetHeight) {
          clearInterval(expandTimer);
          element.style = null;
          return;
        }
        element.style.height = height + 'px';
      }, 10);
      element.style.transition = 'all .3s'
    },
    collapseComment(parentCommentId){
      let id = 'collapseComment' + parentCommentId
      const element = document.getElementById(id);
      element.style.height = 0 + 'px';
      element.style.overflow = 'hidden'
      element.style.transition = 'all .3s'

    },
    newRetractComment(type,parentCommentId){
      console.log('type',type,parentCommentId)
      this.$set(this.retract,parentCommentId,!this.retract[parentCommentId])
    },
    getImg(event,htmlContent){
      if (event.target.localName === 'img'){
        this.imgList = []
        let richtext = htmlContent
        this.imgList = [];
        richtext.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, (match, capture) => {
          this.imgList.push(capture);
        });
        /*当前点击的图片作为第一个图片*/
        let index = this.imgList.indexOf(event.target.currentSrc);
        this.imgList.splice(index,1);
        this.imgList.unshift(event.target.currentSrc);
        this.previewImage = true;
      }
    },
    closeViewer(){
      this.previewImage = false;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.userId = sessionStorage.getItem('_uid')
      console.log('userId',this.userId)
      // this.commentListCopy = this.commentList
    })
  },
  watch:{
    commentList:{
      handler(val){
        this.commentListCopy = val
      },
      immediate:true,
      deep:true
    }
  }
};
</script>

<style scoped lang="less">
#commentComponents{
  .replyItem{
    //padding: 0 10px;
    .topHeader{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .topLeft{
        display: flex;
        align-items: center;
        .avatar{
          flex: none;
          width: 26px;
          height: 26px;
          background: #366AFF;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          font-size: 12px;
        }
        .replayName{
          margin-left: 10px;
          margin-right: 10px;
        }
        .parentUserName{
         margin: 0 10px;
        }
        .commentTime{
          color: #A9B3C6;
          margin-right: 10px;
        }
        .isEditComment{
          color: #A9B3C6;
        }
        .expandReply{
          color: #A9B3C6;
          display: flex;
          align-items: center;
          cursor: pointer;
          i{
            margin-left: 4px;
          }
        }
      }
      .topRight{
        display: none;
        align-items: center;
        i{
          color: #366aff;
          cursor: pointer;
          margin-right: 10px;
        }
        i:last-child{
          margin-right: 0;
        }
      }
    }
    .container{
      //margin-bottom: 20px;
      /deep/img{
        width: 80px;
        height: 80px;
        object-fit: contain;
      }
      /deep/.ask-component-placeholder{
        background-color: #366aff;
        display: inline-block;
        padding: 0px 2px;
        border-radius: 99px;
        margin-right: 6px;
        //margin-bottom: 8px;
        color: #ffffff;
      }
    }
    .likeList{
      margin-top: 20px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .likeItem{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        background: #FFFFFF;
        border: 1px solid #A1B9FF;
        border-radius: 10px;
        padding: 0 8px;
        color: #9EB7FF;
        margin-right: 6px;
        i{
          margin-right: 4px;
        }
      }
    }
  }
  .items{
    padding: 20px 10px;
  }
  .items:hover{
    background: #F3F6FF;
    .topRight{
      display: flex;
    }
  }
  .checkComment{
    background: #F3F6FF;
  }
  .setReplayItemBorder{
    border-bottom: 1px dashed #E0E6F7;
  }
  .loadMore{
    color: #366aff;
    margin: 6px 0;
    padding-left: 30px;
    cursor: pointer;
    i{
      padding-right: 4px;
    }
  }
  .collapseReply{
    color: #366aff;
    margin: 6px 0;
    padding-left: 30px;
    cursor: pointer;
    i{
      padding-right: 4px;
    }
  }
}
.childrenComment{
  .replyItem{
    padding-bottom: 0!important;
    .items{
      padding: 20px 30px!important;
    }
  }
}
</style>
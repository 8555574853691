export function getCurrentNode(breadcrumb,node){
    let parentNode = node
    if (breadcrumb.length > 0){
        parentNode = breadcrumb[breadcrumb.length - 1]
    }
    return parentNode
}
export function parentNode(breadcrumb,node){
    let parentNode = node ? node : {
        id:-1
    }
   if (breadcrumb.length > 0) {
       parentNode = breadcrumb[breadcrumb.length - 1]
   }
    return parentNode
}
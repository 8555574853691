<template>
    <div class="container">
        <p class="drop" v-show="dropDownState === 'loading'">
            <img
                height="32px"
                src="https://static.guoranbot.com/cdn-im-client/common/img/p_loading.gif"
                alt=""
                srcset=""/>
        </p>
        <div class="histroy" id="list" :style="{marginTop:showAlert === 'personal_tryout' && wechatType == '3' ? setMarginTop + 'px' : ''}">
            <div class="scroll_top"></div>
            <div ref="messagenotice" id="message-notice" class="message-notice" >
                <span  @click="clickLoadHistory" class="notice-content first-load-more" v-if="isShowHistoryText">加载聊天记录</span>
                <!-- <span v-if="!isShowHistoryText" style="height: 20px" class="notice-content"></span> -->
            </div>
            <div
                v-for="(msg, index) in filterEmptyMsg"
                :id="'msg_' + msg.keyId + msg.id"
                :class="'msg_' + index"
                :key="msg.keyId">
                <div
                    class="message-notice"
                    v-show=" showTime(msg, index, filterEmptyMsg) && msg.time != null">
                    <p class="notice-content">
                        {{ new Date(msg.time).format_cus() }}
                    </p>
                </div>
                <div
                    class="message"
                    v-if="msg.type && 
                        msg.type !== 'action_assets_card' &&
                        msg.type !== 'not_found_assets' &&
                        msg.type !== 'client-session-change' && 
                        msg.type !== 'receiver-offline'
                    "
                    :class="[ msg.speaker == 'user' ? 'message-kf' : 'message', msg.type == 'answer_intellect_guide'? 'message_intellect_guide': '']">
                    <MessageStatus :message="msg" v-if="msg.speaker === 'user'"></MessageStatus>
                    <div
                        class="user-detail"
                        :style="msg.type === 'action_alert_iframe' && msg.content.displayStyle !=='dialog' ? 'max-width:319px' : ''">
                        <template v-if="sourceOhm === 'ohm' && isOhmPc">
                             <div v-if="msg.speaker != 'user' && (!agentLogoUrl || agentLogoUrl === '' || agentLogoUrl == 'null')" class="user-ava">
                                <span
                                    v-if="msg.type !== 'action_feedback' && msg.type !== 'answer_intellect_guide' && (msg.type !=='action_alert_iframe' || (msg.type ==='action_alert_iframe' && msg.content.displayStyle !=='dialog'))"
                                    :class="['avat',msg.source !== 'AIMER_SERVER' ? 'iconfont guoran-askbotdandutubiao2' : '']">
                                    <template v-if="msg.source == 'AIMER_SERVER'">
                                        <img v-if="(msg.ext && msg.ext.aimerAgentInfo && msg.ext.aimerAgentInfo.avatar)" :src="msg.ext.aimerAgentInfo.avatar" alt="">
                                        <span v-else-if="(msg.ext && msg.ext.aimerAgentInfo && msg.ext.aimerAgentInfo.name)">
                                            {{msg.ext.aimerAgentInfo.name[msg.ext.aimerAgentInfo.name.length - 2]}}{{msg.ext.aimerAgentInfo.name[msg.ext.aimerAgentInfo.name.length - 1]}}
                                        </span>
                                    </template>
                                </span>
                            </div>
                            <div v-if="msg.speaker != 'user' && !(!agentLogoUrl || agentLogoUrl === '' || agentLogoUrl == 'null')" class="user-ava user-ava-self-ohm">
                                <img v-if="msg.type !== 'action_feedback'&& msg.type !== 'answer_intellect_guide' && (msg.type !=='action_alert_iframe' || (msg.type ==='action_alert_iframe' && msg.content.displayStyle !=='dialog'))" height="30px" width="30px" :src="agentLogoUrl" alt="" srcset="">
                            </div>
                        </template>
                       <template v-else>
                            <div v-if="msg.speaker != 'user' && (botPhotoUrl == 'null' || botPhotoUrl == null || !botPhotoUrl)" class="user-ava">
                                <span
                                    v-if="msg.type !== 'action_feedback' && msg.type !== 'answer_intellect_guide' && (msg.type !=='action_alert_iframe' || (msg.type ==='action_alert_iframe' && msg.content.displayStyle !=='dialog'))"
                                    :class="['avat',msg.source !== 'AIMER_SERVER' ? 'iconfont guoran-askbotdandutubiao2' : '']">
                                    <template v-if="msg.source == 'AIMER_SERVER'">
                                        <img v-if="(msg.ext && msg.ext.aimerAgentInfo && msg.ext.aimerAgentInfo.avatar)" :src="msg.ext.aimerAgentInfo.avatar" alt="">
                                        <span v-else-if="(msg.ext && msg.ext.aimerAgentInfo && msg.ext.aimerAgentInfo.name)">
                                            {{msg.ext.aimerAgentInfo.name[msg.ext.aimerAgentInfo.name.length - 2]}}{{msg.ext.aimerAgentInfo.name[msg.ext.aimerAgentInfo.name.length - 1]}}
                                        </span>
                                    </template>
                                </span>
                            </div>
                            <div v-if="msg.speaker != 'user' && !(botPhotoUrl == 'null' || botPhotoUrl == null)" class="user-ava user-ava-self">
                                <img v-if="msg.type !== 'action_feedback'&& msg.type !== 'answer_intellect_guide' && (msg.type !=='action_alert_iframe' || (msg.type ==='action_alert_iframe' && msg.content.displayStyle !=='dialog'))" height="30px" width="30px" :src="botPhotoUrl" alt="" srcset="">
                            </div>
                       </template>
                        <img
                            class="thr-w"
                            v-if="
                                msg.speaker != 'user' &&
                                msg.type !== 'action_feedback' &&
                                msg.type !== 'answer_intellect_guide' && 
                                (msg.type !== 'user_image' ||
                                    msg.type !== 'IMAGE') &&
                                !(
                                    msg.type == 'answer_image' &&
                                    msg.content.recommend&&
                                    !msg.content.recommend.status
                                ) &&
                                !(
                                    msg.type == 'answer_video' &&
                                    msg.content.recommend&&
                                    !msg.content.recommend.status
                                ) && (msg.type !=='action_alert_iframe' ||
                                 (msg.type ==='action_alert_iframe' &&
                                  msg.content.displayStyle !=='dialog'))
                            "
                            src="https://static.guoranbot.com/cdn-im-client/common/img/wthr.png"
                            alt=""
                            srcset=""/>
                        <!-- 等待语音组件 -->
                        <div v-if="msg.type == 'answer_loading' && msg.translated == false" class="media-body loading-yuyin">
                            <msg-loading></msg-loading>
                        </div>
                        <div class="media-body" :class="msg.content && msg.content.items.length != 0 ? 'welcome_card_body' : ''" v-if="msg.type === 'welcome_llm_card'">
                            <WelcomeLlmCard
                                :msgContent="msg.content"
                                @llmCardClickFun="llmCardClickFun"
                            ></WelcomeLlmCard>
                        </div>
                        <div class="media-body " v-if="msg.type === 'welcome_knowledge_file'" >
                            <WelcomeKnowledgeFile
                                :msgContent="msg.content"
                                :mainId="mainId"
                                :userId="userId"
                                :corpId="corpId"
                            ></WelcomeKnowledgeFile>
                        </div>
                        <!-- 欢迎语，文本消息组件 -->
                        <div v-if="(msg.type == 'answer_welcofmetext'|| msg.type == 'answer_text')&&!(msg.recommend != undefined&&msg.recommend&&msg.recommend.status)" class="media-body">
                            <association-intention
                                :sourceOhm="sourceOhm"
                                :isOhmPc="isOhmPc"
                                :msg="msg"
                                :msgType="msg.type">
                            </association-intention>
                        </div>
                        <div
                            v-if="
                                (msg.type == 'answer_welcofmetext' ||
                                    msg.type == 'answer_text') &&
                                msg.recommend != undefined &&
                                msg.recommend&&
                                msg.recommend.status
                            "
                            class="media-body-ps">
                            <association-intention
                                :msg="msg"
                                :msgType="msg.type"
                                @onRadioClick="onRadioClickReco">
                            </association-intention>
                        </div>
                        <ActionAlert
                            style="padding: 13px 0px; width: 100vw"
                            class="media-body"
                            :actionAlertIframe="msg.content"
                            v-if="msg.type === 'action_alert_iframe' && msg.content.displayStyle !== 'dialog'">
                        </ActionAlert>
                        <div @click.stop="stopTempla">
                            <TicketMessage
                                @btnClick="templateInfo"
                                :type="rem"
                                :content="msg.content"
                                v-if="msg.type === 'answer_card_template'">
                            </TicketMessage>
                        </div>
                        <TextMessage
                            @submitClick="confirmValue"
                            :text="msg.content"
                            :type="rem"
                            class="media-body"
                            v-if="msg.type === 'action_confirm_value'">
                        </TextMessage>
                        <FormTemplate
                            :class="['media-body',sourceOhm==='ohm'?'isOhmPcForm':'',wechatType =='13' ?'yunzhijiaPcForm':'']"
                            @submitClick="(param) => formCommit(param, msg.id)"
                            @getTemplateList="(param) => getTemplateList(param, msg.id)"
                            :type="rem"
                            :submit="true === msg.content.submitted"
                            :disable="true === msg.content.submitted"
                            :formList="msg.content"
                            :uploadUrl="uploadUrl"
                            v-if="msg.type === 'action_form'"
                            :ref="'formTemplate'+msg.id"
                            :mainId="mainId">
                        </FormTemplate>
                        <FeedBack
                            v-if="msg.type === 'action_feedback'"
                            :feedBack="msg.content"
                            @positiveFeedback="positiveFeedback">
                        </FeedBack>
                        <AiGuide
                            v-if="msg.type === 'answer_intellect_guide'"
                            :aiGuide="msg"
                            :type="rem"
                            @sendAiGuide="sendAiGuide">
                        </AiGuide>
                        <p class="voice" v-if="msg.type === 'answer_voice'">
                            <AnswerVoice
                                @stopOtherAudio="stopOtherAudio"
                                ref="audioTagCell"
                                :msg="msg">
                            </AnswerVoice>
                        </p>
                        <AnswerDocknowledge
                            v-if="msg.type === 'answer_doc_knowledge'"
                            :msg="msg"
                            :type="rem"
                            :isAskLightning="isAskLightning">
                        </AnswerDocknowledge>
                        <div v-else-if="msg.type == 'answer_doc_knowledge'" class="media-body-ps">
                            <AnswerDocknowledge
                                :msg="msg"
                                :type="rem"
                                :isAskLightning="isAskLightning">
                            </AnswerDocknowledge>
                        </div>
                        <div
                            v-if="msg.type === 'office_file' || msg.type === 'other_file'" class="media-body">
                            <file-type
                                :urls="JSON.parse(msg.content).urls"
                                color="black">
                            </file-type>
                        </div>
                        <!-- 富文本组件 -->
                        <!-- 上 下富文本组件不能合并为一个，会有继承样式问题 -->
                        <div
                            v-if="
                                msg.type === 'answer_rich_text' &&
                                !(
                                    msg.content.recommend&&
                                    msg.content.recommend.status
                                )
                            " class="media-body">
                            <association-intention
                                :msg="msg"
                                :msgType="msg.type">
                            </association-intention>
                        </div>
                        <div
                            v-if="
                                msg.type == 'answer_rich_text' &&
                                msg.content.recommend &&
                                msg.content.recommend.status
                            "
                            class="media-body-ps">
                            <association-intention
                                :msg="msg"
                                :msgType="msg.type"
                                @onRadioClick="onRadioClickReco">
                            </association-intention>
                        </div>
                        <div v-if="msg.type === 'answer_rich_text_pro'" class="media-body">
                            <p v-html="msg.content"></p>
                        </div>
                        <!-- 点选组件 -->
                        <div v-else-if="msg.type == 'answer_radio'" class="media-body-ps">
                            <answer-radio
                                :msg="msg"
                                @onRadioClick="onRadioClick">
                            </answer-radio>
                        </div>
                        <div
                            v-else-if="msg.type == 'action_send_message_to_bot' && msg.content.options != null" class="media-body-ps">
                            <action-send-to-bot
                                :msg="msg"
                                @onRadioClick2="onRadioClick2">
                            </action-send-to-bot>
                        </div>
                        <!-- 图片组件 -->
                        <div
                            v-else-if="msg.type == 'user_image' || msg.type == 'IMAGE'"
                            :key="index"
                            class="imgClass"
                            style="height: 150px; max-width: 300px"
                            v-for="(url, index) in msg.content.urls">
                            <!-- LPY 原生宽度适配有bug，弃用 -->
                            <!-- <img
                                :src="url"
                                style="
                                    height: 100%;
                                    width: auto;
                                    border-radius: 25px 0 25px 25px;
                                    max-width: 260px;
                                "
                                @click="onImageClick(url)"
                            /> -->
                            <template v-if="sourceOhm === 'ohm' && !isOhmPc &&  platform !== 'APPLET_WX'">
                                <viewer :images="[url]">
                                    <img
                                    v-for="(src,index) in [url]"
                                    :src="src"
                                    :key="index"
                                    class="viewer-img"
                                    style="height: 150px;width: auto;border-radius: 10px 0 10px 10px;max-width: 260px;"/>
                                </viewer>
                            </template>
                            <img
                                v-else
                                :src="url"
                                style="height: 100%;width: auto;border-radius: 10px 0 10px 10px;max-width: 260px;"
                                @click="onImageClick(url)"/> 
                        </div>
                        <div class="media-body-ps answer_image" v-else-if="msg.type == 'answer_image'">
                            <div :class="msg.content.recommend && !msg.content.recommend.status ? 'association-intention-f' : ''">
                                <association-intention
                                    :sourceOhm="sourceOhm"
                                    :isOhmPc="isOhmPc"
                                    :msg="msg"
                                    :msgType="msg.type"
                                    @onImageClick="onImageClick"
                                    @onRadioClick="onRadioClickReco">
                                </association-intention>
                            </div>
                        </div>
                        <div v-else-if="msg.type == 'TEMPLATE_CARD_BUTTON'" class="media-body" >
                            <p>
                                {{ JSON.parse(msg.content).name }}
                            </p>
                        </div>
                        <div
                            v-else-if=" msg.type == 'answer_video' || msg.type == 'user_video' || msg.type == 'VIDEO'" class="media-body-ps1">
                            <div
                                :class="
                                    (msg.speaker != 'user' &&
                                    msg.content.recommend&&
                                        !msg.content.recommend.status) ||
                                    msg.speaker == 'user'
                                        ? 'association-intention-f'
                                        : ''
                                ">
                                <association-intention
                                    :msg="msg"
                                    :msgType="msg.type"
                                    @onRadioClick="onRadioClickReco">
                                </association-intention>
                            </div>
                        </div>

                        <div v-else-if="msg.type == 'action_question'" class="media-body">
                            <p v-html="msg.content"></p>
                        </div>
                        <div
                            v-else-if="msg.type == 'USER_ACTION_TO_SATISFACTION_V2' || msg.type == 'USER_ACTION_TO_DISSATISFACTION_V2'"
                            class="media-body">
                            <p v-html="msg.content.input"></p>
                        </div>

                        <div v-else-if="msg.type == 'answer_dissatisfaction'" class="media-body-ps">
                            <answer-dissatisfaction
                                :msg="msg"
                                @sendDissatisfaction="sendDissatisfaction">
                            </answer-dissatisfaction>
                        </div>
                        <div v-else-if="msg.type == 'action_satisfaction'" class="media-body-ps">
                            <action-satisfaction
                                :msg="msg"
                                @sendSatisfaction="sendSatisfaction">
                            </action-satisfaction>
                        </div>
                        <img
                            class="thr-b"
                            v-if="
                                msg.speaker == 'user' &&
                                msg.type !== 'answer_loading' &&
                                msg.type != 'user_image' &&
                                msg.type != 'IMAGE' &&
                                msg.type != 'answer_video' &&
                                msg.type != 'user_video' &&
                                msg.type != 'VIDEO'
                            "
                            src="https://static.guoranbot.com/cdn-im-client/common/img/bthr.png"
                            alt=""
                            srcset=""/>
                        <img
                            v-if="msg.type == 'answer_loading' && msg.translated == false"
                            class="thr-b"
                            src="https://static.guoranbot.com/cdn-im-client/common/img/bthr.png"
                            alt=""
                            srcset=""/>
                        <div v-if="false && msg.speaker == 'user'" class="user-ava-r">
                            <span class="avat iconfont guoran-askbotdandutubiao2"></span>
                        </div>
                        <template
                            v-else-if="msg.type == 'user_action_to_satisfaction' || msg.type == 'user_action_to_unsatisfactory'">
                            <div class="media-body" v-if="msg.speaker == 'user'">
                                <p v-html="msg.content.input"></p>
                            </div>
                        </template>
                    </div>
                </div>
                <div
                    v-if="
                        (msg.type == 'user_action_to_satisfaction' ||
                            msg.type == 'user_action_to_unsatisfactory') &&
                        msg.speaker != 'user' &&
                        msg.content != null
                    "
                    class="message"
                    v-bind:class="msg.speaker == 'user' ? 'message-kf' : 'message'">
                    <bot-action-satisfactor
                        :msg="msg"
                        @onSatisfactionClick="onSatisfactionClick">
                    </bot-action-satisfactor>
                </div>
            </div>
            <div id="msg_none">
                <div v-if="(inLoading && this._isAskLightning) || (inLoading && !this._isAskLightning && (sessionStatus && sessionStatus.receiverMessagePlatform == 'GUORAN_BOT'))" class="message">
                    <div class="user-detail">
                        <template v-if="sourceOhm === 'ohm' && isOhmPc">
                            <div v-if="!agentLogoUrl || agentLogoUrl === '' || agentLogoUrl == 'null'" class="user-ava">
                                <span class="avat iconfont guoran-askbotdandutubiao2"></span>
                            </div>
                            
                            <div v-if="!(!agentLogoUrl || agentLogoUrl === '' || agentLogoUrl == 'null')" class="user-ava  user-ava-self-ohm">
                                <img height="30px" width="30px" :src="agentLogoUrl" alt="" srcset="">
                            </div>
                        </template>
                        <template v-else>
                            <div v-if="botPhotoUrl == 'null' || botPhotoUrl == null" class="user-ava">
                                <span class="avat iconfont guoran-askbotdandutubiao2"></span>
                            </div>
                            
                            <div v-if="!(botPhotoUrl == 'null' || botPhotoUrl == null)" class="user-ava user-ava-self">
                                <img height="30px" width="30px" :src="botPhotoUrl" alt="" srcset="">
                            </div>
                        </template>
                        <img
                            class="thr-w"
                            src="https://static.guoranbot.com/cdn-im-client/common/img/lthr.png"
                            alt=""
                            srcset=""/>
                        <div class="media-body-ps">
                            <div v-show="!_isAskLightning" class="media-body-ps-inner-loading">
                                <msg-loading></msg-loading>
                            </div>
                            <div v-show="_isAskLightning" class="media-body-ps-inner-loading">
                                <div style="border-radius: 50%;overflow: hidden;height: 20px;width: 20px;">
                                    <img height="20px" width="20px" src="https://static.guoranbot.com/cdn-lightning-web/dev/img/loading.3ab3bd5a.gif" alt="" srcset="">
                                </div>
                                <div style="color:black" class="in-loading-timeout">{{inLoadingTimeoutStr}}</div>
                            </div>
                        </div>
                        <br/>
                        <div class="in-loading-timeout" v-show="inLoadingTimeout && !_isAskLightning">{{inLoadingTimeoutStr}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import $ from "jquery";
// import { Toast } from "vant";

import "video.js/dist/video-js.css";
import MessageStatus from "./MessageStatus.vue";
// import { videoPlayer } from "vue-video-player";
import TicketMessage from "askbot-dragon/src/components/message/TicketMessage";
import TextMessage from "askbot-dragon/src/components/message/TextMessage";
import FormTemplate from "askbot-dragon/src/components/formTemplate";
import FeedBack from "askbot-dragon/src/components/feedBack";
import AiGuide from "askbot-dragon/src/components/AiGuide";
import AnswerVoice from "askbot-dragon/src/components/AnswerVoice";
import ActionAlert from "askbot-dragon/src/components/ActionAlertIframe";
import AnswerRadio from "askbot-dragon/src/components/answerRadio";


import FileType from "askbot-dragon/src/components/FileType";
// import Recommend from "askbot-dragon/src/components/recommend";
import botActionSatisfactor from "askbot-dragon/src/components/botActionSatisfactor";
import ActionSatisfaction from "askbot-dragon/src/components/actionSatisfaction";
import ActionSendToBot from "askbot-dragon/src/components/actionSendToBot";
import AssociationIntention from "askbot-dragon/src/components/associationIntention";
import MsgLoading from "askbot-dragon/src/components/msgLoading";
import AnswerDissatisfaction from "askbot-dragon/src/components/answerDissatisfaction";
// import AssetDetails from "askbot-dragon/src/components/assetDetails.vue";
// import senderMessagePlatform from 'askbot-dragon/src/components/senderMessagePlatform'
import AnswerDocknowledge from "askbot-dragon/src/components/AnswerDocknowledge";
// import AssetMessage from 'askbot-dragon/src/components/AssetMessage'
// import * as dd from "dingtalk-jsapi";
import { GetQueryString } from "@/assets/js/filterTree";
import debounce from "../js/debounce";
window.addEventListener(
    "message",
    function (messageEvent) {
        var data = messageEvent.data;
        let iframeObj = document.getElementById(data.iframeId);
        if (iframeObj) {
            if (data.width) {
                /* iframeObj.width=data.width+'px'; */
            }
            if (data.height) {
                iframeObj.height = data.height + "px";
            }
        }
    },
    false
);

export default {
    components: {
        TicketMessage,
        TextMessage,
        FormTemplate,
        FeedBack,
        ActionAlert,
        FileType,
        MessageStatus,
        // Recommend,
        botActionSatisfactor,
        ActionSatisfaction,
        ActionSendToBot,
        AnswerDissatisfaction,
        AnswerRadio,
        AssociationIntention,
        MsgLoading,
        // AssetDetails,
        AiGuide,
        // senderMessagePlatform,
        AnswerDocknowledge,
        AnswerVoice
        // AssetMessage
    },
    name: "message_content",
    props: [
        "messageList",
        "userId",
        "botApiKey",
        "inLoading",
        "inLoadingTimeout",
        "inLoadingTimeoutStr",
        "media_inLoading",
        // "isShowSendFileContent",
        "isOhmPc",
        "sourceOhm",
        "_isAskLightning",
        "sessionStatus",
        "isShowHistoryText"
    ],
    data() {
        return {
            items: [],
            count: 0,
            isLoading: false,
            firstLastMsgDomId: "msg_none",
            timeFlag: 0,
            rem: "rem",
            id2Messages: {},
            uploadUrl: "/open/media/file/upload",
            vanPullHeight: "100vh",
            scrollHeight: 0,
            isTrue: false,
            scrollToHei: 0,
            index: 0,
            list: [],
            defaultOffset: 50,
            top: 0,
            scrollIsToTop: 0,
            startY: 0,
            isDropDown: false,
            isRefreshing: false,
            dropDownState: '',
            isShowFastSelect: 0,
            isShowSendFileContent: 0,
            isTransForm: false,
            isPc: false,
            fisrtHasHistory: false,
            botPhotoUrl: null,
            agentLogoUrl:null,
            showAlert:"",
            wechatType:"",
            setMarginTop:0,
            platform:"",
            mainId:"",
            isAskLightning:1,
            noMoreHistory:false,


           

        };
    },
    mounted() {
        this.mainId = sessionStorage.getItem('_mainId')
        this.platform = GetQueryString("platform");
        this.showAlert = sessionStorage.getItem('_tryoutType');
        this.wechatType = sessionStorage.getItem("_wechatType")
        this.items = this.getData(1);
        this.isAskLightning = GetQueryString('isAskLightning')
        window.imageOnClick = this.imageOnClick;
        window.__rich_text_pro_button_onclick = this.askCkeditorBtnClick;

        this.botPhotoUrl = localStorage.getItem("botPhotoUrl");

        /*pc端使用触顶方式加载聊天记录*/
        const container = document.querySelector("#list");
        let isPc = !this._isMobile();
        this.isPc = isPc;
        if (isPc) {
            //这里的定时是为了列表首次渲染后获取scrollHeight并滑动到底部。
            setTimeout(() => {
                this.scrollHeight = container.scrollHeight;
                container.scrollTo(0, this.scrollHeight);
            }, 10);
        //   这里的2秒钟定时是为了避免滑动频繁，防抖
            container.addEventListener("scroll",  (e) => {
              debounce(() => {
                  console.log('这里的2秒钟定时是为了避免滑动频繁，防抖');
                //滑到顶部时触发下次数据加载
                if (e.target.scrollTop == 0 && !this.noMoreHistory && this.isShowHistoryText) {
                    this.dropDownState = 'loading';
                    //将scrollTop置为10以便下次滑到顶部
                    e.target.scrollTop = 10;
                    this.scrollHeight = container.scrollHeight;
                    this.handlerScroll();
                  //加载数据
                //   this.initData(() => {
                    //这里的定时是为了在列表渲染之后才使用scrollTo。
                    // this.$nextTick(() => {
                    //   const container =
                    //       document.querySelector("#list");
                    //   container.scrollTop =
                    //       container.scrollHeight -
                    //       this.scrollHeight;
                    // });
                //     this.dropDownState = '';
                //     let container = document.querySelector("#list");
                //     this.scrollHeight = container.scrollHeight;
                //     container.style.overflowY = "hidden";
                //     container.style.overflowY = "auto";
                //     setTimeout(() => {
                //         container.scrollIntoView({
                //             block: 'center',
                //             behavior: 'smooth'
                //         });
                //     },500)
                //   });
                }
              },2000)
            });
        }
        if(this.isOhmPc && this.sourceOhm === 'ohm'){
            this.agentLogoUrl = sessionStorage.getItem("agentLogoUrl") || ''
        }
        this.$nextTick(() => {
          this.setMarginTop = document.getElementById('invitation-banner-alert') ? document.getElementById('invitation-banner-alert').offsetHeight : 0
        })
    },
    filters: {
        imageStyle: function (html) {
            //富文本内图片自适应高度宽度
            const regex = new RegExp("<img", "gi");
            return html.replace(
                regex,
                "<img style='max-width: 100%;' onclick='imageOnClick(this)'"
            );
        },
    },
    methods: {
        // 暂停当前播放外的其他音频组件
        stopOtherAudio(refId){
            console.log(refId,this.$refs.audioTagCell,'audioTagCell');
            this.$nextTick(() => {
                this.$refs.audioTagCell && this.$refs.audioTagCell.forEach(item => {
                    console.log(item.activeAudio.paused);
                    item.stopAudioPlay(refId);
                })
            })
        },
        unBot(payload) {
            if (payload) {
                if ((payload.newSession.receiverMessagePlatform == 'CUSTOMER_SERVICE_SYSTEM' ||
                    payload.newSession.receiverMessagePlatform == 'AIMER_SERVER') &&
                    payload.oldSession.receiverMessagePlatform == 'GUORAN_BOT') {
                    return true
                } else if ((payload.oldSession.receiverMessagePlatform == 'CUSTOMER_SERVICE_SYSTEM' ||
                    payload.oldSession.receiverMessagePlatform == 'AIMER_SERVER') &&
                    payload.newSession.receiverMessagePlatform == 'GUORAN_BOT') {
                    return false
                } else if ((payload.oldSession.receiverMessagePlatform == 'CUSTOMER_SERVICE_SYSTEM' ||
                    payload.oldSession.receiverMessagePlatform == 'AIMER_SERVER') &&
                    (payload.newSession.receiverMessagePlatform == 'CUSTOMER_SERVICE_SYSTEM' ||
                        payload.newSession.receiverMessagePlatform == 'AIMER_SERVER')) {
                    return true
                }
            }
        },
        sendAiGuide(recommendType, cell) {
            let text = "";
            if (recommendType === 0) {
                text = cell.questionName;
            } else {
                text = cell.exampleQuestion;
            }
            
            // let id = 'recdNodeId_0_' + cell.intentId + '_' + text;
            let content = JSON.stringify({
                recommendType: recommendType,
                name: text,
                id: cell.intentId
            })
            this.$emit("onRadioClick", content, text, '', "INTELLIGENT");
        },
        clickLoadHistory(){
            this.handlerScroll();
        },
        handlerScroll(){
            this.dropDownState = 'loading';
            this.initData(() => {
                this.$nextTick(() => {
                    this.dropDownState = '';
                    document.querySelector('.drop').style.paddingTop = '0';
                    // let container = document.querySelector("#list");
                    // this.scrollHeight = container.scrollHeight;
                    // container.style.overflowY = "hidden";
                    // container.style.overflowY = "auto";
                    // setTimeout(() => {
                    //     container.scrollIntoView({
                    //         block: 'center',
                    //         behavior: 'smooth'
                    //     });
                    // },500)
                });
            });
        },
        initData(callback) {
            this.$emit("getHistory", callback,true);
        },
        // 开始
        touchStart(e) {
            console.log(e,'开始');
            if (this._isMobile()) {
                this.startY = e.targetTouches[0].pageY;
            }
        },
        // 滑动
        touchMove(e) {
            console.log('滑动');
            if (this._isMobile()) {
                this.scrollIsToTop =
                    document.documentElement.scrollTop ||
                    window.pageYOffset ||
                    document.body.scrollTop;
                var container = this.$el.querySelector("#list");
                if (
                    e.targetTouches[0].pageY > this.startY &&
                    container.scrollTop === 0
                ) {
                    this.isDropDown = true;
                    this.isTransForm = true;
                    if (this.scrollIsToTop === 0 && !this.isRefreshing) {
                        let diff =
                            e.targetTouches[0].pageY -
                            this.startY -
                            this.scrollIsToTop;
                        this.top =
                            Math.pow(diff, 0.8) +
                            (this.dropDownState === 3 ? this.defaultOffset : 0);
                        if (this.top >= this.defaultOffset) {
                            this.dropDownState = 2;
                            if (e.cancelable) {
                                e.preventDefault();
                            }
                        } else {
                            this.dropDownState = 1;
                            if (e.cancelable) {
                                e.preventDefault();
                            }
                        }
                    }
                } else {
                    this.isDropDown = false;
                    this.dropDownState = 1;
                }
            }
        },

        // 触摸结束
        touchEnd() {
            console.log('触摸结束');
            if (this._isMobile()) {
                if (this.isDropDown && !this.isRefreshing) {
                    if (this.top >= this.defaultOffset) {
                        this.clickLoadHistory();
                        this.isRefreshing = true;
                    } else {
                        this.isRefreshing = false;
                        this.isDropDown = false;
                        this.dropDownState = 1;
                        this.top = 0;
                    }
                }
                setTimeout(() => {
                    this.isTransForm = false;
                }, 10);
            }
        },
        vanPullHeightCompu(val) {
            console.log(this.isShowFastSelect,val,9999);
          /*是否是知识管理中引用，内容高度设置不一样*/
          let isAskLightning = GetQueryString('isAskLightning') == 1
                if(this.isOhmPc){
                  if (isAskLightning){
                    this.vanPullHeight =
                        "calc(100vh - " +
                        (Number(this.isShowFastSelect) * 44 + 60) +
                        "px)";
                  } else {
                    this.vanPullHeight =
                        "calc(100vh - " +
                        (Number(this.isShowFastSelect) * 44 + 100+50) +
                        "px)";
                  }
                } else {
                    this.vanPullHeight =
                    "calc(100vh - " +
                    (Number(this.isShowFastSelect) * 44 + Number(val) * 136 + 78) +
                    "px)";
                }
            this.isShowSendFileContent = val;
        },
        isShowFastSelectCompu(val) {
            console.log(this.isShowFastSelect,val,9999112);
            let isAskLightning = GetQueryString('isAskLightning') == 1
            if (isAskLightning == 1) {
                this.vanPullHeight =
                    "calc(100vh - " +
                    (Number(val) * 44 + 60) +
                    "px)";
            } else {
                if(this.isOhmPc){
                       this.vanPullHeight =
                        "calc(100vh - " +
                        (Number(val) * 44+100+50) + "px)";
                } else {
                    this.vanPullHeight =
                    "calc(100vh - " +
                    (Number(val) * 44 +
                        Number(this.isShowSendFileContent) * 136 +
                        75) +
                    "px)";
                }
            }
            
            this.isShowFastSelect = val;
        },
        getData(count) {
            let arr = [];
            for (let index = 0; index < count; index++) {
                arr.push({
                    id: "COUNT_" + this.count + "_" + index,
                    label: "COUNT_" + this.count + "_" + index,
                    size: index,
                    avatar: "https://img2.baidu.com/it/u=2464541379,3481426116&fm=253&fmt=auto&app=138&f=PNG?w=429&h=500",
                });
            }
            return arr;
        },
        //监听iframe中的点击事件
        getIframe() {
            this.$emit("getIframe");
        },
        _isMobile() {
            let flag = navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            );
            return flag;
        },
        onRadioClick(value, showValue, answerApiKey, redioType, msgId) {
            console.log(916, msgId);
            this.$emit(
                "onRadioClick",
                value,
                showValue,
                answerApiKey,
                redioType,
                msgId
            );
        },
        onRadioClick2(value, showValue, answerApiKey) {
            this.$emit("onRadioClick2", value, showValue, answerApiKey);
        },
        onRadioClickReco(id, name, apikey) {
            this.$emit("onRadioClick", id, name, apikey, "recommend");
        },
        scrollToOldFirst() {
            this.$nextTick(() => {
                document
                    .querySelector("#" + this.firstLastMsgDomId)
                    .scrollIntoView({
                        behavior: "auto", // 定义动画过渡效果， "auto"或 "smooth" 之一。默认为 "auto"
                        block: "center", // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
                        inline: "nearest", // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
                    });

                let el = document.getElementsByClassName("msg_0");
                if (el&&el[0]){
                  this.firstLastMsgDomId = el[0].getAttribute("id");
                }
            });
        },

        myOncanplay(id) {
            let video = document.getElementById(id);
            video.play();
            setTimeout(() => {
                video.pause();
            }, 1000);
        },
        endedPlay() {
            // alert("关闭播放")
        },
        statechanged() {
            if (sessionStorage.getItem("_wechatType") == 1) {
                this.$emit("vshowInput");
            }
        },
        loadMore() {},
        // 将匹配结果替换表情图片
        stopTempla(e) {
            e.stopPropagation();
        },
        templateInfo(data) {
            this.$emit("onTemplateInfo", data);
        },
        confirmValue(data) {
            this.$emit("onConfirmValue", data);
            // this.sendTestContent('VALUE_CONFIRM', JSON.stringify(data),null, false)
        },
        positiveFeedback(data) {
            let res = {};
            res.feedbackUid = data.feedbackUid;
            res.feedbackActions = data.feedbackActions;
            res.feedback =
                data.feedback.positiveFeedback != null
                    ? data.feedback.positiveFeedback
                    : data.feedback.negativeFeedback;
            res.apiKey = data.apiKey;
            this.$emit("onFeedback", res);
        },
        formCommit(data, messageId) {
            data.messageId = messageId;
            let agentInfo = {
                corpId: sessionStorage.getItem("_corpid"),
                agentId: sessionStorage.getItem("_agentId"),
                userId: sessionStorage.getItem("_userId"),
                source: sessionStorage.getItem("_source"),
                originUserId: sessionStorage.getItem("_currentUserId"),
                bindCode: sessionStorage.getItem("_bindCode"),
                assetsId:sessionStorage.getItem('assetsCode')
            };
          let sourceType = GetQueryString("type");
          if ((sourceType == '1' || sourceType == '3' || sourceType == '10' || sourceType == '0') && !sessionStorage.getItem("_corpid")){
            console.debug('请截图反馈给果然智汇'+'\n' + window.location.href +'\n' +'userAgent' +'\n' + window.navigator.userAgent)
          }
            data.agentInfo = agentInfo;
            this.$emit("onFormCommit", data);
            // this.sendTestContent('FORM_SUBMIT', JSON.stringify(data),null, false)
        },
        //获取ref下拉值
        getTemplateList(param,id) {
          let url = '/api/data-template/data/page';
          console.debug('ref param',param)
          param.dataQuery[param.field.fieldId].templateId = param.templateId
          this.$http.post(url, param.dataQuery[param.field.fieldId]).then(res => {
            console.debug('ref res',res)
            if (res.data.code === '0') {
              let list = res.data.data.data
              let templateList = []
              templateList = list.map(res => {
                let info = {}
                for (let key in res.fieldValues) {
                  if (Array.isArray(param.fieldId)) {
                    let vals = ''
                    let values = ''
                    param.fieldId.forEach(val => {
                      vals += val
                      if (res.fieldValues[val]) {
                        values += res.fieldValues[val]
                      }
                    })
                    info[vals] = values
                    info.id = res.id
                    info.name = values
                  } else {
                    if (param.fieldId === key) {
                      info[key] = res.fieldValues[key]
                      info.id = res.id
                      info.name = res.fieldValues[key]
                    }
                  }
                }
                return info
              })
              this.$nextTick(() => {
                let ref = 'formTemplate'+id;
                console.debug('refTemplateList',this.$refs[ref],this.$refs[ref][0].refTemplateList)
                if (this.$refs[ref]){
                  this.$set(this.$refs[ref][0].refTemplateList,param.field.fieldId,[
                    ...this.$refs[ref][0].refTemplateList[param.field.fieldId],
                    ...templateList
                  ])
                }
                if (!param.refNextPage[param.field.fieldId]){
                  this.$set(param.refNextPage[param.field.fieldId],'hasNextPage',false)
                }
                if (param.refNextPage[param.field.fieldId]){
                  param.refNextPage[param.field.fieldId].hasNextPage = res.data.data.hasNextPage
                }
              })
            }
          })
        },
        askCkeditorBtnClick(_this) {
            this.$emit(
                "onRadioClickRichBtn",
                JSON.stringify({
                    gotoContent: {
                        scope:
                            _this.getAttribute("__event") === "TO_NODE"
                                ? "NODE"
                                : "INTENT",
                        value: _this.getAttribute("__value"),
                    },
                    assignmentsJson: _this.getAttribute("__assignments"),
                })
            );
        },
        imageOnClick(e) {
            let src = e.getAttribute("src");
            this.onImageClick(src, "uditor");
        },

        onDissatisfactionClick() {},
        // 发送满意度点选消息
        sendSatisfaction(type, choose, answer, answerRadio, answerApiKey) {
            let value = JSON.stringify({
                input: choose,
                reply_text: answer,
                reply_options: answerRadio,
            });

            this.$emit(
                "sendMessage",
                "USER_ACTION_TO_SATISFACTION_V2",
                value,
                null,
                answerApiKey
            );
        },
        sendDissatisfaction(option, answerApiKey) {
            let value = JSON.stringify({
                input: option.name,
                reply_text: option.answer,
            });
            this.$emit(
                "sendMessage",
                "USER_ACTION_TO_DISSATISFACTION_V2",
                value,
                null,
                answerApiKey
            );
        },
        /**
         * 满意度调查点击
         * @param choose 选择的值
         * @param answer 选择后对应回复
         * @param answerRadio 选择不满意时
         */
        onSatisfactionClick(type, choose, answer, answerRadio, answerApiKey) {
            this.$emit(
                "onSatisfactionClick",
                type,
                choose,
                answer,
                answerRadio,
                answerApiKey
            );
        },
        videoOptions(videoContent) {
            // let url =
            //     videoContent.url != null && videoContent.url !== ""
            //         ? videoContent.url
            //         : videoContent.localUrl;
            return {
                muted: true,
                language: "en",
                playbackRates: [0.7, 1.0, 1.5, 2.0],
                preload: "auto",
                "x5-video-player-type": "h5",
                "x5-video-player-fullscreen": true,
                "x5-video-orientation": "portrait",
                sources: [
                    {
                        type: "video/mp4",
                        src:
                            videoContent.url != null && videoContent.url !== ""
                                ? videoContent.url
                                : videoContent.localUrl,
                    },
                ],
                width: "260",
                height: "160",
            };
        },
        onImageClick(imgUrl) {
            // window.open("/static/img.html?imgUrl="+encodeURIComponent(url));
            /*this.$router.push({ path: "/img", query: { url: url } });*/
            var wechatType = sessionStorage.getItem("_wechatType");
            let url = Array.isArray(imgUrl) && imgUrl[0] ? imgUrl[0] : imgUrl;
            let urls = [];
            if (this.getCaption(url, 0)) {
                urls = [this.getCaption(url, 0)];
            } else {
                urls = [url];
            }
            var isMac = /macintosh|mac os x/i.test(navigator.userAgent);

            let pcMacUrls = JSON.parse(JSON.stringify(urls));
            let macUrl = "";
            let dealUrl = "";
            if (isMac && !this._isMobile() && !url.includes('source=ChatGPT')) {
                for (let i = 0; i < pcMacUrls.length; i++) {
                    let index = pcMacUrls[i].lastIndexOf("/");
                    let lastStr = encodeURIComponent(
                        pcMacUrls[i].substring(index + 1, pcMacUrls[i].length)
                    );
                    let preStr = pcMacUrls[i].substring(
                        0,
                        pcMacUrls[i].lastIndexOf("/")
                    );
                    pcMacUrls[i] = preStr + "/" + lastStr;
                }
                dealUrl = this.getCaption(url, 0)
                    ? this.getCaption(url, 0)
                    : url;
                let urlIndex = dealUrl.lastIndexOf("/");
                let urlLastStr = encodeURIComponent(
                    dealUrl.substring(urlIndex + 1, dealUrl.length)
                );
                let urlPreStr = dealUrl.substring(0, dealUrl.lastIndexOf("/"));
                macUrl = urlPreStr + "/" + urlLastStr;
            } else {
                pcMacUrls = urls;
                macUrl = this.getCaption(url, 0)
                    ? this.getCaption(url, 0)
                    : url;
            }
            console.debug(1310, pcMacUrls, macUrl);
            let ua = navigator.userAgent.toLowerCase();
            let isAndroid = false;
            if (ua.indexOf('android') != -1){
              isAndroid = true
            }
            for (let i = 0; i < pcMacUrls.length; i++) {
              if (!isAndroid){
                pcMacUrls[i] = pcMacUrls[i].replaceAll(" ", "%20");
              }
            }
            if (!isAndroid){
              macUrl = macUrl.replaceAll(" ", "%20");
            }
            console.debug(1312, wechatType);
            console.log(this.isOhmPc,this.sourceOhm,'sourceOhm',url);
            if(this.sourceOhm === 'ohm'){
                let platform = GetQueryString("platform");
                console.log(platform === 'APPLET' || platform === 'APPLET_WX' && this._isMobile(),111);
                if(platform === 'APPLET' || platform === 'APPLET_WX'){
                    // wx.previewImage({
                    //     current: url,
                    //     urls: urls,
                    //     success: function () {
                    //         this.stopOtherAudio("");
                    //     },
                    //     fail: function (e) {},
                    // });
                } else {
                    this.$emit('imagePreviewFn',url)
                }
                // window.parent.postMessage({emitName:'imagePreview',url:url},'*');
            } else {
                if (wechatType == "3") {
                    // dd.biz.util.previewImage({
                    //     urls: pcMacUrls, //图片地址列表
                    //     current: macUrl, //当前显示的图片链接
                    //     onSuccess: function (result) {
                    //         /**/
                    //         console.debug(1318, result);
                    //         this.stopOtherAudio("");
                    //     },
                    //     onFail: function (err) {
                    //         console.log(1321, err);
                    //     },
                    // });
                } else if (wechatType == "10") {
                    if (this._isMobile()) {
                        // tt.previewImage({
                        //     urls: [url],
                        //     current: url,
                        //     success(res) {
                        //     console.log(1269, JSON.stringify(res));
                        //         this.stopOtherAudio("");
                        //     },
                        //     fail(res) {
                        //     console.log(`previewImage fail: ${JSON.stringify(res)}`);
                        //     }
                        // });
                    } else {
                        // this.$emit('imagePreviewFn',url)
                        this.$imagePreview({
                            images: [url],
                            index: 0,
                        });
                        // this.stopOtherAudio("");
                    }
                    
                } else if (wechatType == "13"){
                  /*云之家*/
                  console.debug('pcMacUrls',url,urls)
                //   let newUrl = encodeURI(url);
                //   qing.call('previewImage', {
                //     'current': newUrl,
                //     'urls': [newUrl],
                //     'success': function (result) {
                //       console.debug(1401,JSON.stringify(result))
                //       this.stopOtherAudio("");
                //     }
                //   });
                } else {
                    if (this._isMobile()) {
                        // wx.previewImage({
                        //     current: url,
                        //     urls: urls,
                        //     success: function () {
                        //         this.stopOtherAudio("");
                        //     },
                        //     fail: function (e) {},
                        // });
                    } else {
                        this.$imagePreview({
                            images: [url],
                            index: 0,
                        });
                        // this.stopOtherAudio("");
                    }
                }
            }
        },
        getCaption(obj, state) {
          console.log('getImageUrl',obj)
            if(obj.includes('source=ChatGPT')) {
                return obj
            }
            var index = obj.lastIndexOf("?");
            if (state == 0) {
                obj = obj.substring(0, index);
            } else {
                obj = obj.substring(index + 1, obj.length);
            }
            return obj;
        },

        showTime(msg, index, messageList) {
            if (index == 0) {
                return true;
            }
            return (
                msg.sessionId != null &&
                index != 0 &&
                messageList[index - 1] != null &&
                msg.sessionId != messageList[index - 1].sessionId
            );
        },
        //邀请评价
        evaluation(content){
            // let url = content.template;
            this.$emit('evaluation',content)
        },
        llmCardClickFun(item) {
            let count = this.getRandomInt(-3 , item.value.length - 1)
            count = count < 0 ? 0 : count
            count = count >= item.value.length - 1 ? item.value.length - 1 : count
            this.$emit("sendMessage", item.value[count]);
        },
        getRandomInt(min, max) {  
            return Math.round(Math.random() * (max - min)) + min;  
        }
    },
    computed: {
        filterEmptyMsg() {
            return this.messageList.filter(
                (item) => JSON.stringify(item) != "{}"
            );
        },
    },
};
</script>
<style lang="less" scoped>
.answer-docknowledge{
    max-width: 290px;
    .alc-box-introduction{
        width: 290px;
        text-overflow: ellipsis;
        display: -webkit-box; //使用了flex，需要加
        overflow: hidden;     //超出隐藏
        word-break: break-all; //纯英文、数字、中文
        text-overflow: ellipsis;  //省略号
        -webkit-box-orient: vertical; //垂直
        -webkit-line-clamp: 3;  //显示两行
        white-space:pre-line;   //合并空白符序列，但是保留换行符。(如果要使用这个垂直和显示几行的代码也要写)
       
    }
}
</style>
<style lang="less" scoped>
@import "./im.less";

.in-loading-timeout{
    margin-left: 8px;
    height: 58px;
    line-height: 58px;
    color: #888888;
}
#answer-radio {
    background-color: white;
    padding: 15px;
    .other {
        padding-left: 0 !important;
    }
}

.ticketMessage {
    width: calc(100vw - 126px);
}
.loading-yuyin {
    padding: 10px !important;
    .media-body-loadings {
        background: none !important;
    }
}
.media-body-ps {
    .media-body-ps-text-box {
        padding: 8px;
    }
    .text-recommed-intent {
        .other {
            background-color: white !important;
            color: #909399;
        }
    }
}
.yunzhijiaPcForm{
  /deep/.pcFormClass{
    width: 280px !important;
  }
}
.uu_drop_show {
    animation: show-detail 3s ease-in forwards;
}
.uu_drop_hide {
    animation: hide-detail 3s ease-in forwards;
}

.drop {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 50px;
    margin: 0 auto;
    .media-body-loadings {
        background-color: rgba(0, 0, 0, 0);
        /deep/circle {
            fill: #366aff !important;
        }
    }
}

@keyframes show-detail {
    0% {
        opacity: 0;
        height: 50px;
    }
    50% {
        opacity: 0.5;
        height: 25px;
    }
    100% {
        opacity: 1;
        height: 50px;
    }
}
@keyframes hide-detail {
    0% {
        opacity: 1;
        height: 50px;
    }
    50% {
        opacity: 0.5;
        height: 25px;
    }
    100% {
        opacity: 0;
        height: 0px;
    }
    
}

.media-body-ps1 {
    overflow: hidden;
    // background-color: #ffffff;
    border: 1px solid #f4f8fc;
    word-break: break-word;
    flex: 1;
    border-top-left-radius: 0px;
    text-align: left;
    // padding: 15px;
}
.media-body-ps-1 {
    .other {
        /*font-size: 28px;*/
        word-wrap: break-word;
        word-break: break-all;
        overflow: hidden;
        padding: 8px 10px 8px 15px;
        height: auto;
    }
    .ps {
        padding: 10px 10px 10px 15px;
        height: auto;
        border-top: 1px solid #f4f8fc;
        background-color: #ffffff;
        color: #4c61e1;
        cursor: pointer;
        /*font-size: 28px;*/
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        i {
            margin-left: 20px;
            margin-top: 2px;
        }
    }
    .end {
        border: 1px solid #f4f8fc;
        border-bottom-left-radius: 32px !important;
        border-bottom-right-radius: 32px !important;
    }
}

.loading {
    height: 4px;
    margin: 7px auto;
    line-height: 1px;
    text-align: center;
}
.loading span {
    /* margin-left: 9px;
	margin-top: 2px;*/
    display: inline-block;
    width: 4px;
    border-radius: 3px;
    margin-bottom: 0 !important;
    height: 100%;
    margin-right: 3px;
    background: white;
    -webkit-animation: load 1.04s ease infinite;
}
.loading span:last-child {
    margin-right: 0px;
}
@-webkit-keyframes load {
    0% {
        opacity: 1;
        -webkit-transform: scale(1);
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(0);
    }
}

.loading span:nth-child(4) {
    -webkit-animation-delay: 0.13s;
}
.loading span:nth-child(3) {
    -webkit-animation-delay: 0.39s;
}
.loading span:nth-child(2) {
    -webkit-animation-delay: 0.52s;
}
.loading span:nth-child(1) {
    -webkit-animation-delay: 0.65s;
}
/deep/ figure {
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
.container {
    height: calc(100% - 30px);
    overflow-x: hidden;
    overflow-y: auto;
    overflow-anchor: auto !important;
    scroll-behavior: smooth;
    padding: 0px 6px 0 10px;
    color: #000;
    /deep/ #answer-radio{
        .options-list{
            .options-item{
                height: auto;
                line-height: normal;
            }
        }
    }
    /deep/ p{
        font-size: 16px;
        // color: #000;
    }
    .in-loading-timeout{
        font-size: 16px;
    }
    /deep/.answer-docknowledge{
         border-radius: 0 22px 22px 22px;
        background-color: white;
        padding: 15px;
        .answer-text{
            text-align: left !important;
            font-size: 16px;
        }
    }
    .notice-content {
        display: inline-block;
        height: 22px;
        padding: 5px 10px;
        color: #999;
        font-size: 12px;
        cursor: pointer;
    }
}
/*定义滑块颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb {
    border-radius: 7px;
    -webkit-box-shadow: inset 0 0 6px #f5f7fb !important;
    background-color: #e8e8e8;
}
.histroy {
    height: calc(100% - 10px);
    // overflow: hidden;
    /deep/.formClass {
        .title-link {
            width: calc(100% + 30px);
            margin-left: -15px;
        }
        .bottom-link {
            width: calc(100% + 30px);
            margin-left: -15px;
        }
    }
}
.r-loading-box {
    height: 24px;
    padding: 15px 24px !important;
    .media-body-loadings {
        background-color: rgba(0, 0, 0, 0) !important;
    }
}
.first-load-more {
    cursor: pointer;
}

.action_assets_card {
    padding-right: 5px !important;
    margin-bottom: 20px;
    /deep/.content-text {
        .text-label {
            .text-type {
                margin-bottom: 5px;
            }
        }
        .text-title {
            text-align: left !important;
            margin-bottom: 5px;
        }
    }

}
.message_event {
    margin-bottom: 10px;
    > span {
        font-size: 12px;
        font-weight: 400;
        color: #888888;
        /deep/ div {
            display: inline;
        }
    }
}
.tips {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 150px;
    background: #FFFFFF;
    z-index: 999999;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    p {
        line-height: 90px;
        text-align: center;
        i {
            font-size: 16px;
        }
    }
    div {
        width: 100%;
        height: 60px;
        background: #F8FBFF;
        color: #366AFF;
        line-height: 60px;
    }
}
.media-body {
    /deep/.ck.ck-icon, .ck.ck-icon {
        width: 14px !important;
        height: 14px !important;
        color: #333;
    }
}
.service-evaluation{
  background: #FFFFFF;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.03);
  border-radius: 13px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .service-evaluation-l{
    display: flex;
    align-items: center;
    .service-evaluation-icon{
    /*  width: 30px;
      height: 30px;
      flex: none;
      border-radius: 50%;
      background: linear-gradient(-23deg, #96B1FF, #CCEBFF);*/
      display: flex;
      align-items: center;
      justify-content: center;
      i{
        color: #96B1FF;
        font-size: 30px;
      }
    }
    .service-evaluation-tips{
      color: #000000;
      font-weight: 800;
      margin-left: 9px;
      margin-right: 9px;
      font-size: 14px;
    }
  }
  .service-evaluation-r{
    width: 61px;
    height: 27px;
    background: #366AFF;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 13px;
    cursor: pointer;
  }
}
.line-up{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 12px;
  .line-up-content{
    margin-right: 6px;
  }
  .unqueue{
    color: #366AFF;
    cursor:pointer;
  }
}
.message_intellect_guide {
    background-color: white;
    border-radius: 18px;
    max-width: 468px !important;
    margin: 0 4px;
    .user-detail{
        width: 100%;
        max-width: 468px !important;
        .user-ava{
            display: none;
        }
        .ai-guide{
            width: 100%;
        }
    }
}
.voice {
    background: #FFF;
    padding: 2px 0 2px 8px;
    border-radius: 0 30px 30px 30px
} 
.welcome_card_body {
    padding-right: 0 !important;
}
/deep/.message_list-welcome-m {
    .mlwm-msg-type {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .mmt-cell {
            min-width: 290px;
            margin-right: 16px;
        }
    }
    
}
</style>
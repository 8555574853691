<template>
  <div class="timeRange">
    <el-popover
        placement="bottom"
        width="100"
        trigger="click"
        ref="timePopover"
    >
      <div class="timeList">
        <div class="timeItem"
             v-for="(item,index) in timeRangeList"
             :key="index"
             :class="selectValue == item.value ? 'activeTime' : ''"
             @click="timeClick(item)"
        >
          <div class="customTime" v-if="item.value === 'dateRange'">
            {{ $t('botLearn.' + item.value) }}
            <el-date-picker
                v-model="value1"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="onPick"
                :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </div>
          <template v-else>
            {{ $t('botLearn.' + item.value) }}
          </template>
        </div>
      </div>
      <div class="filterTime" slot="reference">
        <span class="date_icon">
          <i class="iconfont guoran-riqixuanze"></i>
        </span>
        <span>{{ selectTime }}</span>
        <div class="downIcon">
          <i class="iconfont el-icon-arrow-down"></i>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: "timeRange",
  data(){
    return{
      selectTime:this.$t('botLearn.Last30days'),
      selectValue:"Last30days",
      timeRangeList:[
        { label: "今天", value: "today" },
        { label: "昨天", value: "yesterday" },
        { label: "最近3天", value: "Last3days" },
        { label: "最近7天", value: "Last7days" },
        { label: "最近15天", value: "Last15days" },
        { label: "最近30天", value: "Last30days" },
        { label: "本周", value: "thisWeek" },
        { label: "上周", value: "lastWeek" },
        { label: "本月", value: "thisMonth" },
        { label: "上月", value: "lastMonth" },
        { label: "上线至今", value: "upToNow" },
        { label: "自定义", value: "dateRange" },
      ],
      value1:[]
    }
  },
  methods:{
    timeClick(item){
      this.selectValue = item.value;
      this.value1 = [];
      if (item.value !== 'dateRange'){
        this.$refs.timePopover.doClose();
        this.$emit('selectTime',item.value);
        this.selectTime = item.label;
      }
    },
    onPick(){
      this.$refs.timePopover.doClose();
      this.$emit('selectTime','dateRange',this.value1);
      const [start, end] = this.value1;
      const isSameDay = this.isSameDay(start, end);
      if (isSameDay) {
        this.selectTime = new Date(this.value1[0]).format('yyyy-MM-dd');
      } else {
        this.selectTime = new Date(this.value1[0]).format('yyyy-MM-dd') + '~' + new Date(this.value1[1]).format('yyyy-MM-dd')
      }
    },
    isSameDay(date1,date2){
      return date1 && date2 && date1.getDate() === date2.getDate();
    },
  }
};
</script>

<style scoped lang="less">
.timeRange{
  //padding-left: 20px;
  color: #000000;
}
.filterTime{
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-right: 14px;
  border-right: 1px solid #D8DDEB;
  .date_icon{
    flex: none;
    width: 19px;
    height: 19px;
    background: #366AFF;
    border-radius: 50%;
    color: #ffffff;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    i{
      font-size: 14px;
    }
  }
  .downIcon{
    flex: none;
    width: 19px;
    height: 19px;
    background: #F5F7FB;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #717B90;
    margin-left: 16px;
    i{
      font-size: 14px;
    }
  }
}
.timeList{
  display: flex;
  flex-direction: column;
  .timeItem{
    padding: 8px 12px;
    cursor: pointer;
  }
  .timeItem:hover{
    background-color: #E0E8FF;
  }
  .activeTime{
    background-color: #E0E8FF;
    color: #366aff;
  }
}
/deep/.el-popover{
  padding: 0;
}
.customTime{
  position: relative !important;
}
//修改控件自带的css
.customTime .el-date-editor {
  position: absolute ;//绝对定位
  top: 0 ;
  left: 0 ;
  opacity: 0;//设置完全透明
}
</style>
export function filterTree(tree,ids,arr=[]){
    if (!tree.length) return []
    console.debug('tree',tree,ids)
    for (let item of tree){
        if (ids.indexOf(item.parentId) !==-1) continue
        let node = {...item,children:[]}
        arr.push(node)
        if (item.children && item.children.length){
            filterTree(item.children,ids,node.children)
        }
    }
    return arr
}
export function mergeTree(target, source) {
    if (!target?.length && !source?.length) {
        return;
    }
    const more = [];
    source.forEach(sNode => {

        const tNode = target.find(tn => tn.name === sNode.name);
        if (!tNode) {
            more.push(sNode);
            return;
        }
        const { children: sChildren, ...props } = sNode;
        Object.assign(tNode, props);
        this.mergeTree(tNode.children, sChildren);
    });
    target.push(...more);
    return target;
}
export function RequiredVerification(list){
    let obj = {
        bool:false,
        message:""
    };
    for (let i=0;i<list.length;i++){
        if (!list[i].parentDirectoryName){
            obj.message = "关联位置不能为空";
            obj.bool = true;
            return obj
        }
        if (!list[i].visitPermissionList || (list[i].visitPermissionList && list[i].visitPermissionList.length == 0)){
            obj.message = "可阅读权限不能为空";
            obj.bool = true;
            return obj
        }
    }
    return obj
}
export const isUseOpenData = () => {
    let bool = false;
    bool = sessionStorage.getItem('isWeChat') == 'true' ? true : false;
    return bool
}
export function platform(node,arr = []){
    if (!node.parent){
        return arr
    }
    arr.unshift(node.data.name);
    platform(node.parent,arr);
    return arr;
}
export function crumbs(node,arr = []){
    if (!node.parent){
        return arr
    }
    arr.unshift(node.data);
    crumbs(node.parent,arr);
    return arr;
}
export function GetQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    //截取#前的url
    var dz_url = window.location.href.split("#")[0];

    var query = dz_url.substr(dz_url.lastIndexOf("?") + 1);
    var r = query.match(reg); //search,查询？后面的参数，并匹配正则
    if (r != null) return decodeURIComponent(r[2]);
    return null;
}
<template>
  <div class="lighting-popup-floating-layer">
    <div class="defaultDialog">
      <div id="lighting-popup-assembly">
        <div class="lighting-popup-header">
          <div class="lighting-popup-name">
            <slot class="lighting-popup-name" name="lighting-popup-name"></slot>
          </div>
          <div class="lighting-popup-tip" v-if="haveTips">
            <slot name="lighting-popup-tip"></slot>
          </div>
        </div>
        <div class="lighting-popup-container">
          <slot name="lighting-popup-con"></slot>
        </div>
        <div class="lighting-popup-container-c">
          <slot name="lighting-popup-con-c"></slot>
        </div>
        <div class="lighting-popup-add-automtated-closeBtn" @click="closeEvent" v-if="needCloseIcon">
          <i class="arsenal_icon">&#xed63;</i>
        </div>
        <div class="lighting-popup-dialog-footer">
          <slot name="lighting-popup-dialog-footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LightingPopup",
  props: {
    haveTips: {
      type: Boolean,
      default: true
    },
    needCloseIcon: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {};
  },
  methods: {
    closeEvent() {
      this.$emit("closeEvent");
    }
  }
};
</script>

<style scoped lang="less">
@keyframes anim-open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes anim-close {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.lighting-popup-floating-layer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2200;
  /*display: flex;
  align-items: center;
  justify-content: center;*/
  animation: dialog-fade-in 0.3s ease-in;

  .defaultDialog {
    position: relative;
    margin: 0 auto;
    margin-top: 8vh;
    /*display: flex;
    justify-content: center;*/

    .color-style {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      .color-item-style {
        width: 38px;
        height: 38px;
        border-radius: 5px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .arrow {
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 0;
          border-top: 10px solid;
          /*border-top-left-radius: 5px;*/
          border-right: 10px solid transparent;
        }
      }
    }

    .status-propertied {
      height: 40px;
      background-color: #FBFCFD;
      border-radius: 5px;
      padding-left: 12px;
    }

    .next-status {
      background-color: #FBFCFD;
      border-radius: 5px;
      padding-left: 12px;
      /*padding-bottom: 12px;*/
    }

  }

  #lighting-popup-assembly {
    width: 646px;
    background-color: white;
    border-radius: 5px;
    position: relative;
    margin: 0 auto;

    .lighting-popup-header {
      padding-top: 34px;
      padding-bottom: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .lighting-popup-name > div {
        font-size: 26px !important;

      }

      .lighting-popup-tip {
        font-size: 14px;
        color: #A9B3C6;
        padding-top: 18px;
      }
    }

    .lighting-popup-container {
      max-height: calc(84vh - 200px);
      overflow-y: auto;
      padding: 0 57px;
      /* min-height: 300px;*/

      .lighting-popup-con {
        /*font-size: 14px;*/
        color: #616161;

        /deep/ .order-field-list {
          display: flex;
          flex-wrap: wrap;
        }

        /deep/ .field-item-class:nth-child(1) {
          .field-name {
            padding-left: 0px !important;
          }

          .el-cascader {
            margin-left: 0px !important;
          }

          .el-select {
            margin-left: 0px !important;
          }
        }

        /deep/ .field-item-class {
          .el-cascader {
            line-height: 0;
          }
        }

        /deep/ .field-item-class:nth-child(2) {
          .field-name {
            padding-left: 30px;
            line-height: 20px;
          }

          .el-cascader {
            margin-left: 30px;
          }

          .el-select {
            margin-left: 30px;
          }
        }

        /*/deep/.field-item-class:nth-child(even){
            .field-name{
                padding-left: 30px;
                line-height: 20px;
            }
            .el-cascader{
                margin-left: 30px;
            }
            .el-select{
                margin-left: 30px;
            }
        }*/

        /deep/ .radio-class {
          background: #FBFCFD;
          border-radius: 5px;
          padding-left: 12px;
          min-height: 40px;
          display: flex;
          align-items: center;
        }

        /deep/ .field-name {
          padding-bottom: 8px;
          line-height: 20px;
        }

        /deep/ .field-item-class {
          padding-bottom: 20px;
          width: calc(50% - 30px);
          padding-right: 30px;
        }

        /deep/ .field-item-class-c {
          width: 100%;
          padding-bottom: 20px;
        }
      }

    }

    .lighting-popup-container-c {
      padding: 0 64px;

      .lighting-popup-con-c {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .lighting-popup-con-panel {
          background: #FFFFFF;
          border: 1px solid #E0E6F7;
          border-radius: 5px;
          width: 248px;
          flex: none;
          position: relative;

          .lighting-popup-con-panel-title {
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            /*  border: 1px solid #E0E6F7;*/
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border-bottom: 1px solid #E0E6F7;
          }

          .left-title {
            background-color: #F5F7FB;
          }

          .right-title {
            background-color: #759FFF;
            color: white;
          }

          .lighting-popup-con-panel-con {
            height: calc(84vh - 300px);
            min-height: 340px;
            overflow-y: scroll;
            overflow-x: hidden;
            padding: 20px 0;

            #filterResort {
              padding-bottom: 30px;
            }

            .lighting-popup-con-panel-con-item {
              display: flex;
              height: 38px;
              align-items: center;
              justify-content: space-between;
              padding: 0 20px;
              /* font-size: 14px;*/
              color: #616161;

              .iconfont {
                display: none;
              }

              .handle {
                cursor: move;
              }
            }

            .lighting-popup-con-panel-con-item:hover {
              background-color: #F6F8FD;

              .iconfont {
                display: block;
                color: #366AFF;
                cursor: pointer;
              }
            }
          }

          .lighting-popup-con-panel-tip {
            position: absolute;
            bottom: 0;
            height: 38px;
            width: 100%;
            background: #F6F8FD;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #616161;
            font-size: 12px;
          }
        }
      }


    }

    .lighting-popup-add-automtated-closeBtn {
      width: 30px;
      height: 30px;
      background: #FFFFFF;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.21);
      border-radius: 50%;
      position: absolute;
      right: -8px;
      top: -10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      i {
        font-size: 18px;
        color: #616161;
      }
    }

    .lighting-popup-dialog-footer {
      padding-bottom: 30px;
      padding-top: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .el-button {
        min-width: 89px;
      }

      .cancel-btn {
        min-width: 99px;
        height: 35px;
        background: #FFFFFF;
        border: 2px solid #E0E6F7;
        border-radius: 19px;
        padding: 0;
        line-height: 33px;
        border-radius: 19px;
        cursor: pointer;
      }

      .confirm-btn {
        height: 35px;
        min-width: 99px;
        background: #366AFF;
        padding: 0;
        line-height: 33px;
        border-radius: 19px;
        border: 2px solid #366AFF;
        margin-left: 10px;
        color: #fff;
        cursor: pointer;

        &.is-loading {
          border-color: #7aa3ff;
        }
      }

      .el-button--default {
        // height: 37px !important;
        border-radius: 17px;
      }

      .el-button--default:hover {
        border-color: #366AFF;
        color: #366AFF;
      }

      .el-button--primary {
        // height: 37px !important;
        border-radius: 17px;
        background-color: #366AFF;
      }

      .delete-footer {
        position: absolute;
        left: 64px;

        .delete-btn {
          width: 28px;
          height: 28px;
          background: #FFFFFF;
          border: 1px solid #E0E6F7;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

      }
    }
  }
}

</style>